import React from "react";
import { useField } from "formik";
import PropTypes from "prop-types";

import Input from "@sharedComponent/Input";

const CustomFormInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="input-and-error-wrapper">
      <div className="label">{label}</div>
      <Input margin="0px 0px 24px 0px" {...field} {...props} />
      {props.icon ? props.icon : null}
      {meta.touched && meta.error ? (
        <p className="error">{meta.error}</p>
      ) : null}
    </div>
  );
};

CustomFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default CustomFormInput;

import http from "./httpService";

const api = "/orders";

/**
 * Create order request.
 *
 * @param {General payload structure} payload
 * @returns
 */
export async function placeOrder(payload) {
  const { data } = await http.post(api, payload, {
    "Content-Type": "application/json"
  });
  return data;
}

/**
 * Update processed order.
 *
 * @param {Order ID} orderId
 * @param {General payload} payload
 * @returns
 */
export async function updateOrder({ orderId, ...payload }) {
  const { data } = await http.put(`${api}/${orderId}`, payload, {
    "Content-Type": "application/json"
  });

  return data;
}

/**
 * Fetch orders per store.
 *
 * @param {Query keys} param0
 * @returns
 */
export async function fetchOrders({ queryKey }) {
  const [_key, { storeId, page, size }] = queryKey;

  const { data } = await http.get(
    `${api}?storeId=${storeId}&cursor=${page}&size=${size}`
  );

  return data;
}

/**
 * Fetch order details.
 *
 * @param {Query key} param0
 * @returns
 */
export async function fetchOrderInfo({ queryKey }) {
  const [_key, { orderId }] = queryKey;

  const { data } = await http.get(`${api}/${orderId}`);

  return data;
}

/**
 * Mark order as shipped.
 *
 * @param {Order ID} orderId
 * @returns
 */
export async function markAsShipped(orderId) {
  const { data } = await http.post(`${api}/${orderId}/ship`);

  return data;
}

/**
 * Cancel an order.
 *
 * @param {General payload} payload
 * @returns
 */
export async function cancelOrder(payload) {
  const { data } = await http.post(
    `${api}/${payload.orderId}/cancel`,
    {
      reason: payload.reason
    },
    {
      "Content-Type": "application/json"
    }
  );

  return data;
}

/**
 * Mark order as paid.
 *
 * @param {General payload} payload
 * @returns
 */
export async function payOrder(payload) {
  const { data } = await http.put(
    `${api}/${payload.orderId}/pay`,
    {
      remarks: [payload.remarks]
    },
    {
      "Content-Type": "application/json"
    }
  );

  return data;
}

/**
 * Generate order invoice.
 *
 * @param {Query keys} param0
 * @returns
 */
export async function generateInvoice({ queryKey }) {
  const [_key, { orderId }] = queryKey;

  const { data } = await http.post(`${api}/${orderId}/invoice`);

  return data;
}

/**
 * Confirm checkout payment via reference.
 *
 * @param {Invoice reference} reference
 * @param {Invoice amount to be paid} amount
 * @returns
 */
export async function confirmPayment(payload) {
  const { data } = await http.post(`${api}/confirmCheckoutPayment`, payload);

  return data;
}

/**
 * Generate NUBAN.
 *
 * @param {Order Id} orderId
 * @returns
 */
export async function generateNuban(orderId) {
  const { data } = await http.post(`${api}/${orderId}/account-number`);

  return data;
}

/**
 * Check Payment Status.
 *
 * @param {Query key} param0
 * @returns
 */
export async function checkPaymentStatus({ queryKey }) {
  const [_key, { orderId }] = queryKey;

  const { data } = await http.get(`${api}/${orderId}/payment-status`);

  return data;
}

export async function checkNubanStatus({ queryKey }) {
  const [, { orderId }] = queryKey;

  const { data } = await http.get(`${api}/${orderId}/account-number-status`);

  return data;
}

export default {
  placeOrder,
  updateOrder,
  fetchOrders,
  fetchOrderInfo,
  markAsShipped,
  cancelOrder,
  payOrder,
  generateInvoice,
  confirmPayment,
  generateNuban,
  checkPaymentStatus,
  checkNubanStatus
};

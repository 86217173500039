import styled from "styled-components";

export const Tabs = styled.div`
  overflow: hidden;
  background: #ffffff;
  font-family: Open Sans;
  height: 1.5em;
  border-bottom: 1px solid #eaeaea;
`;

export const Tab = styled.span`
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-right: 1em;
  color: #2B3C45;

  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;

  border: ${props => (props.active ? "none" : "")};
  border-bottom: ${props => (props.active ? "4px solid #2B3C45" : "")};
  background-color: ${props => (props.active ? "none" : "none")};

  //height: ${props => (props.active ? "3em" : "2.6em; top:.4em")};
  transition: background-color 0.5s ease-in-out;

  :hover {
    background-color: #ffffff;
  }
`;

export const Content = styled.div`
  font-size: 16px;
  ${props => (props.active ? "" : "display:none")}
`;

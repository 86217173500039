import React from "react";
import styled from "styled-components";
import Icons from "@sharedComponent/Icons";
import PropTypes from "prop-types";

export default function LoadingState({ message }) {
  return (
    <Wrapper>
      <Icons.LogoIcon />
      <span className="message">{message}</span>
    </Wrapper>
  );
}

LoadingState.propTypes = {
  message: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    margin: 5px 0 0 0;
    letter-spacing: 1px;
    opacity: 0.5;
    font-family: "Open Sans";
    font-weight: 400;
  }

  .message {
    margin: 5px;
    opacity: 0.5;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 22px;

    @media screen and (max-width: 430px) {
      font-size: 16px;
    }
  }

  svg {
    width: 40px;
    margin-right: 10px;
    fill: #2b3c45 !important;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @media (prefers-reduced-motion: no-preference) {
    svg {
      animation: App-logo-spin infinite 5s linear;
    }
  }
`;

import React, { useState, useRef } from "react";
import Modal from "@sharedComponent/Modal";
import styled from "styled-components";

export default function SuccessModal({ isVisible, toggleModal }) {
  return (
    <Modal isVisible={isVisible} hideModal={toggleModal} title="Successful">
      <Content>
        <div className="content">
          <h2>Welcome, Business_name! Get verified by Paga</h2>
          <p>
            You are now ready to collect payments from your customers! Your
            current daily limit is ₦200,000. Please provide some more
            information to increase your limit.
          </p>
          <div className="content-row" style={{ display: "flex", gap: "16px" }}>
            <div className="btn left">Collect first payment</div>
            <div className="btn right">Increase transaction limit</div>
          </div>
        </div>
      </Content>
    </Modal>
  );
}

const Content = styled.div`
  .content{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    h2{
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      width:252px;
      color: #231F20;
      text-align:center;
      margin-top:156px;
    }
    p{
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #5F5457;
      text-align:center;
    }

    &-row{
      display:flex !important
      gap:16px !important;

      .btn{
        width:236px;
        padding: 16px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        border-radius:8px;
      }

      .left{
        background: #FEF2EC;
      }
      .right{
        background: #F26522;
      }
    }
  }
`;

import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import FeatherIcon from "feather-icons-react";

import notification from "@utils/notification";
import useModal from "@hooks/useModal";
import Modal from "@sharedComponent/Modal";

import AddressCard from "./Common/AddressCard";
import AddPickUpAddress from "./Common/AddPickUpAddress";

import storeApi from "@services/storeService";
import customerApi from "@services/customerService";

export default function PickupInfo() {
  const queryClient = useQueryClient();

  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);

  const { isVisible, toggleModal } = useModal();

  const { storeId } = useSelector(selectors.app);

  // fetch store pickup addresses
  const { isLoading, isError, data } = useQuery(
    ["pickup_address", { storeId }],
    storeApi.fetchStorePickUpAddress
  );

  /** Fetch countries */
  useQuery("countries", customerApi.fetchCountries, {
    initialData: () => {
      const cachedCountries = queryClient.getQueryData("countries");
      if (
        cachedCountries &&
        Date.now() - cachedCountries?.dataUpdatedAt <= 86400
      ) {
        setCountry(cachedCountries);
      }
    },
    onSuccess: res => {
      setCountry(res);
    }
  });

  /** Fetch regions */
  useQuery("regions", customerApi.fetchRegions, {
    initialData: () => {
      const cachedRegions = queryClient.getQueryData("regions");
      if (cachedRegions && Date.now() - cachedRegions?.dataUpdatedAt <= 86400) {
        setRegion(cachedRegions);
      }
    },
    onSuccess: res => {
      setRegion(res);
    }
  });

  /** Remove pickup address */
  const removePickupAddressMutation = useMutation(
    storeApi.removePickupAddress,
    {
      onSuccess: () => {
        queryClient.refetchQueries(["pickup_address", { storeId }]);

        notification.createNotify({
          title: "Success",
          message: "Pickup address deleted",
          type: "success",
          position: "top-right"
        });
      },
      onError: err => {
        notification.createNotify({
          title: "Error!",
          message: err?.response?.data?.message || "Server error",
          type: "danger",
          position: "top-right"
        });
      }
    }
  );

  return (
    <Container>
      <div className="header">
        <div className="title">Pickup Addresses</div>
        <div className="header_button">
          {/* eslint-disable-next-line */}
          <button
            variant="small_outline"
            role="button"
            aria-hidden="true"
            onClick={toggleModal}
          >
            Add pickup address
          </button>
        </div>
      </div>

      <div className="spacing" />

      <div className="addressContainer">
        {isLoading ? (
          <div className="SkeletonContainer">
            <Skeleton height={200} />
          </div>
        ) : !isLoading && isError ? (
          <motion.div className="no-orders">
            <FeatherIcon icon="alert-triangle" size="27" color="#f9a825" />
            <h5>Error! fetching pickup address</h5>
          </motion.div>
        ) : !isLoading && data?.length === 0 ? (
          <motion.div className="no-orders">
            <FeatherIcon icon="map-pin" size="27" />
            <h5>No pickup address</h5>
            <p>Recent pickup address will appear here</p>
          </motion.div>
        ) : !isLoading && data.length > 0 ? (
          <>
            {data.map(address => (
              <AddressCard
                key={address.addressId}
                pickup={address}
                storeId={storeId}
                onRemovePickupAddress={removePickupAddressMutation}
              />
            ))}
          </>
        ) : null}
      </div>

      <Modal
        isVisible={isVisible}
        hideModal={toggleModal}
        title="Add new pickup address"
        id="addPickUpAddress"
      >
        <AddPickUpAddress
          countries={country}
          regions={region}
          onClose={toggleModal}
        />
      </Modal>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 5em;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header_button {
    justify-content: flex-end;
  }

  .title {
    font-size: 20px;
    color: #1e242d;
    font-weight: 600;
    padding: 0;
    margin-right: 1em;
  }

  .caption {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    color: #1e242d;
    margin-top: 30px;
  }

  .spacing {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .no-orders {
    text-align: center;
    margin: 0 auto;
    margin-top: 4em;
    color: #d4d4d4;

    h5 {
      font-size: 20px;
      margin: 14px 0 0 0;
    }

    p {
      font-size: 14px;
      margin-top: 7px;
    }
  }

  .addressContainer {
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 0.5em;

    @media screen and (max-width: 400px) {
      flex-direction: column;
    }
  }

  .SkeletonContainer {
    background: #ffffff;
    border-radius: 4px;
    width: 380px;

    @media screen and (max-width: 384px) {
      width: 300px;
    }
  }

  @media screen and (max-width: 384px) {
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

import React from "react";
import { useFormik } from "formik";
import http from "../@services/httpService";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import { useMutation } from "react-query";
import notification from "@utils/notification";
import styled from "styled-components";

const api = "/sellers";

const ProfileForm = () => {
  const authentication = useSelector(selectors.authentication);

  async function updateUser(obj) {
    const filtered_obj = Object.entries(obj).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    );
    await http.put(`${api}/${authentication.sellerId}`, filtered_obj, {
      "Content-Type": "application/json"
    });
  }

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: res => {
      window.location.reload();
    },
    onError: err => {
      notification.createNotify({
        title: "Could not update user details",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: ""
    },
    onSubmit: (values, { setSubmitting }) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      setSubmitting(false);
      updateUserMutation.mutate(values);
    }
  });
  return (
    <Container>
      <form id="settings-form" onSubmit={formik.handleSubmit}>
        <div className="form">
          <div>
            <label htmlFor="firstName" className="py-1 my-2 d-block label-text">
              First Name
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              className="form-control2 form-control-sm my-1"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName" className="py-1 d-block my-2 label-text">
              Last Name
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              className="form-control2 form-control-sm my-1"
              // style={{display: 'none'}}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};

export default ProfileForm;

const Container = styled.div`
  width: auto;

  .form-group{
    margin-left: 40px;
    @media only screen and (max-width: 992px) {
      margin:0;
    }
  }

  .form-control2 {
    width: 250px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    
  }
  
  .label-text{
    height: 18px;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 500;
    color: #2b3c45;
    text-decoration: none solid rgb(43, 60, 69);
  }
  .form{
    display:flex;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
  }
}
`;

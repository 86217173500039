import React from "react";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function InvoiceItems({ orders }) {
  const { largeScreen } = useDetectScreenSize();

  const itemsTotal =
    orders.orderItems.length > 0
      ? orders.orderItems.reduce(
          (accumulator, item) => accumulator + item.unitPrice * item.quantity,
          0
        )
      : "";

  const shippingCost = orders.shippingFee;
  const totalCost = itemsTotal + shippingCost;

  return (
    <div className="invoiceOrderItems">
      <div className="title">Order items</div>

      <table
        className={
          largeScreen
            ? "table table-hover"
            : "table table-hover table-responsive"
        }
      >
        <thead>
          <tr>
            <th>Items</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {orders.orderItems.map(item => (
            <tr key={item.productId}>
              <td>
                <span className="table-data">{item.itemName}</span>
              </td>
              <td>
                <span className="table-data">{item.quantity}</span>
              </td>
              <td>
                <span className="table-data">
                  <AmountWithSymbol amount={item.unitPrice * item.quantity} />
                </span>
              </td>
            </tr>
          ))}

          <tr>
            <td>
              <span className="table-data">&nbsp;</span>
            </td>
            <td>
              <span className="table-data">
                <span className="shippingPrice">Shipping</span>
              </span>
            </td>
            <td>
              <span className="table-data">
                <span className="shippingPrice">
                  <AmountWithSymbol amount={shippingCost} />
                </span>
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span className="table-data">&nbsp;</span>
            </td>
            <td>
              <span className="table-data">
                <span className="totalPrice">Total</span>
              </span>
            </td>
            <td>
              <span className="table-data">
                <span className="totalPrice">
                  <AmountWithSymbol amount={totalCost} />
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      {/* <div className="costBreakDown">
        <div className="shippingCost">
          <span>Shipping</span>: N 850
        </div>
        <div className="totalCost">
          <span>Total</span>: N 3,850
        </div>
      </div> */}
    </div>
  );
}

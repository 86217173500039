import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function ItemsTable({ order }) {
  const { largeScreen } = useDetectScreenSize();

  const itemsTotal =
    order.orderItems.length > 0
      ? order.orderItems.reduce(
          (accumulator, item) => accumulator + item.unitPrice * item.quantity,
          0
        )
      : "";

  const shippingCost = order.shippingFee;

  const totalCost = itemsTotal + shippingCost;

  return (
    <Wrapper>
      <div className="title">Order items</div>

      <table
        className={largeScreen ? "table table-hover" : "table table-hover "}
      >
        <thead>
          <tr>
            <th>Items</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map(item => (
            <tr key={item.productId}>
              <td>
                <span className="table-data">{item.productName}</span>
              </td>
              <td>
                <span className="table-data">{item.quantity}</span>
              </td>
              <td>
                <span className="table-data">
                  <AmountWithSymbol amount={item.unitPrice * item.quantity} />
                </span>
              </td>
            </tr>
          ))}
          <tr>
            <td>&nbsp;</td>
            <td>
              <span className="caption">Items</span>
            </td>
            <td>
              <span className="figure">
                <AmountWithSymbol amount={itemsTotal} />
              </span>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <span className="caption">Shipping</span>
            </td>
            <td>
              <span className="figure">
                <AmountWithSymbol amount={shippingCost} />
              </span>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <span className="caption">Total</span>
            </td>
            <td>
              <span className="figure">
                <AmountWithSymbol amount={totalCost} />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
}

ItemsTable.propTypes = {
  order: PropTypes.object.isRequired
};

const Wrapper = styled.div`
  margin-top: 10px;
  width: 100%;

  .caption {
    font-size: 14px;
  }

  .figure {
    font-size: 14px;
    font-weight: 800;
  }

  .item-order--cost {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    margin-bottom: 0.7rem;
    margin-right: 17rem;

    .items-cost {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 1.8;
      gap: 8rem;
    }

    .shipping-cost {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 1.8;
      gap: 8rem;
    }

    .total-cost {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: 500;
      line-height: 1.8;
      gap: 8rem;
    }
  }

  .title {
    color: #2b3c45;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .table-data {
    color: #080808;
    font-size: 14px;
    font-family: Open Sans;
    display: flex;

    .qty-input {
      width: 52px;
      height: 32px;
      margin-right: 4px;
    }

    .delete-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .table th,
  .table td {
    padding: 0.7rem;
    text-align: left;
    border-bottom: 1px solid #eceeef;
  }

  .table thead th {
    font-size: 14px;
    font-weight: 500;
    vertical-align: bottom;
  }

  .table tbody + tbody {
    border-top: 2px solid #eceeef;
  }

  .table .table {
    background-color: #fff;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive.table-bordered {
    border: 0;
  }

  @media only screen and (max-width: 400px) {
    .item-order--cost {
      display: flex;

      font-size: 14px;
      margin-bottom: 2rem;

      .items-cost {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        margin: 0 auto;
        gap: 9rem;
      }

      .shipping-cost {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        margin: 0 auto;
        gap: 9rem;
      }

      .total-cost {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        margin: 0 auto;
        gap: 9rem;
        font-weight: 500;
        line-height: 1.8;
      }
    }
  }
`;

import React from "react";
import _ from "lodash";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";

import ActionHeader from "./Common/ActionHeader";
import NoWithdrawal from "./NoWithdrawal";
import Withdrawals from "./Withdrawals";

import { settlementActions } from "./Settlement.state";

import walletApi from "@services/walletService";

export default function Settlement() {
  const dispatch = useDispatch();

  const { businessId, walletId } = useSelector(selectors.app);

  const bankAccountId = "";
  const page = "";
  const size = 10;

  /** Fetch withdrawals linked to business account */
  const { isLoading, isError, data } = useQuery(
    ["withdrawals", { bankAccountId, businessId, page, size }],
    walletApi.fetchWithdrawals,
    {
      onSuccess: res => {
        dispatch(settlementActions.setWithdrawals(res));
      }
    }
  );

  /** Fetch wallet balance */
  useQuery(["walletBalance", { walletId }], walletApi.fetchWalletBalance, {
    onSuccess: res => {
      dispatch(settlementActions.setAvailableBalance(res?.availableBalance));
    }
  });

  if (isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message={"Error fetching withdrawals"} />
        </SlideToTop>
      </Loader>
    );
  }

  return (
    <Container>
      <ActionHeader title="Withdrawals" action="withdraw" />
      <div data-testid="withdraw-el" />

      {isLoading ? (
        <div className="SkeletonContainer">
          <Skeleton height={40} count={5} />
        </div>
      ) : !isLoading && data?.length > 0 ? (
        <Withdrawals withdrawals={data} />
      ) : (
        <NoWithdrawal />
      )}
    </Container>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1.5em;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  .SkeletonContainer {
    padding: 25px;
    background: #ffffff;
    border-radius: 4px;
  }
`;

import * as React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

export default function Modal({
  isVisible,
  hideModal,
  title,
  children,
  id,
  width
}) {
  return isVisible
    ? createPortal(
        <React.Fragment>
          <DModalOverlay />
          <DModalWrapper
            aria-modal={true}
            aria-hidden={true}
            tabIndex={-1}
            role="dialog"
            id={id}
          >
            <DModal width={width}>
              <DHeader>
                <DTitle>{title ? title : `Modal Title`}</DTitle>
                <DClose onClick={hideModal}>
                  <FeatherIcon icon="x" />
                </DClose>
              </DHeader>

              <DBody>{children}</DBody>
            </DModal>
          </DModalWrapper>
        </React.Fragment>,
        document.body
      )
    : null;
}

const DModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 500;
`;

const DModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 15%;
  width: 100%;
  z-index: 1000;
`;

const DModal = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  //margin: 1.875rem;
  padding: 0 12px;
  width: ${({ width }) => (width ? `${width}px` : "540px")};
  position: relative;
  z-index: 100;

  @media only screen and (max-width: 400px) {
    width: 100%;
    height: 100vh;
  }
`;

const DHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
`;

const DTitle = styled.h5`
  color: #2b3c45;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
`;

const DClose = styled.span`
  color: #2b3c45;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
`;

const DBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  /* margin-bottom: 1.5em; */
`;

import http from "./httpService";
import helpers from "@utils/helpers";

const businessKeyName = helpers.getBusinessKeyName();

export function setBusinessId(businessId) {
  localStorage.setItem(businessKeyName, businessId);
}

export function getBusinessId() {
  return localStorage.getItem(businessKeyName);
}

export default {
  setBusinessId,
  getBusinessId
};

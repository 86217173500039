import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import PropTypes from "prop-types";
import styled from "styled-components";
import orderStyles from "../Orders.styles";
import { orderActions } from "../Orders.state";

import OrderItems from "./OrderItems";
import CustomerInfo from "./CustomerInfo";
import DeliveryInfo from "./DeliveryInfo";

const EmptyStateList = ({ message }) => {
  return <section className="empty-state-list">{message}</section>;
};

export default function AddOrder() {
  const dispatch = useDispatch();

  const { isEditMode, orderRequest } = useSelector(selectors.orders);

  useEffect(() => {
    if (isEditMode) {
      dispatch(orderActions.clearOrderRequest());
    }
  }, []);

  return (
    <Wrapper>
      <h4 className="new-order">New order</h4>

      <OrderItems />

      <CustomerInfo />

      {Object.keys(orderRequest.customer).length > 0 ? (
        <DeliveryInfo />
      ) : (
        <EmptyStateList message="Delivery info" />
      )}

      {/* <DeliveryInfo /> */}
    </Wrapper>
  );
}

EmptyStateList.propTypes = {
  message: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  padding: 25px 15px;
  box-sizing: border-box;
  margin-bottom: 20rem;

  .new-order {
    ${orderStyles.title}
  }

  .empty-state-list {
    margin: 16px 0px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    color: #d2d2d2;
    font-size: 20px;
    font-family: Montserrat;
    padding: 17px 30px;
    box-sizing: border-box;
  }
`;

import React from "react";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

export default function Timeline({ loading, order }) {
  const formatText = str => {
    return str.replace(/_/g, " ").toLowerCase();
  };

  return (
    <Wrapper>
      <div className="title">Order timeline</div>

      <ul className="timeline">
        {loading && (
          <>
            <li>
              <Skeleton height={50} width={200} />
            </li>
            <li>
              <Skeleton height={50} width={200} />
            </li>
            <li>
              <Skeleton height={50} width={200} />
            </li>
          </>
        )}
        {!loading && order.orderEvents.length > 0 && (
          <>
            {order.orderEvents.map(events => (
              <li key={events.eventDate}>
                <span className="caption">
                  Order #{order.orderNumber}: {formatText(events.eventType)}
                </span>
                <br />
                <span className="date">
                  {moment(events.eventDate).format("LLL")}
                </span>
                <p className="message">
                  {events.remarks && <span>{events.remarks[0]}</span>}
                </p>
              </li>
            ))}
          </>
        )}
      </ul>
    </Wrapper>
  );
}

Timeline.propTypes = {
  loading: PropTypes.bool.isRequired,
  order: PropTypes.object
};

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 10px;

  .title {
    color: #2b3c45;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 1em;
  }

  ul.timeline {
    font-size: 14px;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
  }

  li {
    padding-bottom: 1.5rem;
    border-left: 2px solid #d4d9df;
    position: relative;
    padding-left: 20px;
    margin-left: 27px;
    &:last-child {
      border: 0px;
      padding-bottom: 0;
    }
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      background: white;
      border: 2px solid #000000;
      border-radius: 50%;
      position: absolute;
      left: -8px;
      top: 0px;
    }
  }

  .caption {
    color: #000;
    font-weight: 500;
    line-height: 1.5;
  }

  .date {
    line-height: 1.5;
    color: #a9a9a9;
  }

  .message {
    line-height: 1.5;
  }
`;

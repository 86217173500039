import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";
import FormWrapper from "../FormWrapper";
import AuthWrapper from "../AuthWrapper";
import CustomFormInput from "@sharedComponent/CustomFormInput";
import RadioInput from "@sharedComponent/RadioInput";
import customerApi from "@services/customerService";
import RadioButton from "@sharedComponent/RadioButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { authenticationActions } from "@App/Auth/Login/login.state";
import { actions } from "@App/App.state";
import notification from "@utils/notification";
import helpers from "@utils/helpers";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { RenderTextArea, RenderSelect } from "@sharedComponent/FormElements";
import useModal from "@hooks/useModal";
import Modal from "@sharedComponent/Modal";
import SuccessModal from "./SuccessModal";

import seller from "@services/sellerService";

export default function BusinessAddress({ regions }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isVisible, toggleModal } = useModal();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [LGA, setLGA] = useState([]);

  const goBack = () => {
    history.push("/signup");
  };

  const onChangeValue = event => {
    console.log(event.target.value);
  };

  const submitBusinessAddressMutation = useMutation(
    seller.updateSellerAndCreateBusiness,
    {
      onSuccess: res => {
        dispatch(actions.setBusinessId(res.businessId));
        dispatch(actions.setStoreId(res.storeId));
        dispatch(actions.setSellerId(res.sellerId));
        dispatch(actions.setWalletId(res.accountId));
        //toggleModal()
        history.push("/");
      },
      onError: err => {
        notification.createNotify({
          title: "Operation error",
          message: err.response.data.message || "Server error",
          type: "danger",
          position: "top-right"
        });
      }
    }
  );

  const selectOptions = [
    // {key:0, value:'Select Business Type'},
    { key: 1, value: "One" },
    { key: 2, value: "Two" },
    { key: 3, value: "Three" }
  ];

  const onSubmitBusinessInfo = values => {
    console.log(values);
  };

  const handleRegionChange = async value => {
    // const selectedRegionObj = regions.find(region => region.name === value);

    // const fetchLGA = await customerApi.fetchLgaByRegionId(selectedRegionObj.id);

    // const filteredLGA = fetchLGA.map(param => {
    //   const container = {};
    //   container.key = param.id;
    //   container.value = param.name;

    //   return container;
    // });

    // setLGA(filteredLGA);
    console.log(value);
  };

  const submitBusinessAddress = values => {
    history.push("./bvn");
    //toggleModal();
    // submitBusinessAddressMutation.mutate(values);
    console.log(values);
  };

  const handleBusinessCategoryChange = value => {
    console.log(value);
  };

  const handleCityChange = value => {
    console.log(value);
  };

  return (
    <Container>
      {/* <Modal
          isVisible={isVisible}
          hideModal={toggleModal}
          title="Successful"
        >
          <div>
            <div className="content">
              <h2>
                Welcome, Business_name!
                Get verified by Paga
              </h2>
            </div>
          </div>
          <h3>Successfully registered</h3>
        </Modal> */}
      <SuccessModal isVisible={isVisible} toggleModal={toggleModal} />

      <div className="left">
        <div className="navigate" onClick={goBack}>
          <ArrowBackIcon />
          Back
        </div>
      </div>
      <FormWrapper
        title="Business Address"
        subTitle="Please tell us where your business is located"
      >
        <Formik
          enableReinitialize
          initialValues={{
            state: "",
            LGA: "",
            city: "",
            streetAddress: ""
          }}
          validationSchema={Yup.object({
            state: Yup.string().required("Feld cannot be empty"),
            LGA: Yup.string().required("Field cannot be empty"),
            city: Yup.string().required("Field cannot be empty"),
            streetAddress: Yup.string().required("Field cannot be empty")
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            submitBusinessAddress(values);
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="form-row">
                <div className="form-row-col">
                  <RenderSelect
                    placeholder="Select a state"
                    label="State"
                    name="state"
                    options={selectOptions}
                    showemptyoptionfirst={1}
                    onChange={e => {
                      setFieldValue("state", e.target.value);
                      handleRegionChange(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="form-row-col">
                  <RenderSelect
                    placeholder="Select LGA"
                    label="LGA"
                    name="LGA"
                    options={selectOptions}
                    showemptyoptionfirst={1}
                    onChange={e => {
                      setFieldValue("LGA", e.target.value);
                      handleBusinessCategoryChange(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>

              <RenderSelect
                placeholder="City"
                label="City"
                name="city"
                options={selectOptions}
                showemptyoptionfirst={1}
                style={{ marginBottom: "20px" }}
                onChange={e => {
                  setFieldValue("city", e.target.value);
                  handleCityChange(e.currentTarget.value);
                }}
              />

              <CustomFormInput
                type="text"
                placeholder="Type your street address"
                name="streetAddress"
                id="streetAddress"
                data-testid="streetAddress"
                label="Street Address"
              />

              <div className="submitContainer">
                <motion.input
                  type="submit"
                  value={
                    submitBusinessAddressMutation.isLoading
                      ? "Just a moment..."
                      : "Next"
                  }
                  className="submit-button"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                  data-testid="submit-form"
                />
              </div>
              <p className="form-bottom">
                By clicking Register, you agree to our <Link to="">Terms </Link>
                and <Link to="">Privacy Policy</Link>.
              </p>
            </Form>
          )}
        </Formik>
      </FormWrapper>
      <div className="right"></div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }

  .left,
  .right {
    width: 25%;
  }

  .left {
    position: relative;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &-col {
      width: 48%;
    }
  }

  select {
    width: 100%;
    height: 48px;
    border: 1px solid #d1d1d1;
    background-color: #f8f7f7;
  }

  textarea {
    background-color: #f8f7f7;
    margin-top: 0 !important;
  }

  .navigate {
    position: absolute;
    right: 0;
    top: 12%;
    cursor: pointer;
  }

  .submitContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type="submit"] {
      background: #2b3c45;
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      width: 100%;
      padding: 16px auto;
      border-radius: 8px;
    }
  }

  .input-and-error-wrapper {
    position: relative;

    svg {
      position: absolute;
      top: 35% !important;
      right: 3%;
    }

    label {
      display: flex;

      .radio-field {
        display: flex;
        align-items: center;
        margin-right: 16px;

        input {
          border: 1px solid #d1d1d1;
        }

        &-label {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #231f20;
        }
      }
    }
  }

  .radio {
    border: 1px solid #e8e8e8 !important;
    color: #e8e8e8;
    background: #e8e8e8;
    accent-color: #231f20;
    margin-right: 2px;
  }
`;

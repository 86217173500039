import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";

import Table from "@sharedComponent/Table";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function Withdrawals({ withdrawals }) {
  const columns = [
    {
      path: "date",
      label: "Date",
      content: withdrawal => (
        <div>{moment(withdrawal.createdDate).format("LLL")}</div>
      )
    },
    {
      path: "amount",
      label: "Withdrawn amount",
      content: withdrawal => (
        <>
          <AmountWithSymbol amount={withdrawal.amount} />
        </>
      )
    },

    {
      path: "to",
      label: "To",
      content: withdrawal => (
        <div>
          {withdrawal.bankDetails.bankAccountName} (
          {withdrawal.bankDetails.bankAccountNumber})
        </div>
      )
    },
    {
      path: "balance",
      label: "Balance",
      content: withdrawal => (
        <>
          <AmountWithSymbol amount={withdrawal.balanceAfterWithdrawal} />
        </>
      )
    }
  ];

  return (
    <Wrapper>
      <div className="settlementsTableWrapper">
        <Table columns={columns} data={withdrawals} />
      </div>
    </Wrapper>
  );
}

Withdrawals.propTypes = {
  withdrawals: PropTypes.array.isRequired
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  width: 100%;

  .settlementsTableWrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12rem;
    box-sizing: border-box;
    overflow: hidden;
  }
`;

import React from "react";
import styled from "styled-components";
import AuthStyles from "@App/Auth/Auth.styles";
import { motion } from "framer-motion";
import Icons from "@sharedComponent/Icons";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

export default function AuthWraper({
  title,
  subTitle,
  IconTitle,
  redirectLink,
  children
}) {
  return (
    <Wrapper>
      <motion.div
        className="auth-wrapper"
        initial={{ opacity: 0, height: "90%" }}
        animate={{ opacity: 1, height: "100%" }}
        transition={{ duration: 0.7 }}
      >
        <NavLink to="/landing" className="signup-icon">
          <Icons.DorokiBrandBlue />
        </NavLink>
        <div className="title">
          {IconTitle && (
            <div className="titleIcon">
              <Icons.CheckMark />
              <h1>{IconTitle}</h1>
            </div>
          )}
          <h1>{title}</h1>
          {redirectLink && (
            <NavLink to={redirectLink?.url}>{redirectLink?.name}</NavLink>
          )}
        </div>
        {subTitle && <div className="reset-password-text">{subTitle}</div>}
        <div className="auth-form-wrapper">{children}</div>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${AuthStyles}
  .signup-icon {
    display: flex;
    justify-content: center;
  }

  .forgot-password-link {
    position: absolute;
    margin-top: -8px;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0px;
  }

  .submitAndErrorContainer {
    margin-top: 40px;

    .submit-error {
      color: #da1a1a;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 400px) {
    .submitAndErrorContainer {
    }
  }
`;

AuthWraper.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  IconTitle: PropTypes.string,
  redirectLink: PropTypes.object,
  children: PropTypes.node
};

import React, { useState } from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import rounded from "./Rounded.svg";
import { styled as newStyle } from "@mui/material/styles";
import IconBtn from "./IconBtn";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    <img src={rounded} />,
    "Pilea Peperomiodes - chinese money plant",
    6.0,
    24,
    4.0
  ),
  createData(
    <img src={rounded} />,
    "Pilea Peperomiodes - chinese money plant",
    9.0,
    37,
    4.3
  ),
  createData(
    <img src={rounded} />,
    "Pilea Peperomiodes - chinese money plant",
    16.0,
    25,
    6.0
  ),
  createData(
    <img src={rounded} />,
    "Pilea Peperomiodes - chinese money plant",
    3.7,
    67,
    4.3
  ),
  createData(
    <img src={rounded} />,
    "Pilea Peperomiodes - chinese money plant",
    16.0,
    49,
    3.9
  ),
  createData(
    <img src={rounded} />,
    "Pilea Peperomiodes - chinese money plant",
    16.0,
    99,
    9.9
  )
];

const StyledTableCell = newStyle(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

export default function ProductTable() {
  return (
    <Wrapper>
      <div className="table">
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, border: "none" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="table-header">Product</TableCell>
                <TableCell align="left" className="table-header">
                  Stock
                </TableCell>
                <TableCell align="left" className="table-header">
                  Created
                </TableCell>
                <TableCell align="left" className="table-header">
                  Price
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow
                  key={row.carbs}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className="table-body"
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.calories}</TableCell>
                  <TableCell align="left">{row.fat}</TableCell>
                  <TableCell align="left" className="price">
                    <AmountWithSymbol amount={row.carbs} />
                  </TableCell>
                  <TableCell align="left">
                    <IconBtn />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  background: #ffffff;
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding-top: 30px;
  width: 100%;

  .table {
    padding: 0 20px;

    &-body {
      color: #2b3c45;
      font-size: 16px;
      text-decoration: none solid rgb(43, 60, 69);
      line-height: 24px;
    }
  }

  .table-header {
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
    color: #2b3c45;
    line-height: 24px;
    text-decoration: none solid rgba(43, 60, 69, 0.5);
    line-height: 24px;
    opacity: 50%;
  }
  .MuiPaper-root {
    border: none !important;
  }

  .MuiTableCell-root {
    font-size: 16px;
    font-family: "Ubuntu", sans-serif;
    line-height: 24px;
    color: #2b3c45;
    font-weight: 300;
    img {
      width: 53px;
      height: 41px;
      border-radius: 2px;
      fill: #dadada;
    }
  }

  .price {
    font-size: 18px;
  }
  .productTableWrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8em;
    margin-bottom: 4em;
    box-sizing: border-box;
    overflow: hidden;
  }
`;

import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Button from "@sharedComponent/Button";

export default function NotFoundPage() {
  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <Wrapper>
      <motion.div
        className="content-wrapper"
        initial={{ opacity: 0, height: "90%" }}
        animate={{ opacity: 1, height: "100%" }}
        transition={{ duration: 0.7 }}
      >
        <div className="errorWrapper">
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>
            The page you are looking for does not exist. Either you typed a
            wrong URL, or you followed a bad link.
          </p>
          <Button className="btn" onClick={goBack}>
            Go Back
          </Button>
        </div>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex !important;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 384px) {
    width: 100vw;
    height: 100vh;
  }

  .content-wrapper {
    display: flex;
    height: 100vh !important;
  }

  .errorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2b3c45;

    h1 {
      width: 606px;
      font-family: Ubuntu;
      font-size: 108px;
      text-decoration: none solid rgba(255, 255, 255, 0.8);
      text-align: center;
      margin: 0;

      @media only screen and (max-width: 384px) {
        width: 300px;
        font-size: 58px;
      }
    }
    h2 {
      width: 606px;
      font-family: Ubuntu;
      font-size: 48px;
      color: #2b3c45;
      text-decoration: none solid rgba(255, 255, 255, 0.8);
      text-align: center;
      margin-top: 12px;

      @media only screen and (max-width: 384px) {
        width: 300px;
        font-size: 38px;
      }
    }
    p {
      font-family: "Noto Sans", sans-serif;
      width: 570px;
      font-family: Noto Sans;
      font-size: 24px;
      font-weight: 300;
      color: #2b3c45;
      text-decoration: none solid rgba(255, 255, 255, 0.8);
      text-align: center;
      margin-top: 59px;

      @media only screen and (max-width: 384px) {
        width: 300px;
        font-size: 18px;
        margin-top: 39px;
      }
    }
  }
  .btn {
    width: 371px;
    height: 57px;
    border-radius: 5px;
    background-color: #2b3c45;
    background-size: cover;
    font-family: Ubuntu;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    margin-top: 74px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 384px) {
      width: 200px;
      font-size: 14px;
      margin-top: 29px !important;
    }
  }
`;

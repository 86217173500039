import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "configureStore";
import { useHistory, useLocation, Route, Redirect } from "react-router";
import { authenticationActions } from "@App/Auth/Login/login.state";
import helpers from "@utils/helpers";

/**
 * protects each url navigation that needs authentication
 * it checks if the user is authenticated and redirects
 * to login if user is not authenticated
 * its a route guard for each routes
 */
export default function ProtectedRoute({ children, ...rest }) {
  const dispatch = useDispatch();
  /* get authentication data from redux store */
  const authentication = useSelector(selectors.authentication);
  const history = useHistory();
  const locationPath = useLocation();

  useEffect(() => {
    /**
     * if the user info is not found in the redux store mostly
     * happens on page reload or redirect from a link
     */
    if (!authentication.emailAddress) {
      // get the user data from local storage which is saved on login
      const token = localStorage.getItem(helpers.getAuthTokenName());

      // if token exists
      if (token) {
        /**
         * 1 - set the redux store data with the data from storage
         * 2 - set the authorization token data from the storage data
         * 3 - pass the user to the url requested
         */
        dispatch(
          authenticationActions.setEmailAndToken({
            token
          })
        );
        // http.setHeader("Authorization", `Bearer ${token.access_token}`);
        // http.intercept.authorize(history);
        history.push(locationPath.pathname);
      }
    }
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        /** this render props represents
         * if user data is found redirect to the requested path
         * else redirect to login
         */
        return authentication.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
}

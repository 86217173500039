import React, { useState } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";

import useModal from "@hooks/useModal";
import Modal from "@sharedComponent/Modal";

import DeletePickupAddress from "./DeletePickupAddress";

export default function AddressCard({
  pickup,
  storeId,
  onRemovePickupAddress
}) {
  const [showDeleteAddressModal, setShowDeleteAddressModal] = useState(false);
  const { isVisible, toggleModal } = useModal();

  const values = {
    storeId: storeId,
    addressId: pickup.addressId
  };

  const handleDeleteAddress = () => {
    setShowDeleteAddressModal(true);
    toggleModal();
  };

  return (
    <AddressWrapper>
      <Address>
        <Caption>Address:</Caption>

        <StreetAddress>{pickup.streetAddress}</StreetAddress>

        <City>{pickup.city}</City>

        <Region>
          {pickup.localGovernmentArea}, {pickup.region}
        </Region>

        <Caption>Note:</Caption>
        <Note>{pickup.remarks}</Note>
      </Address>

      <Footer>
        <span
          className="deleteBtn"
          onClick={() => handleDeleteAddress()}
          aria-label="Remove"
        >
          <FeatherIcon icon="trash" size={18} />
        </span>
      </Footer>

      {showDeleteAddressModal && (
        <Modal
          isVisible={isVisible}
          hideModal={toggleModal}
          title="Delete this pickup address"
          id="removePickupAddress"
        >
          <DeletePickupAddress
            values={values}
            onClose={toggleModal}
            onRemovePickupAddress={onRemovePickupAddress}
          />
        </Modal>
      )}
    </AddressWrapper>
  );
}

AddressCard.propTypes = {
  pickup: PropTypes.object.isRequired,
  storeId: PropTypes.string.isRequired,
  onRemovePickupAddress: PropTypes.object
};

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-sizing: border-box;
  // flex-basis: 50%;
  width: 100%;
  max-width: 380px;

  // max-width: 50%;
  // min-width: 50%;
  // width: 50%;

  // @media (min-width: 40rem) {
  //   width: 320px;
  // }
  // @media (min-width: 56rem) {
  //   width: 320px;
  // }

  // @media screen and (max-width: 400px) {
  //   max-width: 100%;
  //   min-width: 100%;
  //   width: 100%;
  // }
`;

const Address = styled.div`
  text-align: left;
  padding: 16px;
  flex-grow: 1;
  color: #75757a;
  display: block;
`;

const Caption = styled.div`
  color: #060606;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 4px;
  text-align: left;
`;

const StreetAddress = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

const City = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

const Region = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding-bottom: 4px;
`;

const Note = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

const Footer = styled.div`
  color: #2b3c45;

  padding: 8px;
  align-items: center;
  display: flex;
  border-top: 1px solid #ededed;
  box-sizing: border-box;
  justify-content: flex-end;

  .deleteBtn {
    cursor: pointer;
  }
`;

import React from "react";
import styled from "styled-components";

export default function PreviewImages({ productImages }) {
  return (
    <Wrapper className="formContainer">
      <div className="formTitle">Preview product image(s)</div>

      {productImages.length > 0 ? (
        <div className="imageContainer">
          {productImages.map(image => (
            <div key={image.imageUrl} className="thumb">
              <img src={image.imageUrl} />
            </div>
          ))}
        </div>
      ) : (
        <div className="noImage">
          <h5>No image(s) for this product</h5>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 1em;

  .imageContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    padding: 0;
  }

  .thumb {
    width: 150px;
    height: 150px;
    overflow: hidden;
  }

  .thumb img {
    width: 120px;
    height: 120px;
    border: solid 1px #ffffff;
  }

  .noImage {
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

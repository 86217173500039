import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";

import notification from "@utils/notification";
import helpers from "@utils/helpers";
import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";
import LoadingState from "@sharedComponent/LoadingState";
import { orderActions, handleLoadOrderRequestDetails } from "../Orders.state";
import { productActions } from "../../Products/Products.state";
import order from "@services/orderService";
import product from "@services/productService";

import EditCustomer from "./EditCustomer";
import EditShipping from "./EditShipping";
import EditOrderItems from "./EditOrderItems";

const validationSchema = Yup.object({
  shippingType: Yup.string().required("Required"),
  shippingFee: Yup.number().required("Required")
});

export default function EditOrder() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const orderId = params.id;

  const { orderRequest } = useSelector(selectors.orders);

  const customerId = orderRequest.customer.customerId;
  const addressId = orderRequest.shipping.shippingAddressId;

  useEffect(() => {
    dispatch(orderActions.setEditMode());
  }, []);

  /** Fetch order details wih order id */
  const { isLoading, isError, error, data } = useQuery(
    ["order_detail", { orderId }],
    order.fetchOrderInfo,
    {
      refetchOnWindowFocus: false,
      initialData: () => {
        const cachedData = queryClient.getQueryData([
          "order_detail",
          { orderId }
        ]);

        if (cachedData && Date.now() - cachedData.dataUpdatedAt <= 86400) {
          dispatch(handleLoadOrderRequestDetails(cachedData));
        }
      },
      onSuccess: res => {
        dispatch(handleLoadOrderRequestDetails(res));
      }
    }
  );

  const storeId = localStorage.getItem(helpers.getStoreKeyName());
  const size = 10;
  const [page, setPage] = useState("");

  /** Fetch store products to populate search bar */
  const fetchProducts = useQuery(
    ["products", { storeId, page, size }],
    product.fetchProductsPerStore,
    {
      initialData: () => {
        const cachedProducts = queryClient.getQueryData("products");
        if (
          cachedProducts &&
          Date.now() - cachedProducts.dataUpdatedAt <= 86400
        ) {
          dispatch(productActions.setProducts(cachedProducts));
        }
      },
      onSuccess: res => {
        dispatch(productActions.setProducts(res));
      }
    }
  );

  /** Update order query */
  const updateOrderMutation = useMutation(order.updateOrder, {
    onSuccess: res => {
      notification.success("Order updated", "Order has been updated");
      history.push(`/orders/view/${res.orderId}`);
    },
    onError: err => {
      notification.createNotify({
        title: "Error updating order",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    },
    onSettled: (data, error) => {
      if (!error) dispatch(orderActions.clearOrderRequest()); // clear from state
    }
  });

  const goBack = () => {
    history.push("/orders");
  };

  const onSubmitUpdateOrder = values => {
    const payload = {
      orderItems: [...orderRequest.items].map(obj => ({
        productId: obj.productId,
        quantity: obj.quantity
      })),
      customerId: orderRequest.customer.customerId,
      contactId: orderRequest.customer.contactId,
      shippingLocationId: orderRequest.shipping.shippingAddressId,
      shippingType: values.shippingType,
      shippingFee: values.shippingFee
    };

    updateOrderMutation.mutate({ ...payload, orderId });
  };

  if (isLoading && fetchProducts.isLoading) {
    return (
      <Loader>
        <SlideToTop>
          <LoadingState message="loading..." />
        </SlideToTop>
      </Loader>
    );
  }

  if (isError || fetchProducts.isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message={error.message ?? fetchProducts.error.message} />
        </SlideToTop>
      </Loader>
    );
  }

  return (
    <Wrapper>
      <div className="navigation" onClick={goBack}>
        <FeatherIcon icon="chevron-left" size="24" /> Back
      </div>

      <div className="heading">
        <div className="title">Edit Order</div>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          shippingType: orderRequest.shipping.shippingOption || "",
          shippingFee: orderRequest.shipping.shippingCost || ""
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmitUpdateOrder(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="itemsTableContainer">
              <EditOrderItems />
            </div>

            <div className="cardContainer">
              <EditCustomer />
              <EditShipping
                setFieldValue={setFieldValue}
                customerId={customerId}
                addressId={addressId}
              />
            </div>

            <div className="submitActionContainer">
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                type="submit"
                className="action-button"
                disabled={updateOrderMutation.isLoading}
              >
                {updateOrderMutation.isLoading ? "Updating..." : "Update order"}
              </motion.button>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin-top: 16rem;
`;

const Wrapper = styled.div`
  font-family: "Open Sans";
  margin-top: 2rem;
  padding-bottom: 8em;

  .navigation {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    color: #2b3c45;
    font-weight: 500;
    padding: 0 12px;
    cursor: pointer;
    width: 100px;

    svg {
      margin-right: 2px;
    }
  }

  .heading {
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
    margin-bottom: 1rem;
    padding: 0 12px;
  }

  .title {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    color: #2b3c45;
  }

  .cardContainer {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
  }

  .itemsTableContainer {
    margin-left: 16px;
    margin-right: 16px;
    padding: 12px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-top: 25px;
  }

  .submitActionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
  }

  @media only screen and (max-width: 384px) {
    .cardContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 12px;
    }
  }
`;

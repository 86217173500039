import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import _ from "lodash";
import styled from "styled-components";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import helpers from "@utils/helpers";
import buttons from "@styles/buttons";

import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";
import ProductsEmptyState from "./ProductsEmptyState";
import ProductsList from "./ProductsList/ProductsList";
import ProductCard from "./ProductsList/ProductCard";
import ProductSearchBox from "./ProductsList/ProductSearchBox";
import product from "@services/productService";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import ProductTable from "./ProductsList/ProductTable";
import rounded from "../Products/ProductsList/Rounded.svg";

const useStyles = makeStyles({
  button: {
    color: "#ffffff",
    background: "#2b3c45",

    "&:hover": {
      background: "#2b3c45",
      color: "#ffffff"
    }
  }
});

export default function ProductsHome() {
  const classes = useStyles();
  const { largeScreen } = useDetectScreenSize();

  const storeId = localStorage.getItem(helpers.getStoreKeyName());
  const size = "";

  const [page] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [, setStoreProducts] = useState([]);

  const { isLoading, isError, data } = useQuery(
    ["products", { storeId, page, size }],
    product.fetchProductsPerStore,
    {
      onSuccess: res => {
        setStoreProducts(res);
      }
    }
  );

  const onChangeSearchValue = query => {
    setSearchQuery(query);
  };

  let filteredProducts = [];

  if (!isLoading && data) {
    filteredProducts = data.filter(product => {
      return (
        product.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
      );
    });
    console.log(filteredProducts);
  }

  if (isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message={"Error fetching products"} />
        </SlideToTop>
      </Loader>
    );
  }

  return (
    <Wrapper
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="header">
        <div className="title">
          <div className="caption" data-testid="product-el">
            <h2>Products</h2>
          </div>

          {/* <NavLink to="/products/add"> */}
          <Button
            className="btn"
            variant="contained"
            startIcon={<AddIcon />}
            href="/products/add"
          >
            Add Product
          </Button>
          {/* </NavLink> */}
        </div>

        {/* {!isLoading && data > 0 && ( */}
        <div className="searchBox">
          <ProductSearchBox
            value={searchQuery}
            onChange={onChangeSearchValue}
          />
        </div>
        {/* )} */}
      </div>

      {isLoading && !data && (
        <div className="SkeletonContainer">
          <Skeleton height={40} count={5} />
        </div>
      )}

      {!isLoading && data > 0 && (
        <>
          <ProductTable />
        </>
      )}

      {!isLoading && data && (
        <>
          {data.length > 0 ? (
            <>
              {largeScreen && <ProductsList products={filteredProducts} />}
              {!largeScreen && <ProductCard products={filteredProducts} />}
            </>
          ) : (
            <ProductsEmptyState />
          )}
        </>
      )}
    </Wrapper>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;

  @media only screen and (max-width: 384px) {
    margin-top: 56px;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    .caption {
      h2 {
        font-family: Montserrat;
        font-size: 32px;
        color: #2b3c45;
        line-height: 29px;
        margin: 0;

        @media only screen and (max-width: 384px) {
          font-size: 18px;
        }
      }
    }

    .btn {
      background: #0f2531;

      :hover {
        color: #ffffff;
      }
    }

    .search {
      display: flex;
      flex-direction: row;
    }
    .buttonAction {
      padding: 0 12px;
      border-radius: 5px;

      a {
        ${buttons};
        background-color: #2b3c45;
        text-align: center;

        button {
          background-color: #2b3c45;
        }
      }
    }

    .MuiButton-root {
      @media only screen and (max-width: 384px) {
      }
    }

    .MuiButton-root {
      @media only screen and (max-width: 384px) {
      }
    }
  }

  .SkeletonContainer {
    margin-top: 2em;
    padding: 25px;
    background: #ffffff;
  }

  .MuiButton-root {
    text-transform: none;
  }

  .searchBox {
    margin-top: 1em;
    width: 30%;
    border-radius: 4px;
  }

  .show__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .no-orders-card {
    background: #ffffff;
    width: 90%;
    height: 359px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #d4d4d4;
    border-radius: 3px;
    border: 1px solid #e3e3e3;
    font-family: "Open Sans";
    svg {
      width: 27px;
      fill: #d4d4d4;
    }
    h4 {
      font-size: 20px;
      margin: 14px 0 0 0;
    }

    p {
      font-size: 14px;
      margin-top: 7px;
    }
  }
`;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

export default function CustomerInfoCard({ loading, order }) {
  return (
    <Wrapper>
      <div className="customerCard">
        <div className="title">Customer information</div>
        {loading && <Skeleton height={200} />}
        {!loading && order && (
          <>
            <div className="caption">Customer name</div>
            <div className="name">
              {order?.customer?.firstName}&nbsp;{order?.customer?.lastName}
            </div>
            <div className="caption">Email</div>
            <div className="email">{order?.contact?.emailAddress}</div>
            <div className="caption">Contact number</div>
            <div className="number">{order?.contact?.phoneNumber}</div>
          </>
        )}
      </div>
    </Wrapper>
  );
}

CustomerInfoCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  order: PropTypes.object
};

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  .customerCard {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    max-width: 400px;
    margin-right: 15px;
    height: 280px;
    padding: 25px;
    margin-bottom: 1rem;
  }

  .title {
    color: #2b3c45;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .caption {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .name {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  .email {
    font-size: 14px;
    color: #2b3c45;
    margin-bottom: 1rem;
  }

  .number {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 384px) {
    .customerCard {
      max-width: 190%;
    }
  }
`;

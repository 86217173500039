import http from "./httpService";
import business from "./businessService";

const api = "/customers";

export async function createCustomer(payload) {
  const { data } = await http.post(api, payload, {
    "Content-Type": "application/json"
  });
  return data;
}

export async function createCustomerAddress({ customerId, ...values }) {
  const { data } = await http.post(`${api}/${customerId}/addresses`, values, {
    "Content-Type": "application/json"
  });
  return data;
}

export async function fetchCustomers() {
  const businessId = business.getBusinessId();

  const { data } = await http.get(`${api}?businessId=${businessId}`);
  return data;
}

export async function fetchCustomerAddress({ queryKey }) {
  const [_key, { customerId }] = queryKey;

  const { data } = await http.get(`${api}/${customerId}/addresses`);
  return data;
}

export async function fetchAddressInfo({ queryKey }) {
  const [_key, { addressId }] = queryKey;

  const { data } = await http.get(`${api}/addresses/${addressId}`);
  return data;
}

export async function fetchCountries() {
  const { data } = await http.get(`${api}/addresses/countries`);
  return data;
}

export async function fetchRegions() {
  const { data } = await http.get(`${api}/addresses/regions`);
  return data;
}

export async function fetchLgas() {
  const { data } = await http.get(`${api}/addresses/lgas`);
  return data;
}

export async function fetchLgaByRegionId(regionId) {
  const { data } = await http.get(`${api}/addresses/regions/${regionId}/lga`);
  return data;
}

export async function fetchCities() {
  const { data } = await http.get(`${api}/addresses/cities`);
  return data;
}

export async function fetchCitiesByLgaId(lgaId) {
  const { data } = await http.get(`${api}/addresses/lga/${lgaId}/cities`);
  return data;
}

export default {
  createCustomer,
  createCustomerAddress,
  fetchCustomers,
  fetchCustomerAddress,
  fetchAddressInfo,
  fetchCountries,
  fetchRegions,
  fetchLgas,
  fetchLgaByRegionId,
  fetchCities,
  fetchCitiesByLgaId
};

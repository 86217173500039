import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function DeletePickupAddress({
  values,
  onClose,
  onRemovePickupAddress
}) {
  return (
    <Wrapper>
      <Text>This address will be permanently deleted.</Text>
      <ButtonContainer>
        <button
          variant="secondary"
          className="cancel-button"
          onClick={() => onClose()}
        >
          Close
        </button>
        <button
          onClick={() => onRemovePickupAddress.mutate(values)}
          status={onRemovePickupAddress.isLoading ? "disabled" : ""}
        >
          {onRemovePickupAddress.isLoading ? "Deleting..." : "Delete"}
        </button>
      </ButtonContainer>
    </Wrapper>
  );
}

DeletePickupAddress.propTypes = {
  values: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onRemovePickupAddress: PropTypes.object
};

const Wrapper = styled.div`
  width: 100%;
`;

const Text = styled.p`
  color: #060606;
  font-size: 14px;
  padding-bottom: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  padding: 1em;
`;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import FeatherIcon from "feather-icons-react";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import { useOnInstallPWA } from "@hooks/useAddToHomescreenPrompt";
import Intercom from "./Intercom";
import Icons from "@sharedComponent/Icons";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import seller from "@services/sellerService";
import useLogout from "@hooks/useLogout";
import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import DropdownButton from "react-bootstrap/DropdownButton";
import MyVerticallyCenteredModal from "@sharedComponent/BModal";

export default function SideNavigation() {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const [opnenNav, setOpenNav] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();

  const space = "     ";

  const logout = useLogout();

  const gotoPrivacyPolicy = () => {
    history.push("/privacy-policy");
  };

  const handleNavigation = () => {
    setOpenNav(!opnenNav);
  };

  const handleAccount = () => {
    setOpenNav(false);
  };

  const handleModalShow = () => {
    setOpenNav(false);
    setModalShow(true);
  };

  const { sellerId } = useSelector(selectors.app);

  const fetchSellerReq = useQuery(
    ["fetch_seller_info", { sellerId }],
    seller.fetchSellerInfo
  );
  console.log(fetchSellerReq.data);

  return (
    <Container>
      <div>
        <div className="logo">
          <Icons.DorokiLogo />
        </div>
        <ul>
          <MenuLink exact to="/">
            <FeatherIcon icon="home" size="24" />
            Home
          </MenuLink>
          <MenuLink to="/orders">
            <FeatherIcon icon="package" size="24" />
            Orders
          </MenuLink>
          <MenuLink to="/products">
            <FeatherIcon icon="tag" size="24" />
            Products
          </MenuLink>
          <MenuLink to="/settings">
            <FeatherIcon icon="settings" size="24" />
            Manage
          </MenuLink>

          <div>
            <Intercom />
          </div>
        </ul>
      </div>
      {/* <div className="profile dropdown-toggle dropdown-toggle-split" id="dropdown-button-drop-up" key='up' type="button"></div>
          <button className="profile dropdown-toggle dropdown-toggle-split"></button> */}

      <div className="menuContainer">
        {fetchSellerReq.data && (
          <div className="profile-image-container" onClick={handleNavigation}>
            <div className="profile"></div>
            <span className="profile-name">
              {fetchSellerReq.data.firstName}
              {/* {fetchSellerReq.data.lastName} */}
            </span>
          </div>
        )}

        {opnenNav && fetchSellerReq.data && (
          <nav
            ref={dropdownRef}
            className={`profile-menu ${isActive ? "active" : "inactive"}`}
          >
            <ul>
              <li></li>
              {fetchSellerReq.data.businesses.length > 0 ? (
                <li
                  className="nav-menu"
                  style={{ textTransform: "capitalize" }}
                >
                  <a>{fetchSellerReq.data.businesses[0].businessName}</a>
                </li>
              ) : null}
              {/* <li className="nav-menu" style={{ textTransform: "capitalize" }}>
                <a>{fetchSellerReq.data.businesses[0].businessName}</a>
              </li> */}
              <hr className="divider"></hr>
              <li onClick={handleAccount}>
                <a>Your Account</a>
              </li>
              <hr className="divider"></hr>
              <li onClick={handleModalShow}>
                <a>My Settings</a>
              </li>
              <hr className="divider"></hr>
              <li>
                <a> Help Center Terms &amp; Policy</a>
              </li>
              <hr className="divider"></hr>
              <li onClick={logout}>
                <a>Log Out</a>
              </li>
            </ul>
          </nav>
        )}
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {/* <div className="spacing" /> */}
    </Container>
  );
}

const Container = styled.div`
  width: 190px;
  height: 100%;
  background-color: #2b3c45;
  background-size: cover;
  position: fixed;
  overflow-y: auto;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1025px) {
    width: 80%;
  }

  .logo {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .logo svg {
    height: 38px;
    fill: #ffffff;
  }

  .dropdown-menu {
    width: 15rem !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-toggle-split {
  }
  .divider {
    width: 100%;
    height: 1px;
    stroke-width: 1;
  }
  .dorpdown-divider {
    height: 0.5px !important;
    stroke-width: 1;
  }
  .dorpdown-item {
    font-size: 16px;
    color: #0f2531;
  }

  .profile-btn {
  }

  .profile-btn .btn-secondary {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #fff;
  }

  .menuContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    width: 150px;

    .profile-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .profile {
    height: 50px;
    width: 50px;
    background: #fff;
    border-radius: 50%;

    &-name {
      text-transform: capitalize;
      font-weight: 400;
    }

    &:hover .profile-menu {
      background: red !important;
    }

    &-name {
      color: #fff;
      width: 10px;
      font-family: Ubuntu;
      font-size: 16px;
      padding-left: 5px;
    }
  }

  .profile-menu {
    display: block;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.2);
    background: #fff;
    position: absolute;
    bottom: 80px;
    left: 5px;
    width: 180px;

    ul {
      li {
        cursor: pointer;
        padding: 10px 31px;
        box-sizing: border-box;
        &:hover {
          background: #f5f8fa;
        }

        :first-child {
          height: 50px;
          background: #f5f8fa;
          padding: 0;
        }

        a {
          width: 187px;
          height: 22px;
          font-family: Noto Sans;
          font-weight: 500;
          font-size: 16px;
          color: #0f2531;
          text-decoration: none solid rgb(15, 37, 49);
        }
      }
    }
  }

  .nav-menu:hover {
    background: #ededed;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    svg {
      width: 22px;
    }
  }

  .spacing {
    padding-bottom: 20em;
  }

  .pwaInstallPrompt {
    margin: 0 auto;
    box-sizing: border-box;
    height: 237px;
    width: 165px;
    border: 1px solid rgba(255, 255, 255, 0.26);
    border-radius: 5px;
    background-color: #1a164e;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }

    .dismissible {
      height: 9.9px;
      width: 9.9px;
      opacity: 0.5;
      color: #ffffff;
      font-weight: 800;
      margin-left: 7rem;
      margin-bottom: 12px;
      cursor: pointer;
    }

    .close {
      display: flex;
      justify-content: flex-end;
      color: #fff;
      padding: 4px 12px;
      cursor: pointer;
    }

    .title {
      color: #ffffff;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 23px;
    }

    .message {
      opacity: 0.51;
      color: #ffffff;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 1rem;
    }

    .separator {
      margin-top: 10px;
      border: none;
      border-top: 1px dashed #ffffff50;
      width: 100%;
      height: 1px;
      stroke-width: 1;
    }
  }
`;

const MenuLink = styled(NavLink)`
  outline: none;
  text-decoration: none;
  width: 100%;
  font-family: Montserrat;
  font-weight: lighter;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.75s all ease;
  display: flex;
  align-items: center;
  padding: 15px 5px 15px 30px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: 500;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: white !important;
  }
  svg {
    margin-right: 16px;
  }
  &.active {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
    font-weight: 600;
  }
`;

import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "settlement",
  initialState: {
    withdrawals: [],
    wallet: {
      available_bal: 0.0
    },
    bankAccounts: [],
    bankAccountInfo: {}
  },
  reducers: {
    setWithdrawals: (state, action) => {
      state.withdrawals = action.payload;
    },
    setAvailableBalance: (state, action) => {
      state.wallet.available_bal = action.payload;
    },
    setAccountInfo: (state, action) => {
      state.bankAccountInfo = action.payload;
    },
    setBeneficiary: (state, action) => {
      state.bankAccountInfo = {
        ...state.bankAccountInfo,
        alias: action.payload
      };
    },
    setBankAccounts: (state, action) => {
      state.bankAccounts = action.payload;
    },
    setBankAccount: (state, action) => {
      // return {
      //   ...state,
      //   bankAccounts: [...state.bankAccounts, state.bankAccountInfo]
      // };
      state.bankAccounts.unshift(action.payload);
    },
    setWithdrawal: (state, action) => {
      return {
        ...state,
        withdrawals: [...state.withdrawals, action.payload]
      };
    }
  }
});

export default slice.reducer;

// Actions
export const { ...settlementActions } = slice.actions;

export const handleSetSettlements = values => async dispatch => {
  await dispatch(settlementActions.setWithdrawals(values.withdrawals));
  await dispatch(settlementActions.setAvailableBalance(values.walletBalance));
};

export const handleSetBankAccounts = values => async dispatch => {
  await dispatch(settlementActions.setBankAccounts(values));
};

export const handleSetAccountInfo = obj => async dispatch => {
  await dispatch(settlementActions.setAccountInfo(obj));
};

export const handleAddBeneficiary = value => async dispatch => {
  await dispatch(settlementActions.setBeneficiary(value));
  await dispatch(settlementActions.setBankAccount());
};

export const handleUpdateAvailableBalance = value => async dispatch => {
  await dispatch(settlementActions.setAvailableBalance(value));
};

export const handleUpdateWithdrawals = withdrawal => async dispatch => {
  await dispatch(settlementActions.setWithdrawal(withdrawal));
};

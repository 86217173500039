import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import useDetectScreenSize from "@hooks/useDetectScreenSize";

import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { useHistory } from "react-router-dom";

const Table = ({ columns, sortColumn, onSort, data }) => {
  const history = useHistory();

  const { largeScreen } = useDetectScreenSize();

  const handleRowClick = item => {
    console.log(`row with product id ${item} clicked`);
    // history.push(`productpage/${item}`);
  };

  return (
    <Wrapper>
      <table
        className={
          largeScreen
            ? "table table-hover"
            : "table table-hover table-responsive"
        }
      >
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
        <TableBody
          columns={columns}
          data={data}
          handleRowClick={handleRowClick}
          // rowPerPage={row}
        />
      </table>
    </Wrapper>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  // sortColumn: PropTypes.object.isRequired,
  // onSort: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default Table;

const Wrapper = styled.div`
  width: 100%;

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    // border-spacing: 1px;
    // border-right: 0;
  }

  .table th,
  .table td {
    padding: 1rem;
    // vertical-align: top;
    text-align: left;
    //margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }

  .table tr {
    border-bottom: 1px solid #eceeef;
  }

  table td {
    //border-spacing: 0px !important;
    //margin-bottom: 5px;
    border-right: 0;
  }

  .table thead th {
    // vertical-align: bottom;
    // border-bottom: 2px solid #eceeef;
  }

  .table tbody + tbody {
    //border-top: 2px solid #eceeef;
  }

  .table tbody {
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    // border-radius: 0.65rem;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    // border: 1px solid #ebebeb;
    box-shadow: 1px 2px 2px #ebebeb;
  }

  .table-data {
    color: #080808;
    font-size: 14px;
    font-family: "Open Sans";
  }

  .table .table {
    background-color: #fff;
  }

  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }

  .table-bordered {
    border: 1px solid #eceeef;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eceeef;
  }

  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #dff0d8;
  }

  .table-hover .table-success:hover {
    background-color: #d0e9c6;
  }

  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d0e9c6;
  }

  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #d9edf7;
  }

  .table-hover .table-info:hover {
    background-color: #c4e3f3;
  }

  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c4e3f3;
  }

  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #fcf8e3;
  }

  .table-hover .table-warning:hover {
    background-color: #faf2cc;
  }

  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #faf2cc;
  }

  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #f2dede;
  }

  .table-hover .table-danger:hover {
    background-color: #ebcccc;
  }

  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ebcccc;
  }

  .thead-inverse th {
    color: #fff;
    background-color: #292b2c;
  }

  .thead-default th {
    color: #464a4c;
    background-color: #eceeef;
  }

  .table-inverse {
    color: #fff;
    background-color: #292b2c;
  }

  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #fff;
  }

  .table-inverse.table-bordered {
    border: 0;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive.table-bordered {
    border: 0;
  }

  // @media only screen and (max-width: 400px) {
  //   table {
  //     border: 0;
  //   }

  //   table thead {
  //     display: none;
  //   }

  //   table tr {
  //     margin-bottom: 10px;
  //     display: block;
  //     border-bottom: 2px solid #ddd;
  //   }

  //   table td {
  //     display: block;
  //     text-align: left;
  //     font-size: 13px;
  //     border-bottom: 1px dotted #ccc;
  //   }

  //   table td:last-child {
  //     border-bottom: 0;
  //   }

  //   table td:before {
  //     content: attr(data-label);
  //     float: left;
  //     text-transform: uppercase;
  //     font-weight: bold;
  //     margin-right: 20px;
  //   }
  // }
`;

import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Form, Formik } from "formik";
import styled from "styled-components";
import notification from "@utils/notification";

import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";
import LoadingState from "@sharedComponent/LoadingState";

import ImageHandler from "./Common/ImageHandler";
import PreviewImages from "./Common/PreviewImages";
import product from "@services/productService";

import { formInput } from "./Common/SharedFormElements";

export default function UpdateImage() {
  const history = useHistory();
  const params = useParams();

  const productId = params.id;

  const { isLoading, isError, error, data } = useQuery(
    ["product_detail", { productId }],
    product.fetchProductInfo
  );

  const goBack = () => {
    const path = "/products";
    history.push(path);
  };

  const updateProductMutation = useMutation(product.updateProductImages, {
    onSuccess: () => {
      notification.success(
        "Product images updated",
        "Your product images has been updated"
      );
      history.push(`/products`);
    },
    onError: err => {
      notification.createNotify({
        title: "Error updating product image",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onProductUpdate = values => {
    /* alert(JSON.stringify({ ...values, productId: productId })); */
    updateProductMutation.mutate({ ...values, productId: productId });
  };

  if (isLoading && !data) {
    return (
      <Loader>
        <SlideToTop>
          <LoadingState message="" />
        </SlideToTop>
      </Loader>
    );
  }

  if (isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message="Error fetching product image." />
        </SlideToTop>
      </Loader>
    );
  }

  return (
    <Wrapper>
      <div className="heading">
        <div className="heading_title">
          <h2>Product Image</h2>
        </div>
        <div className="heading_button">
          <button variant="cancel" onClick={goBack}>
            Cancel
          </button>
        </div>
      </div>

      <div className="pageBody">
        <PreviewImages productImages={data.images} />

        <Formik
          initialValues={{
            files: []
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onProductUpdate(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ImageHandler values={values} setFieldValue={setFieldValue} />

              <div className="submitActionContainer">
                {values.files.length === 0 ? (
                  <button status="disabled">Upload Image(s)</button>
                ) : values.files.length > 0 &&
                  updateProductMutation.isLoading ? (
                  <button status="disabled">Uploading...</button>
                ) : (
                  <button type="submit">Upload Image(s)</button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Wrapper>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin-top: 16rem;
`;

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 2em;

  .heading {
    z-index: 10;
    display: flex;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5em;
    min-height: 50px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;

    .heading_title {
      color: #2b3c45;
      margin-right: 1em;
    }

    .heading_button {
    }
  }

  .formContainer {
    width: 98%;
    background: #ffffff;
    margin: 12px auto 32px auto;
    box-sizing: border-box;
    padding: 31px 33px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #999999;

    .formTitle {
      font-family: Montserrat;
      font-size: 20px;
      color: #1e242d;
      font-weight: 500;
      padding: 0;
    }
    p {
      padding: 0;
      margin: 0;
      padding-top: 8px;
    }
    label {
      color: #1e242d;
      display: block;
      margin-top: 30px;
    }
    .formInput {
      ${formInput}
    }
    input {
      /* height: 40px; */
    }

    textarea {
      height: 80px;
    }
  }

  .submitActionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    padding: 0 12px;
  }

  .action-button .disabled {
    pointer-events: none;
  }

  .pageBody {
    padding-bottom: 4em;
  }
`;

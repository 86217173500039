import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import PropTypes from "prop-types";

const Card = ({ caption, description, redirectLink, children }) => {
  return (
    <Wrapper>
      <NavLink to={redirectLink}>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.1 }}
        >
          <div className="settings__item">
            <div className="settings__card">
              <div className="settings__card__content">
                <div className="settings__card__icon">
                  <div className="settings__card__icon__svg">{children}</div>
                </div>

                <div className="settings__card__footer">
                  <div className="settings__card__caption">{caption}</div>
                  <div className="settings__card__description">
                    {description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </NavLink>
    </Wrapper>
  );
};

Card.propTypes = {
  caption: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  children: PropTypes.object
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Montserrat;

  .settings__item {
    display: flex;
    align-items: center;
    padding-bottom: 18px;

    .settings__card {
      background-color: #fff;
      border-radius: 0.25rem;
      border: 1px solid #ebebeb;
      box-shadow: 1px 2px 2px #ebebeb;
      display: flex;
      padding: 1rem;
      min-width: 340px;
      margin-right: 24px;

      @media (min-width: 40rem) {
        width: 320px;
      }
      @media (min-width: 56rem) {
        width: 320px;
      }
    }

    .settings__card__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
    }

    .settings__card__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .settings__card__name {
        opacity: 0;
      }

      .settings__card__status {
        color: #53c37a;
        font-size: 14px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }
    }

    .settings__card__icon {
      padding-top: 1em;
      padding-bottom: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .settings__card__footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 4em;

      .settings__card__caption {
        color: #2b3c45;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .settings__card__description {
        display: inline-block;
        color: #121212;
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 1.25rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .settings__item {
      .settings__card {
        margin-right: 4px;
      }
    }
  }

  @media screen and (max-width: 430px) {
    margin-top: 10px;
    .settings__item {
      flex-direction: column;
      .settings__card {
        margin: 0;
        padding: 10px 6px 10px 8px;
        .settings__card__description {
          width: 300px;
        }
      }
    }
  }
`;

export default Card;

import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import SignUp from "./Auth/SignUp/Signup";
import Dashboard from "./Dashboard/Dashboard";
import Welcome from "./Auth/SignUp/Welcome";
import Login from "./Auth/Login/Login";
import ForgotPassword from "./Auth/ForgotPassword/ForgotPassword";
import ForgotPasswordSuccess from "./Auth/ForgotPassword/ForgotPasswordSuccess";
import ChangePassword from "./Auth/ForgotPassword/ChangePassword";
import Invoice from "./Invoice/Invoice";
import CheckoutRedirect from "./Confirmation/CheckoutRedirect";
import VerifyCheckoutPayment from "./Confirmation/VerifyCheckoutPayment";
import ProtectedRoute from "./Auth/Login/ProtectedRoute";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import MUTable from "./Dashboard/Products/ProductsList/MUTable";
import VerifyPhone from "./Auth/SignUp/VerifyPhone";
import VerifyPhone2 from "./Auth/SignUp/VerifyPhone2";
import VerifyNumber from "./Auth/SignUp/VerifyNumber";
import KycSelfie from "./Auth/SignUp/KycSelfie";
import Selfie from "./Auth/SignUp/Selfie";
import SignupSuccess from "./Auth/SignUp/SignupSuccess";
import SignupPaga from "./Auth/SignUp/SignupPaga";
import ConfirmDetails from "./Auth/SignUp/ConfirmDetails";
import CreateAccount from "./Auth/SignUp/CreateAccount";
import UpdateNumber from "./Auth/SignUp/UpdateNumber";
import BusinessInformation from "./Auth/SignUp/BusinessInformation";
import BusinessAddress from "./Auth/SignUp/BusinessAddress";
import { Business } from "@mui/icons-material";
import BvnInput from "./Auth/SignUp/BvnInput";
import VerifyBvn from "./Auth/SignUp/VerifyBvn";
import VerifyBvnOtp from "./Auth/SignUp/VerifyBvnOtp";
import UploadID from "./Auth/SignUp/UploadID";
import ProofAddress from "./Auth/SignUp/ProofAddress";
import FileUpload from "./Auth/SignUp/FileUpload";
// import InstallPwa from "./Auth/SignUp/InstallPwa";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          {/* <SignupPaga /> */}
          <CreateAccount />
        </Route>
        <Route path="/passwords/forgot">
          <ForgotPassword />
        </Route>
        <Route path="/passwords/reset">
          <ChangePassword />
        </Route>
        <Route path="/business">
          <BusinessInformation />
        </Route>
        <Route path="/businessAdd">
          <BusinessAddress />
        </Route>
        <Route path="/bvn">
          <BvnInput />
        </Route>
        <Route path="/upload">
          <FileUpload />
        </Route>
        <Route path="/proof">
          <ProofAddress />
        </Route>
        <Route path="/upload-id">
          <UploadID />
        </Route>
        <Route path="/verify-bvn">
          {/* <VerifyBvn /> */}
          <VerifyBvnOtp />
        </Route>
        <Route path="/forgot-password-email-sent">
          <ForgotPasswordSuccess />
        </Route>
        <Route path="/change-password">
          <ChangePassword />
        </Route>
        <Route path="/welcome/:email">
          {/* <ConfirmDetails /> */}
          <CreateAccount />
          {/* <Welcome /> */}
        </Route>
        <Route path="/signup-paga">
          <SignupPaga />
        </Route>
        <Route path="/success">
          <SignupSuccess />
        </Route>
        <Route path="/initiateNumber">
          {/* <VerifyPhone2 /> */}
          {/* <VerifyNumber /> */}
          <UpdateNumber />
        </Route>
        <Route path="/updateNumber">
          <UpdateNumber />
        </Route>
        <Route path="/otpNumber/:number">
          <VerifyNumber />
        </Route>
        <Route path="/kyc">
          <Selfie />
        </Route>
        <Route path="/table">
          <MUTable />
        </Route>
        {/* <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route> */}
        <ProtectedRoute path="/privacy-policy">
          <PrivacyPolicy />
        </ProtectedRoute>
        <Route path="/checkoutredirect/:reference/:amount">
          <CheckoutRedirect />
        </Route>
        <Route path="/verifycheckoutpayment/:reference/:amount">
          <VerifyCheckoutPayment />
        </Route>
        <Route path="/invoice/:id">
          <Invoice />
        </Route>
        {/* <Route path="*">
          <NotFound />
        </Route> */}
        {/* <ProtectedRoute path="/install-pwa">
          <InstallPwa />
        </ProtectedRoute> */}
        <ProtectedRoute path="/">
          <Dashboard />
        </ProtectedRoute>
      </Switch>
    </BrowserRouter>
  );
}

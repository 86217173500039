import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

export default function DeliveryInfoCard({ loading, order }) {
  return (
    <Wrapper>
      <div className="deliveryCard">
        <div className="title">Delivery information</div>
        {loading && <Skeleton height={200} />}

        {!loading && (
          <>
            {order.deliveryAddress && (
              <>
                <span className="text">
                  {order.customer.firstName}&nbsp;{order.customer.lastName}
                </span>

                <span className="address">
                  {order.deliveryAddress.streetAddress}
                </span>
                <span className="text">
                  {order.deliveryAddress.city},
                  {order.deliveryAddress.localGovernmentArea},
                  {order.deliveryAddress.region}
                </span>

                <span className="note">{order.deliveryAddress.remarks}</span>
              </>
            )}

            {order.pickupAddress && (
              <>
                <div className="title">Pickup info</div>

                <span className="text">
                  {order.customer.firstName}&nbsp;{order.customer.lastName}
                </span>

                <span className="address">
                  {order.pickupAddress.streetAddress}
                </span>
                <span className="text">
                  {order.pickupAddress.city},
                  {order.pickupAddress.localGovernmentArea},
                  {order.pickupAddress.region}
                </span>

                <span className="note">{order.pickupAddress.remarks}</span>
              </>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
}

DeliveryInfoCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  order: PropTypes.object
};

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  .deliveryCard {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    max-width: 400px;
    height: 280px;
    padding: 25px;
    margin-bottom: 1rem;
  }

  .title {
    color: #2b3c45;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .text {
    line-height: 1.5;
    font-size: 14px;
  }

  .address {
    line-height: 1.5;
    font-size: 14px;
    width: 200px;
  }

  .note {
    color: #606060;
    font-size: 14px;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 384px) {
    .deliveryCard {
      max-width: 190%;
    }
  }
`;

import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 28px
  height: 28px;
  border: 3px solid #2B3C4550;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  ::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #2B3C45;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function SmallLoadingIndicator() {
  return (
    <>
      <Wrapper />
    </>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";

import notification from "@utils/notification";
import helpers from "@utils/helpers";
import productService from "@services/productService";

export default function DeleteProduct({ product, onClose }) {
  const queryClient = useQueryClient();

  const [page] = useState("");

  const storeId = localStorage.getItem(helpers.getStoreKeyName());
  const size = 10;

  const deleteProductMutation = useMutation(productService.deleteProduct, {
    onSuccess: () => {
      queryClient.refetchQueries(["products", { storeId, page, size }]);

      /* onClose(); */

      notification.createNotify({
        title: "Success",
        message: "Product deleted",
        type: "success",
        position: "top-right"
      });

      // onClose();
      window.location.reload();
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const handleDeleteProduct = productId => {
    deleteProductMutation.mutate(productId);
  };

  return (
    <Wrapper>
      <div className="content">
        <span className="boldText">{product.name}</span> will no longer be
        available on your list of products.
      </div>

      <div className="footer">
        <button variant="cancel" onClick={() => onClose()}>
          Cancel
        </button>

        {deleteProductMutation.isLoading ? (
          <button status="disabled">Deleting...</button>
        ) : (
          <button onClick={() => handleDeleteProduct(product.productId)}>
            Delete product
          </button>
        )}
      </div>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .content {
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
  }

  .boldText {
    font-weight: 600;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }
`;

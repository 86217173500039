import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import FeatherIcon from "feather-icons-react";
import moment from "moment";

import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function RecentOrders({ loading, orders, isError }) {
  const history = useHistory();

  return (
    <Wrapper>
      <div className="content">
        <div className="header">
          <span className="text">Recent orders</span>
          <span>
            <NavLink to="/orders/">
              <button variant="small_outline">All orders</button>
            </NavLink>
          </span>
        </div>

        <div className="body">
          {loading ? (
            <Skeleton height={30} count={9} />
          ) : !loading && isError ? (
            <motion.div className="no-orders">
              <FeatherIcon icon="alert-triangle" size="27" color="#f9a825" />
              <h5>Error! fetching recent order</h5>
            </motion.div>
          ) : !loading && orders.recentOrdersDetails.length === 0 ? (
            <motion.div className="no-orders">
              <FeatherIcon icon="shopping-cart" size="27" />
              <h5>No orders</h5>
              <p>Recent orders will appear here</p>
            </motion.div>
          ) : (
            !loading &&
            orders.recentOrdersDetails.length > 0 && (
              <table className="table table-hover">
                <tbody>
                  {orders.recentOrdersDetails.map(order => (
                    <tr
                      key={order.id}
                      onClick={() => history.push(`/orders/view/${order.id}`)}
                    >
                      <td>
                        <span className="table-data">
                          {moment(order.createdDate).format("ll")}
                        </span>
                      </td>
                      <td>
                        <span className="table-data">
                          {`${order.customerName.substring(0, 10)}...`}
                        </span>
                      </td>
                      <td>
                        <span className="table-data">
                          <AmountWithSymbol amount={order.amount} />
                        </span>
                      </td>
                      <td>
                        <span className="table-data">
                          {order.paymentStatus === "PAID" && (
                            <span className="paid">Paid</span>
                          )}

                          {order.paymentStatus === "PENDING" && (
                            <span className="unpaid">Unpaid</span>
                          )}

                          {order.paymentStatus === "PROCESSING" && (
                            <span className="processing">Processing</span>
                          )}
                        </span>
                      </td>
                      <td>
                        <span className="table-data">
                          {order.orderStatus === "SHIPPED" && (
                            <span className="fulfilled">
                              <FeatherIcon icon="truck" />
                              Shipped
                            </span>
                          )}

                          {order.orderStatus === "PENDING" && (
                            <span className="pending">
                              <FeatherIcon icon="truck" />
                              Pending
                            </span>
                          )}

                          {order.orderStatus === "EXPIRED" && (
                            <span className="expired">
                              <FeatherIcon icon="truck" />
                              Expired
                            </span>
                          )}

                          {order.orderStatus === "CANCELLED" && (
                            <span className="cancelled">
                              <FeatherIcon icon="truck" />
                              Cancelled
                            </span>
                          )}

                          {order.orderStatus === "INVALIDATED" && (
                            <span className="cancelled">
                              <FeatherIcon icon="truck" />
                              Invalidated
                            </span>
                          )}
                        </span>
                      </td>
                      <td>
                        <span className="table-data">
                          <FeatherIcon
                            icon="chevron-right"
                            size={16}
                            color="#2B3C45"
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  padding: 25px;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  box-shadow: 1px 2px 2px #ebebeb;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  max-width: 780px;
  height: auto;

  .content {
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .text {
      font-size: 16px;
      font-weight: 600;
      color: #2b3c45;
    }
  }

  .body {
    height: 100%;
    color: #333333;
  }

  .no-orders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
    color: #d4d4d4;

    h4 {
      font-size: 20px;
      margin: 14px 0 0 0;
    }

    p {
      font-size: 14px;
      margin-top: 7px;
    }
  }

  .paid {
    color: #4caf50;
  }

  .unpaid {
    color: #838e91;
  }

  .processing {
    color: #ffd600;
  }

  .fulfilled {
    display: flex;
    align-items: center;
    color: #4caf50;

    svg {
      margin-right: 6px;
    }
  }

  .pending {
    display: flex;
    align-items: center;
    color: #838e91;

    svg {
      margin-right: 6px;
    }
  }

  .expired {
    display: flex;
    align-items: center;
    color: #ffd600;

    svg {
      margin-right: 6px;
    }
  }

  .cancelled {
    display: flex;
    align-items: center;
    color: #f44336;

    svg {
      margin-right: 6px;
    }
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5rem;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 0.4rem;
    text-align: left;
  }

  .table tr {
    border-bottom: 1px solid #eceeef;
    cursor: pointer;
  }

  .table td {
    border-right: 0;
  }

  .table tbody {
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    box-shadow: 1px 2px 2px #ebebeb;
  }

  .table-data {
    color: #080808;
    font-size: 14px;
    font-family: "Open Sans";
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive.table-bordered {
    border: 0;
  }

  @media only screen and (max-width: 384px) {
    .table {
      display: block;
      width: 100%;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
`;

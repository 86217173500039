import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";
import Icons from "@sharedComponent/Icons";
import debounce from "lodash.debounce";
import helpers from "@utils/helpers";

import SearchItemTable from "./SearchItemTable";

export default function SearchOrderItems() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { products } = useSelector(selectors.products);

  const getProductSuggestions = async word => {
    if (word) {
      const results = products.filter(item =>
        helpers.toLowerCase(item.name).includes(helpers.toLowerCase(word))
      );

      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const debouncedSave = debounce(
    newValue => getProductSuggestions(newValue),
    1000
  );

  const updateSearchQuery = value => {
    setSearchTerm(value);
    debouncedSave(value);
  };

  const productSearchDropDown = searchResults.length > 0 && (
    <SearchItemTable searchItems={searchResults} />
  );

  return (
    <Wrapper>
      <section className="search">
        <Icons.Search />
        <input
          type="text"
          placeholder="Filter by name"
          value={searchTerm}
          onChange={input => updateSearchQuery(input.target.value)}
        />
      </section>

      {productSearchDropDown}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  width: 100%;

  .search {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 12px;
      position: absolute;
      left: 16px;
    }
    input {
      width: 100%;
      padding-left: 39px;
      font-size: 16px;
    }
  }

  .loading-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4px;
  }
`;

import React from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import notification from "@utils/notification";
import helpers from "@utils/helpers";

import invoice from "@services/invoiceService";

import {
  formInput,
  inputContainer,
  RenderInput,
  RenderTextArea
} from "@sharedComponent/FormElements";

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("email address is required"),
  emailMessage: Yup.string().required("please add a message")
});

export default function SendViaEmail({ email, invoiceUrl, nuban }) {
  /** Send email */
  const sendEmailMutation = useMutation(invoice.sendInvoiceEmail, {
    onError: err => {
      notification.createNotify({
        title: "Error sending email",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    },
    onSuccess: res => {
      notification.createNotify({
        title: "Success",
        message: "Email sent",
        type: "success",
        position: "top-right"
      });
      //onClose(false);
    }
  });

  const onSendEmail = values => {
    sendEmailMutation.mutate(values);
  };

  return (
    <Wrapper>
      <Formik
        initialValues={{
          reference: helpers.invoiceRefExtractor(invoiceUrl),
          emailAddress: email || "",
          emailMessage: "",
          nuban: nuban || ""
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSendEmail(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="inputContainer">
              <RenderInput
                label="Email"
                name="emailAddress"
                type="email"
                onChange={e => {
                  setFieldValue("emailAddress", e.target.value);
                }}
              />
            </div>

            <div className="inputContainer">
              <RenderTextArea
                label="Message"
                name="emailMessage"
                type="text"
                rows="2"
                cols="200"
                style={{ height: "200px" }}
                onChange={e => {
                  setFieldValue("emailMessage", e.target.value);
                }}
              />
            </div>

            <div className="footer">
              <button
                type="submit"
                status={sendEmailMutation.isLoading ? "disabled" : ""}
              >
                {sendEmailMutation.isLoading ? "Sending email..." : "Send"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;

  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;

  .formInput {
    ${formInput}
  }

  .inputContainer {
    margin-top: 1.8em;
    margin-bottom: 1.2em;
    ${inputContainer}
  }

  .form-button {
    display: flex;
    justify-content: flex-end;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 384px) {
    .inputContainer {
      width: 100%;
    }
  }
`;

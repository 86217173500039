import React, { useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";

import useSearchParams from "@hooks/useSearchParams";
import AuthWrapper from "../AuthWrapper";
import CustomFormInput from "@sharedComponent/CustomFormInput";
import notification from "@utils/notification";
import identity from "@services/identityService";

// Schema Validation
const validationSchema = Yup.object({
  password: Yup.string()
    .required("Required")
    .min(10, "Password does not meet criteria below"),
  passwordConfirmation: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function(value) {
      return this.parent.password === value;
    }
  )
});

export default function ChangePassword() {
  const history = useHistory();
  let query = useSearchParams();

  const userId = query.get("userId");
  const token = query.get("token");

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const resetPasswordMutation = useMutation(identity.authorizeResetPassword, {
    onSuccess: () => {
      history.push("/login");

      notification.createNotify({
        title: "Success",
        message: "Password reset successful",
        type: "success",
        position: "top-right"
      });
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const handleResetPassword = async ({ password }) => {
    const request = { token: token, newPassword: password };
    resetPasswordMutation.mutate({ ...request, userId });
  };

  const togglePasswordVisiblity = type => {
    type === "password"
      ? setPasswordShown(passwordShown ? false : true)
      : setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  return (
    <AuthWrapper title="Change password" redirectLink={{ name: "", url: "" }}>
      <Formik
        initialValues={{
          password: "",
          passwordConfirmation: ""
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleResetPassword(values);
        }}
      >
        <Form>
          <CustomFormInput
            type={passwordShown ? "text" : "password"}
            placeholder="Enter a new password"
            name="password"
            id="password"
            data-testid="password"
            icon={
              passwordShown ? (
                <FeatherIcon
                  icon="eye"
                  size={20}
                  onClick={() => togglePasswordVisiblity("password")}
                />
              ) : (
                <FeatherIcon
                  icon="eye-off"
                  size={20}
                  onClick={() => togglePasswordVisiblity("password")}
                />
              )
            }
          />

          <div className="change-pass-text">
            <span data-testid="target-render-el">
              Password must be 10+ characters
            </span>
            <span>Add capitalisation and numbers to make it stronger</span>
          </div>

          <CustomFormInput
            type={confirmPasswordShown ? "text" : "password"}
            placeholder="Confirm new password"
            name="passwordConfirmation"
            id="passwordConfirmation"
            data-testid="passwordConfirmation"
            icon={
              confirmPasswordShown ? (
                <FeatherIcon
                  icon="eye"
                  size={20}
                  onClick={() => togglePasswordVisiblity("confirmpassword")}
                />
              ) : (
                <FeatherIcon
                  icon="eye-off"
                  size={20}
                  onClick={() => togglePasswordVisiblity("confirmpassword")}
                />
              )
            }
          />

          {/* <hr /> */}
          <div className="submitAndErrorContainer">
            <motion.input
              type="submit"
              value={
                resetPasswordMutation.isLoading
                  ? "Just a moment..."
                  : "Change password"
              }
              data-testid="submit-form"
              className="submit-button form-buttons"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            />
          </div>
        </Form>
      </Formik>
    </AuthWrapper>
  );
}

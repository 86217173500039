import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    isOrderItem: true,
    isCustomer: false,
    isShipping: false,
    isEditMode: false,
    orderRequest: {
      items: [],
      customer: {},
      shipping: {
        shippingOption: "",
        shippingCost: 0.0,
        shippingAddressId: ""
      }
    },
    pageSize: 6,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "date", order: "desc" },
    isLoading: false,
    error: false
  },
  reducers: {
    getOrders: (state, action) => {
      state.orders = action.payload;
    },
    getOrderRequest: state => {
      return state.orderRequest;
    },
    setEditMode: state => {
      state.isEditMode = true;
    },
    setAddItemScreen: state => {
      state.isOrderItem = false;
      state.isCustomer = true;
    },
    setCustomerScreen: (state, action) => {
      state.isCustomer = action.payload;
    },
    setShippingScreen: (state, action) => {
      state.isShipping = action.payload;
    },
    setItems: (state, action) => {
      const item = state.orderRequest.items.find(
        product => product.productId === action.payload.productId
      );

      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        action.payload.length > 0
          ? (state.orderRequest.items = action.payload)
          : state.orderRequest.items.push(action.payload);
        // state.orderRequest.items.push(action.payload);
      }
    },
    updateItemQty: (state, action) => {
      const item = state.orderRequest.items.find(
        product => product.productId === action.payload.productId
      );

      if (item) {
        item.quantity = action.payload.quantity;
      } else {
        state.orderRequest.items.push(action.payload);
      }
    },
    setPageChange: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setSearch: (state, action) => {
      state.searchQuery = action.payload.query;
    },
    setSort: (state, action) => {
      state.sortColumn = action.payload.sortColumn;
    },
    setCustomer: (state, action) => {
      state.orderRequest.customer = action.payload;
    },
    setShipping: (state, action) => {
      state.orderRequest.shipping = action.payload;
    },
    setOrder: (state, action) => {
      state.orders.unshift(action.payload);
    },
    setShippingAddress: (state, action) => {
      state.orderRequest.shipping.shippingAddressId = action.payload;
    },
    removeItem: (state, action) => {
      state.orderRequest.items = state.orderRequest.items.filter(
        item => item.productId !== action.payload
      );
    },
    clearOrderRequest: state => {
      state.orderRequest.items = [];
      state.orderRequest.customer = {};
      state.orderRequest.shipping.shippingOption = "";
      state.orderRequest.shipping.shippingCost = 0.0;
      state.orderRequest.shipping.shippingAddressId = "";
    }
  }
});

export default slice.reducer;

export const { ...orderActions } = slice.actions;

export const handlePageChange = page => async dispatch => {
  dispatch(orderActions.setPageChange({ currentPage: page }));
};

export const handleSearch = query => async dispatch => {
  dispatch(orderActions.setSearch({ searchQuery: query, currentPage: 1 }));
};

export const handleSort = sortColumn => async dispatch => {
  dispatch(orderActions.setSort({ sortColumn }));
};

export const handleAddItem = value => async dispatch => {
  dispatch(orderActions.setItems(value));
};

export const handleRemoveItemFromOrder = value => async dispatch => {
  dispatch(orderActions.removeItem(value));
};

export const handleUpdateItemQty = value => async dispatch => {
  dispatch(orderActions.updateItemQty(value));
};

export const handleAddCustomer = value => async dispatch => {
  dispatch(orderActions.setCustomer(value));
};

export const handleAddShipping = value => async dispatch => {
  const shippingObj = {
    shippingCost: value.shippingPrice,
    shippingOption: value.shippingOption,
    shippingLocation: value.shippingLocation,
    customerAddress: {
      state: value.state,
      lga: value.lga,
      city: value.city,
      street: value.address,
      note: value.note
    }
  };

  dispatch(orderActions.setShipping(shippingObj));
};

export const handleCreateOrder = value => async dispatch => {
  dispatch(orderActions.setOrder(value));
};

// load exisitng orders for edit purpose
export const handleLoadOrderRequestDetails = value => async dispatch => {
  dispatch(orderActions.setItems(value.orderItems));
  dispatch(
    orderActions.setCustomer({
      customerId: value.customer.customerId,
      contactId: value.contact.contactId,
      firstName: value.customer.firstName,
      lastName: value.customer.lastName,
      emailAddress: value.contact.emailAddress,
      phoneNumber: value.contact.phoneNumber
    })
  );
  dispatch(
    orderActions.setShipping({
      shippingOption: value.deliveryAddress ? "DELIVERY" : "PICKUP",
      shippingCost: value.shippingFee,
      shippingAddressId: value.deliveryAddress
        ? value.deliveryAddress.addressId
        : value.pickupAddress.addressId
    })
  );
};

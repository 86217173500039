import Icons from "@sharedComponent/Icons";
import React, { useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ImageIcon from "@mui/icons-material/Image";

export default function Images({ imagesList, setImagesList }) {
  const [blobList, setBlobList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [dragover, setDragOver] = useState(false);

  // const useForceUpdate = () => useState()[1];
  // const forceUpdate = useForceUpdate();

  const fileSelector = useRef(null);

  // const list = { hidden: { opacity: 0 } };

  const storeImagesToList = e => {
    addImagesToList(e);
    addBlobToList(e);
  };

  const addImagesToList = e => {
    const files = [...fileList];
    files.push(...e.target.files);
    setFileList(files);
    // console.log(fileList);
  };

  const addBlobToList = e => {
    const fileArray = Array.from(e.target.files)
      .map(file => URL.createObjectURL(file))
      .map(el => ({ src: el, uuid: uuidv4() }));

    setBlobList(prevImages => prevImages.concat(fileArray));
  };

  const onFileSelect = event => {
    event?.target?.files && storeImagesToList(event);
  };

  const onDropImages = () => {
    fileSelector.current.click();
  };

  const removeImage = index => {
    const newImagesList = [...imagesList];
    newImagesList.splice(index, 1);
    setImagesList([...newImagesList]);
  };

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragOver(false);
    let files = [...e.dataTransfer.files];
    files.length > 0 && addImagesToList(files);
  };

  const dragAndDropList = data => {
    const newImagesList = [...imagesList];
    const dataToBeReordered = imagesList[data.source.index];
    newImagesList.splice(data.source.index, 1);
    newImagesList.splice(data.destination.index, 0, dataToBeReordered);
    setImagesList([...newImagesList]);
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      {/* <div className="formTitle">Images</div> */}
      <input
        type="file"
        multiple
        onChange={onFileSelect}
        ref={fileSelector}
        className="file-picker"
      />

      {/* <p>Upload up to five (5) images</p> */}

      <button
        onClick={onDropImages}
        type="button"
        className={
          dragover
            ? "drop-images-button drag-over"
            : "drop-images-button img-inp"
        }
        onDrop={e => {
          handleDrop(e);
        }}
        onDragOver={e => {
          handleDragOver(e);
        }}
        onDragEnter={e => {
          handleDragEnter(e);
        }}
        onDragLeave={e => {
          handleDragLeave(e);
        }}
      >
        <span className="imgIcon">
          <ImageIcon />
        </span>
        Add image (x5)
      </button>

      <DragDropContext onDragEnd={dragAndDropList}>
        <Droppable droppableId="123" direction="horizontal">
          {provided => (
            <ul
              className="images-wrapper"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {blobList.length > 0 &&
                blobList.map((image, index) => (
                  <Draggable
                    draggableId={image.uuid}
                    index={index}
                    key={image.uuid}
                  >
                    {provided => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        {provided.placeholder}
                        <Icons.Close
                          onClick={() => {
                            removeImage(index);
                          }}
                        />
                        <img
                          src={image.src}
                          alt="img"
                          className={
                            index === 0
                              ? "selected-image hero-image"
                              : "selected-image"
                          }
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
}

const Container = styled.div`
  .file-picker {
    display: none;
    margin-top: 96px;
  }

  .imgIcon {
    align-items: left;
  }

  p {
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 14px;
    color: #1e242d;
  }

  .formConatiner {
    padding: 0 !important;
  }

  .images-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 15px;
    list-style-type: none;
    li {
      position: relative;
      svg {
        width: 28px;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        border-radius: 9999%;
        border: 1px solid #ffffff;
      }
    }
  }
  .selected-image {
    width: 78px;
    height: 78px;
    object-fit: cover;
    margin: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .hero-image {
    /* border: 5px solid #2B3C45; */
  }

  .drop-images-button {
    width: 100%;
    max-width: 430px;
    padding: 12px;
    color: #2b3c45;
    background-color: #fff;
    border: 2px solid #2b3c45;
    border-radius: 2px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .drag-over {
    color: #2b3c45;
    background-color: #2b3c4517;
    border: 1px dashed #2b3c45;
  }
`;

import React, { useEffect, useRef } from "react";
import Chart from "chart.js";
import styled from "styled-components";

let myDoughnutChart;

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'Open Sans', sans-serif";
Chart.defaults.global.legend.display = false;
//--Chart Style Options--//

export default function DoughnutChart({ records }) {
  const chartRef = useRef();

  let labels = [];
  let orders = [];
  for (const [key, value] of Object.entries(records)) {
    labels.push(key);
    orders.push(value);
  }

  useEffect(() => {
    buildChart();
  }, []);

  const buildChart = () => {
    const myChartRef = chartRef.current.getContext("2d");

    if (typeof myDoughnutChart !== "undefined") myDoughnutChart.destroy();

    myDoughnutChart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: labels,
        datasets: [
          {
            data: orders,
            backgroundColor: ["#2B3C45", "#2B3C45CC", "#2B3C458C", "#2B3C4533"],
            label: "30 days data"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          //align: "center",
          labels: {
            fontColor: "#333333"
          }
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          displayColors: false,
          titleFontSize: 14,
          bodyFontSize: 12,
          xPadding: 20,
          yPadding: 10,
          callbacks: {
            labelColor: function(tooltipItem, chart) {
              return {
                borderColor: "#2B3C45",
                backgroundColor: "#2B3C45"
              };
            },
            labelTextColor: function(tooltipItem, chart) {
              return "#ffffff";
            }
          },
          backgroundColor: "#2B3C45"
        }
      }
    });
  };

  return (
    <Wrapper>
      <div className="graphContainer">
        <canvas id="myChart" ref={chartRef} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  .graphContainer {
    padding: 5px;
  }

  @media only screen and (max-width: 600px) {
    .graphContainer {
      padding: 0;
    }
  }
`;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";

import {
  formInput,
  RenderInput,
  inputContainer
} from "@sharedComponent/FormElements";

export default function AddCustomerForm() {
  const { orderRequest } = useSelector(selectors.orders);

  return (
    <Wrapper>
      <section className="new-customer--form">
        <div className="new-customer--form--content">
          <div className="input-container">
            {Object.keys(orderRequest.customer).length === 0 ? (
              <RenderInput label="Email" name="emailAddress" type="email" />
            ) : (
              <RenderInput
                label="Email"
                name="emailAddress"
                type="email"
                disabled
              />
            )}
          </div>
          <div className="input-container">
            <RenderInput
              label="Contact number"
              name="phoneNumber"
              type="text"
            />
          </div>
          <div className="input-container">
            <RenderInput label="First name" name="firstName" type="text" />
          </div>
          <div className="input-container">
            <RenderInput label="Last name" name="lastName" type="text" />
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .new-customer--form {
    margin-top: 2rem;

    .form-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 14px;
    }

    .new-customer--form--content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  input[type="submit"] {
    text-decoration: underline;
    text-decoration-style: dotted;
    color: #2b3c45;
    font-weight: 500 !important;
    cursor: pointer;
    background: transparent !important;
  }

  .formInput {
    ${formInput}
  }

  .input-container {
    margin-bottom: 16px;
    ${inputContainer}
  }

  .input-container {
    width: 40%;
  }

  @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
    .new-customer--form {
      width: 100%;

      .new-customer--form--content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .input-container {
        width: 100%;
      }
    }
  }
`;

import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import helpers from "@utils/helpers";
import { motion } from "framer-motion";
import { handleAddCustomer } from "../Orders.state";
import notification from "@utils/notification";
import customer from "@services/customerService";

import {
  formInput,
  inputContainer,
  RenderInput
} from "@sharedComponent/FormElements";

const addCustomerValidationSchema = Yup.object({
  emailAddress: Yup.string()
    .email()
    .required("Required"),
  phoneNumber: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required")
});

export default function AddCustomer({ onClose }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const businessId = localStorage.getItem(helpers.getBusinessKeyName());

  const addCustomerMutation = useMutation(customer.createCustomer, {
    onMutate: newData => {
      queryClient.cancelQueries("customers");

      const previousCustomerData = queryClient.getQueryData("customers");

      queryClient.setQueryData("customers", prev => [
        ...prev,
        { ...newData, customerId: new Date().toISOString() }
      ]);

      return previousCustomerData;
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData("customers", previousValue);

      notification.createNotify({
        title: "Error saving customer",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    },
    onSuccess: newCustomer => {
      queryClient.invalidateQueries("customers");
      dispatch(
        handleAddCustomer({
          customerId: newCustomer.customerId,
          contactId: newCustomer.contactDetails[0].contactId,
          firstName: newCustomer.firstName,
          lastName: newCustomer.lastName,
          emailAddress: newCustomer.contactDetails[0].emailAddress,
          phoneNumber: newCustomer.contactDetails[0].phoneNumber
        })
      );

      notification.createNotify({
        title: "Success",
        message: "Customer added",
        type: "success",
        position: "top-right"
      });

      onClose(false);
    }
  });

  const onSubmitCustomerForm = values => {
    addCustomerMutation.mutate({ ...values, businessId: businessId });
  };

  return (
    <Wrapper>
      <Formik
        enableReinitialize
        initialValues={{
          emailAddress: "",
          phoneNumber: "",
          firstName: "",
          lastName: ""
        }}
        validationSchema={addCustomerValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmitCustomerForm(values);
        }}
      >
        {({ handleSubmit, setFieldValue, handleChange, values, errors }) => (
          <Form>
            <div className="input-container">
              <RenderInput label="Email" name="emailAddress" type="email" />
            </div>

            <div className="input-container">
              <RenderInput
                label="Contact number"
                name="phoneNumber"
                type="text"
              />
            </div>

            <div className="input-container">
              <RenderInput label="First name" name="firstName" type="text" />
            </div>

            <div className="input-container">
              <RenderInput label="Last name" name="lastName" type="text" />
            </div>

            <div className="form-button">
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                type="submit"
                className="action-button"
              >
                {addCustomerMutation.isLoading ? "Saving..." : "Save"}
              </motion.button>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  //width: 100%;

  .formInput {
    ${formInput}
  }

  .input-container {
    margin-bottom: 16px;
    ${inputContainer}
  }

  .form-button {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 384px) {
    .input-container {
      width: 100%;
    }
  }
`;

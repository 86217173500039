import { css } from "styled-components";

export default css`
  width: 100%;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";

  @media screen and (max-width: 400px) {
    padding: 10px;
  }

  .auth-wrapper {
    height: 100%;
    background-color: #ffffff;
    width: 640px;
    padding: 50px 100px;
    box-sizing: border-box;
    color: #2b3c45;
    svg {
      width: 150px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      .titleIcon {
        display: flex;
        align-items: center;
        margin-left: -60px;
        svg {
          height: 31px;
          fill: #53c37a;
        }
        h1 {
          margin-left: -48px;
        }
      }
      .titleText {
        display: flex;
        margin-left: -48px;
      }
      h1 {
        height: 28px;
        font-family: Ubuntu;
        font-size: 24px;
        color: #2b3c45;
        text-decoration: none solid rgb(43, 60, 69);
      }
    }
    .reset-password-text {
      color: #121212;
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      line-height: 1.5;
      margin-top: 10px;
    }
    .change-pass-text {
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      margin-top: -15px;
      span {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #666666;
        font-size: 13px;
        line-height: 1.5;
      }
    }
    h1 {
      font-family: Montserrat;
      font-size: 24px;
      letter-spacing: 1px;
    }
    a {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      color: #2b3c45;
      text-decoration: none;
    }
  }

  .auth-form-wrapper {
    margin-top: 14px;

    p {
      height: 19px;
      font-family: Ubuntu;
      font-size: 16px;
      color: #2b3c45;
      text-decoration: none solid rgb(43, 60, 69);
    }
  }

  .webcam-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-circle {
      width: 160px;
      height: 160px;
      background: #dadada;
      color: #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .webcam-btn {
      border: none;
      margin: 10px 0;
      border-radius: 5px;

      :active {
        border: none !important;
      }
    }
    .webcam-btn-container {
      display: flex;
      justify-content: center;
    }
    span {
      margin-bottom: 10px !important;
    }
  }

  .otpInput {
    margin-bottom: 5px;
  }

  .otpInput div {
    align-items: center;
    width: 60%;
    justify-content: center;
    margin: 0 auto;

    div input {
      width: 3em;
    }
  }

  .otpInput div div input {
    width: 3.25rem !important;
    height: 52px;
    border: none;
    border: 2px solid #ededed;
    font-size: 20px;

    :focus {
      box-shadow: none;
      border: 2px solid #2b3c45;
    }

    @media screen and (max-width: 650px) {
      width: 2em !important;
      border: none;
      border-bottom: 2px solid #ededed;
      font-size: 15px;
    }
  }

  .bvnInput {
    margin-bottom: 5px;
  }

  .bvnInput div {
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0 auto;

    div input {
      width: 3em;
    }
  }

  .bvnInput div div input {
    width: 2.25rem !important;
    height: 52px;
    border: none;
    border: 2px solid #ededed;
    font-size: 20px;

    :focus {
      box-shadow: none;
      border: 2px solid #2b3c45;
    }

    @media screen and (max-width: 650px) {
      width: 2em !important;
      border: none;
      border-bottom: 2px solid #ededed;
      font-size: 15px;
    }
  }

  .input-and-error-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    input {
      font-size: 14px;
      padding: 15px 5px 15px 17px;
      border-radius: 2px;
      border: 1px solid #d1d1d1;
      position: relative;
    }

    .error {
      position: absolute;
      color: #da1a1a;
      font-size: 14px;
      bottom: -10px;
      left: 0px;
      font-size: 13px;
    }

    svg {
      color: #000000;
      position: absolute;
      top: 20%;
      right: -8%;
    }

    svg:hover {
      color: #2b3c45;
      cursor: pointer;
    }

    label {
      height: 19px;
      font-family: Ubuntu;
      font-size: 16px;
      color: #2b3c45;
      text-decoration: none solid rgb(43, 60, 69);
    }

    @media screen and (max-width: 650px) {
      .auth-wrapper {
      }
    }
  }

  .text-content {
    // margin-top: -10px;
    // width: 360px;
    // height: 38px;
    // font-size: 13px;
    // line-height: 19px;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // color: #666666;

    width: 419px;
    height: 38px;
    font-family: Noto Sans;
    font-size: 14px;
    color: #2b3c45;
    text-decoration: none solid rgb(43, 60, 69);
  }

  .clickToLogin {
    display: flex;
    justify-content: center;

    p {
      height: 19px;
      font-family: Noto Sans;
      font-size: 14px;
      color: #2b3c45;
      text-decoration: none solid rgb(43, 60, 69);
      text-align: center;
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 400px) {
    .clickToLogin {
      font-size: 12px;
    }
  }

  .text-content-agreement {
    width: auto;
  }

  .agreement-text {
    display: flex;
  }

  .check-box {
    margin-right: 10px;
    &:focus {
      box-shadow: 0px 0px 0px 0px;
      outline: none;
    }
  }

  .check-box-error {
    color: red;
  }

  hr {
    margin: 15px 0px 15px 0px;
    border: none;
    border-top: 1px solid #e9e9e9;
    color: #121212;
    background-color: #fff;
    width: 100%;
    height: 1px;
    stroke-width: 1;
  }

  .pagaSignup {
    height: 60px;
    border: 2px solid #2b3c45;
    border-radius: 5px;
    background-size: cover;
    font-family: Ubuntu;
    color: #2b3c45;
    text-decoration: none solid rgb(43, 60, 69);
    text-align: center;
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    input[type="submit"] {
      width: 100%;
      background: #fff;
      color: #2b3c45;
      border-radius: 0;
      height: 100%;
    }
    p {
      margin: 0;
      font-size: 16px;
    }
    span {
      position: absolute;
      left: 2%;
      width: 55px;

      svg {
        width: 30px;
        height: 30px;
        fill: #ffc10e;
      }
    }
  }

  .form-buttons {
    font-family: Ubuntu;
    font-size: 16px;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button {
    &:focus {
      border: none;
      box-shadow: none;
    }
    &:hover {
      background-color: #213138;
    }
  }

  .paga-oauth {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    color: #121212;
    &:hover {
      background-color: #d1d1d150;
    }
    svg {
      position: absolute;
      left: 23px;
      width: 20px;
      top: 19px;
    }
  }

  .error-message {
    width: 100%;
    text-align: center;
    color: #ef4444;
  }

  @media screen and (max-width: 650px) {
    .auth-wrapper {
      width: 100%;
      height: 100% !important;
      padding: 50px 30px;
      .title {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 460px) {
    .auth-wrapper {
      padding: 50px 4%;

      .title {
        margin-top: 5px;

        h1 {
          height: 18px;
        }
      }
    }
    .text-content {
      width: 100%;
      height: auto;
    }
    .auth-form-wrapper {
      margin-top: 20px;
    }
  }
`;

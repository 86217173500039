import { useDispatch, useSelector } from "react-redux";
import { selectors } from "configureStore";
import helpers from "@utils/helpers";

const Intercom = () => {
  //Set your APP_ID
  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    APP_ID
  };
  (function() {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function() {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + APP_ID;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  return null;
};

export default Intercom;

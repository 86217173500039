import React, { useState } from "react";
import styled from "styled-components";

export default function Tags({ tagsList, setTagsList }) {
  const [tagString, setTagString] = useState("");

  const onTagValueChange = value => {
    setTagString(value);
    const newTags = value.split(",");
    if (newTags.length > 1) {
      setTagString("");
      if (tagsList.length === 0) {
        setTagsList([newTags[0]]);
      } else {
        setTagsList([...tagsList, newTags[0]]);
      }
    }
  };

  // const updateTagValue = (value, tagIndex) => {
  //   console.log("tagIndex: ", tagIndex);
  //   console.log("value: ", value);
  // };

  const onDeleteTag = tagIndex => {
    let allTagsList = [...tagsList];
    allTagsList.splice(tagIndex, 1);
    setTagsList([...allTagsList]);
  };

  return (
    <Container className="inputContainer">
      <label htmlFor="tags">Tags</label>

      <div className="tagsContainer">
        <ul>
          {tagsList.map((tag, index) => (
            <li key={index}>
              {/* <input
                type="text"
                value={tag}
                onChange={(e) => updateTagValue(e.target.value, index)}
              /> */}
              <span className="tagValue">{tag}</span>
              <svg
                viewBox="0 0 413.348 413.348"
                xmlns="http://www.w3.org/2000/svg"
                onClick={e => {
                  onDeleteTag(index);
                }}
              >
                <path d="M413.348 24.354L388.994 0l-182.32 182.32L24.354 0 0 24.354l182.32 182.32L0 388.994l24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
              </svg>
            </li>
          ))}
        </ul>
        <input
          placeholder="Separate by comma e.g clothes, red, size 12"
          name="tags"
          className="tagInput"
          value={tagString}
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={e => {
            onTagValueChange(e.target.value);
          }}
        />
      </div>
    </Container>
  );
}

const Container = styled.section`
  .tagsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    /* height: 40px; */
    margin-top: 10px;
    width: 100%;
    max-width: 540px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;

    ul {
      padding: 0 0 0 10px;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      width: auto !important;
      max-width: 540px;
      /* background-color: #ff0000; */
      li {
        margin: 6px 6px 0px 0px;
        background-color: #2b3c45;
        color: #ffffff;
        border-radius: 4px;
        width: fit-content;
        padding: 7px 7px 7px 7px;
        display: flex;
        cursor: pointer;
        svg {
          fill: #ffffffad;
          width: 10px;
          margin-left: 5px;
          :hover {
            fill: #ffffff;
          }
        }
        .tagValue {
        }
        input {
          width: 100%;
          padding: 0;
          margin: 0;
        }
      }
    }
    .tagInput {
      padding: 15px 8px 15px 5px;
      color: #121212;
      width: 100%;
      min-width: 100px;
      box-shadow: none;
      outline: none;
      border: none;
      transition: 0.3s all ease;
    }
  }
`;

import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";

import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import useDetectScreenSize from "@hooks/useDetectScreenSize";
import ActionSheet from "actionsheet-react";
import useModal from "@hooks/useModal";
import Modal from "@sharedComponent/Modal";
import CancelOrder from "../Common/CancelOrder";
import ShareInvoice from "../Common/ShareInvoice";
import MarkAsPaid from "../Common/MarkAsPaid";

export default function ActionHeader({
  title,
  order,
  email,
  markShippedMutation,
  cancelOrderMutation,
  payOrderMutation
}) {
  /** drop down options */
  const dropdownOptions = [
    {
      id: 1,
      title: "Edit order",
      meta: "edit",
      url: `/orders/edit/${order.orderId}`,
      icon: <FeatherIcon icon="edit-2" size="18" color="#0F2531" />
    },
    {
      id: 2,
      title: "Mark as paid",
      meta: "pay",
      url: "",
      icon: <FeatherIcon icon="check-circle" size="18" color="#0F2531" />
    },
    {
      id: 3,
      title: "Share invoice",
      meta: "share",
      url: "",
      icon: <FeatherIcon icon="share-2" size="18" color="#0F2531" />
    },
    {
      id: 4,
      title: "Cancel order",
      meta: "cancel",
      url: "",
      icon: <FeatherIcon icon="x-square" size="18" color="#0F2531" />
    }
  ];

  const history = useHistory();

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClickEllipsis = () => setIsActive(!isActive);

  const { largeScreen } = useDetectScreenSize();

  const { isVisible, toggleModal } = useModal();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);

  const redirectRoute = url => {
    history.push(url);
  };

  const handleOpenActionSheet = () => {
    dropdownRef.current.open();
  };

  const handleCloseActionSheet = (meta, url) => {
    if (meta === "cancel") {
      handleCancelOrder();
    } else if (meta === "edit") {
      redirectRoute(url);
    } else if (meta === "share") {
      handleShareInvoice();
    } else if (meta === "pay") {
      handleMarkAsPaid();
    }
    dropdownRef.current.close();
  };

  const handleCancelOrder = () => {
    setShowShareModal(false);
    setShowMarkAsPaidModal(false);
    setShowCancelModal(true);
    toggleModal();
  };

  const handleShareInvoice = () => {
    setShowCancelModal(false);
    setShowMarkAsPaidModal(false);
    setShowShareModal(true);
    toggleModal();
  };

  const handleMarkAsPaid = () => {
    setShowCancelModal(false);
    setShowShareModal(false);
    setShowMarkAsPaidModal(true);
    toggleModal();
  };

  return (
    <Wrapper>
      <div className="heading">
        <div className="title">{title}</div>

        <div className="action-options">
          <div className="payment">
            {order.paymentStatus === "PAID" && (
              <span className="paid">
                <FeatherIcon icon="credit-card" /> Payment: Paid
              </span>
            )}

            {order.paymentStatus === "PENDING" && (
              <span className="unpaid">
                <FeatherIcon icon="credit-card" /> Payment: Unpaid
              </span>
            )}

            {order.paymentStatus === "PROCESSING" && (
              <span className="processing">
                <FeatherIcon icon="credit-card" /> Payment: Unpaid
              </span>
            )}
          </div>

          <div className="shipping">
            {order.orderStatus === "SHIPPED" && (
              <span className="complete">
                <FeatherIcon icon="truck" /> Shipping: Fullfilled
              </span>
            )}

            {order.orderStatus === "PENDING" && (
              <span className="pending">
                <FeatherIcon icon="truck" /> Shipping: Pending
              </span>
            )}

            {order.orderStatus === "EXPIRED" && (
              <span className="expired">
                <FeatherIcon icon="truck" />
                Shipping: Expired
              </span>
            )}

            {order.orderStatus === "CANCELLED" && (
              <span className="cancelled">
                <FeatherIcon icon="truck" />
                Shipping: Cancelled
              </span>
            )}

            {order.orderStatus === "INVALIDATED" && (
              <span className="cancelled">
                <FeatherIcon icon="truck" />
                Shipping: Invalidated
              </span>
            )}
          </div>

          {order.orderStatus === "SHIPPED" ||
          order.orderStatus === "CANCELLED" ||
          order.orderStatus === "INVALIDATED" ? null : (
            <div className="trigger-button">
              <button
                status={markShippedMutation.isLoading ? "disabled" : ""}
                onClick={() => markShippedMutation.mutate(order.orderId)}
              >
                {markShippedMutation.isLoading ? "Saving" : "Mark as shipped"}
              </button>
            </div>
          )}

          {order.paymentStatus !== "PAID" &&
            order.orderStatus !== "CANCELLED" &&
            order.orderStatus !== "INVALIDATED" && (
              <div className="dropdown-options">
                {largeScreen && (
                  <div className="menuContainer" onClick={onClickEllipsis}>
                    <div className="alias">
                      <FeatherIcon icon="more-horizontal" />
                    </div>
                    <nav
                      ref={dropdownRef}
                      className={`menu ${isActive ? "active" : "inactive"}`}
                    >
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              redirectRoute(`/orders/edit/${order.orderId}`)
                            }
                          >
                            <span style={{ display: "flex" }}>
                              <FeatherIcon
                                icon="edit-2"
                                size="18"
                                color="#0F2531"
                              />
                              Edit order
                            </span>
                          </a>
                        </li>

                        <li>
                          <a onClick={() => handleMarkAsPaid(order.orderId)}>
                            <span style={{ display: "flex" }}>
                              <FeatherIcon
                                icon="check-circle"
                                size="18"
                                color="#0F2531"
                              />
                              Mark as paid
                            </span>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => handleShareInvoice()}>
                            <span style={{ display: "flex" }}>
                              <FeatherIcon
                                icon="share-2"
                                size="18"
                                color="#0F2531"
                              />
                              Share invoice
                            </span>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => handleCancelOrder()}>
                            <span style={{ display: "flex" }}>
                              <FeatherIcon
                                icon="x-square"
                                size="18"
                                color="#0F2531"
                              />
                              Cancel order
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}

                {!largeScreen && (
                  <>
                    <div
                      className="menuContainer"
                      onClick={handleOpenActionSheet}
                    >
                      <div className="alias">
                        <FeatherIcon icon="more-horizontal" />
                      </div>
                    </div>

                    <ActionSheet
                      ref={dropdownRef}
                      sheetStyle={{
                        backgroundColor: "#ffffff",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10
                      }}
                      bgStyle={{
                        backgroundColor: "rgba(1, 1, 1, 0.8)"
                      }}
                    >
                      <div className="bar" onClick={handleCloseActionSheet}>
                        &#10005;
                      </div>
                      <div className="sheetContainer">
                        {dropdownOptions.map(option => (
                          <div
                            className="label"
                            key={option.id}
                            onClick={() =>
                              handleCloseActionSheet(option.meta, option.url)
                            }
                          >
                            <span style={{ marginRight: "15px" }}>
                              {option.icon}
                            </span>
                            {option.title}
                          </div>
                        ))}
                      </div>
                    </ActionSheet>
                  </>
                )}

                {showCancelModal && (
                  <Modal
                    isVisible={isVisible}
                    hideModal={toggleModal}
                    title="Reason to cancel"
                    id="cancelOrder"
                    width="350"
                  >
                    <CancelOrder
                      order={order}
                      onClose={toggleModal}
                      onCancelOrder={cancelOrderMutation}
                    />
                  </Modal>
                )}

                {showShareModal && (
                  <Modal
                    isVisible={isVisible}
                    hideModal={toggleModal}
                    title="Share invoice"
                    id="shareInvoice"
                  >
                    <ShareInvoice
                      onClose={toggleModal}
                      email={email}
                      orderId={order.orderId}
                      paymentStatus={order.paymentStatus}
                      nuban={order.dynamicNubanNumber}
                    />
                  </Modal>
                )}

                {showMarkAsPaidModal && (
                  <Modal
                    isVisible={isVisible}
                    hideModal={toggleModal}
                    title="Manually mark order as paid"
                    id="markAsPaid"
                  >
                    <MarkAsPaid
                      onClose={toggleModal}
                      order={order}
                      onMarkAsPaid={payOrderMutation}
                    />
                  </Modal>
                )}
              </div>
            )}
        </div>
      </div>
    </Wrapper>
  );
}

ActionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);

  .heading {
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
    margin-bottom: 1rem;
    padding: 0 12px;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #2b3c45;
  }

  .action-options {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .payment {
    font-size: 14px;
    color: #323232;
    padding: 8px;

    svg {
      margin-right: 5px;
    }
  }

  .unpaid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #838e91;
  }

  .paid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4caf50;
  }

  .processing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffd600;
  }

  .shipping {
    font-size: 14px;
    color: #323232;
    padding: 5px;

    svg {
      margin-right: 5px;
    }
  }

  .pending {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #838e91;
  }

  .complete {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4caf50;
  }

  .expired {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffd600;
  }

  .cancelled {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f44336;
  }

  .trigger-button {
    padding: 12px;
  }

  .menuContainer {
    color: #2b3c45;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    position: relative;
    transition: box-shadow 0.4s ease;

    .alias {
      margin-right: 10px;
    }

    .badgeMenuButton {
      color: #ffffff;
      width: auto;
      background: #2b3c45;
      border-radius: 3px;
      font-size: 14px;
      margin-right: 10px;
      position: relative;
      top: 0;
      left: 0;
      padding: 2px;
    }
  }

  .menu {
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    top: 40px;
    right: 0;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 10;
  }

  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu li a {
    text-decoration: none;
    color: #2b3c45;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px;
    display: block;

    svg {
      margin-right: 5px;
    }
  }

  .menu li:hover {
    background-color: #fafafa;
  }

  .sheetContainer {
    margin-bottom: 5rem;
  }

  .sheetContainer svg {
    margin-right: 5px;
  }

  .bar {
    float: right;
    font-size: 16px;
    font-weight: 800;
    color: #2b3c45;
    border-radius: 10px;
    margin-top: 12px;
    margin-bottom: 5px;
    margin-right: 25px;
    cursor: pointer;
  }

  .label {
    color: #2b3c45;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    padding: 15px;
    transition: all 0.3s;
  }

  .label:hover {
    background-color: #fafafa;
  }

  @media screen and (max-width: 430px) {
    .title {
      display: none;
    }
    .trigger-button {
      display: none;
    }
    .shipping {
      margin-right: 5px;
    }
  }
`;

import React from "react";
import styled from "styled-components";

const BankIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 47">
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.803922 0 0 0 0 0.803922 0 0 0 0 0.803922 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      transform="translate(-672 -261)"
      filter="url(#a)"
      fill="none"
      fillRule="evenodd"
    >
      <g fill="#FFF">
        <path d="M675 298h6v-17h-6zM687 298h6v-17h-6zM698 298h6v-17h-6zM690.745 261L672 270.14v4.593h3.624V277h28.752v-2.267H708v-4.85zM675.624 301v2.418H672V308h36v-4.582h-3.624V301z" />
      </g>
    </g>
  </svg>
);

export default function NoWithdrawal() {
  return (
    <Wrapper>
      <BankIcon />
      <h4>No Withdrawals</h4>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 359px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #d4d4d4;
  font-family: "Open Sans";
  svg {
    width: 27px;
    fill: #d4d4d4;
  }
  h4 {
    font-size: 16px;
    // margin: 14px 0 0 0;
  }

  p {
    font-size: 14px;
    margin-top: 7px;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";

import Tags from "./Tags";
import Switch from "@mui/material/Switch";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  formInput,
  inputContainer,
  RenderInput,
  RenderTextArea,
  RenderCheckbox
} from "@sharedComponent/FormElements";
import Price from "./Price";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { values } from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import Images from "./Images";

export default function BasicInfo({
  tagsList,
  setTagsList,
  isStockTracked,
  values,
  // handleChange,
  setFieldValue
}) {
  const [trackStock, setTrackStock] = useState(
    isStockTracked ? isStockTracked : false
  );

  const helpCard =
    "When Track stock is enabled, we will monitor the number of items sold to make sure you do not sell more items than you have";

  const help = () => {
    return (
      <div style={{ width: "200px", background: "#fff" }}>
        <h2>
          When Track stock is enabled, we will monitor the number of items sold
          to make sure you do not sell more items than you have
        </h2>
      </div>
    );
  };

  const [check, setCheck] = useState(false);

  // const handleChange = event => {
  //   setSuccess(event.target.checked);
  // };

  const handleCheckChange = () => {
    setCheck(!check);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <Wrapper>
      <div className="formContainer">
        {/* <div className="formTitle">Basic Info</div> */}

        {/* <div className="inputContainer">
          <RenderInput
            label="Product name"
            required="Required"
            name="name"
            type="text"
            placeholder="e.g. Shoes, mp3 player,xxx"
          />
        </div> */}
        <RenderInput
          label="Product name"
          name="name"
          type="text"
          required="(Required)"
        />
        <Images />
        <Price setFieldValue={setFieldValue} />

        {/* <Price
          setFieldValue={setFieldValue}
          style={{ width: "inherit" }}
          required="(Required)"
          // value={values.price}
          // onChange={(e) => {handleChange(e)}}
          onChange={handleChange} 
        /> */}

        <section className="addPriceSection" hidden>
          <AddCircleOutlineIcon />
          <span>Add sale price</span>
        </section>

        <RenderTextArea label="Description" name="description" type="text" />

        <section className="addPriceSection" hidden>
          <AddCircleOutlineIcon />
          <span>Add sale price</span>
        </section>

        {/* <RenderTextArea label="Description" name="description" type="text" /> */}

        <Tags tagsList={tagsList} setTagsList={setTagsList} />

        <section className="inputCheckboxContainer">
          <label htmlFor="doNotTrack">
            Track stock{" "}
            <span>
              <Tooltip title={helpCard}>
                <HelpOutlineIcon />
              </Tooltip>
            </span>
          </label>
          <FormControlLabel
            control={<Switch checked={check} onChange={handleCheckChange} />}
            label=""
          />
        </section>

        <RenderInput
          label="No. of units"
          name="stock"
          // value={values.unit}
          type="number"
          placeholder={check ? "" : "Stock not tracked"}
          disabled={check ? false : true}
        />

        {/* <RenderInput label="Price" name="price" type="number" placeholder="&#8358; 0.00"/> */}

        <RenderInput label="SKU (Stock Keeping Unit)" name="sku" type="text" />

        {/* <RenderCheckbox label="Track Stock?" name="stock"/> */}

        {/* <section className="inputCheckboxContainer">
          <input
            placeholder="0"
            type="checkbox"
            name="track"
            className="formInput stockLevel"
            defaultChecked={trackStock}
            onChange={() => setTrackStock(!trackStock)}
          />
          <label htmlFor="doNotTrack">Track stock</label>
        </section> */}

        {/* <div>
        <SplitButton
            key='up'
            id={`dropdown-button-drop-up`}
            drop='up'
            variant="secondary"
            title={`Drop Up`}
          >
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
            <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
          </SplitButton>
        </div> */}

        {trackStock && (
          <>
            <RenderInput
              placeholder="0"
              label="Stock level"
              name="stock"
              type="number"
              style={{ width: "92px" }}
            />
          </>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  .formContainer {
    width: 100%;
  }

  .formInput {
    ${formInput}
  }

  .inputContainer {
    ${inputContainer}
  }

  .inputCheckboxContainer {
    display: flex;
    justify-content: space-between;

    .css-j204z7-MuiFormControlLabel-root {
      margin-right: 0px !important;
    }
  }

  .addPriceSection {
    display: flex;
    justify-content: flex-end;
    color: #2b3c45 !important;

    span {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 384px) {
    .inputContainer {
      width: 100%;
    }
  }
`;

import React from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import PagaCheckoutButton from "react-paga";

import Icons from "@sharedComponent/Icons";

import order from "@services/orderService";

export default function InvoicePayment({
  publicKey,
  amount,
  email,
  paymentRef,
  businessName,
  nuban,
  orderId
}) {
  const { isLoading, data } = useQuery(
    ["check_payment_status", { orderId }],
    order.checkPaymentStatus,
    {
      refetchOnWindowFocus: false
    }
  );

  const getReference = () => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const getCheckoutURI = () => {
    return window.config.REACT_APP_CHECKOUT_URL;
  };

  const reference = `${paymentRef}_${getReference()}`;

  /** use callBackUrl from response and append custom reference and amount to it */
  const chargeUrl = `${window.config.REACT_APP_BASE_URL}/verifycheckoutpayment/${reference}/${amount}`;

  return (
    <div className="InvoicePayment">
      <div className="title">Payment</div>

      <span>Transfer funds to the following Bank Account:</span>
      <div className="bankInfo">Bank: Paga</div>
      <div className="bankInfo">Account Number: {nuban}</div>

      <span className="italic">
        This Account Number is specifically for this invoice. Please do not use
        it for any other transactions.
      </span>

      <div className="payment">
        <div className="cta">
          <div className="orText">Or</div>

          {isLoading ? null : data.paymentStatus === "PAID" ? (
            <button variant="secondary" status="disabled">
              Order has been paid
            </button>
          ) : (
            <PagaCheckoutButton
              src={getCheckoutURI().toString()}
              charge_url={chargeUrl}
              public_key={publicKey}
              amount={amount.toString()}
              email={email}
              payment_reference={reference}
              display_name={businessName}
              display_tagline={businessName}
              funding_sources="BANK,CARD,PAGA"
            />
          )}
        </div>

        <div className="partners">
          <Icons.PagaLogo />
          <Icons.Visa />
          <Icons.MasterCard />
          <Icons.Verve />
        </div>
      </div>
    </div>
  );
}

InvoicePayment.propTypes = {
  publicKey: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  paymentRef: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  nuban: PropTypes.string.isRequired
};

import React from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

export const CustomTextInput = ({ label, width, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <CustomInputWrapper className="inputContainer" width={width}>
      <label htmlFor={props.name} className="inputLabel">
        {label}
      </label>
      <input
        {...field}
        {...props}
        className="formInput"
        id="input-with-width"
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </CustomInputWrapper>
  );
};

export const CustomTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <section className="inputContainer">
        <label htmlFor={props.name}>{label}</label>
        <textarea {...field} {...props} className="formInput" />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </section>
    </>
  );
};

CustomTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

CustomTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export const inputContainer = css`
  position: relative;

  label {
    display: flex;
    flex-direction: column;
  }

  .inputLabel {
    color: ${({ errorMode }) => {
      return errorMode ? "#f26522" : "#1e242d";
    }};
  }

  .error {
    position: absolute;
    bottom: -18px;
    left: 0;
    color: #f26522;
  }
`;

export const formInput = css`
  margin-top: 10px;
  width: 100%;
  max-width: 540px;
  padding: 15px 8px 15px 15px;
  border-radius: 2px;
  color: #121212;
  border: 1px solid #d1d1d1;
  transition: 0.3s all ease;
`;

const CustomInputWrapper = styled.section`
  ${inputContainer}
  #input-with-width {
    width: ${p => (p.width ? p.width : "540px")};
  }
`;

import React from "react";
import AuthWraper from "@App/Auth/AuthWrapper";
import styled from "styled-components";
import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import { Field, ErrorMessage, Formik, Form } from "formik";
import * as Yup from "yup";
import NewPage from "@sharedComponent/NewPage";
import NewPageStyles from "@sharedComponent/NewPage.styles";
import dorokivert from "@resources/svg/doroki-vert.svg";
import http from "../../@services/httpService";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import notification from "@utils/notification";
import { actions } from "@App/App.state";
import helpers from "@utils/helpers";

const api = "/sellers";

const PrivacyPolicy = () => {
  const history = useHistory();
  const authentication = useSelector(selectors.authentication);
  const dispatch = useDispatch();

  async function updateUser() {
    const { data } = await http.put(
      `${api}/${authentication.sellerId}`,
      {
        isAgreedToTerms: true
      },
      {
        "Content-Type": "application/json"
      }
    );
    console.log("privacy");
    console.log(data);
    return data;
  }

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: res => {
      history.push("/");
    },
    onError: err => {
      notification.createNotify({
        title: "Could not update user",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  return (
    <Wrapper>
      <motion.div
        className="content-wrapper"
        initial={{ opacity: 0, height: "90%" }}
        animate={{ opacity: 1, height: "100%" }}
        transition={{ duration: 0.7 }}
      >
        <NewPage>
          {/* <Box align={"center"}> */}
          <div style={{ textAlign: "center" }}>
            <Box
              component={"img"}
              align={"center"}
              src={dorokivert}
              padding={2}
              height={{ xs: 80, sm: 120, md: 150 }}
              width={{ xs: 80, sm: 120, md: 150 }}
              // gutterBottom
            ></Box>
          </div>

          {/* </Box> */}
          <p className="term-text">We've updated our terms and conditions </p>
          <div>
            <p className="info-text">
              In inline with Nigerian Data Protection Policy and International
              best practices we've updated our Privacy Policy and our Terms of
              service.
            </p>
          </div>
          <div>
            <Formik
              initialValues={{ AgreeToTerms: false }}
              validationSchema={Yup.object({
                AgreeToTerms: Yup.boolean().oneOf(
                  [true],
                  "Please agree to terms and policy"
                )
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                updateUserMutation.mutate();
              }}
            >
              <Form>
                <div>
                  <span>
                    <Field
                      type="checkbox"
                      id="submitButtonCheckBox"
                      name="AgreeToTerms"
                      className="check-box-height "
                    />
                  </span>
                  {window.config.REACT_APP_ENV === "production" ? (
                    <span className="agreement-text">
                      {" "}
                      I am over <a>18</a> and I agree to the terms of the{" "}
                      <a
                        href="https://app.termly.io/document/privacy-policy/655480ad-1367-468d-b9f4-0756acfbdad2"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://doroki.com/static/media/terrms_of_service.16dcfbda.pdf"
                        target="_blank"
                      >
                        Terms of Service
                      </a>{" "}
                      of the company.
                    </span>
                  ) : (
                    <span className="agreement-text">
                      {" "}
                      I am over <a>18</a> and I agree to the terms of the{" "}
                      <a
                        href="https://app.termly.io/document/privacy-policy/655480ad-1367-468d-b9f4-0756acfbdad2"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://qa.doroki.com/static/media/terrms_of_service.16dcfbda.pdf"
                        target="_blank"
                      >
                        Terms of Service
                      </a>{" "}
                      of the company.
                    </span>
                  )}
                  <ErrorMessage
                    name="AgreeToTerms"
                    component="div"
                    className="check-box-error"
                  />
                </div>

                <div className="submitAndErrorContainer">
                  <motion.input
                    type="submit"
                    value="Confirm"
                    className="submit-button form-buttons"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                    data-testid="submit-form"
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </NewPage>
      </motion.div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${NewPageStyles}
`;
export default PrivacyPolicy;

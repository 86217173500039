import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import notification from "@utils/notification";
import { actions } from "@App/App.state";
import seller from "@services/sellerService";
import AuthWrapper from "../AuthWrapper";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ImageWebcam from "@sharedComponent/ImageWebcam";
import FormWrapper from "../FormWrapper";

import { display } from "@mui/system";

export default function Selfie() {
  const dispatch = useDispatch();
  const history = useHistory();

  const updateSelfieMutation = useMutation(
    seller.updateSellerAndCreateBusiness,
    {
      onSuccess: res => {
        // dispatch(actions.otp(res.businessId));
        history.push("/");
      },
      onError: err => {
        notification.createNotify({
          title: "Operation error",
          message: err.response.data.message || "Server error",
          type: "danger",
          position: "top-right"
        });
      }
    }
  );

  const onImageSubmit = values => {
    history.push("./business");
    //updateSelfieMutation.mutate(values);
  };

  return (
    <FormWrapper
      title="Smile for a selfie"
      subTitle="As required by the Federal Government, we need a photo of you to verify your identity."
    >
      <div className="signup-form-wrapper" style={{ marginTop: "30px" }}>
        <Formik
          initialValues={{
            image: ""
          }}
          validationSchema={Yup.object({
            // image: Yup.number().required("OTP field cannot be empty")
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onImageSubmit(values);
            console.log("confirming selfie");
          }}
        >
          <Form>
            <div className="webcam-section">
              <ImageWebcam />
              <span
                style={{
                  width: "100%",
                  marginTop: "28px",
                  textAlign: "center",
                  marginBottom: "50px",
                  fontSize: "14px",
                  fontWeight: "400"
                }}
              >
                Get relaxed, remove any eyewear or headgear and smile for the
                camera
              </span>
            </div>
            <div>
              <motion.input
                type="submit"
                value={
                  updateSelfieMutation.isLoading ? "Just a moment..." : "Next"
                }
                className="form-buttons submit-button"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                data-testid="submit-form"
                style={{
                  marginTop: "10px"
                }}
              />
            </div>
            {updateSelfieMutation.isError && (
              <p className="error-message">
                {updateSelfieMutation?.error?.response?.data?.message}
              </p>
            )}
          </Form>
        </Formik>
      </div>
    </FormWrapper>
  );
}

import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";
import FormWrapper from "../FormWrapper";
import AuthWrapper from "../AuthWrapper";
import CustomFormInput from "@sharedComponent/CustomFormInput";
import RadioInput from "@sharedComponent/RadioInput";
import { handleAddCustomer } from "../../Dashboard/Orders/Orders.state";
import RadioButton from "@sharedComponent/RadioButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { authenticationActions } from "@App/Auth/Login/login.state";
import { actions } from "@App/App.state";
import notification from "@utils/notification";
import helpers from "@utils/helpers";
import styled from "styled-components";
import customer from "@services/customerService";
import moment from "moment";

import seller from "@services/sellerService";

export default function CreateAccount({ onClose }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [gender, setGender] = useState("female");
  let { email } = useParams();

  const onChangeValue = event => {
    setGender(event.target.value);
    console.log(event.target.value);
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const tokenKey = helpers.getAuthTokenName();
  const refreshKey = helpers.getRefreshTokenName();

  const signupMutation = useMutation(seller.signupNewSeller, {
    onSuccess: res => {
      const accessToken = res.token.access_token;
      const refreshToken = res.token.refresh_token;
      dispatch(
        authenticationActions.setEmailAndToken({
          emailAddress: res.email,
          token: accessToken,
          sellerId: res.sellerId
        })
      );

      dispatch(actions.setBusinessId(res.businesses[0].businessId));
      dispatch(actions.setStoreId(res.businesses[0].stores[0].storeId));
      dispatch(actions.setSellerId(res.sellerId));
      dispatch(actions.setWalletId(res.businesses[0].walletAccountId));

      // persist to local storage
      localStorage.setItem(tokenKey, accessToken);
      localStorage.setItem(refreshKey, refreshToken);
      localStorage.setItem(
        helpers.getStoreKeyName(),
        res.businesses[0].stores[0].storeId
      );
      history.push("/privacy-policy");
    },
    onError: err => {
      notification.createNotify({
        title: "Could not create account",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onSignUp = values => {
    // const date = moment(values.dob).format('YYYY/DD/MM')
    values = { ...values };
    signupMutation.mutate(values);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <div className="container">
      <Container>
        {/* <div className="navigate" onClick={goBack}>
          <ArrowBackIcon />
          Back
        </div> */}
        <FormWrapper
          title="Create your account"
          subTitle="Fill the information below to proceed"
          // redirectLink={{ name: "Log in instead", url: "/login" }}
        >
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              password: "",
              confirmPassword: "",
              phoneNumber: "",
              dob: "",
              userName: "",
              gender: "F"
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required("First name cannot be empty"),
              lastName: Yup.string().required("Surname cannot be empty"),
              emailAddress: Yup.string()
                .email("Invalid email address")
                .required("email field cannot be empty"),
              password: Yup.string()
                .required("Field cannot be empty")
                .min(10, "Password does not meet critieria below"),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm password is required"),
              phoneNumber: Yup.string()
                .required("phone number cannot be empty")
                .max(11, "Phone number must not be more than 11 digits")
                .min(10, "Phone number must not be less than 10 digit"),
              userName: Yup.string().required("username cannot be empty"),
              dob: Yup.string()
                .required("Date of birth cannot be empty")
                .test(
                  "dob",
                  "You must be at least 18 years old",
                  date => moment().diff(moment(date), "years") >= 18
                )
              // gender: Yup.string().required("Please select a gender")
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              values = { ...values, gender: gender };
              onSignUp(values);
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="form-row">
                  <div className="form-row-col">
                    <CustomFormInput
                      type="text"
                      placeholder="First name"
                      name="firstName"
                      id="firstName"
                      data-testid="firstName"
                      label="First name"
                    />
                  </div>
                  <div className="form-row-col">
                    <CustomFormInput
                      type="text"
                      placeholder="Surname"
                      name="lastName"
                      id="lastName"
                      data-testid="lastName"
                      label="Surname"
                    />
                  </div>
                </div>
                <CustomFormInput
                  type="text"
                  placeholder="Enter Phone number"
                  name="phoneNumber"
                  id="phoneNumber"
                  data-testid="phoneNumber"
                  label="Phone number"
                />

                <CustomFormInput
                  type={passwordShown ? "text" : "password"}
                  placeholder="Create a password"
                  name="password"
                  id="password"
                  data-testid="password"
                  label="Password"
                  icon={
                    passwordShown ? (
                      <FeatherIcon
                        icon="eye"
                        size={20}
                        onClick={togglePasswordVisiblity}
                        style={{ top: "25%" }}
                      />
                    ) : (
                      <FeatherIcon
                        icon="eye-off"
                        size={20}
                        onClick={togglePasswordVisiblity}
                        style={{ top: "25%" }}
                      />
                    )
                  }
                />

                <CustomFormInput
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Create a password"
                  name="confirmPassword"
                  id="confirmPassword"
                  data-testid="confirmPassword"
                  label="Confirm password"
                  icon={
                    confirmPasswordShown ? (
                      <FeatherIcon
                        icon="eye"
                        size={20}
                        onClick={toggleConfirmPasswordVisiblity}
                        style={{ top: "25%" }}
                      />
                    ) : (
                      <FeatherIcon
                        icon="eye-off"
                        size={20}
                        onClick={toggleConfirmPasswordVisiblity}
                        style={{ top: "25%" }}
                      />
                    )
                  }
                />

                <CustomFormInput
                  type="text"
                  placeholder="Enter your email address"
                  name="emailAddress"
                  id="emailAddress"
                  data-testid="emailAddress"
                  label="Email address"
                />

                <CustomFormInput
                  type="text"
                  placeholder="Enter your username"
                  name="userName"
                  id="userName"
                  data-testid="userName"
                  label="Username"
                />

                <CustomFormInput
                  type="date"
                  placeholder="DOB"
                  name="dob"
                  id="dob"
                  data-testid="dob"
                  label="Date of birth"
                />

                <div>
                  <RadioInput
                    gender={gender}
                    //  onChange={(e)=>setFieldValue("gender",e.target.value)}
                    onChange={onChangeValue}
                    type="radio"
                    className="radio"
                    name="gender"
                    data-testid="gender"
                  />
                </div>

                <div className="submitContainer">
                  <motion.input
                    type="submit"
                    value={
                      signupMutation.isLoading
                        ? "Just a moment..."
                        : "Create account"
                    }
                    className="submit-button"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                    data-testid="submit-form"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;

  .form-row {
    display: flex;
    justify-content: space-between;

    &-col {
      width: 48%;
    }
  }

  .navigate {
    margin-top: 50px;
    display: flex;
    cursor: pointer;
  }
  .submitContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type="submit"] {
      background: #2b3c45;
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      width: 100%;
      padding: 16px auto;
      border-radius: 8px;
    }
  }

  .input-and-error-wrapper {
    position: relative;

    svg {
      position: absolute;
      top: 35% !important;
      right: 3%;
    }

    label {
      display: flex;

      .radio-field {
        display: flex;
        align-items: center;
        margin-right: 16px;

        input {
          border: 1px solid #d1d1d1;
        }

        &-label {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #231f20;
        }
      }
    }
  }

  .radio {
    border: 1px solid #e8e8e8 !important;
    color: #e8e8e8;
    background: #e8e8e8;
    accent-color: #231f20;
    margin-right: 2px;
  }
`;

import http from "./httpService";

const api = "/sellers";

/**
 * Inititate password reset.
 *
 * @param {*} payload
 * @returns
 */
export async function resetPassword(payload) {
  const { data } = await http.post(`${api}/passwords/reset`, payload, {
    "Content-Type": "application/json"
  });
  return data;
}

export async function authorizeResetPassword({ userId, ...payload }) {
  const { data } = await http.post(
    `${api}/${userId}/passwords/authorize-reset`,
    payload,
    {
      "Content-Type": "application/json"
    }
  );
  return data;
}

export default {
  resetPassword,
  authorizeResetPassword
};

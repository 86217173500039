import React from "react";
import styled from "styled-components";
import buttons from "@styles/buttons";

import SearchOrderItems from "../AddOrder/SearchOrderItems";
import OrderItemsTable from "../AddOrder/OrderItemsTable";

export default function EditOrderItems() {
  return (
    <Wrapper>
      <section className="topSection">
        <h4>Order Items</h4>
      </section>

      <SearchOrderItems />

      <OrderItemsTable />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  margin-top: 10px;
  padding-bottom: 4em;

  .topSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 100%;
    margin-bottom: 1em;
    h4 {
      font-family: Montserrat;
      font-size: 18px;
      color: #1e242d !important;
      margin: 0;
    }
    span {
      color: #2b3c45;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }
  }

  a {
    ${buttons};
    background-color: #2b3c45;
    text-align: center;
  }
`;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import helpers from "@utils/helpers";
import notification from "@utils/notification";
import product from "@services/productService";
import Images from "../Common/Images";
import AddProductHeader from "../Common/AddProductHeader";
import AddNewProductHeader from "../Common/AddNewProductHeader";
import BasicInfo from "../Common/BasicInfo";
import Price from "../Common/Price";
import ImageHandler from "../Common/ImageHandler";
import { propsToClassKey } from "@mui/styles";

const productSchema = Yup.object({
  name: Yup.string().required("Product name is required"),
  price: Yup.string().required("Price is required")
});

export default function AddProduct() {
  const history = useHistory();

  const [tagsList, setTagsList] = useState([]);

  const storeId = localStorage.getItem(helpers.getStoreKeyName());

  /** Create product mutation */
  const createProductMutation = useMutation(product.createProduct, {
    onSuccess: () => {
      notification.success("Product added", "Your product has been added");
      history.push(`/products`);
    },
    onError: err => {
      notification.createNotify({
        title: "Error adding product",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onProductFormSubmit = values => {
    const preFormated = { ...values, tags: tagsList };
    createProductMutation.mutate(preFormated);
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          storeId: storeId,
          name: "",
          description: "",
          tags: "",
          sku: "",
          stock: "",
          price: "",
          files: []
        }}
        validationSchema={productSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onProductFormSubmit(values);
          console.log("submitted values: ");
          console.log(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          setFieldValue
        }) => (
          <Form>
            {/* <AddProductHeader
              createProductMutation={createProductMutation}
              type="create"
            /> */}

            <AddNewProductHeader
              createProductMutation={createProductMutation}
              type="create"
              handleSubmit={handleSubmit}
            />
            <div className="form-section">
              <div className="form-body">
                <BasicInfo
                  tagsList={tagsList}
                  setTagsList={setTagsList}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />

                {/* <Price setFieldValue={setFieldValue} /> */}
                {/* <ImageHandler values={values} setFieldValue={setFieldValue} /> */}
                {/* <button type="submit" onClick={handleSubmit}>Submit</button> */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 2em;

  .inputCheckboxContainer {
    position: relative;
    display: flex;
    margin-top: 30px;
    align-items: center;
    label,
    input {
      margin-top: 0px !important;
    }
    input {
      width: 20px !important;
      height: 20px !important;
      margin-right: 18px;
    }
  }

  .stockLevel {
    width: 92px !important;
  }

  .skuContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .stockKeepingUnit {
    width: 255px !important;
  }

  .questionMark {
    font-size: 16px;
    color: #717171;
    width: 32px;
    height: 32px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 8px;
    cursor: pointer;
  }

  .header-action-buttons {
    background-color: ${({ blue }) => (blue ? "#2B3C45" : "#f5f5f5")};
    border-radius: 20px;
    padding: 0px 30px;
    height: 40px;
    border: none;
    margin-right: 16px;
    color: ${({ blue }) => (blue ? "#ffffff" : "#606060")};
    font-size: 14px;
    font-family: Open Sans;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: ${({ blue }) => (blue ? "#2011f2" : "#f1f1f1")};
    }
    &:focus {
      box-shadow: ${({ blue }) => (blue ? "none" : "0px 0px 0px 1px #2B3C45")};
      /* color: #2B3C45; */
    }
  }

  .submitActionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    padding: 0 12px;
    margin-bottom: 4em;
  }

  .form-section {
    display: flex;
    justify-content: center;
    background: #ffffff;

    .form-body {
    }
  }
`;

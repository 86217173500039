import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import styled from "styled-components";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import FeatherIcon from "feather-icons-react";

import useDetectScreenSize from "@hooks/useDetectScreenSize";

import helpers from "@utils/helpers";
import buttons from "@styles/buttons";
import orderStyles from "./Orders.styles";
import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";
import order from "@services/orderService";

import OrderListTable from "./OrderListTable";
import OrderListCard from "./OrderListCard";

export default function NoOrders() {
  const [page, setPage] = useState("");
  const storeId = localStorage.getItem(helpers.getStoreKeyName());
  const size = 10;

  const { largeScreen } = useDetectScreenSize();

  const { isLoading, isError, error, data } = useQuery(
    ["orders", { storeId, page, size }],
    order.fetchOrders,
    {
      refetchOnWindowFocus: true,
      keepPreviousData: true
    }
  );

  if (isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message={error.message} />
        </SlideToTop>
      </Loader>
    );
  }

  return (
    <Wrapper
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="title">
        <h2>Orders</h2>
        <NavLink to="/orders/add">Create Order</NavLink>
      </div>

      {isLoading && !data && (
        <div className="SkeletonContainer">
          <Skeleton height={40} count={5} />
        </div>
      )}

      {!isLoading && data && (
        <>
          {data.length > 0 ? (
            <>
              {largeScreen && <OrderListTable orders={data} />}
              {!largeScreen && <OrderListCard orders={data} />}
            </>
          ) : (
            <motion.div className="emptyOrdersCard">
              <FeatherIcon icon="package" />
              <h4>No orders</h4>
              <p>New orders will appear here</p>
            </motion.div>
          )}
        </>
      )}
    </Wrapper>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(motion.div)`
  position: relative;
  width: 100%;

  .title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4em;
    padding: 0 12px;
    top: 1em;

    h2 {
      ${orderStyles.title}
    }

    a {
      ${buttons};
      background-color: #2b3c45;
      text-align: center;
    }
  }

  .SkeletonContainer {
    padding: 25px;
    background: #ffffff;
    border-radius: 4px;
  }

  .showLoader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .emptyOrdersCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #e3e3e3;
    color: #d4d4d4;
    width: 90%;
    margin: 180px auto 0;
    height: 359px;

    h4 {
      font-size: 20px;
      margin: 14px 0 0 0;
    }

    p {
      font-size: 14px;
      margin-top: 7px;
    }
  }
`;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "configureStore";
import { useMutation } from "react-query";
import { Form, Formik } from "formik";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { settlementActions } from "../Settlement.state";

import notification from "@utils/notification";
import helpers from "@utils/helpers";
import {
  formInput,
  RenderInput,
  inputContainer
} from "@sharedComponent/FormElements";
import SmallLoadingIndicator from "@sharedComponent/SmallLoadingIndicator";

import bankAccount from "@services/bankAccountService";

export default function ReviewBankAccount({ setShowReview, selectedBankId }) {
  const dispatch = useDispatch();

  const { bankAccountInfo } = useSelector(selectors.settlement);

  const businessId = localStorage.getItem(helpers.getBusinessKeyName());

  /** Add bank account */
  const saveBankAccount = useMutation(bankAccount.addBankAccount, {
    onSuccess: res => {
      setShowReview(false);
      dispatch(
        settlementActions.setBankAccount({
          bankAccountId: res.bankAccountId,
          bankAccountNumber: res.bankAccountNumber,
          bankName: res.bankName,
          bankAccountNickName: res.bankAccountNickName
        })
      );
      dispatch(settlementActions.setAccountInfo({}));
      notification.createNotify({
        title: "Success",
        message: "Bank account added",
        type: "success",
        position: "top-right"
      });
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const handleReviewandAddBankAccount = values => {
    // alert(JSON.stringify(values));
    saveBankAccount.mutate(values);
  };

  return (
    <Wrapper>
      <div className="subtitle">
        Please review the details below to ensure correctness.
      </div>

      <div className="clear__fix" />

      <div className="review_details">
        <div className="review_text">{bankAccountInfo.bankName}</div>
        <div className="review_text">{bankAccountInfo.bankAccountNumber}</div>
        <div className="review_text">{bankAccountInfo.bankAccountName}</div>
      </div>
      <div className="clear__fix" />

      <Formik
        initialValues={{
          bankExternalId: selectedBankId,
          bankName: bankAccountInfo.bankName,
          bankAccountNumber: bankAccountInfo.bankAccountNumber,
          bankAccountName: bankAccountInfo.bankAccountName,
          bankAccountNickName: "",
          businessId: businessId
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          // alert(JSON.stringify(values));
          handleReviewandAddBankAccount(values);
        }}
      >
        <Form>
          <div className="nuban__wrapper__action">
            <div className="input-container">
              <RenderInput
                label="Add nickname linked to this account (optional)"
                name="bankAccountNickName"
                type="text"
                placeholder=""
              />
            </div>
          </div>

          <div className="nuban__wrapper__action">
            <div className="input-container">
              <button
                type="button"
                className="btn-adjust"
                variant="cancel"
                onClick={() => setShowReview(false)}
              >
                Back
              </button>
            </div>
            <div className="submitAndErrorContainer">
              <button
                type="submit"
                className="btn-adjust"
                status={saveBankAccount.isLoading ? "disabled" : ""}
              >
                {saveBankAccount.isLoading ? "Checking..." : "Continue"}
              </button>

              {saveBankAccount.isLoading ? (
                <AnimatePresence>
                  <div className="loading-wrapper">
                    <SmallLoadingIndicator />
                  </div>
                </AnimatePresence>
              ) : null}
            </div>
          </div>
        </Form>
      </Formik>
      <div className="clear__fix" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  .title {
    font-size: 20px;
    color: #1e242d;
    font-weight: 600;
    padding: 0;
  }

  .review_details {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .review_text {
    font-size: 16px;
    color: #2b3c45;
    font-weight: 600;
  }

  .formInput {
    ${formInput}
  }

  .nuban__wrapper__action {
    display: flex;
    flex: 1 1 auto;
    gap: 2%;
    flex-direction: row;
    align-items: center;
  }

  .input-container {
    ${inputContainer}
  }

  .input-container {
    width: 300px;
  }

  .btn-adjust {
    margin-top: 2em;
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 28px;
    p {
      color: #2b3c45;
      margin-left: 10px;
    }
  }

  .submitAndErrorContainer {
    display: flex;
    flex-direction: row !important;
  }
`;

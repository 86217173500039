/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "@styles/cssResets";
import defaultTheme from "@styles/lite.theme";
import darkTheme from "@styles/dark.theme";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import ReactNotification from "react-notifications-component";
import http from "@utils/http";
import Notification from "@sharedComponent/Notification";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Routes from "./Routes";
import { Route, Switch, BrowserRouter } from "react-router-dom";
// import { useBeforeInstallPWAPrompt } from "@hooks/useAddToHomescreenPrompt";
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

/**
 * global config for React Query via QueryClient
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: 1
      // refetchOnWindowFocus: true,
      // retry: false
      // staleTime: 30000,
    }
  }
});

function App() {
  // useBeforeInstallPWAPrompt();

  useEffect(() => {
    http.setBaseUrl();
  }, []);

  return <ClearCacheComponent />;
}

function MainApp() {
  const app = useSelector(selectors.app);

  return (
    <>
      <ThemeProvider theme={app.darkMode ? darkTheme : defaultTheme}>
        <GlobalStyle />
        <ReactNotification />
        <QueryClientProvider client={queryClient}>
          <Routes />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        <Notification />
      </ThemeProvider>
    </>
  );
}

export default App;

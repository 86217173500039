import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
// import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import styled from "styled-components";

import helpers from "@utils/helpers";

import WeeklySales from "./WeeklySales";
import RecentOrders from "./RecentOrders";
import Miscellanous from "./Miscellanous";

// import seller from "@services/sellerService";
import report from "@services/reportingService";

export default function Main() {
  // const queryClient = useQueryClient();

  const storeId = localStorage.getItem(helpers.getStoreKeyName());
  // console.log('from main '+storeId)

  const { sellerId } = useSelector(selectors.app);

  // const sellerInfo = useQuery(
  //   ["fetch_seller_info", { sellerId }],
  //   seller.fetchSellerInfo,
  //   {
  //     initialData: () => {
  //       return queryClient.getQueryData(["fetch_seller_info", { sellerId }]);
  //     }
  //   }
  // );

  const { isLoading, isError, error, data } = useQuery(
    ["sales_aggregate", { storeId }],
    report.fetchSalesAggregate
  );

  return (
    <Wrapper>
      {/* <MainHeader name={sellerInfo?.data?.firstName || ""} /> */}

      <WeeklySales loading={isLoading} sales={data} isError={isError} />

      <section className="layout">
        <RecentOrders loading={isLoading} orders={data} isError={isError} />
        <Miscellanous loading={isLoading} sales={data} isError={isError} />
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: "Montserrat";
  width: 100%;

  .layout {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0 12px;
    margin-bottom: 5rem;
  }

  .skel {
    padding: 0 12px;
  }

  .container {
    padding: 25px;
    box-sizing: border-box;
    border: 1px solid #ebebeb;
    box-shadow: 1px 2px 2px #ebebeb;
    background: #ffffff;
    border-radius: 4px;
  }

  @media screen and (max-width: 400px) {
    .layout {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0 12px;
    }
  }
`;

import React from "react";

import CustomPriceInput from "@sharedComponent/CustomPriceInput";

export default function Price({ setFieldValue }) {
  return (
    <CustomPriceInput
      label="Price"
      name="price"
      setFieldValue={setFieldValue}
    />
  );
}

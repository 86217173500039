import axios from "axios";

const instance = axios.create({
  baseURL:
    window.config.REACT_APP_BASE_URL +
    window.config.REACT_APP_BACKEND_API_ENDPOINT
});

instance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export default instance;

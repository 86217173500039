import React from "react";
import styled from "styled-components";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import SettlementHome from "./Settlement";
import WithdrawFund from "./WithdrawFund";
import ReviewWithdrawFund from "./ReviewWithdrawFund";

export default function SettlementRoute() {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={`${path}`}>
          <SettlementHome />
        </Route>
        <Route exact path={`${path}/withdraw-fund`}>
          <WithdrawFund />
        </Route>
        <Route exact path={`${path}/review-withdraw-fund`}>
          <ReviewWithdrawFund />
        </Route>
      </Switch>
    </Container>
  );
}

const Container = styled.div`
  font-family: "Open Sans";

  .loading-wrapper {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1025px) {
    margin: 0;
  }
`;

import { createTheme } from "@mui/material/styles";
import green from "@mui/material/colors/blue";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2b3c45"
    },
    secondary: {
      light: "#2b3c45",
      main: "#0044ff"
    }
  }
});

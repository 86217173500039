import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function BankAccounts({ reviewMode }) {
  return (
    <Wrapper>
      <div className="title">To</div>
      <div className="clear__fix" />

      <div className="bank__account__wrapper">
        <div className="bank__account__title">Work account</div>
        <div className="bank__account__name">Ibeyemi Olatunde</div>
        <div className="bank__account__number">XXXXXXXXXX</div>

        {reviewMode ? (
          <>&nbsp;</>
        ) : (
          <div className="bank__account__action">Delete</div>
        )}
      </div>
    </Wrapper>
  );
}

BankAccounts.propTypes = {
  reviewMode: PropTypes.bool.isRequired
};

const Wrapper = styled.div`
  width: 100%;

  .title {
    font-size: 20px;
    color: #1e242d;
    font-weight: 600;
    padding: 0;
  }

  .bank__account__wrapper {
    display: flex;
    flex-direction: column;
  }

  .bank__account__title {
    font-size: 16px;
    color: #1e242d;
    font-weight: 500;
    padding: 0;
  }

  .bank__account__name {
    font-size: 16px;
    color: #1e242d;
    padding: 0;
  }

  .bank__account__number {
    font-size: 16px;
    color: #1e242d;
    padding: 0;
  }

  .bank__account__action {
    font-size: 14px;
    font-weight: 500;
    color: #2f2885;
    padding: 0;
    margin-top: 10px;
  }
`;

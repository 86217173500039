import React from "react";
import PropType from "prop-types";
import { motion } from "framer-motion";

export default function SlideToTop({ children }) {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

SlideToTop.propTypes = {
  children: PropType.node.isRequired
};

import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "configureStore";
import * as Yup from "yup";
import styled from "styled-components";

import { handleUpdateAvailableBalance } from "./Settlement.state";

import notification from "@utils/notification";
import helpers from "@utils/helpers";

import AmountToWithdraw from "./AmountToWithdraw";
import ActionHeader from "./Common/ActionHeader";

import { settlementActions } from "./Settlement.state";

import bankAccountApi from "@services/bankAccountService";
import walletApi from "@services/walletService";

const validationSchema = Yup.object({
  amount: Yup.number().required("Provide amount to withdraw"),
  bankAccountId: Yup.string().required(
    "Please select an account to withdraw to"
  )
});

export default function WithdrawFund() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { wallet, bankAccounts } = useSelector(selectors.settlement);

  const businessId = localStorage.getItem(helpers.getBusinessKeyName());

  const bankAcctList =
    bankAccounts &&
    bankAccounts.map(function(account) {
      return {
        key: account.bankAccountId,
        value: `${account.bankName} (${account.bankAccountNumber})`
      };
    });

  /** Fetch business bank accounts */
  useQuery(["bankAccounts", { businessId }], bankAccountApi.fetchBankAccounts, {
    initialData: () => {
      const cachedBankAccounts = queryClient.getQueryData([
        "bankAccounts",
        { businessId }
      ]);
      if (
        cachedBankAccounts &&
        Date.now() - cachedBankAccounts.dataUpdatedAt <= 86400
      ) {
        dispatch(settlementActions.setBankAccounts(cachedBankAccounts));
      }
    },
    onSuccess: res => {
      dispatch(settlementActions.setBankAccounts(res));
    }
  });

  /** Initiate Withdraw funds mutation */
  const withdrawRequestMutation = useMutation(walletApi.withdraw, {
    onSuccess: res => {
      dispatch(handleUpdateAvailableBalance(res.balanceAfterWithdrawal));

      notification.success("", "Funds withdrawn");
      history.push("/settings/settlement");
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onWithdrawFund = values => {
    if (values.amount === 0 || !helpers.isNumber(values.amount)) {
      notification.error(
        "Operation error",
        "Please enter a valid amount to withdraw"
      );
      return;
    }

    if (parseFloat(values.amount) > parseFloat(wallet.available_bal)) {
      notification.error("Operation error", "Insufficient wallet balance");
      return;
    }

    withdrawRequestMutation.mutate(values);
  };

  return (
    <Container>
      <ActionHeader title="Withdraw funds" action="cancel" />

      <Formik
        enableReinitialize
        initialValues={{
          amount: "",
          bankAccountId: bankAcctList?.[0]?.key || "",
          businessId: businessId
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          //alert(JSON.stringify(values));
          onWithdrawFund(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="card-container">
              <div className="cards">
                <div className="card-item">
                  <div className="card-row">
                    <AmountToWithdraw
                      setFieldValue={setFieldValue}
                      bankAcctList={bankAcctList}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="button-container">
              <div className="submitAndErrorContainer">
                <button
                  type="submit"
                  className="btn-spacing"
                  status={withdrawRequestMutation.isLoading ? "disabled" : ""}
                  data-testid="submit-form"
                >
                  {withdrawRequestMutation.isLoading ? (
                    <div className="justifyContentCenter">
                      <span>Withdrawing...</span>
                    </div>
                  ) : (
                    "Withdraw fund"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  color: rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 0;
  width: 100%;

  .card-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .cards {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 2rem;
    width: 100%;

    .card-item {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      max-width: 1200px;
      border-radius: 6px;
      border: 1px solid #ebebeb;
      overflow: hidden;
      transition: transform 0.5s;
      -webkit-transition: transform 0.5s;
    }

    .card-row {
      display: inline-flex;
      gap: 12em;
      flex: 1 1 auto;
      flex-direction: column;
      padding: 28px 18px 14px 32px;
      line-height: 1.5em;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 12px;
  }

  .btn-spacing {
    margin-bottom: 6rem;
  }

  .bottomText {
    font-size: 14px;
    color: #1e242d;
    padding: 0;
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 430px) {
    .cards {
      padding: 0;
      width: 100%;
      .card-item {
        width: 100%;
        .card-description {
          padding: 0px 0px 0px 0px;
        }
      }
    }
  }
`;

import jwtDecode from "jwt-decode";
import helpers from "@utils/helpers";

export default function getDecodedToken() {
  const token = localStorage.getItem(helpers.getAuthTokenName());
  if (!token) {
    return null;
  }
  return jwtDecode(token);
}

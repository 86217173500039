import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";

import Icons from "@sharedComponent/Icons";

export default function BottomNavigation() {
  const location = useLocation();
  const history = useHistory();
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      setActivePath(pathname);
    });

    return () => {
      unlisten();
    };
  }, []);
  return (
    <Container>
      <div className="links-wrapper">
        <NavLink exact to="/">
          <FeatherIcon icon="home" size="24" />
          <AnimatePresence>
            {activePath === "/" && (
              <motion.span
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 35 }}
                exit={{ opacity: 0, width: 0 }}
                transition={{ duration: 0.5 }}
                className="title"
              >
                Home
              </motion.span>
            )}
          </AnimatePresence>
        </NavLink>
        <NavLink to="/orders">
          <FeatherIcon icon="package" size="24" />
          <AnimatePresence>
            {activePath === "/orders" && (
              <motion.span
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 35 }}
                exit={{ opacity: 0, width: 0 }}
                transition={{ duration: 0.5 }}
                className="title"
              >
                Orders
              </motion.span>
            )}
          </AnimatePresence>
        </NavLink>
        <NavLink to="/products">
          <FeatherIcon icon="tag" size="24" />
          <AnimatePresence>
            {activePath === "/products" && (
              <motion.span
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 35 }}
                exit={{ opacity: 0, width: 0 }}
                transition={{ duration: 0.5 }}
                className="title"
              >
                Products
              </motion.span>
            )}
          </AnimatePresence>
        </NavLink>
        <NavLink to="/settings">
          <FeatherIcon icon="settings" size="24" />
          <AnimatePresence>
            {activePath === "/settings" && (
              <motion.span
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 45 }}
                exit={{ opacity: 0, width: 0 }}
                transition={{ duration: 0.5 }}
                className="title"
              >
                Manage
              </motion.span>
            )}
          </AnimatePresence>
        </NavLink>
      </div>
    </Container>
  );
}

const Container = styled.ul`
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: #2b3c45;
  overflow-x: hidden;
  padding: 0px 15px;
  box-sizing: border-box;
  .links-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
  }
  a {
    color: #ffffff;
    padding: 15px 0px;
    opacity: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      margin-left: 5px;
      padding: 0px 15px 0px 0px;
      font-family: "Open Sans";
    }
    &.active {
      opacity: 1;
    }
  }
  svg {
    width: 28px;
  }
`;

/**
 * notification service,
 * will handle local app notification
 * and also push notification if needed
 * implements success, warning and error methods for notifications
 */

import swal from "sweetalert";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";

export default {
  success: (title, msg) => {
    swal({
      title,
      text: msg,
      icon: "success",
      button: false
    });
  },

  error: (title, msg) => {
    swal({
      title,
      text: msg,
      icon: "error",
      button: false
    });
  },

  info: (title, msg) => {
    swal({
      title,
      text: msg,
      icon: false
    });
  },

  warning: (title, msg) => {
    swal({
      title,
      text: msg,
      icon: "warning",
      button: false
    });
  },

  createNotify: obj => {
    store.addNotification({
      title: obj.title,
      message: obj.message,
      type: obj.type, // 'default', 'success', 'info', 'warning', 'danger'
      container: obj.position || "top-right", // where to position the notifications
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000
      },
      dismissable: { click: true }
    });
  }
};

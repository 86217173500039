import _ from "lodash";
import React from "react";

export default {
  getAuthTokenName: () => {
    return process.env.REACT_APP_AUTH_TOKEN_NAME;
  },

  getRefreshTokenName: () => {
    return process.env.REACT_APP_REFRESH_TOKEN_NAME;
  },

  getStoreKeyName: () => {
    return process.env.REACT_APP_STORE_ALIAS;
  },

  getBusinessKeyName: () => {
    return process.env.REACT_APP_BUSINESS_ID_ALIAS;
  },

  formatAmount: amount => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(amount)) {
      return 0;
    }
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatAmountWithSymbol: amount => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(amount) || !amount) return `&#8358;&nbsp;${amount}`;

    const amt = parseFloat(amount)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //return `&#8358;${amt.slice(0, -3)}.<small>${amt.slice(-2)}</small>`;
    return `&#8358;&nbsp;${amt.slice(0, -3)}`;
    // let curr = <>&#8358;</>
    // return `${curr} ${amt.slice(0, -3)}`;
  },

  isNumber: n => {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parseFloat(n)) && isFinite(n);
  },

  toLowerCase: n => {
    return n.toString().toLowerCase();
  },

  randNum: len => {
    var val = "";
    for (var i = 0; i < len; i++) {
      val += Math.floor(Math.random() * 10);
    }
    return val;
  },

  invoiceRefExtractor: url => {
    return url.split("/")[4];
  },

  addCommaDelimiterToAmount: num => {
    return num.replace(/(.)(?=(.{3})+$)/g, "$1,");
  },

  removeCommaDelimiterToAmount: string => {
    return string.replace(/,/g, "");
  },

  numberFormat: value => {
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN"
    }).format(value);
  }
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setToken } from "@utils/setToken";
import { actions } from "@App/App.state";
import getDecodedToken from "@utils/getDecodedToken";
import SideNavigation from "./SideNavigation";
import MainContent from "./MainContent";
import BottomNavigation from "./BottomNavigation";
import TopNavigation from "./TopNavigation";
import useDetectScreenSize from "@hooks/useDetectScreenSize";
// import { useLocation } from "react-router-dom";

export default function Dashboard() {
  // const [isError, setIsError] = useState(false);

  // const location = useLocation();
  // console.log(location.pathname);
  const dispatch = useDispatch();
  const [sellerId, setSellerId] = useState(null);

  const { largeScreen } = useDetectScreenSize();

  useEffect(() => {
    setToken();
    // checkPath();
    const token = getDecodedToken();
    setSellerId(token?.sub);
    dispatch(actions.setSellerId(token?.sub));
  }, []);

  return (
    <Container>
      <section>{largeScreen && <SideNavigation />}</section>
      <section className="main">
        {!largeScreen && <TopNavigation />}
        <section>
          <MainContent />
        </section>
        {!largeScreen && <BottomNavigation />}
      </section>

      {/* {sellerId && <TopNavigation />}

      <section>
        {largeScreen && <SideNavigation />}
        <section>
          <MainContent />
        </section>
        {!largeScreen && <BottomNavigation />}
      </section> */}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  z-index: -1;
  .main {
    display: flex;
    flex-direction: column;
  }
`;

import http from "./httpService";

const api = "/bankAccounts";

/**
 * Get business bank accounts.
 *
 * @returns
 */
export async function fetchBankAccounts({ queryKey }) {
  const [, { businessId }] = queryKey;

  const { data } = await http.get(`${api}?businessId=${businessId}`);

  return data;
}

/**
 * Add bank account.
 *
 * @param {*} payload
 * @returns
 */
export async function addBankAccount(payload) {
  const { data } = await http.post(api, payload, {
    "Content-Type": "application/json"
  });

  return data;
}

export default {
  fetchBankAccounts,
  addBankAccount
};

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import useModal from "@hooks/useModal";
// import Table from "@sharedComponent/Table";
import Modal from "@sharedComponent/Modal";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";
import DeleteProduct from "../Common/DeleteProduct";
import { productData } from "./ProductData";

import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ElipsisMenu from "@sharedComponent/ElipseisMenu";
import ProductTableColumns from "./ProductTableColumn";
import FeatherIcon from "feather-icons-react";

export default function ProductsList({ products }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const history = useHistory();

  const { isVisible, toggleModal } = useModal();
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const redirectRoute = url => {
    history.push(url);
  };

  const triggerDeleteProduct = productId => {
    const selected = products.find(product => product.productId === productId);
    setSelectedProduct(selected);

    setShowDeleteProductModal(true);
    toggleModal();
  };

  // products = productData;
  const columns = ProductTableColumns;
  console.log({ "products:": products });

  return (
    <Wrapper>
      <div className="productTableWrapper">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="tableLabel"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {products
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.productId}
                    >
                      {columns.map(column => {
                        const value =
                          column.id === "name" ? (
                            <div className="productItems">
                              <div className="imgBox">
                                {row.images.length > 0 ? (
                                  <img
                                    src={row.images[0].imageUrl}
                                    alt={column.name}
                                    className="imgResponsive"
                                  />
                                ) : (
                                  <FeatherIcon icon="camera" />
                                )}
                                {/* <img
                                  src={
                                    row.images.length > 0
                                      ? row.images[0].imageUrl
                                      : ""
                                  }
                                  alt={column.name}
                                  className="imgResponsive"
                                /> */}
                              </div>
                              {row.name}
                            </div>
                          ) : (
                            row[column.id]
                          );
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "elipsis" ? (
                              <ElipsisMenu
                                triggerDeleteProduct={() =>
                                  triggerDeleteProduct(row.productId)
                                }
                                id={row.productId}
                              />
                            ) : column.id === "price" ? (
                              <AmountWithSymbol amount={value} />
                            ) : (
                              // <>&#8358;<span style={{marginLeft:"5px"}}>{value}</span></>
                              value
                            )}
                            {/* {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value
                            } */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      {showDeleteProductModal && (
        <Modal
          isVisible={isVisible}
          hideModal={toggleModal}
          title="Delete product"
          id="deleteProduct"
          width="400"
        >
          <DeleteProduct product={selectedProduct} onClose={toggleModal} />
        </Modal>
      )}
    </Wrapper>

    // <Wrapper>
    //   <div className="productTableWrapper">
    //     <Table columns={columns} data={products} />
    //   </div>

    //   {showDeleteProductModal && (
    //     <Modal
    //       isVisible={isVisible}
    //       hideModal={toggleModal}
    //       title="Delete product"
    //       id="deleteProduct"
    //       width="400"
    //     >
    //       <DeleteProduct product={selectedProduct} onClose={toggleModal} />
    //     </Modal>
    //   )}
    // </Wrapper>
  );
}

ProductsList.propTypes = {
  products: PropTypes.array.isRequired
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Open Sans;
  margin: 0;
  padding: 0;
  width: 100%;

  .productTableWrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8em;
    margin-bottom: 4em;
    box-sizing: border-box;
    overflow: hidden;

    .MuiPaper-rounded{
      padding-bottom:75px;
    }
  }

  .tableLabel{
    color: #2b3c45;
    opacity:0.5;
    width: 52px;
    height: 24px;
    font-size: 14px;
    text-decoration: none solid rgba(43, 60, 69, 0.5);
    line-height: 24px;
  }

  .productItems {
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .imgBox {
    height: 48px;
    width: 48px;
    padding: 2px;
    background: #ffffff;
    border: 1px solid #E3E3E3;

    img {
      height: 48px;
      width: 48px;
      object-fit: contain;
    }

    svg {
      height: 48px;
      width: 48px;
      object-fit: contain;
    }
  }

  .productName {
    font-weight: 600;
    color: #2B3C45;
  }

  .productStock {
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .productStock .tooltiptext {
    background-color: #2B3C45;
    background-size: cover;
    color: #ffffff;
    font-size: 12px;
    padding: 3px 0;
    text-align: center;
    visibility: hidden;
    width: 120px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .productStock:hover .tooltiptext {
    visibility: visible;
  }

  .productPrice {
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .menu {
    position: relative;
  }

  .menu button.ellipsis {
    margin-top: 15px;
    background: transparent;
    color: #907e99;
    text-decoration: none;
    border: none;
    outline: none;
    font-size: 22px;
    height: 1px !important;
    min-width: 0 !important;
    padding: 1px;
    cursor: pointer;
  }

  .menu button.ellipsis:focus {
    outline: 0;
    box-shadow: none;
  }

  .menu button.ellipsis:active {
    outline: none;
    border: none;
  }

  .menu button.ellipsis:focus + ul {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0px);
  }

  .menu ul {
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    top: 40px;
    right: 0;
    width: 150px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 100;
  }

  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .icons {
    margin-right: 5px;
  }

  .menu li a {
    text-decoration: none;
    color: #2B3C45 !important;
    font-weight: 500;
    padding: 15px 20px;
    display: block;
    font-size: 14px;
    cursor: pointer;

    svg {
      fill: #2B3C45 !important
      margin-right: 5px;
    }
  }

  .menu li:hover {
    background-color: #fafafa !important;
  }

  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
    margin-top:13px;
  }

  .css-1ex1afd-MuiTableCell-root{
    color:#2b3c45 !important;
  }

  .css-1fw5qro-MuiPaper-root{
    box-shadow: none;
  }
 
`;

import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "app",
  initialState: {
    darkMode: false,
    sellerId: null,
    businessId: null,
    storeId: null,
    walletId: null,
    showNotification: false,
    notificationMessage: "",
    deferredPromptPWA: null,
    showInstallPWAPrompt: false,
    pwaIsInstalled: false
  },
  reducers: {
    toggleTheme: state => {
      state.darkMode = !state.darkMode;
    },
    setSellerId: (state, action) => {
      state.sellerId = action.payload;
    },
    setBusinessId: (state, action) => {
      state.businessId = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },
    setSellerId: (state, action) => {
      state.sellerId = action.payload;
    },
    setWalletId: (state, action) => {
      state.walletId = action.payload;
    },
    setShowNotification: (state, action) => {
      state.showNotification = true;
      state.notificationMessage = action.payload;
    },
    setHideNotification: state => {
      state.showNotification = false;
      state.notificationMessage = "";
    },
    setPWAInstallPrompt(state, action) {
      state.deferredPromptPWA = action.payload;
      state.showInstallPWAPrompt = true;
    },
    setPWAInstalled: (state, action) => {
      state.pwaIsInstalled = action.payload;
    },
    hidePWAInstallPrompt(state) {
      state.showInstallPWAPrompt = false;
    },
    removePWAInstallPrompt(state) {
      state.deferredPromptPWA = null;
      state.showInstallPWAPrompt = false;
    },
    reset: state => {
      state.sellerId = null;
      state.businessId = null;
      state.storeId = null;
      state.walletId = null;
    }
  }
});

export default slice.reducer;
export const { ...actions } = slice.actions;

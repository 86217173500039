import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

import Icons from "@sharedComponent/Icons";
import SlideToTop from "@sharedComponent/SlideToTop";
import LoadingState from "@sharedComponent/LoadingState";
import ErrorState from "@sharedComponent/ErrorState";
import order from "@services/orderService";

export default function VerifyCheckoutPayment() {
  const params = useParams();

  // const [isLoading, setIsLoading] = useState(true);

  const paymentRef = params.reference;
  const paidAmount = params.amount;

  const verifyTransactionMutation = useMutation(order.confirmPayment, {
    onSuccess: res => {
      // console.log(`verification response ${JSON.stringify(res)}`);
    }
  });

  useEffect(() => {
    verifyTransactionMutation.mutate({
      amount: paidAmount,
      paymentReference: paymentRef
    });
  }, []);

  return (
    <Wrapper>
      <div className="verifyContainer">
        {verifyTransactionMutation.isLoading &&
          !verifyTransactionMutation.data && (
            <Loader>
              <SlideToTop>
                <LoadingState message="verifying..." />
              </SlideToTop>
            </Loader>
          )}

        {verifyTransactionMutation.isError && (
          <Loader>
            <SlideToTop>
              <div style={{ zIndex: "2147483647" }}>
                <ErrorState message={verifyTransactionMutation.error.message} />
              </div>
            </SlideToTop>
          </Loader>
        )}

        {!verifyTransactionMutation.isLoading &&
          verifyTransactionMutation.data && (
            <motion.div
              className="paymentBreakDown"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.7 }}
            >
              <div className="checkIcon">
                <FeatherIcon icon="check-circle" size={72} color="#28a745" />
              </div>

              <div className="title">Payment receieved</div>

              <div className="message">Thank you for shopping with us!</div>

              <div className="ads">
                <span>Powered by</span>
                <span>
                  <Icons.DorokiLogoBlue />
                </span>
              </div>
            </motion.div>
          )}
      </div>
    </Wrapper>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
`;

const Wrapper = styled.div`
  font-family: Open Sans;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  position: absolute;

  .verifyContainer {
    margin-top: 3em;
    margin-bottom: 2em;
    width: 674px;
    height: 100%;
    background-color: #fafafa;
    padding: 25px 50px;
    box-sizing: border-box;
    box-shadow: 1px 2px 2px #e3e3e3;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    color: #1e242d;
  }

  .paymentBreakDown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .checkIcon {
    margin-top: 5em;
  }

  .title {
    color: #2b3c45;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .message {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 15em;
    text-align: center;
  }

  .ads {
    color: #060606;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    gap: 0.5em;

    svg {
      width: 90px;
    }
  }
`;

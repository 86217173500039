import React, { useEffect, useRef } from "react";
import moment from "moment";
import Chart from "chart.js";
import styled from "styled-components";
import helpers from "@utils/helpers";

let myLineChart;

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'Open Sans', sans-serif";
//--Chart Style Options--//

export default function LineChart({ aggregates }) {
  const chartRef = useRef();

  const orders = aggregates.map(function(sale) {
    return { x: sale.quantity, y: sale.amount };
  });

  const labels = aggregates.map(function(item) {
    let preformatLabel = moment(item.date).format("ddd");
    return preformatLabel;
  });

  const maxNum = Math.max.apply(
    Math,
    orders.map(function(val) {
      return val.y + 1000;
    })
  );

  const minNum = Math.min.apply(
    Math,
    orders.map(function(val) {
      return val.y;
    })
  );

  useEffect(() => {
    buildChart();
  }, []);

  const buildChart = () => {
    const myChartRef = chartRef.current.getContext("2d");

    if (typeof myLineChart !== "undefined") myLineChart.destroy();

    myLineChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Orders",
            data: orders,
            fill: false,
            borderColor: "#2B3C45",
            pointRadius: 8,
            pointBackgroundColor: "#ffffff",
            pointHoverBackgroundColor: "#2B3C45"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: false,
        elements: {
          line: {
            tension: 0.000001
          }
        },
        scales: {
          xAxes: [
            {
              ticks: { display: true },
              gridLines: {
                display: false,
                drawBorder: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                display: false,
                suggestedMin: minNum,
                suggestedMax: maxNum
              },
              gridLines: {
                display: false,
                drawBorder: false
              },
              scaleLabel: {
                display: false
              }
            }
          ]
        },
        tooltips: {
          displayColors: false,
          titleFontSize: 14,
          bodyFontSize: 12,
          xPadding: 20,
          yPadding: 10,
          callbacks: {
            // label: (tooltipItem, data) => {
            //   return `₦ ${helpers.formatAmount(tooltipItem.value)}`;
            // },
            label: function(tooltipItem, data, context) {
              var newLine = "\r\n";
              var label = "";
              const sets = data.datasets[0].data;
              var count = sets.length;
              for (var i = 0; i < count; i++) {
                if (i === tooltipItem.index) {
                  label += `${sets[i].x} orders`;
                  label += newLine;
                  label += `₦ ${helpers.formatAmount(sets[i].y)}`;
                }
              }
              return label;
            },
            labelColor: function(tooltipItem, chart) {
              return {
                borderColor: "#2B3C45",
                backgroundColor: "#2B3C45"
              };
            },
            labelTextColor: function(tooltipItem, chart) {
              return "#ffffff";
            }
          },
          backgroundColor: "#2B3C45"
        }
      }
    });
  };

  return (
    <Wrapper>
      <div className="graphContainer">
        <canvas id="myChart" ref={chartRef} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  .graphContainer {
    height: 14vh;
    padding: 10px;
    border-radius: 4px;
    background: linear-gradient(180deg, #ebebf4 0%, #ffffff 100%);
  }
`;

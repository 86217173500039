import axios from "@utils/axiosConfig";
import _ from "lodash";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import getDecodedToken from "@utils/getDecodedToken";
import helpers from "@utils/helpers";
import logger from "./logService";

// const baseURL = window.config.REACT_APP_BACKEND_API_ENDPOINT;
// axios.defaults.baseURL = baseURL;
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

let refreshTokenCount = 0;

function setJwt(type, value) {
  axios.defaults.headers.common[type] = value;
}

// Add a request interceptor
axios.interceptors.request.use(config => {
  const token = localStorage.getItem(helpers.getAuthTokenName());

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    nprogress.done(true);
    return response;
  },
  error => {
    // Reject promise if usual error
    if (error.response.status !== 401) {
      nprogress.done(true);
      logger.log(error);
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === "/token") {
      logger.log(error);
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === "/sellers") {
      logger.log(error);
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (refreshTokenCount >= 3) {
        localStorage.removeItem(helpers.getAuthTokenName());
        localStorage.removeItem(helpers.getRefreshTokenName());
        localStorage.removeItem(helpers.getStoreKeyName());
        localStorage.removeItem(helpers.getBusinessKeyName());
        localStorage.removeItem("persist:root");

        window.location.reload("/login");
      }

      if (refreshTokenCount < 3) {
        originalRequest._retry = true;
        refreshTokenCount += 1;

        const refereshToken = localStorage.getItem(
          helpers.getRefreshTokenName()
        );

        const userData = getDecodedToken();

        const params = new URLSearchParams({
          grant_type: "refresh_token",
          user_id: userData.sub,
          refresh_token: refereshToken
        }).toString();

        return axios
          .post("/token", params, {
            "Content-Type": "application/x-www-form-urlencoded"
          })
          .then(res => {
            const token = res.data.access_token;
            const refresh_token = res.data.refresh_token;
            localStorage.setItem(helpers.getAuthTokenName(), token);
            localStorage.setItem(helpers.getRefreshTokenName(), refresh_token);

            const tokenKey = localStorage.getItem(helpers.getAuthTokenName());
            setJwt("Authorization", `Bearer ${tokenKey}`);

            return axios(originalRequest);
          });
      }
    }
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};

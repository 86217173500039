import http from "./httpService";

const api = "/wallet";

/**
 * Get bank list.
 *
 * @returns
 */
export async function fetchBankList() {
  const { data } = await http.get(`${api}/account/banks`);

  return data;
}

/**
 * Get business withdrawals.
 *
 * @returns
 */
export async function fetchWithdrawals({ queryKey }) {
  const [, { bankAccountId, businessId, page, size }] = queryKey;

  const { data } = await http.get(
    `${api}/withdrawals?bankAccountId=${bankAccountId}&businessId=${businessId}&page=${page}&size=${size}`
  );

  return data;
}

/**
 * Get wallet balance.
 *
 * @param {*} param0
 * @returns
 */
export async function fetchWalletBalance({ queryKey }) {
  const [, { walletId }] = queryKey;

  const { data } = await http.get(`${api}/account/${walletId}/accountBalance`);

  return data;
}

/**
 * Bank account name enquiry.
 *
 */
export async function accountNameEnquiry(params) {
  const { data } = await http.get(
    `${api}/bankAccount/nameInquiry?accountId=${params.walletAccountId}&bankId=${params.bank}&bankAccountNumber=${params.nuban}`
  );

  return data;
}

/**
 * Withdraw funds.
 *
 * @param {*} payload
 * @returns
 */
export async function withdraw(payload) {
  const { data } = await http.post(`${api}/withdraw`, payload);

  return data;
}

export default {
  fetchBankList,
  fetchWithdrawals,
  fetchWalletBalance,
  accountNameEnquiry,
  withdraw
};

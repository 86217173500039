import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";

import useModal from "@hooks/useModal";
import helpers from "@utils/helpers";

import Icons from "@sharedComponent/Icons";
import CustomPriceInput from "@sharedComponent/CustomPriceInput";
import SmallLoadingIndicator from "@sharedComponent/SmallLoadingIndicator";

import AddCustomerAddress from "../Common/AddCustomerAddress";
import CustomerAddress from "../Common/CustomerAddress";
import PickUpLocation from "../Common/PickUpLocation";
import Modal from "../Common/Modal";

import { orderActions } from "../Orders.state";

import customer from "@services/customerService";
import store from "@services/storeService";

import {
  formInput,
  inputContainer,
  radioButtonContainer,
  RenderRadio,
  RenderPriceInput
} from "@sharedComponent/FormElements";

const shippingType = [
  { key: "Delivery", value: "DELIVERY" },
  { key: "Pickup", value: "PICKUP" }
];

export default function EditShipping({ setFieldValue, customerId, addressId }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);

  // const [option, setOption] = useState(orderRequest.shipping.shippingOption);
  const [option, setOption] = useState(null);

  const storeId = localStorage.getItem(helpers.getStoreKeyName());

  const { isVisible, toggleModal } = useModal();

  /** fetch order shipping address */
  const fetchOrderShippingAddress = useQuery(
    ["address_info", { addressId }],
    customer.fetchAddressInfo,
    { refetchOnWindowFocus: true }
  );

  /** fetch customer addresses */
  const fetchCustomerAddress = useQuery(
    ["customer_address", { customerId }],
    customer.fetchCustomerAddress,
    { refetchOnWindowFocus: true }
  );

  /** fetch store pickup addresses */
  const fetchStorePickUpAddress = useQuery(
    ["store_pickup_address", { storeId }],
    store.fetchStorePickUpAddress
  );

  /** fetch countries */
  const fetchCountries = useQuery("countries", customer.fetchCountries, {
    initialData: () => {
      const cachedCountries = queryClient.getQueryData("countries");
      if (
        cachedCountries &&
        Date.now() - cachedCountries.dataUpdatedAt <= 86400
      ) {
        setCountry(cachedCountries);
      }
    },
    onSuccess: res => {
      setCountry(res);
    }
  });

  /** fetch regions */
  const fetchRegions = useQuery("regions", customer.fetchRegions, {
    initialData: () => {
      const cachedRegions = queryClient.getQueryData("regions");
      if (cachedRegions && Date.now() - cachedRegions.dataUpdatedAt <= 86400) {
        setRegion(cachedRegions);
      }
    },
    onSuccess: res => {
      setRegion(res);
    }
  });

  const handleSelectAddress = query => {
    dispatch(orderActions.setShippingAddress(query));
  };

  if (
    fetchOrderShippingAddress.isLoading &&
    fetchCustomerAddress.isLoading &&
    fetchStorePickUpAddress.isLoading &&
    fetchCountries.isLoading &&
    fetchRegions.isLoading
  ) {
    return (
      <Wrapper>
        <AnimatePresence>
          <div className="loading-wrapper">
            <SmallLoadingIndicator />
          </div>
        </AnimatePresence>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <section className="top-section">
        <h4>Delivery</h4>
      </section>

      <div className="shippingAddressForm">
        <div className="shippingAddressFormContent">
          <div className="radioContainer">
            <RenderRadio
              label="Shipping or pick up?"
              options={shippingType}
              name="shippingType"
              onChange={e => {
                setFieldValue("shippingType", e.target.value);
                setOption(e.target.value);
              }}
            />
          </div>

          {/* show existing order address record */}

          {/* {fetchOrderShippingAddress.data &&
            orderRequest.shipping.shippingOption === "DELIVERY" && (
              <>
                <span className="optionCaption">Delivery address:</span>
                <div className="streetAddress">
                  {fetchOrderShippingAddress.data.streetAddress},{" "}
                  {
                    fetchOrderShippingAddress.data.streetAddress
                      .localGovernmentArea
                  }
                  ,{fetchOrderShippingAddress.data.city},{" "}
                  {fetchOrderShippingAddress.data.region},
                </div>
                <span className="optionCaption">Note:</span>
                <div className="note">
                  {fetchOrderShippingAddress.data.remarks}
                </div>
              </>
            )} */}

          {/* {fetchOrderShippingAddress.data &&
            orderRequest.shipping.shippingOption === "PICKUP" && (
              <>
                <span className="optionCaption">Pickup address:</span>
                <div className="streetAddress">
                  {fetchOrderShippingAddress.data.streetAddress},{" "}
                  {
                    fetchOrderShippingAddress.data.streetAddress
                      .localGovernmentArea
                  }
                  ,{fetchOrderShippingAddress.data.city},{" "}
                  {fetchOrderShippingAddress.data.region},
                </div>
                <span className="optionCaption">Note:</span>
                <div className="note">
                  {fetchOrderShippingAddress.data.remarks}
                </div>
              </>
            )} */}

          {/* show customer address or pickup address based on shipping type */}

          {option === "DELIVERY" && (
            <>
              <div className="spacing" />
              <hr className="divider" />
              <div className="spacing" />
              <span className="optionCaption">Customer addresses</span>
              <div
                className="addCustomer"
                role="button"
                aria-hidden="true"
                onClick={toggleModal}
              >
                <Icons.PlusIcon /> Add a new delivery address
              </div>

              {fetchCustomerAddress.data.map(customerAddress => (
                <CustomerAddress
                  key={customerAddress.addressId}
                  address={customerAddress}
                  onChoose={handleSelectAddress}
                />
              ))}
            </>
          )}

          {option === "PICKUP" && (
            <>
              <div className="spacing" />
              <hr className="divider" />
              <div className="spacing" />
              {fetchStorePickUpAddress.data.map(pickUpAddress => (
                <PickUpLocation
                  key={pickUpAddress.addressId}
                  address={pickUpAddress}
                  onChoose={handleSelectAddress}
                />
              ))}
            </>
          )}

          <CustomPriceInput name="shippingFee" setFieldValue={setFieldValue} />

          <div className="inputContainer">
            {/* <div className="priceInputContainer">
              <RenderPriceInput
                label="Shipping fee"
                name="shippingFee"
                placeholder="0.00"
                onChange={e => {
                  setFieldValue("shippingFee", e.target.value);
                }}
              />
            </div> */}
          </div>
        </div>
      </div>

      <Modal
        isVisible={isVisible}
        hideModal={toggleModal}
        title="Add new delivery address"
      >
        <AddCustomerAddress
          countries={country}
          regions={region}
          onClose={toggleModal}
        />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: 25px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 10px;
  // height: 320px;
  // width: 60%;

  .optionCaption {
    font-size: 14px;
    color: #1e242d;
    margin: 4px;
  }

  .streetAddress {
    font-size: 14px;
    color: #6d6d6d;
    margin: 4px;
  }

  .note {
    font-size: 14px;
    color: #6d6d6d;
    margin-bottom: 4px;
  }

  .spacing {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 100%;
    h4 {
      font-family: Montserrat;
      font-size: 18px;
      color: #1e242d !important;
      margin: 0;import AddShippingAddress from '../Common/AddCustomerAddress';

    }
    span {
      color: #2B3C45;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .shippingAddressForm {
    margin-top: 2rem;

    .shippingAddressFormContent {
      display: flex;
      flex-direction: column;
    }
  }

  .formInput {
    ${formInput}
  }

  .inputContainer {
    margin-bottom: 16px;
    ${inputContainer}
  }

  .inputContainer {
    width: 40%;
  }

  .radioContainer {
    margin-bottom: 16px;
    ${radioButtonContainer}
  }

  // .priceInputContainer {
  //   position: relative;
  //   display: flex;
  //   flex-direction column;

  //   .inputLabel {
  //     margin-top: 1em;
  //     margin-bottom: 1em;
  //   }
  
  //   .currency-sign {
  //     position: absolute;
  //     margin-left: 15px;
  //     margin-top: 3.7em;
  //   }

  //   #price {
  //     width: 300px;
  //     padding-left: 30px;
  //     margin-top: 0px;
  //   }
  // }

  .formContainer {
    padding: 0;
  }

  .addCustomer {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    color: #2B3C45;
    cursor: pointer;
    margin-bottom: 1rem;

    svg {
      fill: #2B3C45;
      width: 20px !important;
      height: 20px !important;
      margin-right: 5px;
    }
  }

  @media only screen and (max-width: 384px) {
    width: 100%;
  }
`;

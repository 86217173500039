import { useDispatch } from "react-redux";
import helpers from "@utils/helpers";

import { authenticationActions } from "@App/Auth/Login/login.state";
import { actions } from "@App/App.state";

/**
 * Hook for handling logout across the app
 */

const useLogout = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(authenticationActions.removeUsernameAndToken());
    dispatch(actions.reset());

    localStorage.removeItem(helpers.getAuthTokenName());
    localStorage.removeItem(helpers.getRefreshTokenName());
    localStorage.removeItem(helpers.getStoreKeyName());
    localStorage.removeItem(helpers.getBusinessKeyName());
    localStorage.removeItem("persist:root");

    window.location.reload("/login");
  };
};

export default useLogout;

import React, { useState } from "react";
import styled from "styled-components";

import AddBankAccount from "./AddBankAccount";
import ReviewBankAccount from "./ReviewBankAccount";

export default function LinkNewBankAccount({ bankList, onClose }) {
  const [showReview, setShowReview] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [bankInfo, setBankAccountInfo] = useState({});

  return (
    <Wrapper>
      {!showReview ? (
        <AddBankAccount
          bankList={bankList}
          setShowReview={setShowReview}
          setSelectedBank={setSelectedBank}
        />
      ) : (
        <ReviewBankAccount
          setShowReview={setShowReview}
          selectedBankId={selectedBank}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  .title {
    font-size: 20px;
    color: #1e242d;
    font-weight: 600;
    padding: 0;
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 28px;
    p {
      color: #2b3c45;
      margin-left: 10px;
    }
  }
`;

import { css } from "styled-components";

export const buttonDefault = css`
  border: none;
  background-color: #2B3C45;
  border-radius: 20px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-family: inherit;
  line-height: 1.71;
  /* min-width: 160px; */
  padding: 8px 16px;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  user-select: none
  white-space: nowrap;

  &:hover {
    background-color: #213138;
    /* border: 2px solid #3b32ae; */
  }

  &:focus {
    border: 2px solid #2B3C45;
    background-color: ##213138;
    box-shadow: 0px 0px 0px 3px #bcb7ff;
  }

  &[variant="secondary"] {
    background-color: #ffffff;
    color: #2B3C45;
    border: 2px solid #2B3C45;
    white-space: nowrap;
    &:hover {
      color: #2B3C45;
      /* border: 2px solid #3b32ae; */
    }
    &:focus {
      border: 2px solid #2B3C45;
      box-shadow: 0px 0px 0px 3px #bcb7ff;
    }
  }

  &[variant="cancel"] {
    background-color: #f5f5f5;
    color: #606060;
    white-space: nowrap;
    &:hover {
      background-color: #bcb7ff;
      color: #404040;
      /* border: 2px solid #3b32ae; */
    }
    &:focus {
      border: 2px solid #2B3C45;
      box-shadow: 0px 0px 0px 3px #bcb7ff;
    }
  }

  &[variant="small"] {
    background-color: #2B3C45;
    color: #ffffff;
    padding: 6px 12px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    &:hover {
      background-color: #2B3C45;
      /* border: 2px solid #3b32ae; */
    }
    &:focus {
      border: 2px solid #2B3C45;
      box-shadow: 0px 0px 0px 3px #bcb7ff;
    }
  }

  &[variant="small_outline"] {
    background-color: #ffffff;
    border: 2px solid #2B3C45;
    color: #2B3C45;
    padding: 6px 12px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    &:hover {
      background-color: #2B3C45;
      color: #ffffff;
      /* border: 2px solid #3b32ae; */
    }
    &:focus {
      border: 2px solid #2B3C45;
      box-shadow: 0px 0px 0px 3px #bcb7ff;
    }
  }

  &[status="disabled"] {
    background-color: #f5f5f5;
    color: #dadada;
    border: 2px solid #f5f5f5;
    cursor: not-allowed;
    white-space: nowrap;
    &[variant="secondary"],
    &[variant="small"] {
      background-color: #ffffff;
      color: #dadada;
     /*  border: 2px solid #f5f5f5; */
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

export default {
  buttonDefault
};

import styled from "styled-components";

export default styled.input`
  width: 100%;
  height: 48px;
  padding: 0px 8px;
  box-sizing: border-box;
  border: 2px solid #e8e8e8;
  border-radius: 3px;
  background-color: #f8f7f7;
  background-size: cover;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #aba0a3;
  text-decoration: none solid rgb(0, 0, 0);
  margin: ${props => (props.margin ? props.margin : "0")};
  font-family: Cabin;
  outline: none;
  box-shadow: none;
  &:focus {
    border: 2px solid #2b3c45;
    box-shadow: 0px 0px 0px 3px #2b3c4529;
  }
  @media only screen and (max-width: 470px) {
    width: 100%;
  }
`;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default function AddProductHeader({ createProductMutation, type }) {
  const history = useHistory();

  const resizeHandler = () => {
    // console.log("resize listener 2");
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler, true);
    return () => {
      window.removeEventListener("resize", resizeHandler, true);
    };
  }, []);

  const onDiscardClick = () => {
    history.push("/products");
  };

  return (
    <Container>
      <div className="addProductLabel" data-testid="add-product">
        {type == "create" ? "Add product" : "Update product"}
      </div>

      <div className="rightSideHeader">
        <button variant="cancel" onClick={onDiscardClick}>
          Cancel
        </button>

        <button
          style={{ marginRight: "0px" }}
          type="submit"
          status={createProductMutation.isLoading ? "disabled" : ""}
        >
          {createProductMutation.isLoading
            ? type == "create"
              ? `Saving`
              : `Updating`
            : type == "create"
            ? "Save Product"
            : "Update Product"}
        </button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  font-size: 14px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
  z-index: 10;

  .addProductLabel {
    font-family: Montserrat;
    font-size: 24px;
    color: #2b3c45;
    font-weight: 500;
  }

  .rightSideHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .marginLeft {
    margin-left: 30px;
  }

  .blue {
    background-color: #2b3c45 !important;
    color: #ffffff !important;
  }

  @media screen and (max-width: 760px) {
    .addProductLabel {
      display: none;
    }

    .rightSideHeader {
      width: 100%;
    }

    .header {
      justify-content: center;
    }

    .dropDownContainer {
      margin-left: -17px;
      select {
        margin-left: 0px;
      }
    }
  }
`;

import React from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import AuthWrapper from "../AuthWrapper";

export default function ForgotPasswordSuccess() {
  const history = useHistory();

  return (
    <AuthWrapper
      title="We've sent you an email"
      // subTitle="You should receive an email from us shortly."
      redirectLink={{ name: "", url: "" }}
    >
      <>
        <div
          style={{
            margin: "60px 0px 60px 0px",
            fontFamily: "Monserrat Medium"
          }}
        >
          <p>You should receive an email from us shortly.</p>
          <span>
            Click the link in the email to reset your password. Your password
            will not be reset until a new one has been confirmed.
          </span>
        </div>

        <div className="submitAndErrorContainer">
          <motion.input
            type="submit"
            value="Done"
            data-testid="submit-form"
            onClick={() => {
              history.push("/login");
            }}
            className="submit-button form-buttons"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
          />
        </div>
      </>
      <div data-testid="custom-element" />
    </AuthWrapper>
  );
}

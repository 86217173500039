import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { Form, Formik } from "formik";
import notification from "@utils/notification";
import * as Yup from "yup";
import helpers from "@utils/helpers";
import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";
import LoadingState from "@sharedComponent/LoadingState";
import {
  handleLoadProductRequest,
  handleClearProductRequest
} from "../Products.state";
import product from "@services/productService";

import { formInput, inputContainer } from "../Common/SharedFormElements";
import AddProductHeader from "../Common/AddProductHeader";
import BasicInfo from "../Common/BasicInfo";
import Price from "../Common/Price";

const productSchema = Yup.object({
  name: Yup.string().required("Product name is required"),
  price: Yup.string().required("Product price is required")
});

export default function EditProduct() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const productId = params.id;
  const storeId = localStorage.getItem(helpers.getStoreKeyName());

  const { productRequest } = useSelector(selectors.products);

  const [tagsList, setTagsList] = useState([]);

  /**
   * Load product info
   */
  const { isLoading, isError, error } = useQuery(
    ["product_detail", { productId }],
    product.fetchProductInfo,
    {
      refetchOnWindowFocus: false,
      initialData: () => {
        const cachedData = queryClient.getQueryData([
          "product_detail",
          { productId }
        ]);

        if (cachedData && Date.now() - cachedData.dataUpdatedAt <= 86400) {
          dispatch(handleLoadProductRequest(cachedData));
        }
      },
      onSuccess: res => {
        const tags = res.tags ? res.tags : [];
        setTagsList(tags);
        dispatch(handleLoadProductRequest(res));
      }
    }
  );

  /**
   * Update product
   */
  const updateProductMutation = useMutation(product.updateProduct, {
    onSuccess: res => {
      notification.success("Product updated", "Product has been updated");
      history.push(`/products`);
    },
    onError: err => {
      notification.createNotify({
        title: "Error updating product",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    },
    onSettled: (data, error) => {
      dispatch(handleClearProductRequest()); // clear from state
    }
  });

  const onUpdateProductSubmit = values => {
    const req = { ...values, tags: tagsList };
    updateProductMutation.mutate({ ...req, productId });
  };

  if (isLoading) {
    return (
      <Loader>
        <SlideToTop>
          <LoadingState message="loading..." />
        </SlideToTop>
      </Loader>
    );
  }

  if (isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message={error.message} />
        </SlideToTop>
      </Loader>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={{
          storeId: storeId,
          name: productRequest.name || "",
          description: productRequest.description || "",
          tags: productRequest.tags || "",
          sku: productRequest.sku || "",
          stock: productRequest.stock || "",
          price: productRequest.price || ""
        }}
        validationSchema={productSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onUpdateProductSubmit(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <AddProductHeader
              createProductMutation={updateProductMutation}
              type="update"
            />
            <BasicInfo
              tagsList={tagsList}
              setTagsList={setTagsList}
              isStockTracked={productRequest.isStockTracked}
            />
            <Price setFieldValue={setFieldValue} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin-top: 16rem;
`;

const Container = styled.div`
  width: 100%;
  padding-bottom: 2em;

  .formContainer {
    width: 98%;
    background: #ffffff;
    margin: 12px auto 32px auto;
    box-sizing: border-box;
    padding: 31px 33px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #999999;
    .formTitle {
      font-family: Montserrat;
      font-size: 20px;
      color: #1e242d;
      font-weight: 600;
      padding: 0;
    }
    p {
      padding: 0;
      margin: 0;
      padding-top: 8px;
    }
    label {
      color: #1e242d;
      display: block;
      margin-top: 30px;
    }
    .formInput {
      ${formInput}
    }
    input {
      /* height: 40px; */
    }

    textarea {
      height: 80px;
    }
  }

  .marginLeft {
    margin-left: 30px;
  }

  .inputContainer {
    ${inputContainer}
  }

  .inputCheckboxContainer {
    position: relative;
    display: flex;
    margin-top: 30px;
    align-items: center;
    label,
    input {
      margin-top: 0px !important;
    }
    input {
      width: 20px !important;
      height: 20px !important;
      margin-right: 18px;
    }
  }

  .stockLevel {
    width: 92px !important;
  }

  .skuContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .stockKeepingUnit {
    width: 255px !important;
  }

  .questionMark {
    font-size: 16px;
    color: #717171;
    width: 32px;
    height: 32px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 8px;
    cursor: pointer;
  }

  .header-action-buttons {
    background-color: ${({ blue }) => (blue ? "#2B3C45" : "#f5f5f5")};
    border-radius: 20px;
    padding: 0px 30px;
    height: 40px;
    border: none;
    margin-right: 16px;
    color: ${({ blue }) => (blue ? "#ffffff" : "#606060")};
    font-size: 14px;
    font-family: Open Sans;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: ${({ blue }) => (blue ? "#2011f2" : "#f1f1f1")};
    }
    &:focus {
      box-shadow: ${({ blue }) => (blue ? "none" : "0px 0px 0px 1px #2B3C45")};
      /* color: #2B3C45; */
    }
  }

  .submitActionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    padding: 0 12px;
    margin-bottom: 4em;
  }
`;

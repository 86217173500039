import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import debounce from "lodash.debounce";
import { v4 as uuidv4 } from "uuid";

import Icons from "@sharedComponent/Icons";

import helpers from "@utils/helpers";
import SmallLoadingIndicator from "@sharedComponent/SmallLoadingIndicator";
import customer from "@services/customerService";

import { handleAddCustomer } from "../Orders.state";

export default function SearchCustomer({ onShowCustomerForm }) {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { data, isLoading } = useQuery("customers", customer.fetchCustomers, {
    refetchOnWindowFocus: false
  });

  if (!isLoading && data) {
    const getSuggestions = async word => {
      if (word) {
        const formatWord = helpers.toLowerCase(word);
        const results = data.filter(item => {
          let elements = item.contactDetails.filter(
            contact =>
              helpers.toLowerCase(contact.emailAddress).includes(formatWord) ||
              helpers.toLowerCase(contact.phoneNumber).includes(formatWord)
          );

          if (elements.length > 0) {
            return elements;
          } else {
            return (
              helpers.toLowerCase(item.firstName).includes(formatWord) ||
              helpers.toLowerCase(item.lastName).includes(formatWord)
            );
          }
        });

        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };

    // const debouncedSave = useCallback(
    //   debounce(newValue => getSuggestions(newValue), 1000),
    //   []
    // );

    const debouncedSave = debounce(newValue => getSuggestions(newValue), 1000);

    const updateSearchQuery = value => {
      setSearchTerm(value);
      debouncedSave(value);
    };

    // On Add new customer
    const onAddCustomer = () => {
      onShowCustomerForm(true);
      setSearchResults([]);

      dispatch(handleAddCustomer({}));
    };

    // On Select of old customer
    const onSelectCustomer = customer => {
      onShowCustomerForm(true);
      setSearchResults([]);

      dispatch(handleAddCustomer(customer));
    };

    const customerSearchDropDown = searchResults.map(val =>
      val.contactDetails.length > 1 ? (
        val.contactDetails.map(row => (
          <div
            key={row.contactId + uuidv4()}
            role="button"
            aria-hidden="true"
            className="search-dropdown--customers"
            onClick={() =>
              onSelectCustomer({
                customerId: val.customerId,
                contactId: row.contactId,
                firstName: val.firstName,
                lastName: val.lastName,
                emailAddress: row.emailAddress,
                phoneNumber: row.phoneNumber
              })
            }
          >
            <div className="name">
              {val.firstName} {val.lastName}
            </div>
            <div className="phone">
              {row.emailAddress} <br />
              {row.phoneNumber}
            </div>
          </div>
        ))
      ) : val.contactDetails.length > 0 ? (
        <div
          role="button"
          aria-hidden="true"
          className="search-dropdown--customers"
          key={val.contactDetails[0].contactId + uuidv4()}
          onClick={() =>
            onSelectCustomer({
              customerId: val.customerId,
              contactId: val.contactDetails[0].contactId,
              firstName: val.firstName,
              lastName: val.lastName,
              emailAddress: val.contactDetails[0].emailAddress,
              phoneNumber: val.contactDetails[0].phoneNumber
            })
          }
        >
          <div className="name">
            {val.firstName} {val.lastName}
          </div>
          <div className="phone">
            {val.contactDetails[0].emailAddress}
            <br />
            {val.contactDetails[0].phoneNumber}
          </div>
        </div>
      ) : (
        <>&nbsp;</>
      )
    );

    return (
      <Wrapper>
        <section className="search-dropdown">
          <span>Customer details</span>

          <input
            type="text"
            placeholder="Enter a name, email or phone number"
            value={searchTerm}
            className="customerSearchInput"
            onChange={input => updateSearchQuery(input.target.value)}
          />

          {isLoading && (
            <AnimatePresence>
              <div className="loading-wrapper">
                <SmallLoadingIndicator />
              </div>
            </AnimatePresence>
          )}

          <div className="search-dropdown--content">
            {searchTerm && (
              <div
                className="search-dropdown--add--customer"
                role="button"
                aria-hidden="true"
                onClick={() => onAddCustomer()}
              >
                <Icons.PlusIcon /> Add a new customer
              </div>
            )}

            {searchResults.length > 0 && customerSearchDropDown}
          </div>
        </section>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <AnimatePresence>
        <div className="loading-wrapper">
          <SmallLoadingIndicator />
        </div>
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  width: 100%;

  .search-dropdown {
    margin-top: 2rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    span {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .customerSearchInput {
      width: 100%;
      max-width: 40%;
      padding-left: 19px;
      font-size: 16px;
    }

    svg {
      color: #2b3c45 !important;
    }

    .loading-wrapper {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      margin-top: 2rem !important;
    }

    .search-dropdown--content {
      width: 100%;
      max-width: 40%;
      box-sizing: border-box;
      border: 1px solid #eee;
      display: flex;
      flex-direction: column;
    }

    .search-dropdown--add--customer {
      padding: 12px 16px;
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      color: #2b3c45;
      cursor: pointer;

      svg {
        width: 20px !important;
        height: 20px !important;
        margin-right: 5px;
      }
    }

    .search-dropdown--add--customer:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }

    .search-dropdown--customers {
      padding: 12px 16px;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;

      .name {
        font-weight: 600;
      }
    }

    .search-dropdown--customers:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }

  @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
    .search-dropdown {
      .customerSearchInput {
        width: 100%;
        max-width: 100% !important;
        padding-left: 19px;
        font-size: 16px;
      }

      .search-dropdown--content {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;

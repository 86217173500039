import http from "./httpService";
import helpers from "@utils/helpers";

const api = "/stores";

const storeKeyName = helpers.getStoreKeyName();

/**
 * Create store.
 *
 * @param {*} data
 * @returns
 */
export async function createStore(data) {
  const response = await http.post(api, data);
  return response;
}

/**
 * Fetch shipping locations.
 *
 * @returns
 */
export async function fetchShippingLocations() {
  const response = await http.get(`${api}/shipping-locations`);
  return response;
}

/**
 * Create shipping destination.
 *
 * @param {*} storeId
 * @param {*} data
 * @returns
 */
export async function createShippingDestination(storeId, data) {
  const response = await http.post(
    `${api}/${storeId}/shipping-destinations`,
    data,
    {
      "Content-Type": "application/json"
    }
  );
  return response;
}

/**
 * Fetch shipping destination per store.
 *
 * @param {*} storeId
 * @returns
 */
export async function fetchShippingDestinationsPerStore(storeId) {
  const response = await http.get(`${api}/${storeId}/shipping-destinations`);
  return response;
}

/**
 * Find store by domain name.
 *
 * @param {*} name
 * @param {*} domainName
 * @returns
 */
export async function findStoreByDomainName(name, domainName) {
  if (!domainName) return null;
  const response = await http.get(`${api}?domainName=${domainName}`);
  return response.data;
}

/**
 * Fetch store pickup address.
 *
 * @param {*} param0
 * @returns
 */
export async function fetchStorePickUpAddress({ queryKey }) {
  const [_key, { storeId }] = queryKey;

  const { data } = await http.get(`${api}/${storeId}/pickup-addresses`);
  return data;
}

/**
 * Create store pickup address.
 *
 * @param {*} param0
 * @returns
 */
export async function createPickupAddress({ storeId, ...values }) {
  const { data } = await http.post(
    `${api}/${storeId}/pickup-addresses`,
    values
  );
  return data;
}

/**
 * Remove pickup address.
 *
 * @param {store id} storeId
 * @param {pcikup address id} pickupId
 * @returns
 */
export async function removePickupAddress(values) {
  const { data } = await http.delete(
    `${api}/${values.storeId}/pickup-addresses/${values.addressId}`
  );

  return data;
}

export function setStoreId(storeId) {
  localStorage.setItem(storeKeyName, storeId);
}

export function getStoreId() {
  return localStorage.getItem(storeKeyName);
}

export default {
  createStore,
  fetchShippingLocations,
  createShippingDestination,
  fetchShippingDestinationsPerStore,
  findStoreByDomainName,
  fetchStorePickUpAddress,
  createPickupAddress,
  removePickupAddress,
  setStoreId,
  getStoreId
};

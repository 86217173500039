import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

export default function ProductSearchBox({ value, onChange }) {
  return (
    <Wrapper>
      <input
        type="text"
        name="query"
        className="form-control"
        placeholder="Search Products"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      {/* <input type="submit" value="Search" className="submit"></input> */}
      <Button variant="outlined" className="search-btn">
        Search
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: "Open Sans";
  display: flex;
  @media only screen and (max-width: 384px) {
    flex-direction: column;
  }

  .form-control {
    border-radius: 4px;
    height: 32px;
    margin-bottom: 1em;

    @media only screen and (max-width: 384px) {
      width: 260px !important;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  .search-btn {
    height: 40px;
    width: 160px;
    border: 3px solid #2b3c45;
    margin-left: 46px;
    fontsize: 14px;
    color: #0f2531;

    :hover {
      border: 3px solid #2b3c45;
    }
  }

  .small-btn {
    width: 92px;
  }

  input[type="text"] {
    width: 480px;
    height: 40px;

    &:focus {
      outline: none;
      border: none;
    }
  }

  .submit {
    margin-left: 20px;
    width: 160px;
    height: 40px;
    border: 3px solid #2b3c45;
    font-size: 14px;
    color: #2b3c45;
    text-decoration: none;
    border-radius: 5px;
    background-color: #ffffff;
    color: #123141;

    &:hover {
      background-color: #ffffff;
    }
  }

  button {
    background: "#fff";
    color: "#2b3c45";
  }

  @media only screen and (max-width: 384px) {
    .form-control {
      width: 250px;
    }

    .search-btn {
      width: 260px !important;
      margin-left: 0 !important;
    }
  }
`;

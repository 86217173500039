/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import React from "react";

const Shimmer = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-top: ${({ marginTop }) => marginTop};
  margin: ${({ margin }) => margin};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: "#fafafa";
  background-image: linear-gradient(
    to right,
    #fafafa 0%,
    #f1f1f1 40%,
    #fafafa 80%,
    #fafafa 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
`;

export function ShimmerEffect({
  width,
  height,
  borderRadius,
  marginTop,
  margin
}) {
  return (
    <>
      <Shimmer
        width={width}
        height={height}
        borderRadius={borderRadius}
        marginTop={marginTop}
        margin={margin}
      />
    </>
  );
}

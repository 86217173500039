import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

import Table from "@sharedComponent/Table";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function OrderListTable({ orders, onSort, sortColumn }) {
  const history = useHistory();

  const redirectRoute = url => {
    history.push(url);
  };

  const columns = [
    {
      path: "orderNumber",
      label: "Order Number",
      content: order => (
        <div className="orderContentAlign">{order.orderNumber}</div>
      )
    },
    {
      path: "orderDate",
      label: "Placed On",
      content: order => (
        <div className="orderContentAlign">
          {moment(order.orderDate).format("ll")}
        </div>
      )
    },
    {
      path: "customer",
      label: "Customer",
      content: order => (
        <div className="orderContentAlign">
          {order.customer.firstName}&nbsp;{order.customer.lastName}
        </div>
      )
    },
    {
      path: "amount",
      label: "Price",
      content: order => (
        <div className="orderContentAlign">
          <AmountWithSymbol amount={order.amount} />
        </div>
      )
    },
    {
      path: "paymentStatus",
      label: "Payment",
      content: order => (
        <div className="orderContentAlign">
          {order.paymentStatus === "PAID" && (
            <span className="paid">
              <FeatherIcon icon="check-circle" />
              Paid
            </span>
          )}

          {order.paymentStatus === "PENDING" && (
            <span className="unpaid">
              <FeatherIcon icon="clock" />
              Pending
            </span>
          )}

          {order.paymentStatus === "PROCESSING" && (
            <span className="processing">
              <FeatherIcon icon="clock" />
              Processing
            </span>
          )}
        </div>
      )
    },
    {
      path: "orderStatus",
      label: "Shipping",
      content: order => (
        <div className="orderContentAlign">
          {order.orderStatus === "SHIPPED" && (
            <span className="fulfilled">
              <FeatherIcon icon="truck" />
              Shipped
            </span>
          )}

          {order.orderStatus === "PENDING" && (
            <span className="pending">
              <FeatherIcon icon="truck" />
              Pending
            </span>
          )}

          {order.orderStatus === "EXPIRED" && (
            <span className="expired">
              <FeatherIcon icon="truck" />
              Expired
            </span>
          )}

          {order.orderStatus === "CANCELLED" && (
            <span className="cancelled">
              <FeatherIcon icon="truck" />
              Cancelled
            </span>
          )}

          {order.orderStatus === "INVALIDATED" && (
            <span className="cancelled">
              <FeatherIcon icon="clock" />
              Invalidated
            </span>
          )}
        </div>
      )
    },
    {
      key: "actions",
      label: "Actions",
      content: order => (
        <div className="orderContentAlign">
          {order.orderStatus === "SHIPPED" ? (
            <button
              type="button"
              variant="small"
              onClick={() => redirectRoute(`/orders/view/${order.orderId}`)}
            >
              Fullfilled
            </button>
          ) : (
            <button
              type="button"
              variant="small_outline"
              onClick={() => redirectRoute(`/orders/view/${order.orderId}`)}
            >
              View order
            </button>
          )}
        </div>
      )
    }
    // {
    //   key: "ellipsis",
    //   content: order => (
    //     <div className="orderContentAlign">
    //       {order.paymentStatus === "PAID" ||
    //       order.orderStatus === "CANCELLED" ? (
    //         <div className="disabledEditText">Edit</div>
    //       ) : (
    //         <div
    //           className="editText"
    //           onClick={() => redirectRoute(`/orders/edit/${order.orderId}`)}
    //         >
    //           Edit
    //         </div>
    //       )}
    //     </div>
    //   )
    // }
  ];

  return (
    <Wrapper>
      <div className="orderTableWrapper">
        <Table
          columns={columns}
          data={orders}
          sortColumn={sortColumn}
          onSort={onSort}
        />
      </div>
    </Wrapper>
  );
}

OrderListTable.propTypes = {
  orders: PropTypes.array.isRequired
  // sortColumn: PropTypes.object.isRequired,
  // onSort: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Open Sans;
  margin: 0;
  padding: 0;
  width: 100%;

  .disabledEditText {
    color: #838E91 !important;
    pointer-events: none !important;
  }

  .editText {
    color: #2B3C45 !important;
    cursor: pointer;
    font-weight: 600;
  }

  .orderTableWrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4rem;
    box-sizing: border-box;
    overflow: hidden;
  }

  .orderContentAlign {
    display: flex;
    align-items: center;
  }

  .text {
    color: #2f2f2a;
  }

  .paid {
    display: flex;
    align-items: center;
    color: #4caf50;
    
    svg {
      margin-right: 6px;
    }
  }

  .unpaid {
    display: flex;
    align-items: center;
    color: #838E91;

    svg {
      margin-right: 6px;
    }
  }

  .processing {
    display: flex;
    align-items: center;
    color: #ffd600;

    svg {
      margin-right: 6px;
    }
  }

  .fulfilled {
    display: flex;
    align-items: center;
    color: #4caf50;

    svg {
      margin-right: 6px;
    }
  }

  .pending {
    display: flex;
    align-items: center;
    color: #838E91;

    svg {
      margin-right: 6px;
    }
  }

  .expired {
    display: flex;
    align-items: center;
    color: #ffd600;

    svg {
      margin-right: 6px;
    }
  }

  .cancelled {
    display: flex;
    align-items: center;
    color: #f44336;

    svg {
      margin-right: 6px;
    }
  }

  .fulfillOrderButton {
    display: flex;
    background-color: #2B3C45;
    border: 2px solid #2B3C45;
    box-sizing: border-box;
    color: #ffffff;
    font-size: 14px;
    border-radius: 30px;
    padding: 5px;
    min-width: 107px;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: #2011f2;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .viewOrderButton {
    display: flex;
    background-color: #ffffff;
    border: 2px solid #2B3C45;
    box-sizing: border-box;
    color: #2B3C45;
    font-size: 14px;
    border-radius: 30px;
    padding: 5px;
    min-width: 107px;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: #ffffff;
      background-color: #2011f2;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .menu {
    position: relative;
  }

  .menu button.ellipsis {
    background: transparent;
    color: #2B3C45;
    text-decoration: none;
    border: none;
    outline: none;
    font-size: 22px;
    height: 1px !important;
    min-width: 0 !important;
    padding: 1px;
    cursor: pointer;
  }

  .menu button.ellipsis:focus {
    outline: 0;
    box-shadow: none;
  }

  .menu button.ellipsis:active {
    outline: none;
    border: none;
  }

  .menu button.ellipsis:focus + ul {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0px);
  }

  .menu ul {
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    top: 40px;
    right: 0;
    width: 150px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 100;
  }

  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .icons {
    margin-right: 5px;
  }

  .menu li a {
    text-decoration: none;
    color: #2B3C45 !important;
    font-weight: 500;
    padding: 15px 20px;
    display: block;
    font-size: 14px;
    cursor: pointer;

    svg {
      fill: #2B3C45 !important
      margin-right: 5px;
    }
  }

  .menu li:hover {
    background-color: #fafafa;
  }

  
`;

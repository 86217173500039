import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import ActionHeader from "./Common/ActionHeader";
import BankAccounts from "./Common/BankAccounts";

export default function ReviewWithdrawFund() {
  return (
    <Container>
      <ActionHeader title="Withdraw funds" action="cancel" />

      <div className="card-container">
        <div className="cards">
          <div className="card-item">
            <div className="card-row">
              <div className="title">Review</div>
              <div className="subtitle">
                Please review the details of this transaction
              </div>

              <div className="clear__fix" />

              <div className="amount__text">Amount</div>
              <div className="amount__figure">N 1000.00</div>

              <div className="clear__fix" />
              <BankAccounts reviewMode />

              <div className="clear__fix" />
              <hr className="divider" />

              <div className="bottom__text">
                <div className="clear__fix" />
                Enjoy unlimited free Withdrawals with Paga
                <div className="clear__fix" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="button-container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          className="cancel-button"
        >
          Back
        </motion.div>

        <div className="submitAndErrorContainer">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.3 }}
            className="action-button"
          >
            Withdraw funds
          </motion.button>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  width: 100%;

  .card-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .cards {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 2rem;
    width: 100%;

    .card-item {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      max-width: 1200px;
      border-radius: 6px;
      border: 1px solid #ebebeb;
      overflow: hidden;
      transition: transform 0.5s;
      -webkit-transition: transform 0.5s;
    }

    .card-row {
      display: inline-flex;
      flex: 1 1 auto;
      flex-direction: column;
      padding: 28px 18px 14px 32px;
      line-height: 1.5em;
    }
  }

  .title {
    font-size: 20px;
    color: #1e242d;
    font-weight: 600;
    padding: 0;
  }

  .subtitle {
    font-size: 14px;
    color: #999999;
    padding: 0;
    margin-top: 10px;
  }

  .amount__text {
    font-size: 14px;
    color: #1e242d;
    font-weight: 500;
    padding: 0;
  }

  .amount__figure {
    font-size: 20px;
    color: #1e242d;
    font-weight: 400;
    padding: 0;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }

  .bottom__text {
    font-size: 14px;
    color: #1e242d;
    margin-top: 10px;
  }

  @media screen and (max-width: 430px) {
    .heading {
      .title {
        display: none;
      }
    }

    .withdrawal__options {
      .withdrawal__funds {
        .withdrawal__caption {
          display: none;
        }
      }
    }

    .cards {
      .card-item {
        .card-description {
          padding: 0px 0px 0px 0px;
        }
      }
    }
  }
`;

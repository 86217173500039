import React from "react";
import { useMutation, useQueryClient } from "react-query";
import PropTypes from "prop-types";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import Skeleton from "react-loading-skeleton";

import notification from "@utils/notification";
import orderApi from "@services/orderService";

export default function PaymentInfoCard({ loading, nuban, order, retry }) {
  const queryClient = useQueryClient();

  let link = null;
  const copyToClipBoard = () => {
    link.select();
    navigator.clipboard
      .writeText(link.value)
      .then(() => {
        notification.createNotify({
          title: "Copied to clipboard",
          message: link.value,
          type: "success",
          position: "top-right"
        });
      })
      .catch(error => {
        notification.createNotify({
          title: "Failed to copy",
          message: error,
          type: "warning",
          position: "top-right"
        });
      });
  };

  const orderId = !loading ? order.orderId : null;
  const generateNubanMutation = useMutation(orderApi.generateNuban, {
    onMutate: () => {
      queryClient.cancelQueries(["order_detail", { orderId }]);

      const previousData = queryClient.getQueryData([
        "order_detail",
        { orderId }
      ]);

      // return snapshot
      return previousData;
    },
    onError: err => {
      notification.createNotify({
        title: "Error generating NUBAN",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["order_detail", { orderId }]);
    }
  });

  const onGenerateNuban = orderId => {
    generateNubanMutation.mutate(orderId);
  };

  return (
    <Wrapper>
      <div className="paymentCard">
        <div className="title">Payment information</div>
        <div className="caption">
          The unique transfer details for this order are:
        </div>
        {nuban?.isLoading ||
        !nuban ||
        (retry > 0 &&
          !order?.dynamicNubanNumber &&
          !nuban?.dynamicAccountNumber) ? (
          <Skeleton height={150} />
        ) : !nuban?.isLoading && nuban?.data?.status === "PENDING" ? (
          <Skeleton height={150} />
        ) : nuban?.data?.status === "SUCCESSFUL" ? (
          <div className="paymentInfo">
            <div className="content">
              <div className="accountInfo">
                <div className="name">
                  <strong>Bank:</strong> Paga
                </div>
                <div className="name">
                  <strong>NUBAN:</strong> {nuban?.data?.dynamicAccountNumber}
                  <input
                    type="hidden"
                    defaultValue={`${nuban?.data?.dynamicAccountNumber}`}
                    ref={ref => (link = ref)}
                  />
                </div>
              </div>
              <div className="copyIcon" onClick={copyToClipBoard}>
                <FeatherIcon icon="copy" />
                <span className="tooltiptext">Copy to clipboard</span>
              </div>
            </div>
          </div>
        ) : !loading && order?.dynamicNubanNumber ? (
          <div className="paymentInfo">
            <div className="content">
              <div className="accountInfo">
                <div className="name">
                  <strong>Bank:</strong> Paga
                </div>
                <div className="name">
                  <strong>NUBAN:</strong> {order?.dynamicNubanNumber}
                  <input
                    type="hidden"
                    defaultValue={`Paga ${order?.dynamicNubanNumber}`}
                    ref={ref => (link = ref)}
                  />
                </div>
              </div>
              <div className="copyIcon" onClick={copyToClipBoard}>
                <FeatherIcon icon="copy" />
                <span className="tooltiptext">Copy to clipboard</span>
              </div>
            </div>
          </div>
        ) : nuban.isError || nuban?.data?.status === "FAILED" ? (
          <div className="generateNubanContent">
            <button
              type="button"
              status={generateNubanMutation.isLoading ? "disabled" : ""}
              onClick={() => onGenerateNuban(nuban?.data?.orderId)}
            >
              {generateNubanMutation.isLoading
                ? "Generating..."
                : "Generate Nuban"}
            </button>
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
}

PaymentInfoCard.propTypes = {
  loading: PropTypes.bool.isRequired
};

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  .paymentCard {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    max-width: 400px;
    margin-right: 15px;
    height: 280px;
    padding: 25px;
    margin-bottom: 1rem;
  }

  .title {
    color: #2b3c45;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1em;
  }

  .caption {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1em;
  }

  .name {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  .paymentInfo {
    background-color: #fafafa;
    height: 103px;
    padding: 6px 18px;
  }

  .generateNubanContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 15px 75px 15px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 15px 75px 15px;
  }

  .accountInfo {
    display: flex;
    flex-direction: column;
  }

  .copyIcon {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .copyIcon .tooltiptext {
    background-color: #2b3c45;
    background-size: cover;
    color: #ffffff;
    font-size: 12px;
    padding: 3px 0;
    text-align: center;
    visibility: hidden;
    width: 120px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .copyIcon:hover .tooltiptext {
    visibility: visible;
  }

  .no-orders {
    text-align: center;
    margin: 0 auto;
    margin-top: 1em;
    color: #1e242d;

    p {
      font-size: 14px;
      margin: 14px 0 0 0;
    }
  }

  @media only screen and (max-width: 400px) {
    .paymentCard {
      width: 100%;
    }

    .copyIcon .tooltiptext {
      display: none;
    }
  }
`;

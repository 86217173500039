const ProductTableColumns = [
  {
    path: "name",
    label: "Product",
    id: "name"
  },
  {
    path: "stock",
    label: "Stock",
    id: "stock"
  },
  {
    path: "created",
    label: "Created",
    id: "createdDate"
  },
  {
    path: "price",
    label: "Price",
    id: "price"
  },
  {
    key: "ellipsis",
    id: "elipsis"
  }
];

export default ProductTableColumns;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";
import useModal from "@hooks/useModal";
import Modal from "../Common/Modal";

import EditCustomerSearch from "./EditCustomerSearch";
import AddCustomer from "../Common/AddCustomer";

export default function EditCustomer() {
  const [, setShowCustomerForm] = useState(false);

  const { isVisible, toggleModal } = useModal();

  const { orderRequest } = useSelector(selectors.orders);

  const handleShowCustomerForm = value => {
    // console.log(isShowCustomerForm);
    setShowCustomerForm(value);
  };

  return (
    <Wrapper>
      <section className="top-section">
        <h4>Customer</h4>
        <span role="button" aria-hidden="true" onClick={toggleModal}>
          Add customer
        </span>
      </section>

      <EditCustomerSearch onShowCustomerForm={handleShowCustomerForm} />

      {Object.keys(orderRequest.customer).length > 0 && (
        <div className="customerInfo">
          <p>Customer details</p>

          <hr className="divider" />

          <div className="bioData">
            <div className="bioText">
              {orderRequest.customer.firstName}&nbsp;
              {orderRequest.customer.lastName}
            </div>
            <div className="bioText">{orderRequest.customer.phoneNumber}</div>
            <div className="bioText">{orderRequest.customer.emailAddress}</div>
          </div>
        </div>
      )}

      <Modal
        isVisible={isVisible}
        hideModal={toggleModal}
        title="Add new customer"
      >
        <AddCustomer onClose={toggleModal} />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 25px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 10px;
  //height: 320px;
  //width: 36%;

  .top-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    width: 100%;
    h4 {
      font-family: Montserrat;
      font-size: 18px;
      color: #1e242d !important;
      margin: 0;
    }
    span {
      color: #2b3c45;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .customerInfo {
    position: relative;
    dislay: flex;
    flex-direction: column;
  }

  .bioData {
    margin-top: 10px;
    font-size: 14px;
  }

  .bioText {
    color: #2b3c45;
    font-weight: 600;
    line-height: 1.5em;
  }

  @media only screen and (max-width: 384px) {
    width: 100%;
  }
`;

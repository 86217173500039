import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import notification from "@utils/notification";
import { actions } from "@App/App.state";
import FormWrapper from "../FormWrapper";
import OtpInput from "react-otp-input";
import kycService from "@services/kycService";
import CustomFormInput from "@sharedComponent/CustomFormInput";

export default function VerifyNumber() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  let { number } = useParams();

  const handleOtpChange = otp => {
    setOtp(otp);
  };

  const verifyNumberMutation = useMutation(kycService.verifyPhoneNumber, {
    onSuccess: data => {
      console.log(data);
      const message = "success";
      alert(message);
    },
    onError: err => {
      notification.createNotify({
        title: "Operation error",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const click = otp => {
    history.push("/updateNumber");
    console.log("clicked " + otp);
    //verifyNumberMutation.mutate(otp);
  };

  const verifyNumber = values => {
    history.push("/updateNumber");
    verifyNumberMutation.mutate(values);
  };

  return (
    <FormWrapper
      title="Verify device number"
      subTitle={`We've haveve sent a verification code to ${number}. Please enter below to verify`}
    >
      <div className="signup-form-wrapper" style={{ marginTop: "24px" }}>
        <Formik
          initialValues={{
            otp: ""
          }}
          validationSchema={Yup.object({
            otp: Yup.number("").required("OTP field cannot be empty")
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            console.log(values);
            verifyNumber(values);
          }}
        >
          <Form>
            <div className="otpInput">
              <OtpInput
                value={otp}
                name="otp"
                onChange={handleOtpChange}
                numInputs={5}
                separator={<span style={{ margin: "0 10px" }}></span>}
                style={{ width: "3em" }}
              />
            </div>
            <p
              style={{
                textAlign: "center",
                marginBottom: "80px",
                fontSize: "14px"
              }}
            >
              Retry in 1:12 seconds
            </p>
            <h2
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#5F5457",
                fontWeight: "400"
              }}
            >
              Not getting an SMS? Dial <strong>*242*1#</strong> from the mobile
              number above to receive your verification code
            </h2>

            <div>
              <motion.input
                type="submit"
                value={
                  verifyNumberMutation.isLoading
                    ? "Just a moment..."
                    : "Confirm"
                }
                className="form-buttons submit-button"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                data-testid="submit-form"
                style={{
                  marginTop: "10px",
                  display: otp.length == 5 ? "block" : "none"
                }}
                onClick={() => click(otp)}
              />
            </div>
            {verifyNumberMutation.isError && (
              <p className="error-message">
                {verifyNumberMutation?.error?.response?.data?.message}
              </p>
            )}
          </Form>
        </Formik>
      </div>
    </FormWrapper>
  );
}

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default function InvoiceHeader({ invoiceNumber, invoiceDate }) {
  return (
    <div className="invoiceHeaderSection">
      <div>&nbsp;</div>
      <div className="invoiceFormat">
        <span className="caption">
          Invoice number: <span className="subCaption">{invoiceNumber}</span>
        </span>
        <span className="caption">
          Invoice date:{" "}
          <span className="subCaption">{moment(invoiceDate).format("ll")}</span>
        </span>
      </div>
    </div>
  );
}

InvoiceHeader.propTypes = {
  invoiceNumber: PropTypes.number.isRequired,
  invoiceDate: PropTypes.string.isRequired
};

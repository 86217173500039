import React, { useState } from "react";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { NavLink, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";

import { actions } from "@App/App.state";
import { authenticationActions } from "./login.state";

import notification from "@utils/notification";
import CustomFormInput from "@sharedComponent/CustomFormInput";

import AuthWrapper from "../AuthWrapper";

import auth from "@services/authService";

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [passwordShown, setPasswordShown] = useState(false);

  const loginMutation = useMutation(auth.login, {
    onSuccess: res => {
      dispatch(
        authenticationActions.setEmailAndToken({
          emailAddress: res.email,
          token: res.token,
          AgreeToTerms: res.AgreeToTerms,
          sellerId: res.sellerId
        })
      );

      dispatch(actions.setBusinessId(res.businessId));
      dispatch(actions.setStoreId(res.storeId));
      dispatch(actions.setSellerId(res.sellerId));
      dispatch(actions.setWalletId(res.accountId));

      // history.push("/");
      res.AgreeToTerms === true
        ? history.push("/")
        : history.push("/privacy-policy");
    },
    onError: err => {
      notification.createNotify({
        title: "Could not login to your account",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onLogin = ({ email, password }) => {
    loginMutation.mutate({
      username: email,
      password
    });
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <AuthWrapper
      title="Log in"
      redirectLink={{ name: "Create an account", url: "/signup" }}
    >
      <Formik
        initialValues={{
          email: "",
          password: ""
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email address")
            .required("Field cannot be empty"),
          password: Yup.string().required("Field cannot be empty")
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onLogin(values);
        }}
      >
        <Form>
          <CustomFormInput
            type="text"
            placeholder="Email"
            name="email"
            id="email"
            data-testid="email"
          />
          <CustomFormInput
            type={passwordShown ? "text" : "password"}
            placeholder="Password"
            name="password"
            id="password"
            data-testid="password"
            icon={
              passwordShown ? (
                <FeatherIcon
                  icon="eye"
                  size={20}
                  onClick={togglePasswordVisiblity}
                />
              ) : (
                <FeatherIcon
                  icon="eye-off"
                  size={20}
                  onClick={togglePasswordVisiblity}
                />
              )
            }
          />

          <NavLink
            to="/passwords/forgot"
            className="forgot-password-link"
            data-testid="target-render-el"
          >
            Forgot password?
          </NavLink>

          <div className="submitAndErrorContainer">
            <motion.input
              type="submit"
              value={loginMutation.isLoading ? "Just a moment..." : "Log in"}
              className="submit-button form-buttons"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              data-testid="submit-form"
            />
            {loginMutation.isError && (
              <span className="submit-error">
                {loginMutation?.error?.response?.data?.message}
              </span>
            )}
          </div>
        </Form>
      </Formik>
    </AuthWrapper>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "authentication",
  initialState: {
    emailAddress: null,
    token: null,
    AgreeToTerms: null,
    sellerId: null
  },
  reducers: {
    setEmailAndToken: (state, action) => {
      state.emailAddress = action.payload.emailAddress;
      state.token = action.payload.token;
      state.AgreeToTerms = action.payload.AgreeToTerms;
      state.sellerId = action.payload.sellerId;
    },
    removeUsernameAndToken: state => {
      state.emailAddress = null;
      state.token = null;
      state.AgreeToTerms = null;
      state.sellerId = null;
    }
  }
});

export default slice.reducer;
export const { ...authenticationActions } = slice.actions;

import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import FeatherIcon from "feather-icons-react";

import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function BestSellers({ loading, reports, isError }) {
  return (
    <Wrapper>
      <div className="content">
        <div className="caption">Last 30 days best seller</div>

        <div className="body">
          {loading ? (
            <Skeleton height={50} />
          ) : !loading && isError ? (
            <motion.div className="no-orders">
              <FeatherIcon icon="alert-triangle" size="27" color="#f9a825" />
              <h5 style={{ textAlign: "center" }}>
                Error fetching best seller
              </h5>
            </motion.div>
          ) : !loading && !reports.bestSeller ? (
            <motion.div className="no-orders">
              <FeatherIcon icon="shopping-cart" size="27" />
              <h5>No sales</h5>
            </motion.div>
          ) : !loading && Object.keys(reports.bestSeller).length > 0 ? (
            <div className="bestSeller">
              <div className="item">
                <div className="imgBox">
                  <div className="img">
                    {reports.bestSeller.productImages.length > 0 ? (
                      <img
                        src={reports.bestSeller.productImages[0].imageUrl}
                        alt={reports.bestSeller.productName}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="label">
                  <div className="name">{reports.bestSeller.productName}</div>
                  <div className="amount">
                    <AmountWithSymbol amount={reports.bestSeller.price} />
                  </div>
                </div>
              </div>

              <div className="units">
                <div className="title">Sold</div>
                <div className="sold">{reports.bestSeller.unitSold}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 25px;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  box-shadow: 1px 2px 2px #ebebeb;
  background: #ffffff;
  border-radius: 4px;
  height: 150px;
  width: 100%;
  max-width: 780px;

  .content {
    display: flex;
    flex-direction: column;
  }

  .caption {
    font-size: 16px;
    font-weight: 600;
    color: #2b3c45;
    margin-bottom: 1rem;
  }

  .body {
    height: 100%;
    color: #333333;
  }

  .bestSeller {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2em;
  }

  .imgBox {
    width: 48px;
    height: 48px;
    background: #ecebee;
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 48px;
      width: 48px;
      object-fit: contain;
    }

    svg {
      height: 48px;
      width: 48px;
      object-fit: contain;
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .name {
    font-size: 14px;
  }

  .amount {
    font-size: 16px;
    font-weight: 600;
  }

  .units {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .title {
    font-size: 14px;
  }

  .sold {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  .no-orders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    color: #d4d4d4;

    h4 {
      font-size: 20px;
      margin: 14px 0 0 0;
    }
  }

  @media screen and (max-width: 384px) {
    width: 100%;
    display: inline-block;
    position: relative;

    .no-orders {
      margin-top: -1em;
    }
  }
`;

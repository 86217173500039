import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import FeatherIcon from "feather-icons-react";

import DoughnutChart from "@sharedComponent/DoughnutChart";

export default function PieChart({ loading, reports, isError }) {
  return (
    <Wrapper>
      <div className="content">
        <div className="caption">Last 30 days</div>

        <div className="body">
          {loading ? (
            <div className="circleSkeletion">
              <Skeleton circle={true} height={180} width={180} />
            </div>
          ) : !loading && isError ? (
            <motion.div className="no-orders">
              <FeatherIcon icon="alert-triangle" size="27" color="#f9a825" />
              <h5 style={{ textAlign: "center" }}>
                Error fetching recent sales
              </h5>
            </motion.div>
          ) : !loading && Object.keys(reports.recentOrders).length == 0 ? (
            <motion.div className="no-orders">
              <FeatherIcon icon="shopping-cart" size="27" color="#d4d4d4" />
              <h5>No sales</h5>
              <p>Recent sales will appear here</p>
            </motion.div>
          ) : !loading && Object.keys(reports.recentOrders).length > 0 ? (
            <DoughnutChart records={reports.recentOrders} />
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: 25px;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  box-shadow: 1px 2px 2px #ebebeb;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 6px;
  height: 265px;
  width: 100%;
  max-width: 780px;

  .content {
    display: flex;
    flex-direction: column;
  }

  .caption {
    font-size: 16px;
    font-weight: 600;
    color: #2b3c45;
    margin-bottom: 1rem;
  }

  .body {
    height: 100%;
    color: #333333;
  }

  .circleSkeletion {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-orders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding: 0;
    color: #d4d4d4;

    h4 {
      font-size: 20px;
      margin: 14px 0 0 0;
    }

    p {
      font-size: 14px;
      margin-top: 7px;
    }
  }

  @media screen and (max-width: 384px) {
    width: 100%;
    display: inline-block;
    position: relative;
  }
`;

import React from "react";
import styled from "styled-components";

export default function Payments() {
  return (
    <Container>
      <i className="icon-payments" />
      Payments
    </Container>
  );
}

const Container = styled.div`
  font-family: Montserrat;
  font-size: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bolder;
  @media screen and (max-width: 1025px) {
    margin: 0;
  }
`;

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import Home from "./Home/Home";
import Shipping from "./Shipping/Shipping";
import SettlementRoute from "./Settlement/SettlementRoute";
import NotFoundPage from "@App/Error/NotFoundPage";

export default function Settings() {
  const { path } = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route exact path={`${path}`}>
          <Home />
        </Route>
        <Route path={`${path}/shipping`}>
          <Shipping />
        </Route>
        <Route path={`${path}/settlement`}>
          <SettlementRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: #fafafa;
  background-size: cover;
  overflow-y: hiden;

  @media screen and (max-width: 1025px) {
    padding-left: 0;
  }
`;

import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "customers",
  initialState: {
    customers: [],
    isLoading: false,
    error: false
  },
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    }
  }
});

export default slice.reducer;

export const { ...customerActions } = slice.actions;

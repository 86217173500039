import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

import useModal from "@hooks/useModal";

import Modal from "@sharedComponent/Modal";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";
import DeleteProduct from "../Common/DeleteProduct";

export default function ProductCard({ products }) {
  const history = useHistory();

  const { isVisible, toggleModal } = useModal();
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const redirectRoute = url => {
    history.push(url);
  };

  const triggerDeleteProduct = productId => {
    const selected = products.find(product => product.productId === productId);
    setSelectedProduct(selected);

    setShowDeleteProductModal(true);
    toggleModal();
  };

  return (
    <Wrapper>
      <div className="productWrapper">
        {products.map(product => (
          <div className="productCard" key={product.productId}>
            <div className="productCardBody">
              <div className="productLeft">
                <div className="productImgBox">
                  {product.images.length > 0 ? (
                    <img
                      src={product.images[0].imageUrl}
                      alt={product.name}
                      title={product.name}
                    />
                  ) : (
                    <FeatherIcon icon="camera" />
                  )}
                </div>
                <div className="productName">{product.name}</div>
              </div>

              <div className="productMenu">
                <div className="menu">
                  <button className="ellipsis">
                    <FeatherIcon icon="more-horizontal" />
                  </button>
                  <ul>
                    <li>
                      <a
                        onMouseDown={() =>
                          redirectRoute(`/products/images/${product.productId}`)
                        }
                      >
                        <span className="justifyContent">
                          <FeatherIcon
                            icon="camera"
                            size="18"
                            className="icons"
                          />
                          Image(s)
                        </span>
                      </a>

                      <a
                        onMouseDown={() =>
                          redirectRoute(`/products/edit/${product.productId}`)
                        }
                      >
                        <span className="justifyContent">
                          <FeatherIcon
                            icon="edit-2"
                            size="18"
                            className="icons"
                          />
                          Edit
                        </span>
                      </a>

                      <a
                        onMouseDown={() =>
                          triggerDeleteProduct(product.productId)
                        }
                      >
                        <span className="justifyContent">
                          <FeatherIcon
                            icon="trash-2"
                            size="18"
                            className="icons"
                          />
                          Delete
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />

            <div className="productCardFooter">
              <div className="amount">
                {<AmountWithSymbol amount={product.price} />}
              </div>
              <div className="stock">
                {product.stock ? (
                  `${product.stock} in stock`
                ) : (
                  <strong style={{ fontSize: "18px", fontWeight: 600 }}>
                    ∞
                  </strong>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {showDeleteProductModal && (
        <Modal
          isVisible={isVisible}
          hideModal={toggleModal}
          title="Delete product"
          id="deleteProduct"
          width="400"
        >
          <DeleteProduct product={selectedProduct} onClose={toggleModal} />
        </Modal>
      )}
    </Wrapper>
  );
}

ProductCard.propTypes = {
  products: PropTypes.array.isRequired
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Open Sans;
  margin: 0;
  padding: 0;
  width: 100%;

  .productWrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 15rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .productCard {
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 12px;
    margin-bottom: 1rem;
  }

  .productCardBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    .productImgBox {
      height: 48px;
      width: 48px;
      padding: 2px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      
      img {
        height: 48px;
        width: 48px;
        object-fit: contain;
      }

      svg {
        height: 48px;
        width: 48px;
        object-fit: contain;
      }
    }

    .productLeft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.8em;
    }

    .productName {
      font-weight: 600;
      color: #2B3C45;
    }

    .productMenu {
      color: #2B3C45;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      position: relative;
      transition: box-shadow 0.4s ease;
      float: right !important;

      .menu {
        position: relative;
      }
  
      .menu button.ellipsis {
        background: transparent;
        color: #2B3C45;
        text-decoration: none;
        border: none;
        outline: none;
        font-size: 22px;
        height: 1px !important;
        min-width: 0 !important;
        cursor: pointer;
      }
  
      .menu button.ellipsis:focus {
        outline: 0;
        box-shadow: none;
      }
  
      .menu button.ellipsis:active {
        outline: none;
        border: none;
      }
  
      .menu button.ellipsis:focus + ul {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
      }
  
      .menu ul {
        background: #ffffff;
        border-radius: 4px;
        position: absolute;
        top: 40px;
        right: 0;
        width: 150px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        pointer-events: none;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        z-index: 100;
        .icons {
          margin-right: 5px;
        }
      }
  
      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
  
      .menu li a {
        text-decoration: none;
        color: #2B3C45;
        font-weight: 500;
        padding: 15px 20px;
        display: block;
        font-size: 14px;
  
        svg {
          fill: #2B3C45 !important
          margin-right: 5px;
        }
      }
  
      .menu li:hover {
        background-color: #fafafa;
      }
    }
  }

  hr {
    margin: 15px 0px 15px 0px;
    border: none;
    border-top: 1px solid #eaeaea;
    color: #121212;
    background-color: #fff;
    width: 100%;
    height: 1px;
    stroke-width: 1;
  }

  .productCardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #060606;

    .amount {
      font-size: 14px;
    }

    .stock {
      font-size: 14px; 
    }
  }
`;

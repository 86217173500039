import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";

import useModal from "@hooks/useModal";
import Modal from "@sharedComponent/Modal";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";
import CustomPriceInput from "@sharedComponent/CustomPriceInput";
import {
  formInput,
  inputContainer,
  RenderSelect
} from "@sharedComponent/FormElements";

import LinkNewBankAccount from "./Common/LinkNewBankAccount";

import walletApi from "@services/walletService";

export default function AmountToWithdraw({ setFieldValue, bankAcctList }) {
  const { wallet } = useSelector(selectors.settlement);

  const [banks, setBanks] = useState([]);

  const { isVisible, toggleModal } = useModal();

  useQuery(["bank_list"], walletApi.fetchBankList, {
    onSuccess: banks => {
      const bankList =
        banks &&
        banks.map(function(bank) {
          return { key: bank.bankId, value: bank.name };
        });
      setBanks(bankList);
    }
  });

  return (
    <Container>
      <Modal
        isVisible={isVisible}
        hideModal={toggleModal}
        title="Add new bank account"
      >
        <LinkNewBankAccount bankList={banks} onClose={toggleModal} />
      </Modal>

      <div className="title">Withdrawal details</div>

      <div className="moneyWrapper">
        <span className="caption">Amount to withdraw</span>
      </div>

      <CustomPriceInput name="amount" setFieldValue={setFieldValue} />

      <span className="more__info">
        Available funds:
        <AmountWithSymbol amount={wallet.available_bal} />
      </span>

      <div className="clear__fix" />

      <div className="inputContainer">
        <RenderSelect
          label="Withdraw funds to"
          name="bankAccountId"
          data-testid="bankAccountId"
          numericvalue={1}
          options={bankAcctList || []}
          onChange={e => {
            setFieldValue("bankAccountId", e.target.value);
          }}
        />
      </div>
      <div className="addAccount">
        <span onClick={toggleModal}>Add new account</span>
      </div>

      <div className="clear__fix" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  .title {
    font-size: 20px;
    color: #1e242d;
    font-weight: 600;
    padding: 0;
  }

  .addAccount {
    color: #2b3c45;
    cursor: pointer;
    font-size: 14px;
    margin-top: 1em;
  }

  .more__info {
    display: flex;
    gap: 0.5em;
    margin-top: 0.5em;
    font-size: 12px;
    color: #1e242d;
  }

  .moneyWrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    max-width: 400px;
  }

  .caption {
    font-size: 14px;
    font-weight: 500;
    color: #1e242d;
    margin-right: 20px;
  }

  .formContainer {
    padding: 0;
  }

  .formInput {
    ${formInput}
  }

  .inputContainer {
    ${inputContainer}
  }

  .inputContainer {
    width: 300px;
  }

  @media only screen and (max-width: 384px) {
    .inputContainer {
      width: 100%;
    }
  }
`;

import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import notification from "@utils/notification";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

import { handleAddItem } from "../Orders.state";

export default function SearchItemTable({ searchItems }) {
  const dispatch = useDispatch();

  const { largeScreen } = useDetectScreenSize();

  const handleAddProduct = item => {
    const newItem = {
      productId: item.productId,
      name: item.name,
      price: item.price,
      quantity: 1
    };

    dispatch(handleAddItem(newItem));

    notification.createNotify({
      title: "Item added",
      message: newItem.name,
      type: "info",
      position: "top-center"
    });
  };

  return (
    <Wrapper>
      <table className="table">
        <thead>
          <tr>
            <th>Items</th>
            <th>Stock</th>
            <th>Price</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {searchItems.map(item => (
            <tr key={item.productId}>
              <td>
                <span className="table-data table-bold">{item.name}</span>
              </td>
              <td className="stockColumn">
                <span className="table-data">
                  {item.stock ? (
                    item.stock
                  ) : (
                    <strong style={{ fontSize: "18px", fontWeight: 600 }}>
                      ∞
                    </strong>
                  )}
                </span>
              </td>
              <td className="priceColumn">
                <span className="table-data table-bold">
                  <AmountWithSymbol amount={item.price} />
                </span>
              </td>
              <td className="actionColumn">
                <span className="table-data">
                  {largeScreen && (
                    <button
                      type="button"
                      variant="small"
                      onClick={() => handleAddProduct(item)}
                    >
                      Add
                    </button>
                  )}
                  {!largeScreen && (
                    <button
                      type="button"
                      onClick={() => handleAddProduct(item)}
                    >
                      Add
                    </button>
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
}

SearchItemTable.propTypes = {
  searchItems: PropTypes.array.isRequired
};

const Wrapper = styled.div`
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 10px;

  .stockColumn {
    width: 8%;

    @media only screen and (max-width: 384px) {
      width: 100%;
    }
  }

  .priceColumn {
    width: 20%;

    @media only screen and (max-width: 384px) {
      width: 100%;
    }
  }

  .actionColumn {
    width: 10%;

    @media only screen and (max-width: 384px) {
      width: 100%;
    }
  }

  .table {
    width: 100%;
    max-width: 100%;
    border-spacing: none;
  }

  .table th,
  .table td {
    padding: 0.5rem;
    text-align: left;
  }

  table td {
    border-spacing: none;
  }

  .table thead th {
    font-size: 14px;
    font-weight: 500;
    vertical-align: bottom;
    border-bottom: 2px solid #eceeef;
  }

  .table tbody + tbody {
    border-top: 2px solid #eceeef;
  }

  .table tbody {
    background-color: #f9f9f9;
    border-radius: 0;
    border: 1px solid #ebebeb;
    box-shadow: 1px 2px 2px #ebebeb;
  }

  .table-data {
    color: #080808;
    font-size: 14px;
    font-family: Open Sans;
  }

  .table-bold {
    font-weight: 600;
  }

  .table .table {
    background-color: #fff;
  }

  @media only screen and (max-width: 384px) {
    table {
      border: 0;
    }

    table thead {
      display: none;
    }

    table tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #ddd;
    }

    table td {
      display: block;
      text-align: center !important;
      font-size: 13px;
      padding: 1.5rem;
    }

    table td:last-child {
      border-bottom: 0;
    }

    table td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 20px;
    }
  }
`;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";

import notification from "@utils/notification";
import helpers from "@utils/helpers";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Icons from "@sharedComponent/Icons";

import seller from "@services/sellerService";

export default function SignupSuccess() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [passwordShown, setPasswordShown] = useState(false);

  const tokenKey = helpers.getAuthTokenName();
  const refreshKey = helpers.getRefreshTokenName();

  return (
    <Container>
      <div className="section">
        <div className="doroki-logo">
          <Icons.DorokiBrandBlue />
        </div>
        <img
          src="/icons/thankyoudribblegif.gif"
          alt="registration successful"
        />
        <h1>You've got mail!</h1>
        <p>
          we've sent you a confirmation email. Please click the link in the
          email to verify your email address
        </p>
        <div className="btn">Continue</div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;

    .doroki-logo {
      height: 20px;
      margin-bottom: 77px;

      svg {
        height: 61px;
      }
    }
    img {
      width: 259px;
      height: 194px;
    }
    h1 {
      height: 28px;
      font-family: Ubuntu;
      font-size: 24px;
      color: #2b3c45;
      text-decoration: none solid rgb(43, 60, 69);
      text-align: center;
    }
    p {
      width: 420px;
      font-family: Noto Sans;
      font-size: 14px;
      color: #2b3c45;
      text-decoration: none solid rgb(43, 60, 69);
      text-align: center;
    }
    .btn {
      width: 420px;
      height: 60px;
      border-radius: 5px;
      background-color: #0f2531;
      background-size: cover;
      font-family: Ubuntu;
      font-size: 16px;
      color: #ffffff;
      text-decoration: none solid rgb(255, 255, 255);
      margin-top: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 460px) {
    .section {
      width: 90%;

      p {
        width: inherit;
      }
      .btn {
        width: inherit;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .section {
      width: 80%;
    }
    p {
      width: inherit%;
    }
    .btn {
      width: inherit;
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import notification from "@utils/notification";
import { actions } from "@App/App.state";
import seller from "@services/sellerService";
import FormWrapper from "../FormWrapper";
import OtpInput from "react-otp-input";

import { display } from "@mui/system";

export default function BvnInput() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [bvn, setBvn] = useState("");

  const handleBvnChange = bvn => {
    setBvn(bvn);
    console.log(bvn);
  };

  const handleBVNSubmit = () => {
    history.push("./verify-bvn");
  };

  const updateSellerMutation = useMutation(
    seller.updateSellerAndCreateBusiness,
    {
      onSuccess: res => {
        // dispatch(actions.bvn(res.businessId));
        history.push("/");
      },
      onError: err => {
        notification.createNotify({
          title: "Operation error",
          message: err.response.data.message || "Server error",
          type: "danger",
          position: "top-right"
        });
      }
    }
  );

  const onUpdateSeller = values => {
    //updateSellerMutation.mutate(values);
  };

  return (
    <FormWrapper
      title="BVN"
      subTitle="Please provide the BVN of any authorized person of {Business Name}"
    >
      <div className="signup-form-wrapper" style={{ marginTop: "24px" }}>
        <Formik
          initialValues={{
            bvn: ""
          }}
          validationSchema={Yup.object({
            phoneNumber: Yup.number().required("bvn field cannot be empty")
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onUpdateSeller(values);
          }}
        >
          <Form>
            <div className="bvnInput">
              <OtpInput
                value={bvn}
                name="bvn"
                onChange={handleBvnChange}
                numInputs={11}
                separator={<span style={{ margin: "0 3px" }}></span>}
                style={{ width: "2em" }}
              />
            </div>
            <p style={{ marginBottom: "80px", fontSize: "12px" }}>
              Forgot your BVN? Dial or tap <strong>*565*O#</strong> from your
              registered mobile number to retrieve.
            </p>
            <h2
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: "#5F5457",
                fontWeight: "400"
              }}
            >
              Why we need your BVN
            </h2>

            <div>
              <motion.input
                type="submit"
                value={
                  updateSellerMutation.isLoading ? "Just a moment..." : "Next"
                }
                className="form-buttons submit-button"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                data-testid="submit-form"
                style={{
                  marginTop: "10px",
                  display: bvn.length == 11 ? "block" : "none"
                }}
                onClick={handleBVNSubmit}
              />
            </div>
            {updateSellerMutation.isError && (
              <p className="error-message">
                {updateSellerMutation?.error?.response?.data?.message}
              </p>
            )}
          </Form>
        </Formik>
      </div>
    </FormWrapper>
  );
}

// const Container = styled.div`
//   display: flex;
//   justify-content: center;

//   .form-row {
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 20px;

//     &-col {
//       width: 48%;
//     }
//   }

//   select {
//     width: 100%;
//     height: 48px;
//     border: 1px solid #d1d1d1;
//     background-color: #f8f7f7;
//   }

//   textarea {
//     background-color: #f8f7f7;
//     margin-top: 0 !important;
//   }

//   .navigate {
//     margin-top: 50px;
//     display: flex;
//     cursor: pointer;
//   }
//   .submitContainer {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     input[type="submit"] {
//       background: #2b3c45;
//       color: #ffffff;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 150%;
//       width: 100%;
//       padding: 16px auto;
//       border-radius: 8px;
//     }
//   }

//   .input-and-error-wrapper {
//     position: relative;

//     svg {
//       position: absolute;
//       top: 35% !important;
//       right: 3%;
//     }

//     label {
//       display: flex;

//       .radio-field {
//         display: flex;
//         align-items: center;
//         margin-right: 16px;

//         input {
//           border: 1px solid #d1d1d1;
//         }

//         &-label {
//           font-weight: 400;
//           font-size: 14px;
//           line-height: 150%;
//           color: #231f20;
//         }
//       }
//     }
//   }

//   .radio {
//     border: 1px solid #e8e8e8 !important;
//     color: #e8e8e8;
//     background: #e8e8e8;
//     accent-color: #231f20;
//     margin-right: 2px;
//   }
// `;

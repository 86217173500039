/* eslint-disable react/jsx-filename-extension */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-notifications-component/dist/theme.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import store from "./configureStore";
import App from "./@App/App";
import * as serviceWorker from "./serviceWorker";

import "@resources/fontIcons/css/fontello.css";
import "@resources/fontIcons/css/fontello.css";
import "@utils/i18n/i18n";
import { theme } from "../src/@App/Dashboard/Products/theme";
import { ThemeProvider } from "@mui/material/styles";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotFoundPage from "./@App/Error/NotFoundPage";

let persistor = persistStore(store);

ReactDOM.render(
  // <Provider store={store}>
  //   <PersistGate loading={null} persistor={persistor}>
  //     <App />
  //   </PersistGate>
  // </Provider>,
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path="/">
            <App />
          </Route>
          <Route path="/404">
            <NotFoundPage />
          </Route>
          <Route>
            <App />
          </Route>
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import LineChart from "@sharedComponent/LineChart";

export default function WeeklySales({ loading, sales, isError }) {
  return (
    <Wrapper>
      <div className="container">
        <div className="content">
          <div className="header">
            <span className="text">Weekly sales</span>
            <span>
              <NavLink to="/orders/">
                <button variant="small_outline">All orders</button>
              </NavLink>
            </span>
          </div>

          {loading ? (
            <Skeleton height={100} />
          ) : !loading && isError ? (
            <LineChart aggregates={[]} />
          ) : (
            <LineChart aggregates={sales.dailySales} />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0 12px;

  .container {
    padding: 25px;
    box-sizing: border-box;
    border: 1px solid #ebebeb;
    box-shadow: 1px 2px 2px #ebebeb;
    background: #ffffff;
    border-radius: 4px;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .text {
      font-size: 16px;
      font-weight: 600;
      color: #2b3c45;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #2b3c45;
    margin-bottom: 5px;
  }
`;

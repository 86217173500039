import http from "./httpService";
import store from "./storeService";
import business from "./businessService";
import getDecodedToken from "@utils/getDecodedToken";

const api = "/sellers";

export async function signupNewSeller(data) {
  const response = await http.post(api, data, {
    "Content-Type": "application/json"
  });

  // localStorage.setItem(tokenKey, response.data.token.access_token);
  // localStorage.setItem(refreshKey, response.data.token.refresh_token);

  return { ...response.data, email: data.emailAddress };
}

export async function updateSellerAndCreateBusiness(payload) {
  const { firstName, lastName, businessName, isAgreedToTerms } = payload;
  const { sub } = getDecodedToken();

  await http.put(
    `/sellers/${sub}`,
    {
      firstName,
      lastName
      // isAgreedToTerms
    },
    {
      "Content-Type": "application/json"
    }
  );

  const makeShiftStoreandDomainName = businessName
    .split(" ")
    .join("")
    .toLowerCase();

  const { data } = await http.post(
    `/businesses`,
    {
      businessName,
      ownerUserId: sub,
      store: {
        storeName: businessName,
        description: businessName,
        templateName: "brooklyn",
        storeState: "PUBLISHED",
        domainName: makeShiftStoreandDomainName
      }
    },
    {
      "Content-Type": "application/json"
    }
  );

  store.setStoreId(data.stores[0].storeId);
  business.setBusinessId(data.businessId);

  return {
    sellerId: sub,
    businessId: data.businessId,
    storeId: data.stores[0].storeId,
    accountId: data.walletAccountId
  };
}

export async function fetchSellerInfo({ queryKey }) {
  const [_key, { sellerId }] = queryKey;

  const { data } = await http.get(`${api}/${sellerId}`);

  return data;
}

export async function getBusinessCategory(payload) {
  const { data } = await http.post(
    `${api}/migration/unsecured/businesses/getBusinessTypeAndCategoryLists`,
    payload,
    {
      "Content-Type": "application/json"
    }
  );
  return data;
}

export default {
  signupNewSeller,
  updateSellerAndCreateBusiness,
  fetchSellerInfo,
  getBusinessCategory
};

import React from "react";
import { Field } from "formik";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function CustomPriceInput({
  label,
  name,
  setFieldValue,
  required,
  value
}) {
  return (
    <Container>
      <div className="inputLabel priceLabel" style={{ color: "#1e242d" }}>
        {label}
        <span className="required-label">(Required)</span>
      </div>
      <section className="inputContainer">
        <div className="priceInputContainer" style={{ width: "370px" }}>
          <span>&#8358;</span>
          <Field name={name}>
            {({ field, meta }) => (
              <div style={{ width: "100%" }}>
                <NumberFormat
                  placeholder="0.00"
                  className="inputField"
                  decimalSeparator="."
                  isNumericString={true}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  data-testid={name}
                  name="price"
                  value={field.value}
                  // value={value}
                  onValueChange={val => setFieldValue(name, val.floatValue)}
                  style={{ width: "100%" }}
                  // required
                />

                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </div>
            )}
          </Field>
        </div>
      </section>
    </Container>
  );
}

CustomPriceInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func
};

const Container = styled.div`
  width: 100% !important;

  .label {
    color: #1e242d;
    font-size: 14px;
  }

  .priceLabel {
    display: flex;
    justify-content: space-between;
  }

  .required-label {
    font-size: 16px;
    color: rgb(210, 210, 210) !important;
    text-decoration: none solid rgba(43, 60, 69, 0.4);
    line-height: 24px;
  }

  .inputContainer {
    width: 370px !important;
  }
  .priceInputContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100% important;

    span {
      position: absolute;
      margin-left: 15px;
    }
  }

  .inputField {
    width: 255px;
    padding-left: 40px;
    margin-top: 0px;
  }

  .error {
    color: #f26522;
    font-size: 14px;
  }
`;

import React from "react";
import FeatherIcon from "feather-icons-react";
import { useHistory } from "react-router-dom";

const ElipsisMenu = props => {
  const history = useHistory();

  const redirectRoute = url => {
    history.push(url);
  };

  return (
    <div className="menu">
      <button className="ellipsis">
        <FeatherIcon icon="more-horizontal" />
      </button>
      <ul>
        <li>
          <a onMouseDown={() => redirectRoute(`/products/images/${props.id}`)}>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <FeatherIcon icon="camera" size="18" className="icons" />
              Image(s)
            </span>
          </a>

          <a onMouseDown={() => redirectRoute(`/products/edit/${props.id}`)}>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <FeatherIcon icon="edit-2" size="18" className="icons" />
              Edit
            </span>
          </a>

          <a onMouseDown={() => props.triggerDeleteProduct(props.id)}>
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <FeatherIcon icon="trash-2" size="18" className="icons" />
              Delete
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ElipsisMenu;

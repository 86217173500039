import jwtDecode from "jwt-decode";

import http from "./httpService";
import store from "./storeService";
import business from "./businessService";
import helpers from "@utils/helpers";
import getDecodedToken from "@utils/getDecodedToken";

const api = "/token";

const tokenKey = helpers.getAuthTokenName();
const refreshKey = helpers.getRefreshTokenName();

export async function login(payload) {
  const params = new URLSearchParams({
    grant_type: "password",
    ...payload
  }).toString();

  // login seller
  const response = await http.post(api, params, {
    "Content-Type": "application/x-www-form-urlencoded"
  });

  localStorage.setItem(tokenKey, response.data.access_token);
  localStorage.setItem(refreshKey, response.data.refresh_token);

  http.setJwt("Authorization", `Bearer ${getJwt()}`);

  // fetch seller info
  const { sub } = getDecodedToken();
  const { data } = await http.get(`sellers/${sub}`);

  // check if business is created for seller
  // otherwise show them the welcome screen
  if (data.businesses.length > 0) {
    store.setStoreId(data.businesses[0].stores[0].storeId);
    business.setBusinessId(data.businesses[0].businessId);

    return {
      token: response.data.access_token,
      email: data.emailAddress,
      sellerId: data.sellerId,
      AgreeToTerms: data.isAgreedToTerms,
      businessId: data.businesses[0].businessId,
      storeId: data.businesses[0].stores[0].storeId,
      accountId: data.businesses[0].walletAccountId
    };
  } else {
    window.location.replace("/welcome");
  }
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  getCurrentUser,
  getJwt
};

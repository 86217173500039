import React from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import FeatherIcon from "feather-icons-react";
import ShowProgress from "./LinearProgress";

export default function IdImage({ values, setFieldValue }) {
  const maxSize = 1048576;

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }

    // bubble up accepted uploaded images to formik
    setFieldValue("files", values.files.concat(acceptedFiles));
  };

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg, image/jpg",
    minSize: 0
  });

  const isFileTooLarge =
    rejectedFiles?.rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  const isFileCountExceeded = values.files.length > 5;

  const handleRemoveImg = selectedFile => {
    const newList = values.files.filter(file => {
      return selectedFile.path !== file.path;
    });

    setFieldValue("files", [...newList]);
  };

  return (
    <Container className="formContainer">
      {/* <div className="formTitle">Imagesss</div> */}

      <div className="dropzoneContainer" {...getRootProps()}>
        <input {...getInputProps()} />
        {!isDragActive && (
          <div className="upload">
            <span style={{ marginBottom: "10px" }}>
              <svg
                width="38"
                height="42"
                viewBox="0 0 39 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 4.25C9 3.2835 9.7835 2.5 10.75 2.5H22.5V11.25C22.5 13.3211 24.1789 15 26.25 15H36V35.75C36 36.7165 35.2165 37.5 34.25 37.5H22.7608C22.2353 38.4086 21.6029 39.2476 20.8809 40H34.25C36.5972 40 38.5 38.0972 38.5 35.75V14.4142C38.5 13.8175 38.2629 13.2452 37.841 12.8232L25.6768 0.65901C25.2548 0.237054 24.6825 0 24.0858 0H10.75C8.40279 0 6.5 1.90279 6.5 4.25V18.9963C7.29632 18.6642 8.13275 18.4091 9 18.2402V4.25ZM33.9822 12.5H26.25C25.5596 12.5 25 11.9404 25 11.25V3.51777L33.9822 12.5ZM22.5 31C22.5 37.0751 17.5751 42 11.5 42C5.42487 42 0.5 37.0751 0.5 31C0.5 24.9249 5.42487 20 11.5 20C17.5751 20 22.5 24.9249 22.5 31ZM12.5 24C12.5 23.4477 12.0523 23 11.5 23C10.9477 23 10.5 23.4477 10.5 24V30H4.5C3.94771 30 3.5 30.4477 3.5 31C3.5 31.5523 3.94771 32 4.5 32H10.5V38C10.5 38.5523 10.9477 39 11.5 39C12.0523 39 12.5 38.5523 12.5 38V32H18.5C19.0523 32 19.5 31.5523 19.5 31C19.5 30.4477 19.0523 30 18.5 30H12.5V24Z"
                  fill="#2b3c45"
                />
              </svg>
            </span>
            <div>
              <h3>Select a file to upload</h3>
              <p>or drag and drop it here</p>
            </div>
          </div>
        )}
        {isDragActive && !isDragReject && "Drop it like it's hot!"}
        {isDragReject && "File type not accepted, sorry!"}
        {isFileTooLarge && <div className="error">File is too large.</div>}
        {isFileCountExceeded && (
          <div className="error">File count exceeded.</div>
        )}
      </div>

      <ul className="imagesWrapper">
        {values.files.length > 0 &&
          values.files.map((image, index) => (
            // <li key={index}>
            //   <FeatherIcon
            //     icon="x-circle"
            //     size={20}
            //     onClick={() => handleRemoveImg(image)}
            //   />

            //   <img
            //     src={URL.createObjectURL(image)}
            //     alt="img"
            //     className={
            //       index === 0 ? "selected-image hero-image" : "selected-image"
            //     }
            //   />
            //   <h3 style={{fontSize:'10px'}}>{image.path}</h3>
            // </li>
            <li
              key={index}
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                marginBottom: "37px"
              }}
            >
              <div className="details">
                <div className="left">
                  <svg
                    width="33"
                    height="35"
                    viewBox="0 0 33 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.76174 0.833374H21.8939C22.485 0.833374 23.057 1.04285 23.5084 1.42461L31.4477 8.13994C32.0093 8.61495 32.3332 9.31317 32.3332 10.0487V30.9723C32.3332 33.9565 32.2991 34.1667 29.2379 34.1667H8.76174C5.70058 34.1667 5.6665 33.9565 5.6665 30.9723V4.02782C5.6665 1.04358 5.70058 0.833374 8.76174 0.833374Z"
                      fill="#E8E8E8"
                    />
                    <rect
                      y="18.5001"
                      width="19"
                      height="11"
                      rx="2"
                      fill="#F26522"
                    />
                    <path
                      d="M4.41086 23.9381V22.1461H5.34886C6.07686 22.1461 6.38486 22.4821 6.38486 23.0491C6.38486 23.5951 6.07686 23.9381 5.34886 23.9381H4.41086ZM7.03586 23.0491C7.03586 22.2511 6.49686 21.6211 5.34886 21.6211H3.77386V26.5001H4.41086V24.4631H5.34886C6.55986 24.4631 7.03586 23.7911 7.03586 23.0491ZM9.34657 21.6211H7.82757V26.5001H9.34657C10.9496 26.5001 11.9366 25.5831 11.9366 24.0711C11.9366 22.5591 10.9496 21.6211 9.34657 21.6211ZM8.46457 25.9751V22.1391H9.34657C10.6206 22.1391 11.2856 22.8811 11.2856 24.0711C11.2856 25.2611 10.6206 25.9751 9.34657 25.9751H8.46457ZM12.7768 26.5001H13.4138V24.3021H15.1358V23.7841H13.4138V22.1391H15.5348V21.6211H12.7768V26.5001Z"
                      fill="white"
                    />
                  </svg>
                  <div className="file">
                    <h3 className="fileName">{image.path}</h3>
                    <h4>12 seconds left</h4>
                    {/* <p>12 seconds left</p> */}
                  </div>
                </div>
                <FeatherIcon
                  icon="x-circle"
                  size={20}
                  onClick={() => handleRemoveImg(image)}
                />
              </div>
              <div>
                <ShowProgress />
              </div>
            </li>
          ))}
      </ul>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  .file-picker {
    display: none;
  }

  p {
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 14px;
    color: #1e242d;
  }

  .file {
    display: flex;
    flex-direction: column;

    .fileName {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #231f20;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      color: #aba0a3;
    }
    h4 {
      font-size: 12px;
      color: #aba0a3;
    }
  }
  .dropzoneContainer {
    cursor: pointer;
    width: 100%;

    padding: 12px;
    color: #999999;
    background-color: #f5f5f5;
    border: 1px dashed #d1d1d1;
    border-radius: 2px;
    margin-top: 10px;

    .upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .error {
      margin-top: 2px;
      color: #df3826;
    }

    @media only screen and (max-width: 400px) {
      //max-width: 230px;
      width: 100%;
    }
  }

  .imagesWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 15px;
    list-style-type: none;

    li {
      position: relative;
      svg {
        width: 28px;

        background: #ffffff;
        border-radius: 9999%;
        border: 1px solid #ffffff;
        cursor: pointer;
      }
      .details {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          align-items: center;
          gap: 17px;
          width: 300px;
        }
      }
    }
  }

  .selected-image {
    width: 78px;
    height: 78px;
    object-fit: cover;
    margin: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .resetImages {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    color: #2b3c45;
    font-weight: 600;
  }

  @media only screen and (max-width: 400px) {
  }
`;

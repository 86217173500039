import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import AuthWrapper from "../AuthWrapper";
import CustomFormInput from "@sharedComponent/CustomFormInput";
import notification from "@utils/notification";
import identity from "@services/identityService";

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Field cannot be empty")
});

export default function ForgotPassword() {
  const history = useHistory();
  const changePasswordMutation = useMutation(identity.resetPassword, {
    onSuccess: () => {
      history.push("/forgot-password-email-sent");
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onResetPassword = async ({ emailAddress }) => {
    changePasswordMutation.mutate({ emailAddress });
  };

  return (
    <AuthWrapper
      title="Forgot password?"
      subTitle="We'll send an email with a link to reset your password to the email
      address provided. Your password will not be reset until a new one has
      been confirmed."
      redirectLink={{ name: "", url: "" }}
    >
      <Formik
        initialValues={{
          emailAddress: ""
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onResetPassword(values);
        }}
      >
        <Form>
          <CustomFormInput
            type="text"
            placeholder="Enter your email address"
            name="emailAddress"
            id="emailAddress"
            data-testid="email"
          />
          <NavLink to="/login" className="forgot-password-link">
            Remember your password? Login
          </NavLink>

          <div className="submitAndErrorContainer">
            <motion.input
              type="submit"
              data-testid="submit-form"
              value={
                changePasswordMutation.isLoading
                  ? "Just a moment..."
                  : "Send password reset email"
              }
              className="submit-button form-buttons"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            />
            {/* {changePasswordMutation.isError && (
              <span className="submit-error">
                {changePasswordMutation?.error?.response?.data?.message}
              </span>
            )} */}
          </div>
        </Form>
      </Formik>
      <div data-testid="custom-element" />
    </AuthWrapper>
  );
}

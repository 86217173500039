import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import notification from "@utils/notification";
import { actions } from "@App/App.state";
import seller from "@services/sellerService";
import FormWrapper from "../FormWrapper";
import OtpInput from "react-otp-input";

import { display } from "@mui/system";

export default function VerifyBvnOtp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");

  const handleOtpChange = otp => {
    setOtp(otp);
    console.log(otp);
  };

  const updateSellerMutation = useMutation(
    seller.updateSellerAndCreateBusiness,
    {
      onSuccess: res => {
        // dispatch(actions.otp(res.businessId));
        history.push("/");
      },
      onError: err => {
        notification.createNotify({
          title: "Operation error",
          message: err.response.data.message || "Server error",
          type: "danger",
          position: "top-right"
        });
      }
    }
  );

  const handleVerifyBVN = () => {
    history.push("./upload-id");
  };

  const onUpdateSeller = values => {
    history.push("./upload-id");
    updateSellerMutation.mutate(values);
  };

  return (
    <FormWrapper
      title="Verify BVN"
      subTitle="We’ve sent a verification code to number. Please enter below to verify"
    >
      <div className="signup-form-wrapper" style={{ marginTop: "24px" }}>
        <Formik
          initialValues={{
            otp: ""
          }}
          validationSchema={Yup.object({
            phoneNumber: Yup.number().required("OTP field cannot be empty")
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onUpdateSeller(values);
          }}
        >
          <Form>
            <div className="otpInput">
              <OtpInput
                value={otp}
                name="otp"
                onChange={handleOtpChange}
                numInputs={5}
                separator={<span style={{ margin: "0 10px" }}></span>}
                style={{ width: "3em" }}
              />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px"
              }}
            >
              Retry in 1:12 seconds
            </p>

            <div>
              <motion.input
                type="submit"
                value={
                  updateSellerMutation.isLoading
                    ? "Just a moment..."
                    : "Confirm"
                }
                className="form-buttons submit-button"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                data-testid="submit-form"
                style={{
                  marginTop: "10px",
                  display: otp.length == 5 ? "block" : "none"
                }}
                onClick={handleVerifyBVN}
              />
            </div>
            {updateSellerMutation.isError && (
              <p className="error-message">
                {updateSellerMutation?.error?.response?.data?.message}
              </p>
            )}
          </Form>
        </Formik>
      </div>
    </FormWrapper>
  );
}

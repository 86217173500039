import http from "./httpService";

const api = "/reports";

/**
 * Fetch seller report aggregates
 *
 * @param {*} payload
 * @returns
 */
export async function fetchSalesAggregate({ queryKey }) {
  const [, { storeId }] = queryKey;

  const { data } = await http.get(`${api}/stores/${storeId}`);
  return data;
}

export default {
  fetchSalesAggregate
};

import React from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import {
  formInput,
  RenderTextArea,
  inputContainer
} from "@sharedComponent/FormElements";

const validationSchema = Yup.object({
  reason: Yup.string().required("Required")
});

export default function CancelOrder({ order, onClose, onCancelOrder }) {
  const onSubmitCancelOrder = values => {
    onCancelOrder.mutate(values);
    //onClose(false);
  };

  return (
    <Wrapper>
      <Formik
        initialValues={{
          reason: "",
          orderId: order.orderId
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmitCancelOrder(values);
        }}
      >
        <Form>
          <div className="input-container">
            <RenderTextArea
              label="Reason for cancelling"
              name="reason"
              type="text"
              placeholder=""
            />
          </div>

          <div className="form-button">
            <button
              type="submit"
              status={onCancelOrder.isLoading ? "disabled" : ""}
            >
              {onCancelOrder.isLoading ? "Saving" : "Continue"}
            </button>
          </div>
        </Form>
      </Formik>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;

  .formInput {
    ${formInput}
  }

  .input-container {
    margin-bottom: 16px;
    ${inputContainer}
  }

  .form-button {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 384px) {
    .input-container {
      width: 100%;
    }
  }
`;

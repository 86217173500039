import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import AddOrder from "./AddOrder/AddOrder";
import EditOrder from "./EditOrder/EditOrder";
import NoOrders from "./NoOrders";
import OrderDetail from "./OrderDetails/OrderDetail";
import NotFoundPage from "@App/Error/NotFoundPage";

import BlankPage from "@App/Error/NotFoundPage";

export default function Orders() {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={`${path}`}>
          <NoOrders />
        </Route>
        <Route exact path={`${path}/add`}>
          <AddOrder />
        </Route>

        <Route exact path={`${path}/view/:id`}>
          <OrderDetail />
        </Route>

        <Route exact path={`${path}/edit/:id`}>
          <EditOrder />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1025px) {
    margin: 0;
  }
`;

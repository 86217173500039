import React from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import FeatherIcon from "feather-icons-react";

export default function ImageHandler({ values, setFieldValue }) {
  const maxSize = 1048576;

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }

    // bubble up accepted uploaded images to formik
    setFieldValue("files", values.files.concat(acceptedFiles));
  };

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg, image/jpg",
    minSize: 0
  });

  const isFileTooLarge =
    rejectedFiles?.rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  // const isFileCountExceeded = values.files.length > 5;

  const handleRemoveImg = selectedFile => {
    const newList = values.files.filter(file => {
      return selectedFile.path !== file.path;
    });

    setFieldValue("files", [...newList]);
  };

  return (
    <Container className="formContainer">
      <div className="formTitle">Images</div>

      <div className="dropzoneContainer" {...getRootProps()}>
        <input {...getInputProps()} />
        {!isDragActive && "Drag and drop files to upload, or click to browse"}
        {isDragActive && !isDragReject && "Drop it like it's hot!"}
        {isDragReject && "File type not accepted, sorry!"}
        {isFileTooLarge && <div className="error">File is too large.</div>}
        {/* {isFileCountExceeded && (
          <div className="error">File count exceeded.</div>
        )} */}
      </div>

      <ul className="imagesWrapper">
        {values.files.length > 0 &&
          values.files.map((image, index) => (
            <li key={index}>
              <FeatherIcon
                icon="x-circle"
                size={20}
                onClick={() => handleRemoveImg(image)}
              />

              <img
                src={URL.createObjectURL(image)}
                alt="img"
                className={
                  index === 0 ? "selected-image hero-image" : "selected-image"
                }
              />
            </li>
          ))}
      </ul>
    </Container>
  );
}

const Container = styled.div`
  .file-picker {
    display: none;
  }

  p {
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 14px;
    color: #1e242d;
  }

  .dropzoneContainer {
    cursor: pointer;
    width: 100%;
    max-width: 430px;
    padding: 12px;
    color: #999999;
    background-color: #f5f5f5;
    border: 1px dashed #d1d1d1;
    border-radius: 2px;
    margin-top: 10px;

    .error {
      margin-top: 2px;
      color: #df3826;
    }

    @media only screen and (max-width: 400px) {
      max-width: 230px;
    }
  }

  .imagesWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 15px;
    list-style-type: none;

    li {
      position: relative;
      svg {
        width: 28px;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        border-radius: 9999%;
        border: 1px solid #ffffff;
        cursor: pointer;
      }
    }
  }

  .selected-image {
    width: 78px;
    height: 78px;
    object-fit: cover;
    margin: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .resetImages {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    color: #2b3c45;
    font-weight: 600;
  }
`;

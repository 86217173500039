import { ReactComponent as Home } from "@resources/icons/home.svg";
import { ReactComponent as Orders } from "@resources/icons/orders.svg";
import { ReactComponent as Products } from "@resources/icons/products.svg";
import { ReactComponent as Website } from "@resources/icons/website.svg";
import { ReactComponent as Payments } from "@resources/icons/payments.svg";
import { ReactComponent as Settings } from "@resources/icons/settings.svg";
import { ReactComponent as SignOut } from "@resources/icons/signOut.svg";
import { ReactComponent as Language } from "@resources/icons/language.svg";
import { ReactComponent as Close } from "@resources/icons/close.svg";
import { ReactComponent as DorokiLogo } from "@resources/icons/logo.svg";
import { ReactComponent as DorokiLogoBlue } from "@resources/icons/logoBlue.svg";
import { ReactComponent as Menu } from "@resources/icons/menu.svg";
import { ReactComponent as Facebook } from "@resources/icons/icon_facebook.svg";
import { ReactComponent as Google } from "@resources/icons/icon_google.svg";
import { ReactComponent as Paga } from "@resources/icons/icon_paga.svg";
import { ReactComponent as CheckMark } from "@resources/icons/checkMark.svg";
import { ReactComponent as Cart } from "@resources/icons/cart.svg";
import { ReactComponent as Empty } from "@resources/icons/empty.svg";
import { ReactComponent as Search } from "@resources/icons/search.svg";
import { ReactComponent as Back } from "@resources/icons/back.svg";
import { ReactComponent as ChevronBack } from "@resources/icons/chevronBack.svg";
import { ReactComponent as SortAsc } from "@resources/icons/sortAsc.svg";
import { ReactComponent as SortDesc } from "@resources/icons/sortDesc.svg";
import { ReactComponent as ArrowUp } from "@resources/icons/arrowUp.svg";
import { ReactComponent as ArrowDown } from "@resources/icons/arrowDown.svg";
import { ReactComponent as PlusIcon } from "@resources/icons/plusIcon.svg";
import { ReactComponent as CompleteActive } from "@resources/icons/completeActive.svg";
import { ReactComponent as Pending } from "@resources/icons/pending.svg";
import { ReactComponent as Ellipsis } from "@resources/icons/ellipsis.svg";
import { ReactComponent as Delete } from "@resources/icons/delete.svg";
import { ReactComponent as Edit } from "@resources/icons/edit.svg";
import { ReactComponent as Shipping } from "@resources/icons/shipping.svg";
import { ReactComponent as PagaLogo } from "@resources/icons/pagaLogo.svg";
import { ReactComponent as Card } from "@resources/icons/card.svg";
import { ReactComponent as LogoIcon } from "@resources/icons/logo_icon.svg";
import { ReactComponent as ErrorIcon } from "@resources/icons/error.svg";
import { ReactComponent as Manage } from "@resources/icons/manage.svg";
import { ReactComponent as Visa } from "@resources/icons/visa.svg";
import { ReactComponent as MasterCard } from "@resources/icons/masterCard.svg";
import { ReactComponent as Verve } from "@resources/icons/verve.svg";
import { ReactComponent as DorokiLogoGrey } from "@resources/icons/dorokiGrey.svg";
import { ReactComponent as DorokiBrandBlue } from "@resources/icons/dorokiBrandBlue.svg";

export default {
  Home,
  Orders,
  Products,
  Website,
  Payments,
  Settings,
  SignOut,
  Language,
  Close,
  DorokiBrandBlue,
  DorokiLogo,
  DorokiLogoBlue,
  Menu,
  Facebook,
  Google,
  Paga,
  CheckMark,
  Cart,
  Empty,
  Search,
  Back,
  ChevronBack,
  SortAsc,
  SortDesc,
  ArrowUp,
  ArrowDown,
  PlusIcon,
  CompleteActive,
  Pending,
  Ellipsis,
  Delete,
  Edit,
  Shipping,
  PagaLogo,
  Card,
  LogoIcon,
  ErrorIcon,
  Manage,
  Visa,
  MasterCard,
  Verve,
  DorokiLogoGrey
};

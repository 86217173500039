import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";

import AuthWrapper from "../AuthWrapper";
import CustomFormInput from "@sharedComponent/CustomFormInput";

import { authenticationActions } from "@App/Auth/Login/login.state";

import notification from "@utils/notification";
import helpers from "@utils/helpers";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Icons from "@sharedComponent/Icons";

import seller from "@services/sellerService";

export default function SignupPaga() {
  const history = useHistory();
  const dispatch = useDispatch();
  const mailRef = useRef("");

  const [passwordShown, setPasswordShown] = useState(false);

  const tokenKey = helpers.getAuthTokenName();
  const refreshKey = helpers.getRefreshTokenName();

  const signUpWithPaga = () => {
    console.log("siging up with paga");
  };

  const signupMutation = useMutation(seller.signupNewSeller, {
    onSuccess: res => {
      const accessToken = res.token.access_token;
      const refreshToken = res.token.refresh_token;
      mailRef.current = res.email;
      // persist to redux
      dispatch(
        authenticationActions.setEmailAndToken({
          emailAddress: res.email,
          token: accessToken
        })
      );

      // persist to local storage
      localStorage.setItem(tokenKey, accessToken);
      localStorage.setItem(refreshKey, refreshToken);

      history.push(`/welcome/${mailRef.current}`);
    },
    onError: err => {
      notification.createNotify({
        title: "Could not create account",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onSignUp = ({ email, password }) => {
    signupMutation.mutate({
      emailAddress: email,
      password
    });
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className="container" style={{ background: "#fff" }}>
      <Container>
        <AuthWrapper
          title="Create an account"
          // redirectLink={{ name: "Log in instead", url: "/login" }}
        >
          {/* <div className="pagaSignup" onClick={signUpWithPaga}>
            <span>
              <Icons.Paga />
            </span>
            <p>Sign up with Paga</p>
          </div>

          <p>Or</p> */}
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Field cannot be empty"),
              password: Yup.string()
                .required("Field cannot be empty")
                .min(10, "Password does not meet critieria below")
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              onSignUp(values);
            }}
          >
            <Form>
              <CustomFormInput
                type="text"
                placeholder="Enter your email address"
                name="email"
                id="email"
                data-testid="email"
              />

              <CustomFormInput
                type={passwordShown ? "text" : "password"}
                placeholder="Create a password"
                name="password"
                id="password"
                data-testid="password"
                icon={
                  passwordShown ? (
                    <FeatherIcon
                      icon="eye"
                      size={20}
                      onClick={togglePasswordVisiblity}
                      style={{ top: "25%" }}
                    />
                  ) : (
                    <FeatherIcon
                      icon="eye-off"
                      size={20}
                      onClick={togglePasswordVisiblity}
                      style={{ top: "25%" }}
                    />
                  )
                }
              />

              <div className="text-content">
                <span data-testid="target-render-el">
                  Your password must be 10 or more characters.{" "}
                </span>
                <span>
                  Use Capitalization, and numbers to make it stronger.
                </span>
              </div>

              <div className="submitAndErrorContainer">
                <motion.input
                  type="submit"
                  value={
                    signupMutation.isLoading
                      ? "Just a moment..."
                      : "Create my account"
                  }
                  className="submit-button form-buttons"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                  data-testid="submit-form"
                />
              </div>
              <div className="clickToLogin">
                <p>
                  Already have an account? Click
                  <span>
                    <NavLink to="/login"> here </NavLink>
                  </span>
                  to log in.
                </p>
              </div>
            </Form>
          </Formik>
        </AuthWrapper>
      </Container>
    </div>
  );
}

const Container = styled.div``;

import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";
import FormWrapper from "../FormWrapper";
import AuthWrapper from "../AuthWrapper";
import CustomFormInput from "@sharedComponent/CustomFormInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { authenticationActions } from "@App/Auth/Login/login.state";
import { actions } from "@App/App.state";
import notification from "@utils/notification";
import helpers from "@utils/helpers";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { RenderTextArea, RenderSelect } from "@sharedComponent/FormElements";

import seller from "@services/sellerService";

export default function BusinessInformation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);

  const onChangeValue = event => {
    console.log(event.target.value);
  };

  //fetch business categories

  const submitBusinessForm = useMutation();

  const fetchBusinessCategory = useMutation(seller.getBusinessCategory, {
    onSuccess: res => {
      dispatch(actions.setBusinessId(res.businessId));
      dispatch(actions.setStoreId(res.storeId));
      dispatch(actions.setSellerId(res.sellerId));
      dispatch(actions.setWalletId(res.accountId));
      console.log(res);
      //history.push("/");
    },
    onError: err => {
      notification.createNotify({
        title: "Operation error",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const submitBusiness = values => {
    fetchBusinessCategory.mutate({
      latitude: 0,
      longitude: 0,
      accuracy: 0
    });
    console.log(values);
  };

  useEffect(() => {
    //fetchBusinessCategory.mutate()
    //submitBusiness()
    fetchBusinessCategory.mutate({
      latitude: 0,
      longitude: 0,
      accuracy: 0
    });
  }, []);

  const selectOptions = [
    // {key:0, value:'Select Business Type'},
    { key: 1, value: "One" },
    { key: 2, value: "Two" },
    { key: 3, value: "Three" }
  ];

  const onSubmitBusinessInfo = values => {
    //history.push("./businessAdd");
    console.log(values);
    fetchBusinessCategory.mutate({
      latitude: 0,
      longitude: 0,
      accuracy: 0
    });
  };

  const handleBusinessTypeChange = value => {
    console.log(value);
  };

  const handleBusinessCategoryChange = value => {
    console.log(value);
  };

  return (
    <Container>
      <div className="left">
        {/* <div className="navigate" onClick={goBack}>
          <ArrowBackIcon />
          Back
        </div> */}
      </div>
      <FormWrapper
        title="Business Information"
        subTitle="Tell us a bit about your business"
      >
        <Formik
          enableReinitialize
          initialValues={{
            businessName: "",
            businessType: "",
            businessCategory: "",
            description: ""
          }}
          validationSchema={Yup.object({
            businessName: Yup.string().required("Feld cannot be empty"),
            businessType: Yup.string().required("Field cannot be empty"),
            businessCategory: Yup.string().required("Field cannot be empty"),
            description: Yup.string().required("Field cannot be empty")
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onSubmitBusinessInfo(values);
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <CustomFormInput
                type="text"
                placeholder="Business Name"
                name="businessName"
                id="businessName"
                data-testid="businessName"
                label="Business name"
              />
              <div className="form-row">
                <div className="form-row-col">
                  <RenderSelect
                    placeholder="Select type"
                    label="Business Type"
                    name="businessType"
                    options={selectOptions}
                    showemptyoptionfirst={1}
                    onChange={e => {
                      setFieldValue("businessType", e.target.value);
                      handleBusinessTypeChange(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="form-row-col">
                  <RenderSelect
                    placeholder="Select business category"
                    label="business Category"
                    name="businessCategory"
                    options={selectOptions}
                    showemptyoptionfirst={1}
                    onChange={e => {
                      setFieldValue("businessCategory", e.target.value);
                      handleBusinessCategoryChange(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>

              <RenderTextArea
                label="Description"
                name="description"
                type="text"
                data-testid="description"
                style={{
                  width: "100%",
                  height: "127px",
                  marginBottom: "45px",
                  marginTop: "10px"
                }}
              />

              <div className="submitContainer">
                <motion.input
                  type="submit"
                  value={
                    fetchBusinessCategory.isLoading
                      ? "Just a moment..."
                      : "Next"
                  }
                  className="submit-button"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                  data-testid="submit-form"
                />
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
      <div className="right"></div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }

  .left,
  .right {
    width: 25%;
  }

  .left {
    position: relative;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &-col {
      width: 48%;
    }
  }

  select {
    width: 100%;
    height: 48px;
    border: 1px solid #d1d1d1;
    background-color: #f8f7f7;
  }

  textarea {
    background-color: #f8f7f7;
    margin-top: 0 !important;
  }

  .navigate {
    <<<<<<<HEAD
    // margin-top: 70px;
    // display: flex;
    // justify-content:flex-end;
    position: absolute;
    right: 0;
    top: 12%;
    =======margin-top: 50px;
    display: flex;
    >>>>>>>cc5ba4d (added selfie and bvn form)
    cursor: pointer;
  }
  .submitContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type="submit"] {
      background: #2b3c45;
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      width: 100%;
      padding: 16px auto;
      border-radius: 8px;
    }
  }

  .input-and-error-wrapper {
    position: relative;

    svg {
      position: absolute;
      top: 35% !important;
      right: 3%;
    }

    label {
      display: flex;

      .radio-field {
        display: flex;
        align-items: center;
        margin-right: 16px;

        input {
          border: 1px solid #d1d1d1;
        }

        &-label {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #231f20;
        }
      }
    }
  }

  .radio {
    border: 1px solid #e8e8e8 !important;
    color: #e8e8e8;
    background: #e8e8e8;
    accent-color: #231f20;
    margin-right: 2px;
  }
`;

import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
// import Navigate from "react-router-dom"
import styled from "styled-components";
import Main from "./Main/Main";
import Orders from "./Orders/Orders";
import Products from "./Products/Products";
import Payments from "./Payments/Payments";
import Settings from "./Settings/Settings";
import WelcomePage from "./WelcomePage";
import NotFoundPage from "../Error/NotFoundPage";

export default function MainContent() {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={`${path}`}>
          {/* <Home /> */}
          <Main />
        </Route>
        <Route path={`${path}orders`}>
          <Orders />
        </Route>
        <Route path={`${path}products`}>
          <Products />
        </Route>
        <Route path={`${path}payments`}>
          <Payments />
        </Route>
        <Route path={`${path}welcome-page`}>
          <WelcomePage />
        </Route>
        <Route path={`${path}settings`}>
          <Settings />
        </Route>
        <Route path={`${path}404`}>
          <NotFoundPage />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-left: 190px;
  box-sizing: border-box;
  height: 100%;
  background-color: #fafafa;
  background-size: cover;
  position: fixed;
  display: block;
  z-index: 1;
  padding-top: 10px;
  overflow-y: auto;
  @media screen and (max-width: 1025px) {
    padding-left: 0;
  }

  @media screen and (min-width: 1026px) {
    padding-top: 0;
    padding-left: 190px;
  }

  @media screen and (max-width: 430px) {
    //margin: auto;
  }
`;

import React from "react";
import styled from "styled-components";
import SearchOrderItems from "./SearchOrderItems";

export default function SelectProducts() {
  return (
    <Wrapper>
      <h4>Add products</h4>

      <SearchOrderItems />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  h4 {
    margin-top: 40px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
  }
`;

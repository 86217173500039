import { css } from "styled-components";

export default css`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b3c45;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";

  .content-wrapper {
    height: 100%;
    background-color: #ffffff;
    width: 640px;
    padding: 50px 100px;
    box-sizing: border-box;
    color: #2b3c45;

    .term-text {
      color: #1e242d
      width: 440px;
      height: 69px;
      font-family: Montserrat;
      font-size: 24px;
      color: #1e242d;
      text-decoration: none solid rgb(30, 36, 45);
      text-align: center;
      line-height: 1.2;
    }
    .check-box-height {
      height: auto;
    }
    .agreement-text {
      width: 440px;
      height: 43px;
      font-family: Open Sans;
      font-size: 16px;
      color: #2b3c45;
      text-decoration: none solid rgb(43, 60, 69);
      width: 440px;
      line-height: 1.2;
    }
    .submitAndErrorContainer {
      margin-top: 3rem;
    }
    .check-box-error {
      color: red;
      padding: 5px 0px 5px 0;
    }
    .info-text {
      width: 440px;
      height: 64px;
      font-family: Open Sans;
      font-size: 16px;
      color: #2b3c45;
      text-decoration: none solid rgb(43, 60, 69);
      line-height: 1.5;
      margin-bottom: 2rem;
    }

    .img-caption {
      width: 26px;
      height: 26px;
      fill: #0f2531;
      text-align: center
    }
    
    svg {
      width: 150px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      .titleIcon {
        display: flex;
        align-items: center;
        margin-left: -60px;
        svg {
          height: 31px;
          fill: #53c37a;
        }
        h1 {

          // margin-left: -48px;
        }
      }
      .titleText {
        display: flex;
        margin-left: -48px;
      }
    }

    .reset-password-text {
      color: #121212;
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      line-height: 1.5;
      margin-top: 10px;
    }
    .change-pass-text {
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      margin-top: -15px;
      span {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #666666;
        font-size: 13px;
        line-height: 1.5;
      }
    }
    h1 {
      font-family: Montserrat;
      font-size: 24px;
      letter-spacing: 1px;
    }
    a {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      color: #2b3c45;
      text-decoration: none;
    }
  }

  .auth-form-wrapper {
    margin-top: 34px;
  }

  .input-and-error-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    input {
      font-size: 14px;
      padding: 15px 5px 15px 17px;
      border-radius: 2px;
      border: 1px solid #d1d1d1;
    }

    .error {
      position: absolute;
      color: #da1a1a;
      font-size: 14px;
      bottom: -10px;
      left: 0px;
      font-size: 13px;
    }

    svg {
      color: #000000;
      position: absolute;
      top: 18%;
      right: -8%;
    }

    svg:hover {
      color: #2b3c45;
      cursor: pointer;
    }
  }

  .text-content {
    margin-top: -10px;
    width: 360px;
    height: 38px;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #666666;
  }

  hr {
    margin: 15px 0px 15px 0px;
    border: none;
    border-top: 1px solid #e9e9e9;
    color: #121212;
    background-color: #fff;
    width: 100%;
    height: 1px;
    stroke-width: 1;
  }

  .form-buttons {
    width: 440px;
    height: 60px;
    border-radius: 50px;
    background-color: #2b3c45;
    background-size: cover;
    font-family: Open Sans;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    margin-top: 25px;
  }

  .submit-button {
    &:focus {
      border: none;
      box-shadow: none;
    }
    &:hover {
      background-color: #0f2531;
    }
  }

  .paga-oauth {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    color: #121212;
    &:hover {
      background-color: #d1d1d150;
    }
    svg {
      position: absolute;
      left: 23px;
      width: 20px;
      top: 19px;
    }
  }

  .error-message {
    width: 100%;
    text-align: center;
    color: #ef4444;
  }

  @media screen and (max-width: 650px) {
    .auth-wrapper {
      width: 100%;
      height: 100% !important;
      padding: 50px 30px;
      .title {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .auth-wrapper {
      padding: 50px 4%;
    }
    .text-content {
      // width: 100%;
      // height: auto;
      display: flex
    }
    .auth-form-wrapper {
      margin-top: 20px;
    }
  }
`;

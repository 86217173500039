import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import styled from "styled-components";

import notification from "@utils/notification";

import customerApi from "@services/customerService";
import storeApi from "@services/storeService";

import {
  formInput,
  RenderInput,
  RenderSelect,
  RenderTextArea,
  inputContainer
} from "@sharedComponent/FormElements";

// Validation schema.
const schema = Yup.object({
  country: Yup.string().required("Required"),
  region: Yup.string().required("Required"),
  localGovernmentArea: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  streetAddress: Yup.string().required("Required")
});

export default function AddPickUpAddress({ countries, regions, onClose }) {
  const queryClient = useQueryClient();

  const [, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [lga, setLGA] = useState([]);
  const [city, setCity] = useState([]);

  const { storeId } = useSelector(selectors.app);

  useEffect(() => {
    const countryData = countries.map(function(param) {
      return { key: param.id, value: param.name };
    });
    setCountry(countryData);

    const stateData = regions.map(function(state) {
      return { key: state.id, value: state.name };
    });
    setState(stateData);
  }, []);

  /**
   * Mutation to fire create pickup address.
   */
  const addPickupAddressMutation = useMutation(storeApi.createPickupAddress, {
    onMutate: newData => {
      queryClient.cancelQueries(["pickup_address", { storeId }]);

      const previousAddresses = queryClient.getQueryData([
        "pickup_address",
        { storeId }
      ]);

      queryClient.setQueryData(["pickup_address", { storeId }], prev => [
        ...prev,
        { ...newData, addressId: new Date().toISOString() }
      ]);

      // return snapshot
      return previousAddresses;
    },
    onError: (err, previousValue) => {
      queryClient.setQueryData(["pickup_address", { storeId }], previousValue);

      notification.createNotify({
        title: "Error saving address",
        message: err?.response?.data?.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["pickup_address", { storeId }]);

      // close modal
      onClose(false);
    }
  });

  const onSubmitAddressForm = values => {
    addPickupAddressMutation.mutate({ ...values, storeId });
  };

  const handleRegionChange = async value => {
    const selectedRegionObj = regions.find(region => region.name === value);

    const fetchLGA = await customerApi.fetchLgaByRegionId(selectedRegionObj.id);

    const filteredLGA = fetchLGA.map(param => {
      const container = {};
      container.key = param.id;
      container.value = param.name;

      return container;
    });

    setLGA(filteredLGA);
  };

  const handleLGAChange = async value => {
    const selectedLGAObj = lga.find(item => item.value === value);

    const fetchCities = await customerApi.fetchCitiesByLgaId(
      selectedLGAObj.key
    );

    const filteredCities = fetchCities.map(param => {
      const container = {};
      container.key = param.id;
      container.value = param.name;

      return container;
    });

    setCity(filteredCities);
  };

  return (
    <Wrapper>
      <Formik
        enableReinitialize
        initialValues={{
          country: "Nigeria",
          region: "",
          localGovernmentArea: "",
          city: "",
          streetAddress: "",
          remarks: ""
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmitAddressForm(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="input-container">
              <RenderInput
                label="Country"
                name="country"
                placeholder="Nigeria"
                value="Nigeria"
                onChange={() => setFieldValue("country", "Nigeria")}
                readOnly
              />
            </div>

            <div className="input-container">
              <RenderSelect
                placeholder="Select State"
                label="State"
                name="region"
                options={state}
                showemptyoptionfirst={1}
                onChange={e => {
                  setFieldValue("region", e.target.value);
                  handleRegionChange(e.target.value);
                }}
              />
            </div>

            <div className="input-container">
              <RenderSelect
                placeholder="Select LGA"
                label="LGA"
                name="localGovernmentArea"
                options={lga}
                showemptyoptionfirst={1}
                onChange={e => {
                  setFieldValue("localGovernmentArea", e.target.value);
                  handleLGAChange(e.currentTarget.value);
                }}
              />
            </div>

            <div className="input-container">
              <RenderSelect
                placeholder="Select City"
                label="City"
                name="city"
                options={city}
                showemptyoptionfirst={1}
                onChange={e => {
                  setFieldValue("city", e.target.value);
                }}
              />
            </div>

            <div className="input-container">
              <RenderTextArea
                label="Street address"
                name="streetAddress"
                type="text"
                placeholder=""
              />
            </div>

            <div className="input-container">
              <RenderTextArea
                label="Note for driver"
                name="remarks"
                type="text"
                placeholder=""
              />
            </div>

            <div className="form-button">
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                type="submit"
                status={addPickupAddressMutation?.isLoading ? "disabled" : ""}
              >
                {addPickupAddressMutation?.isLoading
                  ? "Saving address..."
                  : "Save"}
              </motion.button>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  //width: 100%;

  .formInput {
    ${formInput}
  }

  .input-container {
    margin-bottom: 16px;
    ${inputContainer}
  }

  .form-button {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 384px) {
    .input-container {
      width: 100%;
    }
  }
`;

import React from "react";
import { useField, Formik, Field, Form } from "formik";
import styled from "styled-components";

const RadioButton = ({ label, gender, value }) => {
  return (
    <Label>
      <Field
        type="radio"
        name="gender"
        checked={gender}
        style={{ marginRight: "8px" }}
        className="radio"
        id="radio-btn"
        value={value}
      />
      {label}
    </Label>
  );
};

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #231f20;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  .radio {
    border: 1px solid #e8e8e8 !important;
    color: #e8e8e8;
    background: #e8e8e8;
    accent-color: #231f20;
  }
`;
export default RadioButton;

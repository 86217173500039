import { Field, useField } from "formik";
import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";

export const RenderInput = ({ label, required, ...props }) => {
  const [field, meta] = useField(props);
  const { name } = props;
  return (
    <>
      <section className="inputContainer">
        {/* <label htmlFor={name} className="inputLabel">
          <div className="">
            {label}
            <span className="required-label">
              {required}
            </span>
          </div>
        </label> */}
        <label htmlFor={name} className="label">
          <div className="input-label">{label}</div>
          <span className="required-label">{required}</span>
        </label>
        <input
          {...field}
          {...props}
          className="formInput"
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
          placeholder={props.placeholder}
        />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </section>
    </>
  );
};

export const RenderTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { name } = props;
  return (
    <>
      <section className="inputContainer">
        <label htmlFor={name}>{label}</label>
        <textarea
          {...field}
          {...props}
          className="formInput"
          // onKeyPress={e => {
          //   if (e.key === "Enter") e.preventDefault();
          // }}
        />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </section>
    </>
  );
};

export const RenderSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const {
    name,
    options,
    numericvalue,
    showemptyoptionfirst,
    selectlabel
  } = props;
  return (
    <>
      <section className="inputContainer">
        <label htmlFor={name} className="inputLabel">
          {label}
        </label>
        <select {...field} {...props} className="formInput">
          {showemptyoptionfirst ? <option value="" disabled hidden /> : null}
          <option value="" selected disabled>
            {selectlabel}
          </option>
          {options.map(option => (
            <option
              key={option.key}
              value={numericvalue ? option.key : option.value}
            >
              {option.value}
            </option>
          ))}
        </select>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </section>
    </>
  );
};

export const RenderCheckbox = ({ label, children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  const { name } = props;
  return (
    <>
      <section className="inputContainer">
        <label htmlFor={name} className="inputLabel">
          {label}
        </label>
        <input type="checkbox" {...field} {...props} className="formInput" />
        {children}
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </section>
    </>
  );
};

export const RenderRadio = props => {
  const [field, meta] = useField(props);
  const { label, name, options } = props;
  return (
    <>
      <section className="radioButtonContainer">
        <label className="inputLabel">{label}</label>

        <div className="radio-input--container">
          <Field name={name}>
            {({ field }) => {
              return options.map(option => (
                <label key={option.key}>
                  <input
                    type="radio"
                    name={name}
                    {...field}
                    {...props}
                    //checked={field.value === option.value}
                    value={option.value}
                    className="radio-circle"
                  />
                  <span className="radio-label">{option.key}</span>
                </label>
              ));
            }}
          </Field>
        </div>

        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </section>
    </>
  );
};

RenderInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

RenderTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

RenderSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  numericvalue: PropTypes.number
};

RenderCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

RenderRadio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export const inputContainer = css`
  position: relative;
  label {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .inputLabel {
    color: ${({ errorMode }) => {
      return errorMode ? "#f26522" : "#1e242d";
    }};
    font-size: 12px;
    font-weight: 400;
  }

  .required-label {
    font-size: 16px;
    color: rgb(210, 210, 210) !important;
    text-decoration: none solid rgba(43, 60, 69, 0.4);
    line-height: 24px;
  }

  .error {
    position: absolute;
    bottom: -18px;
    left: 0;
    color: #f26522;
    font-size: 14px;
  }
`;

export const formInput = css`
  margin-top: 10px;
  width: 100%;
  max-width: 540px;
  padding: 15px 8px 15px 15px;
  border-radius: 2px;
  color: #121212;
  border: 1px solid #d1d1d1;
  transition: 0.3s all ease;
`;

export const radioButtonContainer = css`
  position: relative;

  .inputLabel {
    color: ${({ errorMode }) => {
      return errorMode ? "#f26522" : "#1e242d";
    }};
    font-size: 14px;
    font-weight: 500;
  }

  .error {
    position: absolute;
    // bottom: -18px;
    left: 0;
    color: #f26522;
    font-size: 14px;
  }

  .radio-input--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 12px;

    .radio-label {
      position: relative;
      margin-left: 8px;
      align-items: center;
      font-size: 14px;
      color: #6d6d6d;
    }

    .radio-circle {
      left: 0;
      height: 22px;
      width: 22px;
      border: 1px solid #000000;
      border-radius: 50%;
      margin-bottom: 1.5rem;
      &:hover {
        background-color: #f5f5f5;
      }
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    &:checked {
      background-color: #2196f3;
    }
    &:hover input ~ .radio-circle {
      background-color: #f5f5f5;
    }

    .radio-circle:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .radio-circle {
      background-color: #ffffff;
    }

    input:checked ~ .radio-circle:after {
      display: block;
    }

    .radio-circle:after {
      top: 7px;
      left: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #2b3c45;
    }
  }
`;

export const priceInputContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;

  span {
    position: absolute;
    margin-left: 15px;
  }

  #price {
    width: 300px;
    padding-left: 40px;
    margin-top: 0px;
  }

  .errorMsg {
    font-size: 14px;
    margin-top: 4px;
    position: absolute;
    color: #f26522;
  }
`;

import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import FeatherIcon from "feather-icons-react";

export default function ProductsEmptyState() {
  return (
    <Container>
      <motion.div className="emptyProductsCard">
        <FeatherIcon icon="tag" />
        <h4>No products</h4>
        <p>New products will appear here</p>
      </motion.div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;

  .emptyProductsCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #e3e3e3;
    color: #d4d4d4;
    width: 90%;
    margin: 180px auto 0;
    height: 359px;

    h4 {
      font-size: 20px;
      margin: 14px 0 0 0;
    }

    p {
      font-size: 14px;
      margin-top: 7px;
    }
  }
`;

import { actions } from "@App/App.state";
import { selectors } from "configureStore";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Icons from "./Icons";

export default function Notification() {
  const app = useSelector(selectors.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (app.showNotification) {
      const notificationTimer = setTimeout(() => {
        dispatch(actions.setHideNotification());
      }, 5000);

      return () => {
        clearTimeout(notificationTimer);
      };
    }
  }, [app.showNotification]);

  return (
    <>
      <AnimatePresence>
        {app.showNotification === true && (
          <Wrapper
            initial={{
              y: 150,
              opacity: 0.5
            }}
            animate={{ y: 0, opacity: 1 }}
            exit={{
              y: 150,
              opacity: 0.5
            }}
            transition={{ duration: 0.5 }}
          >
            <Icons.CheckMark />
            <h2>{app.notificationMessage || "Success"}</h2>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
}
const Wrapper = styled(motion.div)`
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  width: 100%;
  padding: 48px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 381px;
  bottom: 30px;
  right: 30px;
  svg {
    height: 40px;
    fill: #54c27a;
  }
  h2 {
    margin: 18px 0 0 0;
    font-family: "Montserrat";
    font-size: 24px;
    color: #2b3c45;
  }

  @media screen and (max-width: 500px) {
    max-width: 100%;
    border-radius: 0px;
    padding: 20px;
    bottom: 0;
    right: 0;
    bottom: 0;
    h2 {
      font-size: 18px;
      margin-top: 10px;
    }
  }
`;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import Icons from "@sharedComponent/Icons";
import Modal from "../Common/Modal";
import useModal from "@hooks/useModal";
import { orderActions } from "../Orders.state";

import AddCustomerAddress from "../Common/AddCustomerAddress";
import CustomerAddress from "../Common/CustomerAddress";
import PickUpLocation from "../Common/PickUpLocation";
import {
  formInput,
  inputContainer,
  radioButtonContainer,
  RenderRadio,
  priceInputContainer
} from "@sharedComponent/FormElements";
import CustomPriceInput from "@sharedComponent/CustomPriceInput";

import customer from "@services/customerService";

const shippingType = [
  { key: "Delivery", value: "DELIVERY" },
  { key: "Pickup", value: "PICKUP" }
];

export default function SetShippingAddress({
  setFieldValue,
  customerAddresses,
  pickUpAddresses
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);

  const [option, setOption] = useState(null);
  const { isVisible, toggleModal } = useModal();

  /** Fetch countries */
  useQuery("countries", customer.fetchCountries, {
    initialData: () => {
      const cachedCountries = queryClient.getQueryData("countries");
      if (
        cachedCountries &&
        Date.now() - cachedCountries.dataUpdatedAt <= 86400
      ) {
        setCountry(cachedCountries);
      }
    },
    onSuccess: res => {
      setCountry(res);
    }
  });

  /** Fetch regions */
  useQuery("regions", customer.fetchRegions, {
    initialData: () => {
      const cachedRegions = queryClient.getQueryData("regions");
      if (cachedRegions && Date.now() - cachedRegions.dataUpdatedAt <= 86400) {
        setRegion(cachedRegions);
      }
    },
    onSuccess: res => {
      setRegion(res);
    }
  });

  const handleSelectAddress = query => {
    dispatch(orderActions.setShippingAddress(query));
  };

  return (
    <Wrapper>
      {/* Modal Popup */}
      <Modal
        isVisible={isVisible}
        hideModal={toggleModal}
        title="Add new delivery address"
      >
        <AddCustomerAddress
          countries={country}
          regions={region}
          onClose={toggleModal}
        />
      </Modal>

      <div className="shipping-address--form">
        <div className="shipping-address-form--content">
          <div className="radio-container">
            <RenderRadio
              label="Shipping or pick up?"
              options={shippingType}
              name="shippingType"
              onChange={e => {
                setFieldValue("shippingType", e.target.value);
                setOption(e.target.value);
              }}
            />
          </div>

          {option === "DELIVERY" && (
            <>
              <hr className="divider" />
              <div className="spacing" />
              <h4>Customer addresses</h4>
              <div
                className="add--customer"
                role="button"
                aria-hidden="true"
                onClick={toggleModal}
              >
                <Icons.PlusIcon /> Add a new delivery address
              </div>

              {customerAddresses.length > 0 ? (
                <>
                  {customerAddresses.map(customerAddress => (
                    <CustomerAddress
                      key={customerAddress.addressId}
                      address={customerAddress}
                      onChoose={handleSelectAddress}
                    />
                  ))}
                </>
              ) : (
                <h3 className="notFound">No address found for this customer</h3>
              )}
            </>
          )}

          {option === "PICKUP" && (
            <>
              <hr className="divider" />
              <div className="spacing" />
              <h4>Pickup locations</h4>

              {pickUpAddresses.length > 0 ? (
                <>
                  {pickUpAddresses.map(pickUpAddress => (
                    <PickUpLocation
                      key={pickUpAddress.addressId}
                      address={pickUpAddress}
                      onChoose={handleSelectAddress}
                    />
                  ))}
                </>
              ) : (
                <h3 className="notFound">
                  No pickup location linked to this store
                </h3>
              )}
            </>
          )}

          <hr className="divider" />
          <div className="spacing" />

          <div className="caption">Shipping cost</div>

          <div className="spacing" />

          <CustomPriceInput
            label=""
            name="shippingFee"
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .shipping-address--form {
    margin-top: 2rem;

    .shipping-address--form--content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .formContainer {
    padding: 0px;
  }

  .formInput {
    ${formInput}
  }

  .input-container {
    margin-bottom: 1em;
    ${inputContainer}
  }

  .input-container {
    width: 40%;
  }

  .radio-container {
    margin-bottom: 16px;
    ${radioButtonContainer}
  }

  .spacing {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .caption {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .price-input--container {
    ${priceInputContainer};
  }

  .add--customer {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    color: #2b3c45;
    cursor: pointer;
    margin-bottom: 1rem;

    svg {
      fill: #2b3c45;
      width: 20px !important;
      height: 20px !important;
      margin-right: 5px;
    }
  }

  .notFound {
    color: #2b3c45;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
  }

  @media only screen and (max-width: 384px) {
    .shipping-address--form {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .input-container {
      width: 100%;
    }
  }
`;

import React, { useState } from "react";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import ProfileForm from "./ProfileForm";

export default function MyVerticallyCenteredModal(props) {
  const [active, setIsActive] = useState(false);

  const handleChange = () => {
    setIsActive(!active);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Wrapper className="body px-4">
        <Modal-content className="modal-content">
          <Modal.Header closeButton className="no-border">
            <Modal.Title id="contained-modal-title-vcenter">
              My Settings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb-1">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="pb-0"
            >
              <Tab
                eventKey="profile"
                title="Profile"
                tabClassName="rounded-0 nav-link ps-0 no-border"
              >
                <ProfileForm />
              </Tab>

              <Tab
                eventKey="contact"
                title="Security"
                disabled
                tabClassName="rounded-0 nav-link ps-0 no-border"
              >
                <p>
                  Cras mattis consectetur purus sit amet fermentum. Cras justo
                  odio, dapibus ac facilisis in, egestas eget quam. Morbi leo
                  risus, porta ac consectetur ac, vestibulum at eros.
                </p>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer
            className="no-border footer-container"
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div className="footer" style={{ width: "100%" }}>
              <Button onClick={props.onHide} className="cancel-btn">
                Close
              </Button>
              <button form="settings-form" className="save-btn" type="submit">
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal-content>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 558px;
  border-radius: 5px;
  fill: #ffffff;
  box-shadow: 0px 4px 20px rgba(0,0,0,0.4);

  .footer {
    display: flex;
    justify-content: space-between;
  }
  .caption-style {
    color: '#2b3c45'; 
    opacity: '60%'
  }

  .modal-content{
    display:flex;
    flex-direction:column;
  }
  .nav-item .nav-link{
    color: #2b3c45;
    width:fit-content !important;
    font-size: 16px;
    margin-right: 18px;
    opacity:60%;
    border:none;
    font-weight: 500;
  }
  .modal-title{
    font-family: ubuntu;
    font-weight: bold;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-bottom: 3px solid #2b3c45;
    opacity: 100%;
    -webkit-appearance: none;
  }
  .nav{
    height: fit-content !important;
  }
  .modal-header{
  }
  .modal-body{
  }
  .body{
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    color: #2b3c45;
    font-family:Ubuntu;
    font-weight: 500;
  }
  .cancel-btn {
    width: 160px;
    height: 40px;
    border: 3px solid #2b3c45;
    border-radius: 20px; 
    background-size: cover;
    background-color: white;
    font-family: Open Sans;
    font-size: 14px;
    color: #2b3c45;
    text-decoration: none solid rgb(43, 60, 69);
    text-align: center;

    :active{
      background-color: #2b3c45 !important;
      color: white;
      border: none;
    }
  }
  .save-btn {
    width: 160px;
    height: 40px;
    border-radius: 20px;
    background-color: #2b3c45;
    background-size: cover;
    border-color: white;
    font-family: Open Sans;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;

    :active{
      border:none;
    }
  }
  .no-border {
    border: none
  }
  .footer-container{
    height:52%;
    @media only screen and (max-width: 992px) {
      height:40%;
    }
  }
  .pills-border {
    margin-bottom: -1px;
    border: none
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    color: #2b3c45;
    background-color: #fff;
    font-size: 16px;
    font-family: Ubuntu;
}
.nav-link:active{
  opacity:100%;

}
.nav-tabs .nav-link {
   margin-bottom: 0px; 
   border: 1px solid transparent; 
   border-top-left-radius: 0 
   border-top-right-radius: 0 
}
`;

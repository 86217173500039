/* eslint-disable import/prefer-default-export */
const { css } = require("styled-components");

const title = css`
  font-family: Montserrat;
  font-size: 24px;
  color: #2b3c45;
  line-height: 29px;
  margin: 0;
`;

const orderStyles = { title };
export default orderStyles;

import styled, { css } from "styled-components";

// export const buttonStyle = css`
//   padding: 15px 8px;
//   border-radius: 20px;
//   border: none;
//   background-color: #2B3C45;
//   opacity: ${props => (props.disabled ? 0.2 : 1)};
//   background-size: cover;
//   font-size: 16px;
//   color: #ffffff;
//   font-family: Cabin;
//   text-decoration: none solid rgb(255, 255, 255);
//   text-align: center;
//   cursor: pointer;
//   transition: 0.5s all ease;
//   outline: none;

//   &:hover {
//     background-color: #2011f2;
//   }

//   &:active {
//     background-color: #2011f2;
//   }

//   @media only screen and (max-width: 480px) {
//     width: 100%;
//   }
// `;

const Button = styled.button`
  border-radius: ${({ bigRadius }) => (bigRadius ? "30px" : "20px")};
  background-color: ${({ primary }) => (primary ? "#2B3C45" : "#000")};
  color: ${({ primary }) => (primary ? "#fff" : "#000")};
  padding: ${({ big }) => (big ? "18px 30px" : "10px 28px")};
  font-size: ${({ bigFont }) => (bigFont ? "18px" : "16px")};
  outline: none;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${({ primary }) => (primary ? "#fff" : "#2B3C45")};
    color: #000;
  }
  &:active {
    transform: translateY(0.5rem);
  }

  @media only screen and (max-width: 1000px) {
    /* width: 100%; */
    padding: ${({ big }) => (big ? "18px 30px" : "10px 20px")};
  }
  @media only screen and (max-width: 375px) {
    padding: ${({ big }) => (big ? "12px 20px" : "10px 20px")};
    font-size: ${({ bigFont }) => (bigFont ? "16px" : "18px")};
  }
`;

export default Button;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import getDecodedToken from "@utils/getDecodedToken";
import Icons from "@sharedComponent/Icons";
import useLogout from "@hooks/useLogout";
import { useQueryClient } from "react-query";
import { useOnInstallPWA } from "@hooks/useAddToHomescreenPrompt";

export default function NavigationDrawer({
  showNavigationDrawer,
  setShowNavigationDrawer
}) {
  return (
    <Wrapper>
      {showNavigationDrawer && (
        <motion.div
          className="drawer-wrapper"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 }
          }}
          transition={{ ease: "easeOut", duration: 0.5 }}
          onClick={() => setShowNavigationDrawer(false)}
        />
      )}

      <AnimatePresence>
        {showNavigationDrawer && (
          <motion.div
            className="drawer"
            key="drawer"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: { x: -350 },
              visible: { x: 0, opacity: 1 },
              exit: { x: -350, opacity: 1 }
            }}
            transition={{ ease: "easeOut", duration: 0.5 }}
          >
            <Icons.Close
              className="close-icon"
              onClick={() => setShowNavigationDrawer(false)}
            />
            <TopSection />
            <DrawerList setShowNavigationDrawer={setShowNavigationDrawer} />
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const TopSection = () => {
  const queryClient = useQueryClient();

  const [sellerId, setSellerId] = useState(null);

  useEffect(() => {
    const token = getDecodedToken();
    setSellerId(token?.sub);
  }, []);

  // fetch cached seller_info
  const cachedSellerInfo = queryClient.getQueryData([
    "fetch_seller_info",
    { sellerId }
  ]);

  return (
    <div className="top-section">
      <Icons.DorokiLogo className="drawer-logo" />
      <p>
        {cachedSellerInfo?.businesses[0]?.businessName || "No business name"}
      </p>
    </div>
  );
};

const DrawerList = ({ setShowNavigationDrawer }) => {
  const logout = useLogout();

  const [showPrompt, setShowPrompt] = useState(0);

  // const installPWA = useOnInstallPWA();

  useEffect(() => {
    const checkPWAInstallStatus = localStorage.getItem("PWAInstalled");

    if (checkPWAInstallStatus === "true") {
      setShowPrompt(0);
    } else {
      setShowPrompt(1);
    }
  }, []);

  // const onClosePrompt = () => {
  //   setShowPrompt(0);
  //   localStorage.setItem("PWAInstalled", true);
  // };

  return (
    <div
      className="drawer-list"
      role="button"
      onClick={() => {
        setShowNavigationDrawer(false);
      }}
      tabIndex="0"
    >
      <NavLink exact to="/">
        <div className="icon-and-text">
          <FeatherIcon icon="home" size="24" />
          <p>Home</p>
        </div>
      </NavLink>

      <NavLink to="/orders">
        <div className="icon-and-text">
          <FeatherIcon icon="package" size="24" />
          <p>Orders</p>
        </div>
      </NavLink>

      <NavLink to="/products">
        <div className="icon-and-text">
          <FeatherIcon icon="tag" size="24" />
          <p>Products</p>
        </div>
      </NavLink>

      <NavLink to="/settings">
        <div className="icon-and-text">
          <FeatherIcon icon="settings" size="24" />
          <p>Manage</p>
        </div>
      </NavLink>

      <hr className="separator" />

      <button type="button" onClick={logout}>
        <div className="icon-and-text">
          <FeatherIcon icon="log-out" size="24" />
          <p>Sign Out</p>
        </div>
      </button>

      <div className="spacing" />

      {/* {showPrompt && (
        <div className="pwaInstallPrompt">
          <div className="close" onClick={() => onClosePrompt()}>
            <FeatherIcon icon="x-square" size="24" />
          </div>
          <div className="content">
            <div className="title">Why not install the app?</div>
            <div className="message">
              Save time and hassle and install Doroki on your Homescreen for a
              better experience.
            </div>
            <div className="callToAction">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                className="action-button"
                onClick={installPWA}
              >
                Install now
              </motion.div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

const Wrapper = styled.div`
  .drawer-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #2B3C4542;
    z-index: 9999;
  }

  .drawer {
    position: fixed;
    width: 80%;
    max-width: 350px;
    height: 100%;
    z-index: 99999;
    background-color: #2B3C45;
    backdrop-filter: blur(15px);
    bottom: 0;
    left: 0;
    box-shadow: 9px 0px 10px #00000052;
    overflow-y: auto;
    .close-icon {
      height: 31px;
      fill: #ffffff75;
      position: absolute;
      top: 5px;
      right: 10px;
    }

    .top-section {
      padding: 20px;
      border-bottom: 1px dashed #ffffff50;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #ffffff;
      p {
        margin: 5px 0px 0px 0px;
        opacity: 0.5;
      }
    }

    .drawer-logo {
      height: 50px;
      .logo-fill {
        fill: #ffffff !important;
      }import useLogout from '../../@hooks/useLogout';

    }

    img {
      width: 48px;
      height: 45px;
    }
  }

  .drawer-list {
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    padding-top: 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;

    button {
      background: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }

    a,
    button {
      height: 48px;
      width: 210px;
      color: #ffffff;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 12px 0px 12px;
      margin: 0px 15px 0px 15px;
      border-radius: 8px;
      transition: 0.3s all ease;
      z-index: 10;
      box-sizing: border-box;
      transition: 0.5s all ease;
      text-decoration: none;
      outline: none;
      p {
        padding: none;
        margin: none;
        margin-left: 10px;
        font-size: 16px;
      }
      // svg {
      //   width: 24px;
      //   fill: #ffffff;
      // }
      &:hover {
        background-color: #70245920;
      }
      .icon-and-text {
        display: flex;
        align-items: center;
      }
      &:focus {
        background: transparent;
      }
      &.active {
        background-color: #ffffff30;
        // svg {
        //   fill: #ffffff;
        // }
      }
    }
  }

  .separator {
    margin-top: 10px;
    border: none;
    border-top: 1px dashed #ffffff50;
    width: 100%;
    height: 1px;
    stroke-width: 1;
  }

  .spacing {
    padding-bottom: 2rem;
  }

  .pwaInstallPrompt {
    margin: 0 auto;

    box-sizing: border-box;
    height: 237px;
    width: 280px;
    border: 1px solid rgba(255, 255, 255, 0.26);
    border-radius: 5px;
    background-color: #1a164e;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }

    .dismissible {
      height: 9.9px;
      width: 9.9px;
      opacity: 0.5;
      color: #ffffff;
      font-weight: 800;
      margin-left: 15rem;
      margin-bottom: 12px;
      cursor: pointer;
    }

    .close {
      display: flex;
      justify-content: flex-end;
      color: #fff;
      padding: 4px 12px;
      cursor: pointer;
    }

    .title {
      color: #ffffff;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 23px;
    }

    .message {
      text-align: center;
      opacity: 0.51;
      color: #ffffff;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 1rem;
    }

    .callToAction {
      text-align: center;
    }

    .action-button {
      padding: 0 12px;
    }
  }
`;

import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import FeatherIcon from "feather-icons-react";

import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function OrderListCard({ orders }) {
  const history = useHistory();

  const redirectRoute = url => {
    history.push(url);
  };

  return (
    <Wrapper>
      <div className="orders__table__wrapper">
        {orders.map(order => (
          <div className="orders__card" key={order.orderId}>
            <div className="orders__header">
              <span>
                {/* <span className="orderNo">{order.orderNumber}</span> */}
                <span className="orderDate">
                  {moment(order.orderDate).format("LLL")}
                </span>
              </span>

              <div className="orders__menu__container">
                <div className="menu">
                  <button className="ellipsis">
                    <FeatherIcon icon="more-horizontal" />
                  </button>
                  <ul>
                    <li>
                      <a
                        onMouseDown={() =>
                          redirectRoute(`/orders/view/${order.orderId}`)
                        }
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          <FeatherIcon icon="eye" size="18" className="icons" />
                          View
                        </span>
                      </a>
                    </li>

                    {order.paymentStatus === "PAID" ||
                    order.orderStatus === "CANCELLED" ? null : (
                      <li>
                        <a
                          onMouseDown={() =>
                            redirectRoute(`/orders/edit/${order.orderId}`)
                          }
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center"
                            }}
                          >
                            <FeatherIcon
                              icon="edit-2"
                              size="18"
                              className="icons"
                            />
                            Edit
                          </span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="orders__body">
              <span>
                {order.customer.firstName}&nbsp;{order.customer.lastName}
              </span>
            </div>

            <div className="orders__footer">
              {/* // Payment Status */}
              {order.paymentStatus === "PAID" && (
                <span className="paid">
                  <AmountWithSymbol amount={order.amount} />
                </span>
              )}

              {order.paymentStatus === "PENDING" && (
                <span className="unpaid">
                  <AmountWithSymbol amount={order.amount} />
                </span>
              )}

              {order.paymentStatus === "PROCESSING" && (
                <span className="processing">
                  <AmountWithSymbol amount={order.amount} />
                </span>
              )}

              {/* // Order Status */}
              {order.orderStatus === "SHIPPED" && (
                <span className="fulfilled">
                  <FeatherIcon icon="truck" />
                  Fullfilled
                </span>
              )}

              {order.orderStatus === "PENDING" && (
                <span className="pending">
                  <FeatherIcon icon="clock" />
                  Pending
                </span>
              )}

              {order.orderStatus === "EXPIRED" && (
                <span className="expired">
                  <FeatherIcon icon="clock" />
                  Expired
                </span>
              )}

              {order.orderStatus === "CANCELLED" && (
                <span className="cancelled">
                  <FeatherIcon icon="clock" />
                  Cancelled
                </span>
              )}

              {order.orderStatus === "INVALIDATED" && (
                <span className="cancelled">
                  <FeatherIcon icon="clock" />
                  Invalidated
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

OrderListCard.propTypes = {
  orders: PropTypes.array.isRequired
};

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: "Open Sans";
  margin: 0;
  padding: 0;
  width: 100%;

  .orders__table__wrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 5rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .orders__card {
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 12px;
    margin-bottom: 1rem;
  }

  .orders__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    padding: 5px;

    .orderNo {
      font-size: 14px;
      color: #2B3C45;
      margin-right: 5px;
    }

    .orderDate {
      font-size: 14px;
      color: #000;
    }

    .orders__menu__container {
      color: #2B3C45;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      position: relative;
      transition: box-shadow 0.4s ease;
    }

    .alias {
      position: relative;
      font-size: 22px;
    }

    .menu {
      position: relative;
    }

    .menu button.ellipsis {
      background: transparent;
      color: #2B3C45;
      text-decoration: none;
      border: none;
      outline: none;
      font-size: 22px;
      height: 1px !important;
      min-width: 0 !important;
      cursor: pointer;
    }

    .menu button.ellipsis:focus {
      outline: 0;
      box-shadow: none;
    }

    .menu button.ellipsis:active {
      outline: none;
      border: none;
    }

    .menu button.ellipsis:focus + ul {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }

    .menu ul {
      background: #ffffff;
      border-radius: 4px;
      position: absolute;
      top: 40px;
      right: 0;
      width: 150px;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
      opacity: 0;
      pointer-events: none;
      transform: translateY(-20px);
      transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

      z-index: 100;

      .icons {
        margin-right: 5px;
      }
    }

    .menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .menu li a {
      text-decoration: none;
      color: #2B3C45;
      font-weight: 500;
      padding: 15px 20px;
      display: block;
      font-size: 14px;

      svg {
        fill: #2B3C45 !important
        margin-right: 5px;
      }
    }

    .menu li:hover {
      background-color: #fafafa;
    }
  }

  .orders__body {
    padding: 8px;
    margin-bottom: 5px;
  }

  .orders__body span {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }

  .orders__footer {
    border-top: 2px solid #eceeef;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: #000;
    font-size: 14px;
  }

  .paid {
    display: flex;
    align-items: center;
    color: #4caf50;

    svg {
      margin-right: 6px;
    }
  }

  .unpaid {
    display: flex;
    align-items: center;
    color: #838E91;

    svg {
      margin-right: 6px;
    }
  }

  .processing {
    display: flex;
    align-items: center;
    color: #ffd600;

    svg {
      margin-right: 6px;
    }
  }

  .fulfilled {
    display: flex;
    align-items: center;
    color: #4caf50;

    svg {
      margin-right: 6px;
    }
  }

  .pending {
    display: flex;
    align-items: center;
    color: #838E91;

    svg {
      margin-right: 6px;
    }
  }

  .expired {
    display: flex;
    align-items: center;
    color: #ffd600;

    svg {
      margin-right: 6px;
    }
  }

  .cancelled {
    display: flex;
    align-items: center;
    color: #f44336;

    svg {
      margin-right: 6px;
    }
  }
`;

import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function ActionHeader({ title }) {
  return (
    <Wrapper>
      <div className="heading">
        <div className="heading_title">
          <h2>{title}</h2>
        </div>
      </div>
    </Wrapper>
  );
}

ActionHeader.propTypes = {
  title: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  .heading {
    z-index: 10;
    display: flex;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5em;
    min-height: 50px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;

    .heading_title {
      color: #2b3c45;
      margin-right: 1em;
    }

    .heading_button {
    }
  }
`;

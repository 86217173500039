import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import { useQuery, useQueryClient } from "react-query";
import { motion } from "framer-motion";
import helpers from "@utils/helpers";
import buttons from "@styles/buttons";
import Icons from "@sharedComponent/Icons";
import styled from "styled-components";
import SmallLoadingIndicator from "@sharedComponent/SmallLoadingIndicator";
import orderStyles from "../Orders.styles";
import { orderActions } from "../Orders.state";
import product from "@services/productService";
import { productActions } from "../../Products/Products.state";

import SelectProducts from "./SelectProducts";
import OrderItemsTable from "./OrderItemsTable";

export default function OrderItems() {
  const [page, setPage] = useState("");
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const storeId = localStorage.getItem(helpers.getStoreKeyName());
  const size = 10;

  const [showAddProductSelection, setShowAddProductSelection] = useState(true);

  const { isOrderItem, orderRequest } = useSelector(selectors.orders);

  const fetchProducts = useQuery(
    ["products", { storeId, page, size }],
    product.fetchProductsPerStore,
    {
      initialData: () => {
        const cachedProducts = queryClient.getQueryData("products");

        // If the query exists and has data that is no older than 1 day ...
        if (
          cachedProducts &&
          Date.now() - cachedProducts.dataUpdatedAt <= 86400
        ) {
          // return the products (in this case dispatch to redux store)
          dispatch(productActions.setProducts(cachedProducts));
        }
      },
      onSuccess: res => {
        dispatch(productActions.setProducts(res));
      }
    }
  );

  if (fetchProducts.isLoading && !fetchProducts.data) {
    return (
      <Wrapper>
        <div className="order-items">
          <section className="top-section">
            <h4>Order items</h4>
            <div className="button-nav">
              <SmallLoadingIndicator />
            </div>
          </section>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="order-items">
        <section className="top-section">
          <h4>Order items</h4>
          {showAddProductSelection ? (
            <div className="button-nav">
              <button
                type="button"
                onClick={() => {
                  setShowAddProductSelection(false);
                }}
                variant="small_outline"
              >
                Done
              </button>
            </div>
          ) : (
            <div className="button-nav">
              <button
                type="button"
                onClick={() => {
                  setShowAddProductSelection(true);
                }}
                variant="small"
              >
                Add product
              </button>
            </div>
          )}
        </section>

        {!showAddProductSelection &&
          (orderRequest.items.length > 0 ? (
            <OrderItemsTable />
          ) : (
            <section className="icon-and-message">
              <Icons.Empty />
              <p>No products added</p>
            </section>
          ))}

        {showAddProductSelection && <SelectProducts />}
      </div>

      {orderRequest.items.length > 0 && (
        <div className="submit-action-container">
          {isOrderItem ? (
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="action-button"
              onClick={() => dispatch(orderActions.setAddItemScreen())}
            >
              Continue
            </motion.button>
          ) : (
            <button type="button" status="disabled">
              Continue
            </button>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .order-items {
    padding: 25px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-top: 25px;
    min-height: 120px;

    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      h4 {
        ${orderStyles.title};
        color: #1e242d !important;
      }
    }

    .icon-and-message {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      svg {
        height: 40px;
        fill: #d4d4d4;
      }
      p {
        font-family: "Open Sans";
        font-size: 16px;
        color: #d4d4d4;
        margin-top: 10px;
      }
    }

    a {
      ${buttons};
      background-color: #2b3c45;
      text-align: center;
    }
  }

  .submit-action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
  }

  @media screen and (max-width: 620px) {
    .order-items {
      .top-section {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 18px;
        }
      }

      .button-nav {
        margin-top: 10px;
      }
    }
  }
`;

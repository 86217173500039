import { useState, useEffect } from "react";

const getBreakPoint = mediaWidth => {
  if (mediaWidth) {
    if (mediaWidth < 639) {
      return false;
    } else if (mediaWidth < 1024) {
      return false;
    } else if (mediaWidth < 1200) {
      return true;
    } else {
      return true;
    }
  } else {
    return undefined;
  }
};

const useDetectScreenSize = () => {
  // const [largeScreen, setLargeScreen] = useState(
  //   !(document.documentElement.clientWidth < 1025)
  // );

  // const breakpoints = {
  //   phoneSmall: 639,
  //   phone: 767,
  //   tablet: 1023,
  //   desktopSmall: 1199,
  //   desktopMedium: 1439,
  //   desktopLarge: 1799,
  //   desktopXlarge: 1919
  // };

  // const resizeHandler = () => {
  //   const width = document.documentElement.clientWidth;
  //   if (width < 1025) {
  //     setLargeScreen(false);
  //   } else {
  //     setLargeScreen(true);
  //   }
  // };

  const [largeScreen, setLargeScreen] = useState(
    getBreakPoint(document.documentElement.clientWidth)
  );

  const resizeHandler = () => {
    const width = document.documentElement.clientWidth;
    setLargeScreen(getBreakPoint(width));
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler, true);
    return () => {
      window.removeEventListener("resize", resizeHandler, true);
    };
  }, []);

  return { largeScreen };
};

export default useDetectScreenSize;

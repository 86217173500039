import React, { useState } from "react";
import styled from "styled-components";

export default function welcomepage() {
  return (
    <Wrapper>
      <header>
        <p>Welcome to your store</p>
      </header>
      <div className="main">
        <h2>Welcome Ibrahim Azeez</h2>
        <div className="get-started">
          <div>
            <h3>Get started</h3>
            <p>We need a few details from you before you can start selling</p>
          </div>
          <div className="get-started-btn">Let's get started</div>
        </div>
        <hr></hr>
        <p>You can also</p>
        <div className="product-card">
          <div>
            <h3 className="title">Add your products</h3>
            <p>Add your first product to manage your inventory or put online</p>
          </div>
          <div className="btn-container">Add products</div>
        </div>

        <div className="product-card setup">
          <div>
            <h3 className="title">Set up your online store</h3>
            <p>Claim your URL to set up your store and start selling online</p>
          </div>
          <div className="btn-container">Set up your store</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  header {
    height: 124px;
    fill: #ffffff;
    padding: 41px 0 47px 43px;

    p {
      font-family: Ubuntu;
      font-size: 2rem;
      color: #0f2531;
      text-decoration: none solid rgb(15, 37, 49);
    }
  }

  .main {
    padding: 0 41px;

    h2 {
      font-family: Ubuntu;
      font-size: 1.5rem;
      color: #8d8d8d;
      text-decoration: none solid rgb(141, 141, 141);
    }
    .get-started {
      display: flex;
      border: 1px solid #0f2531;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      padding: 31px;

      &-btn {
        width: 240px;
        height: 50px;
        border-radius: 5px;
        background-color: #0f2531;
        background-size: cover;
        font-family: Ubuntu;
        font-size: 16px;
        color: #ffffff;
        text-decoration: none solid rgb(255, 255, 255);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      hr {
        height: 1px;
        stroke-width: 1;
        margin-top: 53px;
      }
      h3 {
        font-size: 1.75rem;
      }
    }

    .product-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 31px;
      border: 1px solid #dadada;
      border-radius: 5px;
      fill: #ffffff;
      stroke-width: 1;
      height: auto;

      .btn-container {
        width: 160px;
        height: 40px;
        border: 2px solid #0f2531;
        border-radius: 5px;
        background-size: cover;
        font-family: Ubuntu;
        font-size: 16px;
        color: #0f2531;
        text-decoration: none solid rgb(15, 37, 49);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .setup {
      margin-top: 41px;
    }
  }

  @media screen and (max-width: 430px) {
    header {
      padding: 10px 0 10px 15px;
      height: auto;
    }
    .main {
      padding: 0 15px;
    }
    .get-started {
      flex-direction: column;
    }
    .product-card {
      flex-direction: column;
      height: auto;
    }
  }

  @media screen and (min-width: 1026px) {
    header {
      padding: 10px 0 10px 43px;
      height: auto;
    }
    .get-started {
      height: 120px;
    }
    .product-card {
      height: 120px !important;
    }
  }

  @media screen and (max-width: 650px) {
    header {
      padding: 10px 0 0 15px;
      height: auto;
    }
    .main {
      padding: 10px 21px;

      .get-started-btn {
        margin-left: 10px;
      }
    }
    .get-started {
      padding: 21px;
    }
    .product-card {
      height: auto;
    }
  }
`;

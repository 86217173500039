import React, { useState } from "react";
import { useField, Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import RadioButton from "./RadioButton";

const RadioInput = ({ gender, handleChange, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="input-and-error-wrapper">
      <div className="label">Gender</div>
      <label style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ marginRight: "8px", display: "flex", alignItems: "center" }}
        >
          <input value="F" checked={gender === "F"} {...props} />{" "}
          <div className="label">Female</div>
        </div>
        <div
          style={{ marginRight: "8px", display: "flex", alignItems: "center" }}
        >
          <input value="M" checked={gender === "M"} {...props} />{" "}
          <div className="label">Male</div>
        </div>
        <div>
          {meta.touched && meta.error ? (
            <p className="error">{meta.error}</p>
          ) : null}
        </div>
      </label>
    </div>
  );
};

export default RadioInput;

import axios from "axios";
import Querystring from "querystring";
import getDecodedToken from "./getDecodedToken";

const API_URL = process.env.REACT_APP_BACKEND_API_ENDPOINT;

export default {
  setBaseUrl: () => {
    /**
     * concatenates API_URL with api endpoints for all requests
     * */
    axios.defaults.baseURL = API_URL;
  },
  get: async apiEndPoint => {
    const response = await axios.get(apiEndPoint);
    return response;
  },
  post: async (apiEndPoint, requestBody) => {
    const response = await axios.post(apiEndPoint, requestBody);
    return response;
  },
  put: async (apiEndPoint, requestBody) => {
    const response = await axios.post(apiEndPoint, requestBody);
    return response;
  },
  delete: async apiEndPoint => {
    const response = await axios.delete(apiEndPoint);
    return response;
  },
  setHeader: (type, value) => {
    /**
     * Sets http headers for needs like adding a Bearer token and also
     * other headers when needed.
     */
    axios.defaults.headers.common[type] = value;
  },

  intercept: {
    authorize: () => {
      let refreshTokenCount = 0;
      axios.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          const originalRequest = error.config;

          if (error.response.status === 401) {
            if (refreshTokenCount >= 4) {
              localStorage.removeItem("token");
              window.location.replace("/login");
            }
            if (refreshTokenCount < 4) {
              // eslint-disable-next-line no-underscore-dangle
              originalRequest._retry = true;
              refreshTokenCount += 1;

              const tokenData = JSON.parse(localStorage.getItem("token"));
              const userData = getDecodedToken();

              const queryStringData = Querystring.stringify({
                grant_type: "refresh_token",
                user_id: userData.sub,
                refresh_token: tokenData.refresh_token
              });

              return axios
                .post("/token", queryStringData, {
                  "Content-Type": "application/x-www-form-urlencoded",
                  auth: {
                    username: process.env.REACT_APP_AUTH_USERNAME,
                    password: process.env.REACT_APP_AUTH_PASSWORD
                  }
                })
                .then(refreshTokenResponse => {
                  const token = refreshTokenResponse.data;
                  localStorage.setItem("token", JSON.stringify(token));
                  this.setHeader(
                    "Authorization",
                    `Bearer ${token.access_token}`
                  );
                  return axios(originalRequest);
                });
            }
          }
          return Promise.reject(error);
        }
      );
    },
    logger: () => {
      /** Logs every response to the console */
      axios.interceptors.response.use(
        function(response) {
          console.log(
            " +++++++++++++++ HTTP RESPONSE +++++++++++++++ ",
            response
          );
          return response;
        },
        function(error) {
          console.log(" -------------- HTTP ERROR --------------", error);
          throw error;
        }
      );
    }
  }
};

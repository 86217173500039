import React from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "configureStore";
import { Form, Formik } from "formik";
import { AnimatePresence } from "framer-motion";
import * as Yup from "yup";
import styled from "styled-components";

import { settlementActions } from "../Settlement.state";

import SmallLoadingIndicator from "@sharedComponent/SmallLoadingIndicator";
import {
  formInput,
  RenderInput,
  RenderSelect,
  inputContainer
} from "@sharedComponent/FormElements";
import notification from "@utils/notification";

import wallet from "@services/walletService";

const schema = Yup.object({
  bank: Yup.string().required("Please select a bank"),
  nuban: Yup.string().required("NUBAN is required")
});

export default function AddBankAccount({
  bankList,
  setShowReview,
  setSelectedBank
}) {
  const dispatch = useDispatch();

  const { walletId } = useSelector(selectors.app);

  /** check bank account name */
  const addBankAccount = useMutation(wallet.accountNameEnquiry, {
    onSuccess: res => {
      setShowReview(true);
      dispatch(settlementActions.setAccountInfo(res));
    },
    onError: err => {
      notification.createNotify({
        title: "Could not verify account at this time.",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const handleAccountNameEnquiry = values => {
    setSelectedBank(values.bank);
    addBankAccount.mutate(values);
  };

  const compare = (a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  };

  return (
    <Wrapper>
      <div className="subtitle">
        This will be added to your settlement accounts.
      </div>

      <div className="clear__fix" />

      <Formik
        initialValues={{
          bank: "",
          nuban: "",
          walletAccountId: walletId
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleAccountNameEnquiry(values);
        }}
      >
        <Form>
          <div className="input-container">
            <RenderSelect
              label="Select bank"
              name="bank"
              numericvalue={1}
              showemptyoptionfirst={1}
              options={bankList.sort(compare)}
              placeholder="Select bank"
            />
          </div>
          <div className="clear__fix" />

          <div className="nuban__wrapper__action">
            <div className="input-container">
              <RenderInput
                label="NUBAN"
                name="nuban"
                type="text"
                placeholder="10 digit NUBAN"
              />
            </div>
            <div className="submitAndErrorContainer">
              <button
                type="submit"
                className="btn-adjust"
                status={addBankAccount.isLoading ? "disabled" : ""}
              >
                {addBankAccount.isLoading ? "Checking..." : "Review"}
              </button>
              {addBankAccount.isLoading ? (
                <AnimatePresence>
                  <div className="loading-wrapper">
                    <SmallLoadingIndicator />
                  </div>
                </AnimatePresence>
              ) : null}
            </div>
          </div>
        </Form>
      </Formik>
      <div className="clear__fix" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  .title {
    font-size: 20px;
    color: #1e242d;
    font-weight: 600;
    padding: 0;
  }

  .formInput {
    ${formInput}
  }

  .nuban__wrapper__action {
    display: flex;
    flex: 1 1 auto;
    gap: 2%;
    flex-direction: row;
    align-items: center;
  }

  .input-container {
    ${inputContainer}
  }

  .input-container {
    width: 300px;
  }

  .btn-adjust {
    margin-top: 2em;
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 28px;
    p {
      color: #2b3c45;
      margin-left: 10px;
    }
  }

  .submitAndErrorContainer {
    display: flex;
    flex-direction: row !important;
  }
`;

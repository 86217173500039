import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import helpers from "@utils/helpers";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import { Tabs, Tab, Content } from "./Tab.styles";
import CopyUrl from "./CopyUrl";
import SendViaEmail from "./SendViaEmail";

import order from "@services/orderService";

export default function ShareInvoice({
  onClose,
  email,
  orderId,
  paymentStatus,
  nuban
}) {
  const history = useHistory();

  const [active, setActive] = useState(0);

  const { largeScreen } = useDetectScreenSize();

  /** Generate and fetch order invoice */
  const generateOrderInvoice = useQuery(
    ["generate_order_invoice", { orderId }],
    order.generateInvoice,
    {
      keepPreviousData: true
    }
  );

  const handleClick = e => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  const handlePreviewInvoice = url => {
    const invoiceRef = helpers.invoiceRefExtractor(url);

    history.push(`/invoice/${invoiceRef}`);
  };

  return (
    <Wrapper>
      {largeScreen && (
        <Tabs>
          <Tab onClick={handleClick} active={active === 0} id={0}>
            COPY URL
          </Tab>

          <Tab onClick={handleClick} active={active === 1} id={1}>
            EMAIL
          </Tab>
        </Tabs>
      )}

      <>
        <Content active={active === 0}>
          {generateOrderInvoice.isLoading ? (
            <div style={{ marginTop: "1em" }}>
              <Skeleton height={50} />
            </div>
          ) : !generateOrderInvoice.isLoading &&
            generateOrderInvoice.isError ? (
            <div className="alert alert-danger">
              <strong>Warning!</strong> Invoice service unreachable.
            </div>
          ) : paymentStatus === "PAID" && !generateOrderInvoice.data ? (
            <div className="alert alert-info">
              <strong>Update!</strong> Order has been marked as paid.
            </div>
          ) : generateOrderInvoice.data ? (
            <CopyUrl invoice={generateOrderInvoice} />
          ) : null}
        </Content>

        <Content active={active === 1}>
          {generateOrderInvoice.isLoading ? (
            <div style={{ marginTop: "1em" }}>
              <Skeleton height={50} />
            </div>
          ) : !generateOrderInvoice.isLoading &&
            generateOrderInvoice.isError ? (
            <div className="alert alert-danger">
              <strong>Warning!</strong> Invoice service unreachable.
            </div>
          ) : paymentStatus === "PAID" && !generateOrderInvoice.data ? (
            <div className="alert alert-info">
              <strong>Update!</strong> Order has been marked as paid.
            </div>
          ) : generateOrderInvoice.data ? (
            <SendViaEmail
              email={email}
              invoiceUrl={generateOrderInvoice.data.invoiceUrl}
              nuban={nuban}
            />
          ) : null}
        </Content>
      </>

      {!largeScreen && (
        <div className="tabbedButtons">
          <TabButton onClick={handleClick} active={active === 0} id={0}>
            <TabButtonText active={active === 0} id={0}>
              Share link
            </TabButtonText>
          </TabButton>

          <TabButton onClick={handleClick} active={active === 1} id={1}>
            <TabButtonText active={active === 1} id={1}>
              Send via email
            </TabButtonText>
          </TabButton>

          <TabButton>
            {generateOrderInvoice.data ? (
              <TabButtonText
                onClick={() =>
                  handlePreviewInvoice(generateOrderInvoice.data.invoiceUrl)
                }
              >
                Preview invoice
              </TabButtonText>
            ) : (
              <TabButtonText>Can't preview invoice now</TabButtonText>
            )}
          </TabButton>
        </div>
      )}
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;

  .tabbedButtons {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .alert {
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  .unreachable {
    color: #ff5252;
    fontweight: bold;
    margin-top: 2em;
  }
`;

const TabButton = styled.span`
  font-family: "Open Sans";
  font-size: 14px;
  border-radius: 30px;
  border: 2px solid #2b3c45;
  cursor: pointer;

  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${props => (props.active ? "#2B3C45" : "#ffffff")};
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: ${props => (props.active ? "#ffffff" : "#2B3C45")};
    color: ${props => (props.active ? "#2B3C45" : "#ffffff")};
  }

  &:focus {
    background-color: ${props => (props.active ? "#2B3C45" : "#ffffff")};
  }
`;

const TabButtonText = styled.span`
  font-family: "Open Sans";
  font-size: 14px;

  color: ${props => (props.active ? "#ffffff" : "#2B3C45")};

  &:hover {
    color: ${props => (props.active ? "#2B3C45" : "#ffffff")};
  }

  &:focus {
    color: ${props => (props.active ? "#ffffff" : "#2B3C45")};
  }
`;

import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import Card from "../Common/Card";

export default function Settings() {
  const listAnimation = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
  };

  // const { largeScreen } = useDetectScreenSize();

  return (
    <Container>
      {/* {largeScreen && (
        <div className="promptContainer">
          <div className="promptContent">
            <div className="promptText">
              <div className="promptCaption">Why not install the app?</div>
              <div className="promptMessage">
                Save time and hassle and install Doroki on your Homescreen for a
                better experience.
              </div>
            </div>
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              type="button"
              className="action-button"
            >
              Install now
            </motion.button>
          </div>
        </div>
      )} */}

      <div className="heading">
        <div className="title">Manage store</div>
      </div>

      <motion.div
        initial="hidden"
        animate="visible"
        variants={listAnimation}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="settings">
          <Card
            status="true"
            caption="Shipping"
            description="Add your store address and other pick-up locations."
            redirectLink="/settings/shipping"
          >
            <FeatherIcon icon="truck" size="48" color="#2B3C45" />
          </Card>

          <Card
            caption="Withdraw"
            description="Manage the account in which payments will be made."
            redirectLink="/settings/settlement"
          >
            <FeatherIcon icon="dollar-sign" size="48" color="#2B3C45" />
          </Card>

          {/* <Card
            caption="Account info"
            description="Edit email address, username and password"
            redirectLink="/settings/account-info"
          >
            <FeatherIcon icon="user" size="48" color="#2B3C45" />
          </Card> */}

          {/* <Card
            caption="Account Verification"
            description="Increase your transaction limit by setting up your BVN."
            redirectLink="/settings/bvn"
          >
            <FeatherIcon icon="shield" size="48" color="#2B3C45" />
          </Card> */}
        </div>
      </motion.div>
    </Container>
  );
}

const Container = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-family: Montserrat;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  .promptContainer {
    margin: 0;
    box-sizing: border-box;
    height: 85px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.26);
    background-color: #ffffff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);

    .promptContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
    }

    .promptText {
      display: flex;
      flex-direction: column;
    }

    .promptCaption {
      color: #2b3c45;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 23px;
    }

    .promptMessage {
      opacity: 0.51;
      color: #000000;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 1rem;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 38px;
    padding: 0 12px;
    margin-bottom: 48px;

    .title {
      font-size: 24px;
      font-weight: 600;
      color: #2b3c45;
    }

    .action {
      font-weight: 800;
      color: #2b3c45;
    }
  }

  .settings {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 12px;
    padding-bottom: 80px;
  }

  @media screen and (max-width: 1025px) {
    margin: 0;
  }

  @media screen and (max-width: 430px) {
    .settings {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      padding: 0;
      padding-bottom: 80px;
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import notification from "@utils/notification";
import { actions } from "@App/App.state";
import seller from "@services/sellerService";
import kyc from "@services/kycService";
import AuthWrapper from "../AuthWrapper";
import { getKycLevel } from "@services/kycService";
import FormWrapper from "../FormWrapper";

import CustomFormInput from "@sharedComponent/CustomFormInput";

export default function UpdateNumber() {
  const dispatch = useDispatch();
  const history = useHistory();

  const verifyPhoneNumberMutation = useMutation(
    kyc.initializePhoneVerification,
    {
      onSuccess: data => {
        console.log(data);
        const message = "success";
        //alert(message);
      },
      onError: err => {
        notification.createNotify({
          title: "Operation error",
          message: err.response.data.message || "Server error",
          type: "danger",
          position: "top-right"
        });
      }
    }
  );

  // const { isLoading, isError, data } = useQuery("kyclevel", getKycLevel, {
  //   onSuccess: () => {
  //     console.log("fetched data");
  //     console.log(data);
  //   },
  //   onError: () => {
  //     console.log("there is an error");
  //   }
  // });

  const handlePhoneSubmit = values => {
    const data = { ...values, validationType: "SMS" };
    verifyPhoneNumberMutation.mutate(data);
    history.push("/kyc");
  };

  return (
    <FormWrapper
      title="Verify phone number"
      subTitle="We’ll send you a verification code to make sure we can reach you on this number. The verified number will be attached to your Paga account."
    >
      <div className="signup-form-wrapper">
        <Formik
          initialValues={{
            newPhoneNumber: ""
          }}
          validationSchema={Yup.object({
            newPhoneNumber: Yup.number().required(
              "Phone number field cannot be empty"
            )
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handlePhoneSubmit(values);
          }}
        >
          <Form>
            <CustomFormInput
              type="number"
              placeholder="Enter a phone number to be verified"
              name="newPhoneNumber"
              id="phoneNumber"
              label="Phone number"
              data-testid="phoneNumber"
            />

            <div>
              <motion.input
                type="submit"
                value={
                  verifyPhoneNumberMutation.isLoading
                    ? "Just a moment..."
                    : "Create account"
                }
                className="form-buttons submit-button"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                data-testid="submit-form"
              />
            </div>
            {verifyPhoneNumberMutation.isError && (
              <p className="error-message">
                {verifyPhoneNumberMutation?.error?.response?.data?.message}
              </p>
            )}
          </Form>
        </Formik>
      </div>
    </FormWrapper>
  );
}

import React from "react";
import PropTypes from "prop-types";

export default function InvoiceDelivery({ address }) {
  return (
    <div className="invoiceDelivery">
      <div className="title">Delivery address</div>

      {address.pickupAddress && (
        <>
          <span>{address.pickupAddress.streetAddress}</span>
          <span>{address.pickupAddress.city}</span>
          <span>
            {address.pickupAddress.localGovernmentArea},{" "}
            {address.pickupAddress.region} State.
          </span>
        </>
      )}

      {address.deliveryAddress && (
        <>
          <span>
            {address.deliveryAddress.streetAddress},{" "}
            {address.deliveryAddress.city}
          </span>

          <span>
            {address.deliveryAddress.localGovernmentArea},{" "}
            {address.deliveryAddress.region} State.
          </span>
        </>
      )}
    </div>
  );
}

InvoiceDelivery.propTypes = {
  address: PropTypes.object.isRequired
};

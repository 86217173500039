import http from "./httpService";

const api = "/products";

export async function createProduct(payload) {
  const { files, ...productDetails } = payload; // remove image files

  const productDetailJson = JSON.stringify(productDetails);
  const productDetailBlob = new Blob([productDetailJson], {
    type: "application/json"
  });

  const fd = new FormData();

  payload.files.forEach(file => {
    fd.append("images", file);
  });

  fd.append("productDetails", productDetailBlob);

  const { data } = await http.post(api, fd, {
    "Content-Type": "multipart/form-data"
  });

  return data;
}

export async function updateProduct({ productId, ...payload }) {
  const { data } = await http.put(`${api}/${productId}`, payload, {
    "Content-Type": "application/json"
  });

  return data;
}

export async function fetchProductsPerStore({ queryKey }) {
  const [_key, { storeId, page, size }] = queryKey;

  const { data } = await http.get(
    `${api}?storeId=${storeId}&cursor=${page}&size=${size}`
  );

  return data;
}

export async function fetchProductInfo({ queryKey }) {
  const [_key, { productId }] = queryKey;

  const { data } = await http.get(`${api}/${productId}`);

  return data;
}

export async function updateProductImages(payload) {
  const fd = new FormData();

  payload.files.forEach(file => {
    fd.append("images", file);
  });

  const { data } = await http.put(`${api}/${payload.productId}/images`, fd, {
    "Content-Type": "multipart/form-data"
  });

  return data;
}

export async function deleteProduct(productId) {
  const { data } = await http.delete(`${api}/${productId}`);

  return data;
}

export default {
  createProduct,
  updateProduct,
  fetchProductsPerStore,
  fetchProductInfo,
  updateProductImages,
  deleteProduct
};

import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";

export default function ErrorState({ message }) {
  return (
    <Wrapper>
      <FeatherIcon icon="alert-triangle" size={60} color="#f9a825" />
      <h3>{message || "Error"}</h3>
    </Wrapper>
  );
}

ErrorState.propTypes = {
  message: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    margin: 5px 0 0 0;
    letter-spacing: 1px;
    opacity: 0.5;
    font-family: "Open Sans";
  }

  svg {
    margin-right: 10px;
  }
`;

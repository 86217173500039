import http from "./httpService";

const api = "/invoices";

/**
 * Get order details from invoice reference.
 *
 * @param {Query keys} param0
 * @returns
 */
export async function fetchOrderInvoiceDetails({ queryKey }) {
  const [, { invoiceRef }] = queryKey;

  const { data } = await http.get(`${api}/${invoiceRef}`);

  return data;
}

/**
 * Send invoice email.
 *
 * @param {general payload} payload
 * @returns
 */
export async function sendInvoiceEmail(payload) {
  const { data } = await http.post(`${api}/email`, payload, {
    "Content-Type": "application/json"
  });

  return data;
}

export default {
  fetchOrderInvoiceDetails,
  sendInvoiceEmail
};

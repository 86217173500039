import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import styled from "styled-components";

import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";
import InvoiceWrapper from "./InvoiceWrapper";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceItems from "./InvoiceItems";
import InvoiceDelivery from "./InvoiceDelivery";
import InvoicePayment from "./InvoicePayment";

import invoice from "@services/invoiceService";

export default function Invoice() {
  const params = useParams();

  const invoiceRef = params.id;

  /** Fetch order invoice details  */
  const { isLoading, isError, error, data } = useQuery(
    ["invoice_detail", { invoiceRef }],
    invoice.fetchOrderInvoiceDetails,
    {
      refetchOnWindowFocus: false
    }
  );

  if (isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message={error.message} />
        </SlideToTop>
      </Loader>
    );
  }

  if (isLoading && !data) {
    return null;
  }

  return (
    <InvoiceWrapper>
      <InvoiceHeader
        invoiceNumber={data.invoiceNumber}
        invoiceDate={data.createdDate}
      />
      <InvoiceDelivery address={data.metadata.order} /> <hr />
      <InvoicePayment
        publicKey={data.publicKey}
        amount={data.amount}
        email={data.customerEmail}
        paymentRef={data.paymentReference}
        businessName={data.businessName}
        nuban={data.metadata.order.dynamicNubanNumber}
        orderId={data.metadata.order.orderId}
      />
      <hr />
      <InvoiceItems orders={data.metadata.order} />
    </InvoiceWrapper>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

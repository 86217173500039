import React from "react";
import helpers from "@utils/helpers";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function AmountWithSymbol({ amount }) {
  return (
    <Wrapper>
      <span
        className="naira"
        style={{ color: "#2b3c45" }}
        dangerouslySetInnerHTML={{
          __html: helpers.formatAmountWithSymbol(amount)
        }}
      />
    </Wrapper>
  );
}

AmountWithSymbol.propTypes = {
  amount: PropTypes.number
};

const Wrapper = styled.div`
  font-family: "Open Sans";

  .naira {
  }
`;

import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import notification from "@utils/notification";

import { formInput, inputContainer } from "@sharedComponent/FormElements";

export default function CopyUrl({ invoice }) {
  const url = invoice.data.invoiceUrl;

  let link = null;

  const handlePreviewInvoice = () => {
    const url = invoice.data.invoiceUrl;
    const invoiceRef = url.split("/")[4];

    const win = window.open(`/invoice/${invoiceRef}`, "_blank");
    win.focus();
  };

  const copyToClipBoard = () => {
    link.select();
    navigator.clipboard
      .writeText(link.value)
      .then(() => {
        notification.createNotify({
          title: "Copied to clipboard",
          message: link.value,
          type: "success",
          position: "top-right"
        });
      })
      .catch(error => {
        notification.createNotify({
          title: "Failed to copy",
          message: error,
          type: "warning",
          position: "top-right"
        });
      });
  };

  return (
    <Wrapper>
      <div className="inputContainer">
        <label htmlFor="url">URL</label>
        <input
          className="formInput"
          label="URL"
          id="url"
          name="url"
          defaultValue={url}
          ref={ref => (link = ref)}
          type="text"
        />
      </div>

      <div className="footer">
        <span onClick={handlePreviewInvoice}>Preview invoice</span>
        <button
          type="submit"
          className="action-button"
          onClick={copyToClipBoard}
        >
          Copy link
        </button>
      </div>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;

  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;

  .formInput {
    ${formInput}
  }

  .inputContainer {
    margin-top: 1.8em;
    margin-bottom: 1.2em;
    ${inputContainer}
  }

  .form-button {
    display: flex;
    justify-content: flex-end;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      cursor: pointer;
      color: #2b3c45;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 384px) {
    .inputContainer {
      width: 100%;
    }
  }
`;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { motion } from "framer-motion";
import { selectors } from "configureStore";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import helpers from "@utils/helpers";
import notification from "@utils/notification";
import orderStyles from "../Orders.styles";
import { orderActions, handleAddCustomer } from "../Orders.state";
import customer from "@services/customerService";
import { customerActions } from "../../Customers/Customers.state";

import SearchCustomer from "./SearchCustomer";
import AddCustomerForm from "./AddCustomerForm";

const EmptyStateList = ({ message }) => {
  return <section className="empty-state-list">{message}</section>;
};

const addCustomerValidationSchema = Yup.object({
  emailAddress: Yup.string()
    .email()
    .required("Required"),
  phoneNumber: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required")
});

export default function CustomerInfo() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { isCustomer, orderRequest } = useSelector(selectors.orders);

  const [isShowCustomerForm, setShowCustomerForm] = useState(false);

  const businessId = localStorage.getItem(helpers.getBusinessKeyName());

  const fetchCustomers = useQuery("customers", customer.fetchCustomers, {
    onSuccess: res => {
      dispatch(customerActions.setCustomers(res));
    }
  });

  const saveCustomerMutation = useMutation(customer.createCustomer, {
    onMutate: newData => {
      // cancel query
      queryClient.cancelQueries("customers");

      // create a snapshot
      const previousCustomers = queryClient.getQueryData("customers");

      // optimistically update state
      queryClient.setQueryData("customers", prev => [
        ...prev,
        { ...newData, customerId: new Date().toISOString() }
      ]);

      // return snapshot
      return previousCustomers;
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData("customers", previousValue);

      notification.createNotify({
        title: "Operation error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    },
    onSuccess: newCustomer => {
      queryClient.invalidateQueries("customers");

      dispatch(
        handleAddCustomer({
          customerId: newCustomer.customerId,
          contactId: newCustomer.contactDetails[0].contactId,
          firstName: newCustomer.firstName,
          lastName: newCustomer.lastName,
          emailAddress: newCustomer.contactDetails[0].emailAddress,
          phoneNumber: newCustomer.contactDetails[0].phoneNumber
        })
      );

      dispatch(orderActions.setShippingScreen(true));
      setShowCustomerForm(true);
    }
  });

  const handleShowCustomerForm = value => {
    setShowCustomerForm(value);
  };

  const onSubmitCustomerForm = values => {
    if (Object.keys(orderRequest.customer).length === 0) {
      saveCustomerMutation.mutate({ ...values, businessId: businessId });
    } else {
      dispatch(orderActions.setShippingScreen(true));
      setShowCustomerForm(true);
    }
  };

  return (
    <Wrapper>
      {isCustomer ? (
        <>
          <Formik
            enableReinitialize
            initialValues={{
              emailAddress: orderRequest.customer.emailAddress || "",
              phoneNumber: orderRequest.customer.phoneNumber || "",
              firstName: orderRequest.customer.firstName || "",
              lastName: orderRequest.customer.lastName || ""
            }}
            validationSchema={addCustomerValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              onSubmitCustomerForm(values);
            }}
          >
            {({}) => (
              <Form>
                <div className="customer-info">
                  <section className="top-section">
                    <h4>Customer info</h4>
                  </section>

                  <SearchCustomer onShowCustomerForm={handleShowCustomerForm} />

                  {isShowCustomerForm && <hr className="divider" />}

                  {isShowCustomerForm && <AddCustomerForm />}
                </div>

                <div className="submit-action-container">
                  {isCustomer ? (
                    <motion.button
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.3 }}
                      type="submit"
                      className="action-button"
                    >
                      {saveCustomerMutation.isLoading
                        ? "Just a moment..."
                        : "Continue"}
                    </motion.button>
                  ) : (
                    <button type="button" status="disabled">
                      Continue
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <EmptyStateList message="Customer info" />
      )}
    </Wrapper>
  );
}

EmptyStateList.propTypes = {
  message: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  .customer-info {
    padding: 25px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-top: 25px;
    min-height: 120px;

    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      h4 {
        ${orderStyles.title};
        color: #1e242d !important;
      }
    }
  }

  .empty-state-list {
    margin: 16px 0px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    color: #d2d2d2;
    font-size: 20px;
    font-family: Montserrat;
    padding: 17px 30px;
    box-sizing: border-box;
  }

  .submit-action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
  }

  @media screen and (max-width: 620px) {
    .customer-info {
      .top-section {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 18px;
        }
      }

      .button-nav {
        margin-top: 10px;
      }
    }
  }
`;

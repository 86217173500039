import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import app from "@App/App.state";
import authentication from "@App/Auth/Login/login.state";
import settlement from "@App/Dashboard/Settings/Settlement/Settlement.state.js";
import orders from "@App/Dashboard/Orders/Orders.state.js";
import products from "@App/Dashboard/Products/Products.state.js";
import customers from "@App/Dashboard/Customers/Customers.state.js";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const allReducers = combineReducers({
  app,
  authentication,
  settlement,
  orders,
  products,
  customers
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["orders"]
};

const persistedReducer = persistReducer(persistConfig, allReducers);

export default createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export const selectors = {
  app: state => state.app,
  authentication: state => state.authentication,
  settlement: state => state.settlement,
  orders: state => state.orders,
  products: state => state.products,
  customers: state => state.customers
};

import React from "react";
import styled from "styled-components";

export default function CustomerAddress({ address, onChoose }) {
  return (
    <PickupContainer>
      <RadioButton>
        <input
          type="radio"
          name="addressOption"
          value={address.addressId}
          style={{ width: "22px", height: "22px" }}
          onChange={e => onChoose(e.currentTarget.value)}
        />
      </RadioButton>

      <Caption>{address.streetAddress}</Caption>

      <Note>Note: {address.remarks}</Note>
    </PickupContainer>
  );
}

const PickupContainer = styled.div`
  align-items: center;
  background-color: #ffffff;
  outline: 1px solid #ebebeb;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  padding: 20px;
  margin-bottom: 2rem;

  @media only screen and (max-width: 384px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const RadioButton = styled.span`
  width: 20px;
`;

const Caption = styled.span`
  color: #060606;
  font-size: 14px;
`;

const Note = styled.span`
  color: #606060;
  font-size: 14px;
`;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";

import useDetectScreenSize from "@hooks/useDetectScreenSize";
import Icons from "@sharedComponent/Icons";
import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

import {
  handleRemoveItemFromOrder,
  handleUpdateItemQty
} from "../Orders.state";

export default function OrderItemsTable() {
  const dispatch = useDispatch();

  const { largeScreen } = useDetectScreenSize();

  const { orderRequest } = useSelector(selectors.orders);

  const total =
    orderRequest.items.length > 0
      ? orderRequest.items.reduce(
          (accumulator, item) =>
            accumulator + (item.price ?? item.unitPrice) * item.quantity,
          0
        )
      : 0.0;

  const handleChangeQty = (value, item) => {
    const updatedItem = {
      productId: item.productId,
      name: item.name ?? item.productName,
      price: item.price ?? item.unitPrice,
      quantity: value
    };

    dispatch(handleUpdateItemQty(updatedItem));
  };

  const handleDeleteItem = productId => {
    dispatch(handleRemoveItemFromOrder(productId));
  };

  return (
    <Wrapper>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Items</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {orderRequest.items.map(item => (
            <tr key={item.productId}>
              <td>
                <span className="table-data">
                  {item.name ?? item.productName}
                </span>
              </td>
              <td>
                <span className="table-data">
                  <input
                    type="number"
                    name="qty"
                    min="1"
                    defaultValue={item.quantity}
                    onChange={e => handleChangeQty(e.target.value, item)}
                    className="qty-input"
                  />
                  <span className="delete-btn">
                    <Icons.Delete
                      className="icons"
                      role="button"
                      aria-hidden="true"
                      onClick={() => handleDeleteItem(item.productId)}
                    />
                  </span>
                </span>
              </td>
              <td>
                <AmountWithSymbol
                  amount={(item.price ?? item.unitPrice) * item.quantity}
                />
              </td>
            </tr>
          ))}

          <tr>
            <td>&nbsp;</td>
            <td>
              <span className="caption">
                <strong>Total</strong>
              </span>
            </td>
            <td>
              <span className="figure">
                <strong>
                  <AmountWithSymbol amount={total} />
                </strong>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: Open Sans;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 10px;

  .item-order-cost {
    display: flex;
    justify-content: flex-end;
    gap: 4em;
    padding-right: 21em;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.7rem;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    table-layout: fixed;
  }

  .table-data {
    color: #080808;
    font-size: 14px;
    font-family: Open Sans;
    display: flex;

    .qty-input {
      width: 52px;
      height: 32px;
      margin-right: 4px;
    }

    .delete-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .table th,
  .table td {
    padding: 0.7rem;
    text-align: left;
    border-bottom: 1px solid #eceeef;
    overflow: hidden;
  }

  .table thead th {
    font-size: 14px;
    font-weight: 500;
    vertical-align: bottom;
  }

  .table tbody + tbody {
    border-top: 2px solid #eceeef;
  }

  .table .table {
    background-color: #fff;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }

  @media only screen and (max-width: 650px) {
    .item-order-cost {
      display: flex;
      gap: 4em;
      padding-left: 20em;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0.7rem;
    }
  }

  @media only screen and (max-width: 384px) {
    .item-order-cost {
      display: flex;
      gap: 0;
      padding-right: 0;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0.7rem;
    }

    .table {
      display: block;
      width: 100%;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
`;

import React from "react";
import styled from "styled-components";

import BestSellers from "./BestSellers";
import PieChart from "./PieChart";

export default function Miscellanous({ loading, sales, isError }) {
  return (
    <Wrapper>
      <BestSellers loading={loading} reports={sales} isError={isError} />
      <PieChart loading={loading} reports={sales} isError={isError} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40%;

  @media screen and (max-width: 400px) {
    gap: 1.5em;
    width: 100%;
  }
`;

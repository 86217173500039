import React from "react";
import styled from "styled-components";
import AuthStyles from "@App/Auth/Auth.styles";
import { motion } from "framer-motion";
import Icons from "@sharedComponent/Icons";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

export default function FormWrapper({ title, subTitle, IconTitle, children }) {
  return (
    <Wrapper>
      <div className="form-container">
        <motion.div
          className="form-container-wrapper"
          initial={{ opacity: 0, height: "90%" }}
          animate={{ opacity: 1, height: "100%" }}
          transition={{ duration: 0.7 }}
        >
          <div className="form-title">
            {title && <h1>{title}</h1>}
            {subTitle && <p className="form-sub-title">{subTitle}</p>}
          </div>
          <div className="form-content">{children}</div>
        </motion.div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${AuthStyles}
  font-family: 'Roboto', sans-serif;
  font-style: normal;

  .form-container {
    width: 522px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    display: flex;
    justify-content: center;

    &-wrapper {
      width: 100%;

      .form-content {
        width: 100%;
        padding: 24px;
        @media screen and (max-width: 400px) {
          padding: 10px;
        }
      }
    }
    .label {
      font-size: 12px;
    }

    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }
  .signup-icon {
    display: flex;
    justify-content: center;
  }

  .forgot-password-link {
    position: absolute;
    margin-top: -8px;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0px;
  }

  .submitAndErrorContainer {
    margin-top: 40px;

    .submit-error {
      color: #da1a1a;
      font-size: 14px;
    }
  }

  .form-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #231f20;
    font-family: "Roboto";
    background: #f8f7f7;
    padding: 8px 24px;

    h1 {
      margin: 0px !important;
      padding: 0px !important;
      font-size: 18px;
    }
    @media screen and (max-width: 400px) {
      padding: 8px 10px;
    }
  }

  .form-bottom {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #aba0a3;
    margin-top: 1rem;

    a {
      color: #2b3c45;
    }
  }

  .form-sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #aba0a3;
    margin: 0;
  }

  @media screen and (max-width: 400px) {
    .submitAndErrorContainer {
    }
  }
`;

FormWrapper.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  IconTitle: PropTypes.string,
  children: PropTypes.node
};

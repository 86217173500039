import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "products",
  initialState: {
    products: [],
    productRequest: {},
    pageSize: 6,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "date", order: "desc" },
    isLoading: false,
    error: false
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProduct: (state, action) => {
      state.productRequest = action.payload;
    },
    clearProductRequest: state => {
      state.productRequest = {};
    }
  }
});

export default slice.reducer;

export const { ...productActions } = slice.actions;

export const handleLoadProductRequest = value => async dispatch => {
  dispatch(productActions.setProduct(value));
};

export const handleClearProductRequest = () => async dispatch => {
  dispatch(productActions.clearProductRequest());
};

import React from "react";
import styled from "styled-components";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import AddProduct from "./AddProduct/AddProduct";
import EditProduct from "./EditProduct/EditProduct";
import ProductsHome from "./ProductsHome";
import UpdateImage from "./UpdateImage";
import NotFoundPage from "@App/Error/NotFoundPage";

export default function Products() {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={`${path}`}>
          <ProductsHome />
        </Route>
        <Route exact path={`${path}/add`}>
          <AddProduct />
        </Route>
        <Route exact path={`${path}/edit/:id`}>
          <EditProduct />
        </Route>
        <Route exact path={`${path}/images/:id`}>
          <UpdateImage />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Container>
  );
}

const Container = styled.div`
  font-family: "Open Sans";
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .loading-wrapper {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1025px) {
    margin: 0;
  }
`;

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import styled from "styled-components";
import PropTypes from "prop-types";

import AmountWithSymbol from "@sharedComponent/AmountWithSymbol";

export default function ActionHeader({ title, action }) {
  const history = useHistory();

  const { wallet } = useSelector(selectors.settlement);

  return (
    <Wrapper>
      <div className="title">{title}</div>

      <div className="heading">
        <div className="withdrawal__options">
          <div className="withdrawal__funds">
            <div className="withdrawal__caption">Available Balance:</div>
            <div className="withdrawal__figure">
              <AmountWithSymbol amount={wallet.available_bal} />
            </div>
          </div>
          <div className="withdrawal__button">
            {action === "withdraw" ? (
              <button
                onClick={() =>
                  history.push("/settings/settlement/withdraw-fund")
                }
              >
                Withdraw funds
              </button>
            ) : (
              <button
                variant="cancel"
                onClick={() => history.push("/settings/settlement")}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

ActionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  font-size: 14px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
  z-index: 10;
  margin-bottom: 3em;

  .title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    color: #2b3c45;
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2em;
  }

  .withdrawal__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .withdrawal__funds {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .withdrawal__caption {
    font-size: 14px;
    color: #323232;
    margin-right: 5px;
  }

  .withdrawal__figure {
    font-size: 20px;
    font-weight: 500;
    color: #2b3c45;
    margin-right: 20px;
  }

  .withdrawal__button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media screen and (max-width: 400px) {
    .title {
      display: none;
    }
  }
`;

import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import Skeleton from "react-loading-skeleton";

import notification from "@utils/notification";
import ErrorState from "@sharedComponent/ErrorState";
import SlideToTop from "@sharedComponent/SlideToTop";
import ActionHeader from "./ActionHeader";
import CustomerInfoCard from "./CustomerInfoCard";
import DeliveryInfoCard from "./DeliveryInfoCard";
import PaymentInfoCard from "./PaymentInfoCard";
import ItemsTable from "./ItemsTable";
import Timeline from "./Timeline";

import order from "@services/orderService";

export default function OrderDetail() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const params = useParams();

  const [countRetry, setCountRetry] = useState(2);
  const [refetchInterval, setRefetchInterval] = useState(4000);

  const orderId = params.id;

  /** Fetch order detail with order id */
  const { isLoading, isError, error, data } = useQuery(
    ["order_detail", { orderId }],
    order.fetchOrderInfo
  );

  const dynamicNubanStatus = useQuery(
    ["nuban_status", { orderId }],
    order.checkNubanStatus,
    {
      // Refetch the data every second
      retry: 5,
      refetchInterval,
      onSuccess: res => {
        if (res.status === "FAILED") {
          setCountRetry(countRetry - 1);
        }

        if (res.status === "SUCCESSFUL") {
          setCountRetry(countRetry - 2);
          setRefetchInterval(false);
        } else if (res.status === "FAILED" && countRetry <= 0) {
          setRefetchInterval(false);
        }
      },
      onError: err => {
        setRefetchInterval(false);
      }
    }
  );

  /** Mark order as shipped */
  const markShippedMutation = useMutation(order.markAsShipped, {
    onSuccess: () => {
      queryClient.refetchQueries(["order_detail", { orderId }]);
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  /** Cancel order */
  const cancelOrderMutation = useMutation(order.cancelOrder, {
    onSuccess: () => {
      queryClient.refetchQueries(["order_detail", { orderId }]);

      notification.createNotify({
        title: "Success",
        message: "Order cancelled",
        type: "success",
        position: "top-right"
      });

      history.push("/orders");
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  /** Mark order as paid */
  const payOrderMutation = useMutation(order.payOrder, {
    onSuccess: () => {
      queryClient.refetchQueries(["order_detail", { orderId }]);

      notification.createNotify({
        title: "Success",
        message: "Order marked as paid",
        type: "success",
        position: "top-right"
      });
    },
    onError: err => {
      notification.createNotify({
        title: "Error!",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const goBack = () => {
    history.push("/orders");
  };

  if (isError) {
    return (
      <Loader>
        <SlideToTop>
          <ErrorState message={error.message} />
        </SlideToTop>
      </Loader>
    );
  }

  return (
    <Container>
      <div className="navigation" onClick={goBack}>
        <FeatherIcon icon="chevron-left" size="24" />
        Back
      </div>

      {isLoading && !data ? (
        <div className="heading">
          <Skeleton height={67} />
        </div>
      ) : (
        <ActionHeader
          loading={isLoading}
          title={`Order #${data.orderNumber}`}
          order={data}
          email={data?.contact?.emailAddress}
          markShippedMutation={markShippedMutation}
          cancelOrderMutation={cancelOrderMutation}
          payOrderMutation={payOrderMutation}
        />
      )}

      <div className="card-container">
        <PaymentInfoCard
          loading={isLoading}
          nuban={dynamicNubanStatus}
          order={data}
          retry={countRetry}
        />

        <CustomerInfoCard loading={isLoading} order={data} />

        <DeliveryInfoCard loading={isLoading} order={data} />
      </div>

      <div className="items-table--container">
        {isLoading && !data ? (
          <Skeleton height={30} count={9} />
        ) : (
          <ItemsTable order={data} />
        )}
      </div>

      <div className="timeline-container">
        <Timeline loading={isLoading} order={data} />
      </div>

      <div className="spacing" />
    </Container>
  );
}

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  font-family: "Open Sans";
  margin-top: 2rem;

  .heading {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    min-height: 70px;
    margin-bottom: 1rem;
    padding: 0 12px;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #2b3c45;
    font-weight: 500;
    padding: 0 12px;
    cursor: pointer;
    width: 100px;
    margin-bottom: 1em;

    svg {
      margin-right: 2px;
    }
  }

  .card-container {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .items-table--container {
    margin-left: 16px;
    margin-right: 16px;
    padding: 25px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-top: 25px;
  }

  .timeline-container {
    margin-left: 16px;
    margin-right: 16px;
    padding: 25px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-top: 25px;
  }

  .spacing {
    margin-top: 1rem;
    padding-bottom: 8rem;
  }

  @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
    .card-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 12px;
      width: 100%;
    }
  }
`;

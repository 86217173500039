import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { selectors } from "configureStore";
import { useQuery } from "react-query";
import useMedia from "use-media";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { useHistory, NavLink } from "react-router-dom";

import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import useLogout from "@hooks/useLogout";

import Icons from "@sharedComponent/Icons";
import NavigationDrawer from "./NavigationDrawer";
import { ShimmerEffect } from "@sharedComponent/ShimmerEffect";
import seller from "@services/sellerService";
import MyVerticallyCenteredModal from "@sharedComponent/BModal";

export default function TopNavigation() {
  const isLargeScreen = useMedia({ minWidth: "1030px" });
  const [showNavigationDrawer, setShowNavigationDrawer] = useState(false);

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();

  const logout = useLogout();

  const gotoPrivacyPolicy = () => {
    history.push("/privacy-policy");
  };

  const { sellerId } = useSelector(selectors.app);

  const fetchSellerReq = useQuery(
    ["fetch_seller_info", { sellerId }],
    seller.fetchSellerInfo
  );

  return (
    <>
      <Container>
        {!isLargeScreen && (
          // <FeatherIcon
          //   icon="menu"
          //   className="humburger-menu"
          //   size="32"
          //   fill="#0F2531"
          //   onClick={() => {
          //     setShowNavigationDrawer(true);
          //   }}
          // />

          <Icons.Menu
            className="humburger-menu"
            onClick={() => {
              setShowNavigationDrawer(true);
            }}
          />
        )}

        <div className="toolBar">
          <div className="leftSide">
            <Icons.DorokiBrandBlue />
          </div>
          <div className="tSpace" />
          <div className="rightSide">
            {fetchSellerReq.isLoading && (
              <>
                <ShimmerEffect width="150px" height="20px" borderRadius="5px" />
                <ShimmerEffect
                  width="100px"
                  height="20px"
                  borderRadius="5px"
                  margin="0px 0px 0px 10px"
                />
              </>
            )}

            {fetchSellerReq.data && (
              <>
                <div className="businessName">
                  {fetchSellerReq?.data?.businesses[0]?.stores[0]?.storeName ||
                    "No store name"}
                </div>
                <div className="menuContainer" onClick={onClick}>
                  <div className="alias">
                    {fetchSellerReq?.data?.firstName || "No firstname"}
                  </div>
                  <i className="icon-crousel-down carousel-down" />
                  <nav
                    ref={dropdownRef}
                    className={`menu ${isActive ? "active" : "inactive"}`}
                  >
                    <ul>
                      <li>
                        {/* eslint-disable-next-line */}
                        <a onClick={() => setModalShow(true)}>
                          <span style={{ display: "flex" }}>
                            {/* <FeatherIcon
                              icon="settings"
                              size="16"
                              className="menuIcons"
                            /> */}
                            My Settings
                          </span>
                        </a>
                        <a onClick={gotoPrivacyPolicy}>
                          <span style={{ display: "flex" }}>
                            {/* <FeatherIcon
                              icon="book-open"
                              size="16"
                              className="menuIcons"
                            /> */}
                            Privacy Policy
                          </span>
                        </a>
                        <a onClick={logout}>
                          <span style={{ display: "flex" }}>
                            {/* <FeatherIcon
                              icon="log-out"
                              size="16"
                              className="menuIcons"
                            /> */}
                            Logout
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </>
            )}
          </div>
          {/* <NavigationDrawer
          showNavigationDrawer={showNavigationDrawer}
          setShowNavigationDrawer={setShowNavigationDrawer}
        /> */}
        </div>
      </Container>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

const Container = styled.div`
  z-index: 12;
  width: 100%;
  height: 60px;
  position: fixed;
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 28px;
  font-family: Montserrat;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  background-color: #ffffff;
  border-bottom: 1px solid #e3e3e3;
  .toolBar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    padding: 0 12px;
    min-height: 50px;
    width: 100%;
    .leftSide {
      display: flex;
      align-items: center;
      svg {
        height: 31px;
      }
    }
    .tSpace {
      flex-grow: 1 !important;
    }
    .rightSide {
      font-size: 14px;
      display: flex;
      align-items: center;
      .businessName {
        padding: 0 12px;
        color: #2b3c45;
        font-weight: 500;
        margin-right: 30px;
      }
      #siteStatus {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f2921d;
        box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.2);
        margin-right: 7px;
      }
      .marginRight {
        margin-right: 15px;
      }
      i {
        margin-left: -7px;
        margin-top: -4px;
      }
      .menuContainer {
        color: #2b3c45;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        position: relative;
        transition: box-shadow 0.4s ease;
        .alias {
          margin-right: 10px;
        }
        .carousel-down {
          font-weight: 800;
        }
      }
      .menu {
        background: #ffffff;
        border-radius: 4px;
        position: absolute;
        top: 40px;
        right: 0;
        width: 200px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        .menuIcons {
          height: 16px;
          margin-right: 5px;
          position: relative;
          width: 16px;
          color: #0f2531;
        }
        span {
          font-family: Ubuntu;
          font-weight: 400;
          font-size: 16px;
        }
      }
      .menu.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      .menu li {
        //border-bottom: 1px solid #dddddd;
      }
      .menu li a {
        text-decoration: none;
        color: #2b3c45;
        font-weight: 500;
        padding: 15px 20px;
        display: block;
      }
      .menuBars {
        display: flex;
        flex-direction: column;
        span {
          width: 18px;
          height: 2px;
          margin-bottom: 3px;
          background: #2b3c45;
          position: relative;
          transform-origin: 4px 0px;
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        }
      }
    }
  }

  .humburger-menu {
    height: 24px;
    cursor: pointer;
  }

  @media screen and (max-width: 1025px) {
    display: flex;
    justify-content: space-between;
    padding: 0px 28px;
    .rightSide {
      display: none !important;
    }
    .leftSide {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
  @media screen and (max-width: 475px) {
    padding: 0px 7px;
  }
  @media screen and (max-width: 430px) {
    padding: 0px 15px;
    .marginRight {
      display: none;
    }
    .menuContainer .icon-crousel-down {
      display: none;
    }
    .menuBars {
      display: none !important;
    }
  }
`;

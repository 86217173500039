import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

export default function AddNewProductHeader({
  createProductMutation,
  type,
  handleSubmit
}) {
  const history = useHistory();

  const resizeHandler = () => {
    // console.log("resize listener 2");
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler, true);
    return () => {
      window.removeEventListener("resize", resizeHandler, true);
    };
  }, []);

  const onDiscardClick = () => {
    history.push("/products");
  };

  return (
    <Container>
      <div className="addProductLabel" data-testid="add-product">
        {type == "create" ? "Add product" : "Update product"}
      </div>

      <div className="rightSideHeader">
        <Button variant="outlined" className="preview-btn">
          Preview
        </Button>

        <Button
          className="addproduct-btn"
          variant="contained"
          startIcon={<AddIcon />}
          type="submit"
          onClick={handleSubmit}
          // href="/products/add"
        >
          Add Product
        </Button>
      </div>

      {/* <div className="rightSideHeader">
        <button variant="cancel" onClick={onDiscardClick}>
          Cancel
        </button>

        <button
          style={{ marginRight: "0px" }}
          type="submit"
          status={createProductMutation.isLoading ? "disabled" : ""}
        >
          {createProductMutation.isLoading
            ? type == "create"
              ? `Saving`
              : `Updating`
            : type == "create"
            ? "Save Product"
            : "Update Product"}
        </button>
      </div> */}
    </Container>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  font-size: 14px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 43px;
  box-sizing: border-box;
  z-index: 10;

  .addProductLabel {
    font-family: Ubuntu;
    font-size: 32px;
    color: #2b3c45;
    text-decoration: none solid rgb(0, 0, 0);
    font-weight: 500;
  }

  .rightSideHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    .addproduct-btn {
      width: 120px;
      height: 40px;
      border-radius: 5px;
      border: none;
      font-family: ubuntu;
      background: #2b3c45 !important;
      font-size: 10px;
      font-weight: normal;
      text-decoration: none solid rgb(255, 255, 255);
      text-align: center;
      text-transform: lowercase !important;

      &:hover {
        color: #ffffff;
      }

      font-size: 14px;
      text-decoration: none solid rgb(255, 255, 255);
      text-align: center;
      text-transform: lowercase !important;
      font-size: 10px;
      font-weight: normal;
      text-decoration: none solid rgb(255, 255, 255);
      text-align: center;
      text-transform: lowercase !important;

      &:hover {
        color: #ffffff;
      }
    }

    .preview-btn {
      margin-right: 43px;
      width: 120px;
      height: 40px;
      border: 2px solid #0f2531;
      border-radius: 5px;
      background-color: #ffffff;
      background-size: cover;
      font-family: Ubuntu;
      font-size: 14px;
      color: #0f2531;
      text-decoration: none solid rgb(15, 37, 49);
      text-align: center;
      text-transform: lowercase;
    }
  }

  .marginLeft {
    margin-left: 30px;
  }

  .blue {
    background-color: #2b3c45 !important;
    color: #ffffff !important;
  }

  @media screen and (max-width: 760px) {
    .addProductLabel {
      display: none;
    }

    .rightSideHeader {
      width: 100%;
    }

    .header {
      justify-content: center;
    }

    .dropDownContainer {
      margin-left: -17px;
      select {
        margin-left: 0px;
      }
    }
  }
`;

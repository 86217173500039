import { css } from "styled-components";

export default css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e3e3e3;

  .invoiceContainer {
    margin-top: 3em;
    height: auto;
    width: 674px;
    background-color: #fafafa;
    padding: 25px 50px;
    box-sizing: border-box;
    box-shadow: 1px 2px 2px #e3e3e3;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    color: #1e242d;
  }

  .invoiceAdvert {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    color: #060606;
    font-size: 14px;
    line-height: 22px;
    // width: 400px;
    margin-top: 1em;
    margin-bottom: 8em;

    svg {
      width: 80px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .caption {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .address {
    font-size: 14px;
    line-height: 20px;
  }

  .subCaption {
    font-weight: 300;
  }

  .lineBreak {
    border: 1px solid #eaeaea;
    width: 100%;
    margin: 0;
  }

  hr {
    margin: 15px 0px 15px 0px;
    border: none;
    border-top: 1px solid #eaeaea;
    color: #121212;
    background-color: #fff;
    width: 100%;
    height: 1px;
    stroke-width: 1;
  }

  .invoiceLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    svg {
      width: 72px;
    }
  }

  .invoiceHeaderSection {
    color: #2f2f2f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .invoiceFormat {
      display: flex;
      flex-direction: column;
    }
  }

  .invoiceIdentitySection {
    display: flex;
    flex-direction: column;
  }

  .invoiceOrderItems {
    display: flex;
    flex-direction: column;
  }

  .shippingPrice {
    font-size: 14px;
    font-weight: 300;
    color: #606060 !important;
  }

  .totalPrice {
    font-size: 14px;
    font-weight: 600;
    color: #1e242d !important;
  }

  .costBreakDown {
    display: flex;
    flex-direction: column;

    font-size: 14px;

    .shippingCost {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 1.8;
      font-size: 14px;
      font-weight: 300;
      color: #606060;
    }

    .totalCost {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 1.8;
      font-size: 14px;
      font-weight: 600;
      color: #1e242d;
    }
  }

  .invoiceDelivery {
    color: #060606;
    display: flex;
    flex-direction: column;

    width: 300px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8;
  }

  .InvoicePayment {
    color: #060606;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8;

    .bankInfo {
      font-weight: 600;
    }

    .italic {
      font-size: 12px;
      font-style: italic;
      margin-top: 0.5em;
      margin-bottom: 1em;
    }

    .payment {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cta {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .orText {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        margin-right: 1em;
      }

      .partners {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
      }

      svg {
        width: 40px;
      }
    }
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .table-data {
    color: #080808;
    font-size: 14px;
    font-family: Open Sans;
    display: flex;

    .qty-input {
      width: 52px;
      height: 32px;
      margin-right: 4px;
    }

    .delete-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .table th,
  .table td {
    padding: 0.7rem;
    text-align: left;
    border-bottom: 1px solid #eceeef;
  }

  .table thead th {
    font-size: 14px;
    font-weight: 500;
    vertical-align: bottom;
  }

  .table tbody + tbody {
    border-top: 2px solid #eceeef;
  }

  .table .table {
    background-color: #fff;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive.table-bordered {
    border: 0;
  }

  @media only screen and (max-width: 400px) {
    .invoiceContainer {
      width: 100%;
    }

    .InvoicePayment {
      .payment {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "configureStore";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import PropTypes from "prop-types";
import styled from "styled-components";
import orderStyles from "../Orders.styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import helpers from "@utils/helpers";
import { orderActions } from "../Orders.state";
import notification from "@utils/notification";
import customer from "@services/customerService";
import store from "@services/storeService";
import order from "@services/orderService";

import SetShippingAddress from "./SetShippingAddress";

const EmptyStateList = ({ message }) => {
  return <section className="empty-state-list">{message}</section>;
};

const shippingValidationSchema = Yup.object({
  shippingType: Yup.string().required("Required"),
  shippingFee: Yup.number().required("Required")
});

export default function DeliveryInfo() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isShipping, orderRequest } = useSelector(selectors.orders);

  const customerId = orderRequest.customer.customerId;

  const storeId = localStorage.getItem(helpers.getStoreKeyName());

  // fetch customer addresses
  const fetchCustomerAddress = useQuery(
    ["customer_address", { customerId }],
    customer.fetchCustomerAddress
  );

  // fetch store pickup addresses
  const fetchStorePickUpAddress = useQuery(
    ["store_pickup_address", { storeId }],
    store.fetchStorePickUpAddress
  );

  // place order
  const placeOrderMutation = useMutation(order.placeOrder, {
    onSuccess: res => {
      dispatch(orderActions.clearOrderRequest()); // clear from state

      notification.success("Order created", "Your order has been placed");
      history.push(`/orders/view/${res.orderId}`);
    },
    onError: err => {
      notification.createNotify({
        title: "Error placing order",
        message: err.response.data.message || "Server error",
        type: "danger",
        position: "top-right"
      });
    }
  });

  const onSubmitShippingForm = values => {
    const payload = {
      orderItems: [...orderRequest.items].map(obj => ({
        productId: obj.productId,
        quantity: obj.quantity
      })),
      customerId: orderRequest.customer.customerId,
      contactId: orderRequest.customer.contactId,
      storeId: storeId,
      shippingLocationId: orderRequest.shipping.shippingAddressId,
      shippingType: values.shippingType,
      shippingFee: values.shippingFee
    };

    placeOrderMutation.mutate(payload);
  };

  return (
    <Wrapper>
      {isShipping && fetchCustomerAddress.data ? (
        <>
          <Formik
            initialValues={{
              shippingType: "",
              shippingFee: ""
            }}
            validationSchema={shippingValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              onSubmitShippingForm(values);
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="delivery-info">
                  <section className="top-section">
                    <h4>Shipping info</h4>
                  </section>

                  <SetShippingAddress
                    setFieldValue={setFieldValue}
                    customerAddresses={fetchCustomerAddress.data}
                    pickUpAddresses={fetchStorePickUpAddress.data}
                  />
                </div>
                <div className="submit-action-container">
                  {isShipping ? (
                    <button
                      type="submit"
                      status={placeOrderMutation.isLoading ? "disabled" : ""}
                    >
                      {placeOrderMutation.isLoading
                        ? "Just a moment..."
                        : " Create order"}
                    </button>
                  ) : (
                    <button type="button" status="disabled">
                      Continue
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <EmptyStateList message="Delivery info" />
      )}
    </Wrapper>
  );
}

EmptyStateList.propTypes = {
  message: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  margin-bottom: 10rem;

  .delivery-info {
    padding: 25px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-top: 25px;
    min-height: 120px;

    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      h4 {
        ${orderStyles.title};
        color: #1e242d !important;
      }
    }
  }

  .empty-state-list {
    margin: 16px 0px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    color: #d2d2d2;
    font-size: 20px;
    font-family: Montserrat;
    padding: 17px 30px;
    box-sizing: border-box;
  }

  .submit-action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
  }

  @media screen and (max-width: 620px) {
    .delivery-info {
      .top-section {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 18px;
        }
      }

      .button-nav {
        margin-top: 10px;
      }
    }
  }
`;

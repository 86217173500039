import React from "react";
import styled from "styled-components";

import PickupInfo from "./PickupInfo";
import ActionHeader from "./Common/ActionHeader";

export default function Shipping() {
  return (
    <Container>
      <ActionHeader title="Shipping settings" />

      <div className="card-container">
        <div className="cards">
          <div className="card-item">
            <div className="card-row">
              <PickupInfo />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  width: 100%;

  .spacing {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .clear {
    bottom: 12rem;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .cards {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 2rem;
    width: 100%;
  }

  .card-item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ebebeb;
    overflow: hidden;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
  }

  .card-row {
    display: inline-flex;
    gap: 12em;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 28px 18px 14px 32px;
    line-height: 1.5em;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 12px;
  }

  .btn-spacing {
    margin-bottom: 6rem;
  }

  @media screen and (max-width: 1025px) {
    margin: 0;
  }

  @media screen and (max-width: 384px) {
    .card-item {
      max-width: 384px;
      width: 100%;
    }
  }
`;

import React from "react";
import styled from "styled-components";
import InvoiceStyles from "./Invoice.styles";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import Icons from "@sharedComponent/Icons";

export default function InvoiceWrapper({ children }) {
  return (
    <Wrapper>
      <motion.div
        className="invoiceContainer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7 }}
      >
        {children}
      </motion.div>

      <div className="invoiceAdvert">
        Powered by <Icons.DorokiLogoGrey />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${InvoiceStyles}
`;

InvoiceWrapper.propTypes = {
  children: PropTypes.node
};
